import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const updateLitterWeight = createAsyncThunk(
  'litterProfile/updateLitterWeight',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const res = await axios.post(`/pets/api/v1/pets/litters/health`, payload)
      return res.data.data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updateLitterWeight
