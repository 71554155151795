import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

const LineChart = (props) => {
  const { chartId, data, colorSet } = props
  let chart

  useEffect(() => {
    if (data) {
      if (chart && chart._super) {
        chart.dispose()
      }
      initChart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const initChart = () => {
    //All of our work goes here now
    am4core.useTheme(am4themesAnimated)

    chart = am4core.create(chartId, am4charts.XYChart)
    chart.paddingRight = 20

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.minGridDistance = 50
    dateAxis.renderer.grid.template.location = 0.5
    dateAxis.startLocation = 0.5
    dateAxis.endLocation = 0.5

    // Y Axis
    const valueYAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueYAxis.title.text = 'Weight'
    // Create value axis

    // Set chart color set
    chart.colors.list = colorSet.map((color) => am4core.color(color))

    for (let i = 0; i < data.length; i++) {
      // Create series
      const series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'value'
      series.dataFields.dateX = 'date'
      series.strokeWidth = 3
      // series.tensionX = 0.8;
      series.bullets.push(new am4charts.CircleBullet())
      series.tooltipText = '{dateX}: [b]{valueY}'
      series.data = data[i]
    }

    chart.cursor = new am4charts.XYCursor()
  }

  return <div id={chartId} className='line-graph' />
}

export default LineChart
