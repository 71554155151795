import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'

const FormErrorMessage = ({ error }) => {
  return <span className='item-error'>{get(error, 'message', '')}</span>
}

FormErrorMessage.propTypes = {
  error: PropTypes.object,
}

export default React.memo(FormErrorMessage)
