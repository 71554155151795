import React from 'react'

export function processLink(link = '') {
  if (link.startsWith('https://') || link.startsWith('http://')) {
    return link
  }

  return `//${link}`
}

export function inputToDatabase(input = '') {
  input = input ? input : ''
  if (isNaN(input)) {
    return input
      .replace(/<script>.*<\/\s*script>/g, '')
      .replace(/<[^>]*>/g, '')
      .replace(/(\r\n|\n|\r)/gm, '\n')
  } else {
    return input
  }
}

export function textToHtml(text) {
  return (
    <p>
      {text.split('\n').map((text, index) => (
        <React.Fragment key={`${text}-${index}`}>
          {text}
          <br />
        </React.Fragment>
      ))}
    </p>
  )
}

export function textToHtmlText(text) {
  if (!text) {
    return ''
  }
  return text.replace(/\n/g, '<br>')
}

export function formattedSingleParagraph(text) {
  const data = inputToDatabase(text)
  return isNaN(data)
    ? data.replace(/\s*$/g, '').replace(/^\s*/g, '').replace(/\s+/g, ' ')
    : data
}

export function formattedMultiParagraph(text) {
  const data = inputToDatabase(text)
  return isNaN(data)
    ? data
        .replace(/^\s*/g, '')
        .replace(/\s*$/g, '')
        .replace(/ +/g, ' ')
        .replace(/\n+/g, '\n\n')
    : data
}

export function formattedNoWhiteSpace(text) {
  const data = inputToDatabase(text)
  return isNaN(data) ? data.replace(/\s+/g, '') : data
}

export function toProperCase(text) {
  return text[0].toUpperCase() + text.slice(1).toLowerCase()
}
