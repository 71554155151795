import { createSlice } from '@reduxjs/toolkit'
import appFollow from './appFollow'
import appUnfollow from './appUnfollow'
import deletePost from './deletePost'
import loadLightboxPost from './loadLightboxPost'
import deleteUserProfileField from './deleteUserProfileField'
import fetchUserAvailablePets from './fetchUserAvailablePets'
import userAnswerToReservation from './userAnswerToReservation'
import userConfirmPetOffer from './userConfirmPetOffer'
import userWithDrawPetOffer from './userWithDrawPetOffer'
import fetchBreederTestimonials from './fetchBreederTestimonials'
import hideBreederTestimonials from './hideBreederTestimonials'

const initialState = {
  lightbox: {
    isShown: false,
    activeImage: -1,
    content: {
      s3Id: '',
      image: '',
      post: {},
    },
    isShowPost: false,
    imageSource: [],
  },
  focusedPhoto: {
    isShown: false,
    activeImage: -1,
    imageSource: [],
  },
}

const mainSlice = createSlice({
  name: 'mainReducer',
  initialState,
  reducers: {
    openLightbox: (state, { payload }) => {
      const { url, s3Id, imageSource, imageIdx } = payload
      state.lightbox = {
        ...state.lightbox,
        isShown: true,
        content: {
          s3Id: s3Id,
          image: url,
          post: {},
        },
        activeImage: imageIdx,
        isShowPost: false,
        imageSource: imageSource ? imageSource : state.lightbox.imageSource,
      }
    },
    closeLightbox: (state) => {
      state.lightbox = {
        isShown: false,
        content: {
          image: '',
          post: {},
        },
        activeImage: -1,
        isShowPost: false,
        imageSource: [],
      }
    },
    showLightboxPost: (state) => {
      state.lightbox.isShowPost = true
    },
    hideLightboxPost: (state) => {
      state.lightbox.isShowPost = false
    },
    likeLightboxComment: (state, { payload }) => {
      const { like, commentId } = payload
      state.lightbox = {
        ...state.lightbox,
        content: {
          ...state.lightbox.content,
          post: {
            ...state.lightbox.content.post,
            comments: state.lightbox.content.post.comments.map((cmt) => {
              if (cmt.id === commentId) {
                return {
                  ...cmt,
                  likes: cmt.likes ? cmt.likes.concat([like]) : [like],
                }
              } else {
                return cmt
              }
            }),
          },
        },
      }
    },
    unlikeLightboxComment: (state, { payload }) => {
      const { commentId, likeId } = payload
      state.lightbox = {
        ...state.lightbox,
        content: {
          ...state.lightbox.content,
          post: {
            ...state.lightbox.content.post,
            comments: state.lightbox.content.post.comments.map((cmt) => {
              if (cmt.id === commentId) {
                return {
                  ...cmt,
                  likes: cmt.likes.filter((like) => like.id !== likeId),
                }
              } else {
                return cmt
              }
            }),
          },
        },
      }
    },
    unlikeLightboxPost: (state, { payload }) => {
      state.lightbox = {
        ...state.lightbox,
        content: {
          ...state.lightbox.content,
          post: {
            ...state.lightbox.content.post,
            likes: state.lightbox.content.post.likes.filter(
              (like) => like.id !== payload,
            ),
          },
        },
      }
    },
    openFocusedPhoto: (state, { payload }) => {
      const { imageIdx, imageSource } = payload
      state.focusedPhoto = {
        ...state.focusedPhoto,
        activeImage: imageIdx,
        imageSource,
        isShown: true,
      }
    },
    closeFocusedPhoto: (state) => {
      state.focusedPhoto = {
        ...state.focusedPhoto,
        activeImage: -1,
        imageSource: [],
        isShown: false,
      }
    },
    navigateFocusedPhoto: (state, { payload }) => {
      state.focusedPhoto = {
        ...state.focusedPhoto,
        activeImage: state.focusedPhoto.activeImage + payload,
      }
    },
  },
  extraReducers: {
    [loadLightboxPost.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.lightbox.content.post = payload
      }
    },
  },
})

const { reducer, actions } = mainSlice

export {
  appFollow,
  appUnfollow,
  deletePost,
  loadLightboxPost,
  deleteUserProfileField,
  fetchUserAvailablePets,
  userAnswerToReservation,
  userConfirmPetOffer,
  userWithDrawPetOffer,
  fetchBreederTestimonials,
  hideBreederTestimonials,
}

export const {
  openLightbox,
  closeLightbox,
  showLightboxPost,
  hideLightboxPost,
  likeLightboxComment,
  unlikeLightboxComment,
  unlikeLightboxPost,
  openFocusedPhoto,
  closeFocusedPhoto,
  navigateFocusedPhoto,
} = actions

export default reducer
