const fi = {
  ad: 'Andorra',
  ae: 'Arabiemiirikunnat',
  af: 'Afganistan',
  ag: 'Antigua ja Barbuda',
  ai: 'Anguilla',
  al: 'Albania',
  am: 'Armenia',
  ao: 'Angola',
  ar: 'Argentiina',
  as: 'Amerikan Samoa',
  at: 'Itävalta',
  au: 'Australia',
  aw: 'Aruba',
  ax: 'Ahvenanmaa',
  az: 'Azerbaidžan',
  ba: 'Bosnia ja Hertsegovina',
  bb: 'Barbados',
  bd: 'Bangladesh',
  be: 'Belgia',
  bf: 'Burkina Faso',
  bg: 'Bulgaria',
  bh: 'Bahrain',
  bi: 'Burundi',
  bj: 'Benin',
  bm: 'Bermuda',
  bn: 'Brunei',
  bo: 'Bolivia',
  br: 'Brasilia',
  bs: 'Bahama',
  bt: 'Bhutan',
  bv: 'Bouvet’nsaari',
  bw: 'Botswana',
  by: 'Valko-Venäjä',
  bz: 'Belize',
  ca: 'Kanada',
  cc: 'Kookossaaret',
  cd: 'Kongo (Kongo-Kinshasa)',
  cf: 'Keski-Afrikan tasavalta',
  cg: 'Kongo (Kongo-Brazzaville)',
  ch: 'Sveitsi',
  ci: 'Norsunluurannikko',
  ck: 'Cookinsaaret',
  cl: 'Chile',
  cm: 'Kamerun',
  cn: 'Kiina',
  co: 'Kolumbia',
  cr: 'Costa Rica',
  cu: 'Kuuba',
  cv: 'Kap Verde',
  cx: 'Joulusaari',
  cy: 'Kypros',
  cz: 'Tšekki',
  de: 'Saksa',
  dj: 'Djibouti',
  dk: 'Tanska',
  dm: 'Dominica',
  do: 'Dominikaaninen tasavalta',
  dz: 'Algeria',
  ec: 'Ecuador',
  ee: 'Viro',
  eg: 'Egypti',
  eh: 'Länsi-Sahara',
  er: 'Eritrea',
  es: 'Espanja',
  et: 'Etiopia',
  fi: 'Suomi',
  fj: 'Fidži',
  fk: 'Falklandinsaaret',
  fm: 'Mikronesia',
  fo: 'Färsaaret',
  fr: 'Ranska',
  ga: 'Gabon',
  gb: 'Britannia',
  gd: 'Grenada',
  ge: 'Georgia',
  gf: 'Ranskan Guayana',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gl: 'Grönlanti',
  gm: 'Gambia',
  gn: 'Guinea',
  gp: 'Guadeloupe',
  gq: 'Päiväntasaajan Guinea',
  gr: 'Kreikka',
  gs: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
  gt: 'Guatemala',
  gu: 'Guam',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  hk: 'Hongkong',
  hm: 'Heard ja McDonaldinsaaret',
  hn: 'Honduras',
  hr: 'Kroatia',
  ht: 'Haiti',
  hu: 'Unkari',
  id: 'Indonesia',
  ie: 'Irlanti',
  il: 'Israel',
  in: 'Intia',
  io: 'Brittiläinen Intian valtameren alue',
  iq: 'Irak',
  ir: 'Iran',
  is: 'Islanti',
  it: 'Italia',
  jm: 'Jamaika',
  jo: 'Jordania',
  jp: 'Japani',
  ke: 'Kenia',
  kg: 'Kirgisia',
  kh: 'Kambodža',
  ki: 'Kiribati',
  km: 'Komorit',
  kn: 'Saint Kitts ja Nevis',
  kp: 'Korean demokraattinen kansantasavalta (Pohjois-Korea)',
  kr: 'Korean tasavalta (Etelä-Korea)',
  kw: 'Kuwait',
  ky: 'Caymansaaret',
  kz: 'Kazakstan',
  la: 'Laos',
  lb: 'Libanon',
  lc: 'Saint Lucia',
  li: 'Liechtenstein',
  lk: 'Sri Lanka',
  lr: 'Liberia',
  ls: 'Lesotho',
  lt: 'Liettua',
  lu: 'Luxemburg',
  lv: 'Latvia',
  ly: 'Libya',
  ma: 'Marokko',
  mc: 'Monaco',
  md: 'Moldova',
  me: 'Montenegro',
  mg: 'Madagaskar',
  mh: 'Marshallinsaaret',
  mk: 'Makedonia',
  ml: 'Mali',
  mm: 'Myanmar',
  mn: 'Mongolia',
  mo: 'Macao',
  mp: 'Pohjois-Mariaanit',
  mq: 'Martinique',
  mr: 'Mauritania',
  ms: 'Montserrat',
  mt: 'Malta',
  mu: 'Mauritius',
  mv: 'Malediivit',
  mw: 'Malawi',
  mx: 'Meksiko',
  my: 'Malesia',
  mz: 'Mosambik',
  na: 'Namibia',
  nc: 'Uusi-Kaledonia',
  ne: 'Niger',
  nf: 'Norfolkinsaari',
  ng: 'Nigeria',
  ni: 'Nicaragua',
  nl: 'Alankomaat',
  no: 'Norja',
  np: 'Nepal',
  nr: 'Nauru',
  nu: 'Niue',
  nz: 'Uusi-Seelanti',
  om: 'Oman',
  pa: 'Panama',
  pe: 'Peru',
  pf: 'Ranskan Polynesia',
  pg: 'Papua-Uusi-Guinea',
  ph: 'Filippiinit',
  pk: 'Pakistan',
  pl: 'Puola',
  pm: 'Saint-Pierre ja Miquelon',
  pn: 'Pitcairn',
  pr: 'Puerto Rico',
  ps: 'Palestiinan valtio',
  pt: 'Portugali',
  pw: 'Palau',
  py: 'Paraguay',
  qa: 'Qatar',
  re: 'Réunion',
  ro: 'Romania',
  rs: 'Serbia',
  ru: 'Venäjä',
  rw: 'Ruanda',
  sa: 'Saudi-Arabia',
  sb: 'Salomonsaaret',
  sc: 'Seychellit',
  sd: 'Sudan',
  se: 'Ruotsi',
  sg: 'Singapore',
  sh: 'Saint Helena,  Ascension ja Tristan da Cunha',
  si: 'Slovenia',
  sj: 'Svalbard ja Jan Mayen',
  sk: 'Slovakia',
  sl: 'Sierra Leone',
  sm: 'San Marino',
  sn: 'Senegal',
  so: 'Somalia',
  sr: 'Suriname',
  st: 'São Tomé ja Príncipe',
  sv: 'El Salvador',
  sy: 'Syyria',
  sz: 'Swazimaa',
  tc: 'Turks- ja Caicossaaret',
  td: 'Tšad',
  tf: 'Ranskan eteläiset alueet',
  tg: 'Togo',
  th: 'Thaimaa',
  tj: 'Tadžikistan',
  tk: 'Tokelau',
  tl: 'Itä-Timor',
  tm: 'Turkmenistan',
  tn: 'Tunisia',
  to: 'Tonga',
  tr: 'Turkki',
  tt: 'Trinidad ja Tobago',
  tv: 'Tuvalu',
  tw: 'Taiwan',
  tz: 'Tansania',
  ua: 'Ukraina',
  ug: 'Uganda',
  um: 'Yhdysvaltain pienet erillissaaret',
  us: 'Yhdysvallat (USA)',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  va: 'Vatikaani',
  vc: 'Saint Vincent ja Grenadiinit',
  ve: 'Venezuela',
  vg: 'Brittiläiset Neitsytsaaret',
  vi: 'Yhdysvaltain Neitsytsaaret',
  vn: 'Vietnam',
  vu: 'Vanuatu',
  wf: 'Wallis ja Futuna',
  ws: 'Samoa',
  ye: 'Jemen',
  yt: 'Mayotte',
  za: 'Etelä-Afrikka',
  zm: 'Sambia',
  zw: 'Zimbabwe',
}

export default fi
