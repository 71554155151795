import { createAsyncThunk } from '@reduxjs/toolkit'

const updatePetAvailability = createAsyncThunk(
  'litterProfile/updatePetAvailability',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default updatePetAvailability
