import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const updatePetWeight = createAsyncThunk(
  'petProfile/updatePetWeight',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const res = await axios.put(`/pets/api/v1/pets/health/${payload.petId}`, {
        weight: payload.weight,
        dateWeight: payload.dateWeight,
      })
      return res.data.data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updatePetWeight
