import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const fetchCompanyAbout = createAsyncThunk(
  'companyProfile/fetchCompanyAbout',
  async (companyId, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`/api/v1/companies/about/${companyId}`)
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default fetchCompanyAbout
