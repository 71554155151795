import React from 'react'
import * as styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const Footer = (props) => {
  const { t, i18n } = useTranslation()
  const { isLogin } = props
  const date = new Date()
  let year = date.getFullYear()
  return (
    <div
      className={`${styles[`footer`]} ${isLogin ? styles[`footer-login`] : ''}`}
    >
      <span>
        <a
          target='_blank'
          href={`https://pezzie.com/terms2-${i18n.language}.html`}
          className={styles[`link`]}
          rel='noreferrer'
        >
          {t('common:Terms of Service and Privacy Policy')}
        </a>
      </span>
      <span>© {year} Pezzie Ltd Oy</span>
    </div>
  )
}
export default Footer
