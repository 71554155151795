import {
  FORM_MODE,
  countries as constCountries,
  urlValidation,
} from 'commons/constants'
import { addResource, removeResource } from 'commons/utils/i18next'
import {
  FormImagePicker,
  FormInput,
  FormSelect,
  FormTextArea,
} from 'components/commonFields'
import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Form } from 'semantic-ui-react'
import { companyForm, countries } from 'translations'
import {
  companySizeOptions,
  industryOptions,
  organizationTypeOptions,
  sectorOptions,
} from './data'
import * as styles from './styles.module.scss'

const namespace = 'companyForm'

const CompanyProfileForm = ({
  mode,
  onSubmit,
  onCancel,
  companyProfile,
  readOnly,
}) => {
  const createMode = mode === FORM_MODE.CREATE

  const { t, i18n } = useTranslation(namespace)

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
  })

  const industry = watch('industry')

  const [isAddedResource, setIsAddedResource] = useState(false)
  const [checkTNC, setCheckTNC] = useState(false)

  useEffect(() => {
    addResource(i18n, { [namespace]: companyForm })
    addResource(i18n, { [namespace]: countries })
    setIsAddedResource(true)
    return () => {
      removeResource(i18n, { [namespace]: companyForm })
      removeResource(i18n, { [namespace]: countries })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyProfile) {
      const { avatar, background, id, userId, ...defaultValues } =
        companyProfile
      reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile])

  const countryOptions = useMemo(
    () =>
      constCountries.map((country) => ({
        key: country,
        value: country,
        text: t(country),
        flag: country,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, i18n, isAddedResource],
  )

  if (!isAddedResource) {
    return null
  }

  const parseOptions = (input) => {
    if (!input) {
      return []
    }
    return input.map((value) => ({
      key: value,
      text: t(value),
      value,
    }))
  }

  return (
    <div className={styles[`company-profile-form`]}>
      <Form className='form-wrap'>
        {createMode && (
          <FormImagePicker
            control={control}
            name='profileS3Id'
            error={get(errors, 'profileS3Id')}
          />
        )}
        <FormInput
          control={control}
          name='name'
          error={get(errors, 'name')}
          label={t('Company name')}
          placeholder={t('Company name')}
          rules={{
            required: t('profileForm:Content is required in field'),
          }}
          readOnly={readOnly}
        />
        <FormSelect
          control={control}
          name='organizationType'
          label={t('Organization type')}
          placeholder={`${t('Organization type')}*`}
          rules={{
            required: t('profileForm:Content is required in field'),
          }}
          error={get(errors, 'organizationType')}
          options={parseOptions(organizationTypeOptions)}
          readOnly={readOnly}
        />
        <FormSelect
          control={control}
          name='industry'
          label={t('Select industry')}
          placeholder={`${t('Select industry')}*`}
          rules={{
            required: t('profileForm:Content is required in field'),
          }}
          error={get(errors, 'industry')}
          options={parseOptions(industryOptions)}
          onChange={() => setValue('natureOfBusinesses', [])}
          readOnly={readOnly}
        />
        <FormSelect
          control={control}
          name='natureOfBusinesses'
          label={t('Business sector')}
          placeholder={`${t('Business sector')}*`}
          rules={{
            required: t('profileForm:Content is required in field'),
          }}
          error={get(errors, 'natureOfBusinesses')}
          options={parseOptions(sectorOptions[industry])}
          search
          multiple
          readOnly={readOnly}
        />
        <FormSelect
          control={control}
          name='companySize'
          label={t('Company size')}
          placeholder={`${t('Company size')}*`}
          rules={{
            required: t('profileForm:Content is required in field'),
          }}
          error={get(errors, 'companySize')}
          options={companySizeOptions.map((value) => ({
            key: value,
            value,
            text: value.replace('employees', t('employees')),
          }))}
          readOnly={readOnly}
        />
        <FormSelect
          control={control}
          name='location'
          rules={{
            required: t('profileForm:Content is required in field'),
          }}
          error={get(errors, 'location')}
          label={t('Country')}
          placeholder={t('Country')}
          readOnly={readOnly}
          search
          options={countryOptions}
        />
        <FormInput
          control={control}
          name='companyIdentifier'
          error={get(errors, 'companyIdentifier')}
          label={t('Company ID')}
          placeholder={`${t('Company ID')}`}
          readOnly={readOnly}
        />
        <FormInput
          control={control}
          name='vatNumber'
          error={get(errors, 'vatNumber')}
          label={t('Company VAT number')}
          placeholder={`${t('Company VAT number')}`}
          readOnly={readOnly}
        />
        <FormInput
          control={control}
          name='websiteUrl'
          error={get(errors, 'websiteUrl')}
          label={t('Website URL')}
          placeholder={`${t('Website URL')}`}
          rules={{
            validate: urlValidation,
          }}
          readOnly={readOnly}
        />
        <FormInput
          control={control}
          name='headquartersLocation'
          label={t('Headquarters location')}
          placeholder={t('Headquarters location')}
          readOnly={readOnly}
        />
        <FormInput
          control={control}
          name='brands'
          label={t('Brands')}
          placeholder={t('Brands')}
          readOnly={readOnly}
        />
        <FormTextArea
          control={control}
          name='bio'
          error={get(errors, 'bio')}
          label={t('Description')}
          placeholder={t('Here you can tell more about your organization')}
          readOnly={readOnly}
        />
        {createMode && (
          <div className={styles.tnc}>
            <Checkbox onChange={(_event, data) => setCheckTNC(data.checked)} />
            <div>
              <span>
                {t(
                  `I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages.`,
                )}
              </span>
              <p>
                <a
                  href='https://pezzie.com/terms2-en.html'
                  target='_blank'
                  rel='noreferrer'
                >
                  <strong>
                    {t('Read the Pezzie terms of Service and Privacy Policy')}
                  </strong>
                </a>
              </p>
            </div>
          </div>
        )}
        {!readOnly && (
          <div
            className={`${styles['button-wrap']} ${
              createMode ? styles['button-wrap__create'] : ''
            }`}
          >
            {!!onCancel && (
              <Button basic color='white' onClick={onCancel}>
                {t('profileForm:Cancel')}
              </Button>
            )}
            <Button
              primary
              onClick={handleSubmit(onSubmit)}
              disabled={createMode && !checkTNC}
            >
              {t(createMode ? 'profileForm:Create' : 'common:Save')}
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

export default React.memo(CompanyProfileForm)
