import { RATING_MAPPING } from 'commons/constants'
import { addMessageToUser } from 'commons/slice'
import find from 'lodash/find'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import service from 'services/likePost'

export const useRatings = (post) => {
  const dispatch = useDispatch()

  const { currentUser } = useSelector((state) => state.appReducer)
  const { id } = currentUser

  const [liked, setLiked] = useState(false)
  const [likedAmount, setLikeAmount] = useState(0)
  const [likedList, setLikedList] = useState([])

  useEffect(() => {
    if (!post) {
      return
    }
    const like = find(
      get(post, 'ratings'),
      (item) => item.ratingType === RATING_MAPPING.LIKE,
    )
    if (!like) {
      return
    }
    const { amount, identifiers } = like
    setLikeAmount(amount)
    setLikedList(identifiers)
    setLiked(identifiers.some((user) => user.id === id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post])

  const onLike = async () => {
    try {
      await service.likePost({
        userId: id,
        postsId: post.id,
        ratingType: RATING_MAPPING.LIKE,
      })
      setLikeAmount(likedAmount + 1)
      setLikedList([currentUser, ...likedList])
      setLiked(true)
    } catch (error) {
      dispatch(addMessageToUser(error))
    }
  }

  const onDisLike = async (postsId) => {
    try {
      await service.likePost({
        userId: id,
        postsId: post.id,
        ratingType: 'DISLIKE',
      })
      setLikeAmount(likedAmount - 1)
      setLikedList(likedList.filter((user) => user.id !== currentUser.id))
      setLiked(false)
    } catch (error) {
      dispatch(addMessageToUser(error))
    }
  }

  return {
    liked,
    likedAmount,
    onLike,
    onDisLike,
    likedList,
  }
}
