import React from 'react'
import * as styles from './message_to_user.module.scss'
import { Modal } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { removeMessageToUser } from '../../slice'
import { useTranslation } from 'react-i18next'

const Message = () => {
  const { t } = useTranslation()
  const message = useSelector((state) => state.appReducer?.message)
  const dispatch = useDispatch()
  return (
    <div className={styles[`message`]}>
      <Modal
        closeIcon
        onClose={() => dispatch(removeMessageToUser())}
        content={
          <div className={styles[`message-detail`]}>
            <p>{t(`message:${message}`)}</p>
          </div>
        }
        open={message !== ''}
      />
    </div>
  )
}
export default Message
