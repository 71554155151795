import { createSlice } from '@reduxjs/toolkit'
import fetchFollowing from './fetchFollowing'
import follow from './follow'
import unFollow from './unFollow'
import fetchFollowers from './fetchFollowers'
import fetchFollowRequests from './fetchFollowRequests'
import fetchSuggestFollow from './fetchSuggestFollow'
import search from './search'
import acceptRequest from './acceptRequest'
import cancelRequest from './cancelRequest'
import invitePeople from './invitePeople'
import { suggestionReducerMapping } from '../constant'

const initialState = {
  totalRequests: 0,
  totalFollowing: 0,
  followings: [],
  items: [],
  feedback: null,
  suggestions: {
    litters: {
      data: [],
      page: 1,
      isFetchEnd: false,
    },
    users: {
      data: [],
      page: 1,
      isFetchEnd: false,
    },
    pets: {
      data: [],
      page: 1,
      isFetchEnd: false,
    },
  },
  totalFollowers: 0,
  followers: [],
  requests: [],
  followingOffset: 0,
  followerOffset: 0,
  requestOffset: 0,
  isFollowingFetchEnd: false,
  isFollowingFetching: false,
  isFollowerFetchEnd: false,
  isFollowerFetching: false,
  isFollowerRequestFetchEnd: false,
  isFollowerRequestFetching: false,
}

const followingSlice = createSlice({
  name: 'following',
  initialState,
  reducers: {
    resetState: (state) => {
      return { ...initialState, totalRequests: state.totalRequests }
    },
    tooShortSearchString: (state) => {
      state.followings = []
      state.feedback = 'Search string must be at least three characters long.'
    },
  },
  extraReducers: {
    [fetchFollowing.pending]: (state) => {
      state.isFollowingFetching = true
    },
    [fetchFollowing.fulfilled]: (state, { payload }) => {
      const { records, offset, limit, totalRecords } = payload
      state.isFollowingFetching = false
      state.followings = state.followings.concat(records)
      state.followingOffset = offset + limit
      state.isFollowingFetchEnd = offset + limit >= totalRecords
      state.feedback = !state.followings.length ? 'No results.' : null
      state.totalFollowing = totalRecords
    },
    [fetchFollowing.rejected]: (state) => {
      state.isFollowingFetching = false
    },
    [follow.fulfilled]: (state) => {
      state.followingOffset = 0
      state.petSuggestionOffset = 0
      state.userSuggestionOffset = 0
    },
    [unFollow.fulfilled]: (state, { payload }) => {
      state.followings = state.followings.filter(
        (fl) => fl.followId !== payload.followId,
      )
      state.totalFollowing--
      state.followingOffset =
        state.followingOffset > 0
          ? state.followingOffset - 1
          : state.followingOffset
    },
    [fetchFollowers.pending]: (state) => {
      state.isFollowerFetching = true
    },
    [fetchFollowers.fulfilled]: (state, { payload }) => {
      const { records, offset, limit, totalRecords } = payload
      state.isFollowerFetching = false
      state.isFollowerFetchEnd = offset + limit >= totalRecords
      state.followerOffset = offset + limit
      state.followers = state.followers.concat(records)
      state.totalFollowers = totalRecords
    },
    [fetchFollowers.rejected]: (state) => {
      state.isFollowerFetching = false
    },
    [fetchFollowRequests.pending]: (state) => {
      state.isFollowerRequestFetching = true
    },
    [fetchFollowRequests.fulfilled]: (state, { payload }) => {
      const { records, offset, limit, totalRecords } = payload
      state.isFollowerRequestFetching = false
      state.isFollowerRequestFetchEnd = offset + limit >= totalRecords
      state.requestOffset = offset + limit
      state.requests = offset === 0 ? records : state.requests.concat(records)
      state.totalRequests = totalRecords
    },
    [fetchFollowRequests.reject]: (state) => {
      state.isFollowerRequestFetching = false
    },
    [fetchSuggestFollow.fulfilled]: (state, { payload }) => {
      const { result, type, page } = payload
      if (type === 'ALL') {
        Object.keys(state.suggestions).forEach((suggest) => {
          state.suggestions[suggest].data = result[suggest]
          state.suggestions[suggest].page = 2
        })
      } else {
        const suggestState = state.suggestions[suggestionReducerMapping[type]]
        const suggestResult = result[suggestionReducerMapping[type]]
        if (!suggestResult.length) {
          suggestState.isFetchEnd = true
        } else {
          suggestState.data = page
            ? suggestResult
            : suggestState.data.concat(suggestResult)
          if (!page) {
            suggestState.page += 1
          }
        }
      }
    },
    [search.fulfilled]: (state, { payload: items }) => {
      const { followings } = state
      const filtered = items.filter((item) => {
        for (let i = 0; i < followings.length; i++) {
          if (followings[i].target.id === item.id) {
            return false
          }
        }
        return true
      })
      state.items = filtered
      state.feedback = filtered.length === 0 ? 'No results.' : null
    },
    [search.rejected]: (state, { payload }) => {
      state.followings = []
      state.feedback = payload
    },
    [acceptRequest.fulfilled]: (state, { payload }) => {
      state.requestOffset--
      state.requests.filter((item) => item.id !== payload)
    },
    [cancelRequest.fulfilled]: (state, { payload }) => {
      state.requestOffset--
      state.requests.filter((item) => item.id !== payload)
    },
  },
})

export {
  fetchFollowing,
  follow,
  unFollow,
  fetchFollowers,
  fetchFollowRequests,
  fetchSuggestFollow,
  search,
  acceptRequest,
  cancelRequest,
  invitePeople,
}

const { actions, reducer } = followingSlice

export const { resetState, tooShortSearchString } = actions

export default reducer
