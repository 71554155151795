import { createSlice } from '@reduxjs/toolkit'
import getLitterChildren from './getLitterChildren'
import deleteOffspring from './deleteOffspring'

const initialState = {
  isFetching: false,
  isFetchEnded: false,
  data: [],
  offset: 0,
  error: '',
}

const slice = createSlice({
  name: 'litterChildrenReducer',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: {
    [getLitterChildren.pending]: (state) => {
      state.isFetching = true
      state.error = ''
    },
    [getLitterChildren.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.data = state.data.concat(payload.records)
      state.offset += payload.limit
      state.isFetchEnded =
        payload.offset + payload.limit >= payload.totalRecords
    },
    [getLitterChildren.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload
    },
    [deleteOffspring.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload)
      state.offset -= 1
    },
  },
})

const { actions, reducer: litterChildrenReducer } = slice

export const { resetState } = actions

export { getLitterChildren, deleteOffspring }

export default litterChildrenReducer
