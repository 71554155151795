import { translation } from 'commons/resources/dynamic_configs/index'
import PetPlaceholder from 'commons/resources/icons//default_Pet Blue 174px.svg'
import * as spaceStyles from 'commons/scss/space.module.scss'
import * as textStyles from 'commons/scss/text.module.scss'
import { addMessageToUser } from 'commons/slice'
import { ERROR_INVALID_PRICE } from 'commons/utils/error_messages_var'
import { addResource, removeResource } from 'commons/utils/i18next'
import { toProperCase } from 'commons/utils/sanitize'
import i18n from 'i18next'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'semantic-ui-react'
import {
  ActionButton,
  Avatar,
  Name,
  TextWithLink,
  TransferOffer,
} from '../index'
import ReservationStatus from './ReservationStatus'
import * as styles from './reservation_tab.module.scss'

const ReservationTab = (props) => {
  const [isSetPriceShown, setIsSetPriceShown] = useState(false)
  const [price, setPrice] = useState('')
  const [pet, setPet] = useState({})

  const id = Math.random().toString(36).substr(2, 9)
  const namespace = 'ReservationTab-' + id
  const resources = {
    [namespace]: translation['pet']['form'],
  }

  const { t } = useTranslation()

  const {
    pets,
    offers,
    currentUser,
    isFromBreederProfile,
    onFetchReservationTab,
    onConfirmPetOffer,
  } = props

  useEffect(() => {
    if (onFetchReservationTab) {
      onFetchReservationTab()
    }
    addResource(i18n, resources)
    return () => {
      if (namespace) {
        const resources = {
          [namespace]: t,
        }
        removeResource(i18n, resources)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tr = (text) => {
    return t ? t(namespace + ':' + text) : text
  }

  const toggleAvailability = (avail, pet) => {
    let availability = pet.availability
    if (avail === 'unavailable') {
      availability = []
    } else {
      if (!availability) {
        availability = []
      }
      if (availability.includes(avail)) {
        availability = availability.filter(function (value) {
          return value !== avail
        })
      } else {
        availability.push(avail)
      }
    }
    return availability
  }

  const onUpdateAvailability = (avail, pet) => {
    if (
      avail === 'forSales' &&
      (!pet.availability || !pet.availability.includes(avail))
    ) {
      setIsSetPriceShown(true)
      setPet(pet)
      return
    }

    onUpdateAvailability(pet.id, toggleAvailability(avail, pet), pet.price)
  }

  const onSetPrice = (e) => {
    e.preventDefault()

    if (isNaN(price)) {
      addMessageToUser(ERROR_INVALID_PRICE)
      setIsSetPriceShown(false)
      setPrice('')
      setPet({})
      return
    }

    props.onSetPrice(pet.id, toggleAvailability('forSales', pet), price)

    setIsSetPriceShown(false)
    setPrice('')
    setPet({})
  }

  const onSetPriceCancel = () => {
    setIsSetPriceShown(false)
  }

  const onAnswerReservation = (answer, reservation, petId) => {
    props.onAnswerReservation(answer, reservation, petId)
  }

  const onWithdrawPetOffer = (reservationId) => {
    props.onWithdrawPetOffer(reservationId)
  }

  const availOptions = [
    { text: t('common:For sales'), icon: false, value: 'forSales' },
    {
      text: t('common:Looking for home'),
      icon: false,
      value: 'lookingForHome',
    },
    {
      text: t('common:On breeding terms'),
      icon: false,
      value: 'onBreedingTerms',
    },
    {
      text: t('common:Unavailable for reservation'),
      icon: false,
      value: 'unavailable',
    },
  ]

  const availGenerator = (avail, gender, price) => {
    const availability = !avail?.length
      ? ['unavailable']
      : avail[0] === 'In memorial'
      ? avail.slice(1)
      : avail

    return (
      <React.Fragment>
        {availability.map((a) => (
          <React.Fragment key={a}>
            <div className={`${styles[`avail`]} ${styles[`avail--${a}`]}`}>
              <span
                className={`${styles[`status`]} ${styles[`status--${a}`]}`}
              />
              <p>{tr(a)}</p>
            </div>
            {a === 'forSales' && price ? (
              <div className={`${styles[`avail`]} ${styles[`avail--${a}`]}`}>
                <span
                  className={`${styles[`status`]} ${styles[`status--${a}`]}`}
                />

                <p>{price} €</p>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        ))}
        <div className={`${styles[`avail`]} ${styles[`avail--gender`]}`}>
          <span className={`${styles[`status`]} ${styles[`status--gender`]}`} />
          <p>{t(`common:${toProperCase(gender)}`)}</p>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className={styles[`reservation-tab`]}>
      <Modal
        className={styles[`modal`]}
        open={isSetPriceShown}
        content={
          <div className={styles[`set-price-modal`]}>
            <p className={styles[`header`]}>
              {t('common:Please set the price for the offspring')}
            </p>
            <form onSubmit={onSetPrice} className={styles[`price-form`]}>
              <div className={styles[`form-item`]}>
                <span className={styles[`icon--price`]} />
                <input
                  placeholder={t('common:Price')}
                  type='text'
                  name='price'
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                />
              </div>

              <button
                type='submit'
                className={`${styles[`button`]} ${styles[`button--submit`]}`}
              >
                {t('common:Set price')}
              </button>
              <p
                onClick={onSetPriceCancel}
                className={`${styles[`button`]} ${styles[`button--cancel`]}`}
              >
                {t('common:Cancel')}
              </p>
            </form>
          </div>
        }
      />
      <div className={styles[`pets`]}>
        {isEmpty(pets) && isEmpty(offers) ? (
          <div className={styles[`no-pets`]}>
            <p>
              {isFromBreederProfile
                ? t('common:No available pets yet')
                : t(
                    'common:No offspring have been created for this litter yet',
                  )}
            </p>
          </div>
        ) : (
          map(pets, (pet) => (
            <div key={pet.id} className={styles[`pet`]}>
              <div className={styles[`pet-info`]}>
                <Avatar
                  followIcon={false}
                  size={{
                    width: '90px',
                    height: '90px',
                  }}
                  nameShown={false}
                  profileData={pet}
                  content={{
                    image: pet?.avatar || pet?.profile || PetPlaceholder,
                  }}
                  profileType={'pet'}
                  id={pet.id}
                />
                <div className={styles[`main-info`]}>
                  <Name
                    profileData={pet}
                    name={pet.name}
                    profileType={'pet'}
                    id={pet.id}
                  />
                  <div className={styles[`availability`]}>
                    {availGenerator(
                      pet.details.availability,
                      pet.gender,
                      pet.details.price,
                    )}
                  </div>
                </div>
              </div>
              <p className={styles['description']}>
                {pet.details.description ||
                  t('common:No description available')}
              </p>
              {!isFromBreederProfile && (
                <div className={styles[`actions`]}>
                  <ActionButton
                    onOptionClick={(avail) => onUpdateAvailability(avail, pet)}
                    header={t('common:Set availability')}
                    options={availOptions}
                    tr={tr}
                    position={{
                      top: '10px',
                      right: '10px',
                    }}
                  />
                </div>
              )}
              <ReservationStatus
                onConfirmPetOffer={onConfirmPetOffer}
                onAnswerReservation={(answer, reservation) => {
                  onAnswerReservation(answer, reservation, pet.id)
                }}
                petId={pet.id}
                reservations={
                  pet._reservationData
                    ? pet._reservationData.filter(
                        (reser) => reser.action === 'promise',
                      )
                    : []
                }
              />
            </div>
          ))
        )}
        {offers && offers.length > 0 && (
          <TransferOffer
            onWithdraw={(reservationId) => onWithdrawPetOffer(reservationId)}
            ownTransfers={offers.filter(
              (offer) => offer.userId.id === currentUser.id,
            )}
          />
        )}
      </div>
    </div>
  )
}

export default ReservationTab
