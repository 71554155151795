import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'
import { uploadFiles } from 'services/fileManagement'

export const createNewCompany = createAsyncThunk(
  'companies/create',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(toggleLoading())
      const { profileS3Id, ...data } = payload
      if (profileS3Id) {
        const currentUser = get(getState(), 'appReducer.currentUser')
        const uploadData = await uploadFiles({
          userId: currentUser.id,
          files: [profileS3Id],
          action: UPLOAD_ACTION.POST,
        })
        data.profileS3Id = get(uploadData, '0.s3Id')
      }
      const response = await axios.post('/api/v1/companies', data)
      return response.data.data.id
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

const initialState = {
  isCreateCompanySuccess: false,
  companyId: null,
}

const createCompanySlice = createSlice({
  name: 'createCompanyReducer',
  initialState,
  reducers: {
    resetCreateCompanyState: () => {
      return initialState
    },
  },
  extraReducers: {
    [createNewCompany.pending]: (state) => {
      state.isCreateCompanySuccess = false
    },
    [createNewCompany.fulfilled]: (state, { payload }) => {
      state.isCreateCompanySuccess = true
      state.companyId = payload
    },
  },
})

const { reducer: createCompanyReducer, actions } = createCompanySlice

export const { resetCreateCompanyState } = actions

export default createCompanyReducer
