//error messages
export const ERROR_DEFAULT = 'Something went wrong, please try again later'
export const ERROR_FOLLOW_LIMIT =
  'You have reached your maximum followings. Please consider upgrading your subscription plan or unfollow previous connections.'
export const ERROR_LIMIT =
  'You have reached your maximum media capacity. Please consider upgrading your subscription plan or delete previous pictures.'
export const ERROR_OFFER_PET_TO_SAME_USER = 'This pet is already being offered.'
export const ERROR_ADD_PARENT_TO_LITTER__AGE =
  'Parents of a litter must be older than the litter.'
export const ERROR_ADD_PARENT_TO_PET__AGE =
  'Parent must be older than the offspring.'
export const ERROR_OFFER_PET_TO_SELF = `You can't transfer this pet to yourself.`
export const ERROR_INVALID_DATE = 'Invalid date format (DD-MM-YYYY).'
export const ERROR_WEIGHT_BE_NUMBER = 'Weight must be a number.'
export const ERROR_INVALID_PRICE = 'Invalid price value.'
export const ERROR_TIME_OUT = 'Request timeout.'
export const ERROR_WRONG_PASSWORD = 'Incorrect password.'
export const ERROR_FORM_ERRORS =
  'There are errors in your form. Please fix them.'
export const ERROR_PASSWORDS_MISMATCH = 'Passwords do not match.'
export const ERROR_INVALID_EMAIL = 'Invalid email.'
export const ERROR_PASSWORD_SHORT = 'Minimum password length is 12'
export const ERROR_PASSWORD_LONG = 'Maximum password length is 64'
export const ERROR_REQUIRED = 'Content is required in field'
export const ERROR_NOT_ALLOWED = `You don't have permission for this task.`
export const ERROR_INVALID_CODE = 'Invalid code, please try again'
export const ERROR_UPDATE_PET_WEIGHT__DATE = `You can't update pet's weight before pet's date of birth or after today.`

//success messages
export const SUCCESS_OFFER_PET =
  'Your pet has been offered to the user. Please check the Reservations and transfers tab for more info.'
export const SUCCESS_TRANSFER_PET =
  'Congratulations, pet has been transfered successfully.'
export const SUCCESS_PASSWORD_CHANGE =
  'Your password has been changed. Do you want to sign out?'
export const SUCCESS_TESTIMONIAL_SENT =
  'Your recommendation has been sent for approval.'

//noti messages
export const NOTI_OFFER_DECLINE = 'Offer declined!'
export const NOTI_VERIFY_EMAIL =
  'Please verify your account by clicking the link sent to your email and log in later.'
export const NOTI_EMAIL_CASE_SENSITIVE =
  'Email address contains upper case letters. Note that login is case-sensitive.'

//remind messages
export const REMIND_CORRECT_USER = 'Please choose the correct user.'
export const REMIND_RESET_PASSWORD =
  'Please use the code sent to your email to reset the password.'

export const ERROR_PASSWORDS_INCORRECT = 'Password incorrect.'
export const ERROR_EMAIL_EXISTS = 'Email already exists.'
export const SUCCESS_EMAIL_CHANGE = 'Your email has been changed.'
export const SUCCESS_INVITE_PEOPLE = 'Send invites successfully.'
export const SUCCESS_PAYMENT =
  'Congratulations on your successful payment, your account will be upgraded in a few minutes.'
export const ERROR_MEDIA_LONG = 'Maximum media in post length is 10.'
