import React from 'react'
import { styled } from 'styled-components'

const Wrapper = styled.div((props) => ({
  width: props.width,
  height: props.height,
  backgroundColor:
    props.backgroundColor ||
    (props.isActive ? 'var(--color-primary)' : 'var(--color-secondary)'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: props.isActive ? 'pointer' : 'default',
  overflow: 'hidden',
}))

const IconPlaceholder = ({
  icon,
  width = 46,
  height = 46,
  onClick,
  backgroundColor,
  isActive = false,
  iconWidth = 'auto',
  iconHeight = 'auto',
}) => {
  return (
    <Wrapper
      width={width}
      height={height}
      onClick={onClick}
      backgroundColor={backgroundColor}
      isActive={isActive}
    >
      <img src={icon} width={iconWidth} height={iconHeight} alt='icon' />
    </Wrapper>
  )
}

export default React.memo(IconPlaceholder)
