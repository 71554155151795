import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'

const PAGE_SIZE = 20

const fetchFollowers = createAsyncThunk(
  'following/fetchFollowers',
  async (userId, { getState }) => {
    const {
      followingReducer: { followerOffset },
      appReducer,
    } = getState()
    try {
      const result = await axios.get(`/activities/api/v2/follows/follower`, {
        params: {
          offset: followerOffset,
          limit: PAGE_SIZE,
          userId: userId || appReducer.currentUser.id,
        },
      })
      return result.data.data
    } catch (error) {
      return []
    }
  },
)

export default fetchFollowers
