import { createAsyncThunk } from '@reduxjs/toolkit'

const fetchLitterParents = createAsyncThunk(
  'litterProfile/fetchLitterParents',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default fetchLitterParents
