import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import get from 'lodash/get'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import {
  ERROR_DEFAULT,
  ERROR_EMAIL_EXISTS,
  ERROR_PASSWORDS_INCORRECT,
  SUCCESS_EMAIL_CHANGE,
} from 'commons/utils/error_messages_var'

const updateEmail = createAsyncThunk(
  'settings/updateEmail',
  async (payload, { dispatch }) => {
    dispatch(toggleLoading())
    try {
      await axios.post(`/user/api/v1/auth/changeEmailUser`, payload)
      return dispatch(addMessageToUser(SUCCESS_EMAIL_CHANGE))
    } catch (error) {
      const code = get(error, 'response.data.code', '')
      const mes = get(error, 'response.data.message', '')
      const message =
        code === 'PEZZIE.400'
          ? ERROR_PASSWORDS_INCORRECT
          : code === 'USER_00017'
          ? ERROR_EMAIL_EXISTS
          : mes || ERROR_DEFAULT
      dispatch(addMessageToUser(message))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updateEmail
