import commentIcon from 'assets/icons/comment.svg'
import likeWhiteIcon from 'assets/icons/like-white.svg'
import likeIcon from 'assets/icons/like.svg'
import likedIcon from 'assets/icons/liked.svg'
import shareIcon from 'assets/icons/share.svg'
import { InlineSpinner } from 'commons/components'
import AcceptModal from 'commons/components/AcceptModal'
import BlockModal from 'commons/components/ActionModal/BlockModal'
import EditModal from '../EditModal'
import { PROFILE_TYPE } from 'commons/constants'
import DeletedImage from 'commons/resources/images/deleted_photo.svg'
import { blockUser } from 'commons/slice'
import { getFileType } from 'commons/utils'
import {
  getContentWithMention,
  getContentWithoutMention,
} from 'commons/utils/emojis'
import Avatar from 'components/Avatar'
import LoadMore from 'components/LoadMore'
import { useComments, useRatings } from 'hooks'
import get from 'lodash/get'
import { ActionButton, Name } from 'pages/Main/main_routes/commons/component'
import { openFocusedPhoto } from 'pages/Main/slice'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactImageFallback from 'react-image-fallback'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import { Card, Divider, Modal } from 'semantic-ui-react'
import CommentInput from '../CommentInput'
import Comment from './Comment'
import * as styles from './styles.module.scss'
import IconPlaceholder from 'components/IconPlaceholder'

const socials = [
  {
    baseURL: 'https://www.facebook.com/sharer/sharer.php?u=',
    icon: 'facebook',
    text: 'Facebook',
  },
  {
    baseURL: 'whatsapp://send?text=',
    icon: 'whatsapp',
    text: 'WhatsApp',
  },
  {
    baseURL: 'http://www.linkedin.com/shareArticle?mini=true&url=',
    icon: 'linkedin',
    text: 'LinkedIn',
  },
]

const PostComponent = ({
  post,
  isLightBox,
  onDeletePost,
  onEditPost,
  onPinPost,
  highlightCommentId,
}) => {
  const commentRef = useRef()

  const { postType } = post
  const postInfo = get(
    post,
    postType === 'COMPANY' ? 'companyInfo' : 'userInfo',
    {},
  )
  const { t } = useTranslation()
  const appReducer = useSelector((state) => state.appReducer)
  const dispatch = useDispatch()
  const { currentUser } = appReducer
  const { locale } = currentUser

  const additionalData = useMemo(() => {
    const draft = {}
    const petId = get(post, 'petInfo.id')
    petId && (draft.petId = petId)
    const companyId = get(post, 'companyInfo.id')
    companyId && (draft.companyId = companyId)
    return draft
  }, [post])

  const {
    isFetching,
    comments,
    isCommenting,
    isFetchEnded,
    addComment,
    addChildComment,
    fetchComments,
    fetchChildComments,
    updateComment,
    removeComment,
    rateComment,
  } = useComments(
    post.id,
    highlightCommentId,
    additionalData,
    post.previewComments,
    post.totalComments,
  )

  const isOwner = currentUser.id === post.userInfo?.id

  const { liked, likedAmount, onDisLike, onLike, likedList } = useRatings(post)
  const [modalType, setModalType] = useState(null)
  const [showShareModal, setShowShareModal] = useState(false)

  const actionOptions = useMemo(
    () => [
      ...(isOwner
        ? [
            { value: 'delete', text: t('post:Delete post'), icon: 'delete' },
            { value: 'edit', text: t('post:Edit post'), icon: 'edit' },
          ]
        : [
            { value: 'report', text: t('post:Report post'), icon: 'report' },
            { value: 'block', text: t('post:Block user'), icon: 'block' },
            { value: 'mute', text: t('post:Mute user'), icon: 'mute' },
          ]),
      ...(!onPinPost
        ? []
        : post.isPin
        ? [{ value: 'pin', text: t('post:Unpin post'), icon: 'unpin' }]
        : [{ value: 'pin', text: t('post:Pin post'), icon: 'pin' }]),
    ],
    [t, post, isOwner, onPinPost],
  )

  const destData = useMemo(() => {
    if (post.postType === 'PET') {
      return {
        profileType: post.postType.toLowerCase(),
        ...post.petInfo,
      }
    }
    return null
  }, [post])

  useEffect(() => {
    if (modalType) {
      document.body.classList.add(styles['show-modal'])
    } else {
      document.body.classList.remove(styles['show-modal'])
    }
  }, [modalType])

  const getPostMetadata = () => {
    if (!post.metadata) {
      return null
    }
    return JSON.parse(post.metadata)
  }

  const postMetadata = getPostMetadata()

  const shortLinkPreview = (link) => {
    if (!link) return ''
    const originLink = new URL(link)
    return originLink.hostname || ''
  }

  const onClickLike = () => {
    liked ? onDisLike() : onLike()
  }

  const onOptionClick = (value) => {
    if (value === 'pin') {
      return onPinPost(post)
    }
    setModalType(value)
  }

  const onAccept = () => {
    dispatch(blockUser({ id: post.userInfo.id, type: postType }))
    setModalType(null)
  }

  const onCancel = () => {
    setModalType(null)
  }

  const onConfirmDelete = () => {
    onDeletePost(post.id)
    setModalType(null)
  }

  const renderComments = () => {
    if (!isFetching && !comments.length) {
      return (
        <p className={styles[`show-comment`]}>{t('common:No comments yet')}</p>
      )
    }
    return (
      <>
        {comments
          .filter((cmt) => !cmt.parent)
          .map((item) => (
            <Comment
              key={item.id}
              comment={item}
              addChildComment={addChildComment}
              fetchChildComments={fetchChildComments}
              updateComment={updateComment}
              removeComment={removeComment}
              rateComment={rateComment}
            />
          ))}
        {isFetching && <InlineSpinner />}
        {!(isFetching || isFetchEnded) && (
          <LoadMore onClick={fetchComments}>{t('common:Load more')}</LoadMore>
        )}
      </>
    )
  }

  const onAddComment = (text, _pictures, files) => {
    const { content, mentions } = getContentWithoutMention(text)
    return addComment(content, files, mentions)
  }

  const onClickEditPost = (params) => {
    onEditPost(params)
    setModalType(false)
  }

  const media = post.files?.filter((item) => !item.deletedAt)

  const onOpenLightbox = (imageIdx) => {
    dispatch(openFocusedPhoto({ imageIdx, imageSource: media }))
  }

  const renderPreview = (url) => {
    if (getFileType(url) === 'video') {
      return (
        <video controls autoPlay muted>
          <source src={url} />
        </video>
      )
    }
    return <ReactImageFallback src={url} fallbackImage={DeletedImage} />
  }

  const postWithMention = useMemo(
    () => getContentWithMention(post.content, post.mentions),
    [post],
  )

  const onClickComment = () => {
    commentRef.current?.focus()
  }

  const onClickShare = () => {
    setShowShareModal(true)
  }

  return (
    <Card
      key={post.id}
      className={`${styles[`post-card`]} ${
        post.isPin ? styles['post-pin'] : ''
      }`}
      fluid
    >
      {modalType === 'block' && (
        <BlockModal
          title={t('Block user') + '?'}
          accept={t('post:Block user')}
          cancel={t('common:Cancel')}
          onAccept={onAccept}
          onCancel={onCancel}
        />
      )}
      {modalType === 'delete' && (
        <AcceptModal
          title={t('post:Delete post') + '?'}
          accept={t('common:Delete')}
          cancel={t('common:Cancel')}
          onAccept={onConfirmDelete}
          onCancel={onCancel}
        />
      )}
      {modalType === 'edit' && (
        <EditModal
          title={t('common:Edit')}
          accept={t('common:Edit')}
          cancel={t('common:Cancel')}
          onAccept={onClickEditPost}
          onCancel={onCancel}
          post={post}
          isLightBox={isLightBox}
        />
      )}
      <Card.Header className={styles[`card-header`]}>
        <div className={styles[`ava-container`]}>
          <Avatar
            src={postInfo.profileImage}
            link={`/user/${postInfo.id}`}
            type={PROFILE_TYPE.USER}
            showStatus
            id={postInfo.id}
          />
        </div>
        <div className={styles[`info`]}>
          <div className={styles[`post-header`]}>
            <div className={styles['user-name']}>
              <div className={styles['user-name__wrap']}>
                <Name
                  profileData={post.userInfo}
                  name={postInfo.displayName}
                  profileType={postType.toLowerCase()}
                  id={postInfo.id}
                />
                {!!destData && (
                  <>
                    ➤
                    <Name
                      profileData={destData}
                      name={destData.displayName}
                      profileType={destData.profileType}
                      id={destData.id}
                    />
                  </>
                )}
              </div>
              {!!onEditPost && (
                <ActionButton
                  onOptionClick={onOptionClick}
                  header={t('common:Actions')}
                  options={actionOptions}
                  backgroundWhite
                />
              )}
            </div>
            {!!post.createdDate && (
              <a
                href={`/user/${post.userInfo.id}?post_id=${post.id}`}
                className={styles[`active-hour`]}
              >
                <Moment locale={locale ? locale : 'en'} ago fromNow>
                  {post.createdDate}
                </Moment>
              </a>
            )}
          </div>
        </div>
      </Card.Header>
      <div
        className={styles['post-text']}
        dangerouslySetInnerHTML={{ __html: postWithMention }}
      />
      {postMetadata && (
        <a
          href={postMetadata.url}
          alt={postMetadata.title}
          target='_blank'
          rel='noreferrer'
        >
          <div
            className={`${styles['link-preview-container']} ${
              !postMetadata?.img ? styles['non-img'] : ''
            }`}
          >
            {postMetadata?.img && (
              <div className={styles['link-preview-image']}>
                <img
                  className={styles['preview-img']}
                  width='150'
                  height='150'
                  src={postMetadata?.img}
                  alt='link preview'
                />
              </div>
            )}
            <div className={styles['link-preview-content']}>
              <p>{shortLinkPreview(postMetadata?.url).toUpperCase()}</p>
              <p className={styles['link-preview-title']}>
                {postMetadata?.title || shortLinkPreview(postMetadata?.url)}
              </p>
              <p className={styles['link-preview-description']}>
                {postMetadata?.description}
              </p>
            </div>
          </div>
        </a>
      )}
      {!!media.length && (
        <Card.Content className={styles[`card-content`]}>
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            className={styles['carousel']}
          >
            {media.map((item, idx) => {
              return (
                <div
                  key={item.s3Id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onOpenLightbox(idx)}
                >
                  {renderPreview(item.url)}
                </div>
              )
            })}
          </Carousel>
        </Card.Content>
      )}
      {!!(likedAmount || post.totalComments) && (
        <>
          <Card.Description className={styles['statistic']}>
            <div className={styles['liked-amount']}>
              {!!likedAmount && (
                <>
                  <IconPlaceholder
                    isActive
                    icon={likeWhiteIcon}
                    width={24}
                    height={24}
                    iconWidth={16}
                  />
                  <span className={styles['action']}>{likedAmount}</span>
                </>
              )}
            </div>
            {!!post.totalComments && (
              <div className={styles['comment-count']}>
                {post.totalComments} {t('common:comments')}
              </div>
            )}
          </Card.Description>
          <Divider className={styles.divider} />
        </>
      )}
      <Card.Description className={styles['cta']}>
        <div
          className={`${styles['cta__action']} ${
            liked ? styles['cta__action--active'] : ''
          }`}
          onClick={() => onClickLike(post.id)}
        >
          <img src={liked ? likedIcon : likeIcon} alt='like' />
          {t('common:Like')}
        </div>
        <div className={styles['cta__action']} onClick={onClickComment}>
          <img src={commentIcon} alt='comment' />
          {t('common:Comment')}
        </div>
        <div className={styles['cta__action']} onClick={onClickShare}>
          <img src={shareIcon} alt='share' />
          {t('common:Share')}
          <Modal
            className={styles['share-modal']}
            size='tiny'
            open={showShareModal}
            onClose={() => setShowShareModal(false)}
          >
            <Modal.Content>
              <div className={styles[`content`]}>
                <span
                  onClick={() => setShowShareModal(false)}
                  className={styles[`icon-close`]}
                />
                <h3 className={styles[`title`]}>Share</h3>
                <ul className={styles[`socials`]}>
                  {socials.map((item) => {
                    return (
                      <li key={item.icon} className={styles[item.icon]}>
                        <a
                          href={`${item.baseURL}${window.location.origin}/user/${post.userInfo.id}?post_id=${post.id}`}
                          target='_blank'
                          rel='noreferrer'
                          title={t(`common:Share on ${item.text}`)}
                        >
                          <i
                            className={styles[`icon-${item.icon}`]}
                            alt={item.text}
                          />
                          {t(`common:Share on ${item.text}`)}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Modal.Content>
          </Modal>
        </div>
      </Card.Description>
      <Card.Description className={styles['comment-input']}>
        <CommentInput
          commentRef={commentRef}
          onSubmit={onAddComment}
          isLoading={isCommenting}
          userInfo={currentUser}
        />
      </Card.Description>
      <Card.Description className={styles['comment-section']}>
        {renderComments()}
      </Card.Description>
    </Card>
  )
}

export default React.memo(PostComponent)
