import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const fetchUserFiles = createAsyncThunk(
  'userProfile/fetchUserFiles',
  async (_offset, { rejectWithValue, getState, dispatch }) => {
    try {
      const { userProfileReducer, appReducer } = getState()
      const { files } = userProfileReducer
      const { offset, limit } = files

      const res = await axios.get(
        `/user/api/v2/users/files/${appReducer.currentUser.id}`,
        {
          params: {
            offset: _offset ?? offset,
            limit,
          },
        },
      )
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default fetchUserFiles
