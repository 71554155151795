import { createSlice } from '@reduxjs/toolkit'
import searchPetForm from './search'

const initialState = {
  results: [],
  searchState: {},
  page: 1,
  loading: false,
  fetchEnd: false,
  sort: null,
  iterations: 0,
}

const searchSlice = createSlice({
  name: 'searchReducer',
  initialState,
  reducers: {
    resetSearchState: () => {
      return initialState
    },
    setPet: (state, { payload }) => {
      state.results = payload
    },
    saveSearchState: (state, { payload }) => {
      state.searchState = payload
    },
    restoreSearchState: (state) => {
      state.searchState = null
    },
  },
  extraReducers: {
    [searchPetForm.pending]: (state) => {
      state.loading = true
    },
    [searchPetForm.fulfilled]: (state, { payload }) => {
      state.results = state.page === 1 ? payload : state.results.concat(payload)
      state.loading = false
      state.page += 1
      state.fetchEnd = !payload.length
    },
    [searchPetForm.rejected]: (state) => {
      state.loading = false
    },
  },
})

export * from './actions'

const { actions, reducer } = searchSlice

export const { resetSearchState, setPet, saveSearchState, restoreSearchState } =
  actions

export { searchPetForm }

export default reducer
