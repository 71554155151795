import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'
import { uploadFiles } from 'services/fileManagement'

const createCompanyPersonnel = createAsyncThunk(
  'companyProfile/createCompanyPersonnel',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const { profileS3Id, backgroundS3Id, userId, ...data } = payload
      if (profileS3Id) {
        const { appReducer } = getState()
        const uploadData = await uploadFiles({
          userId: appReducer.currentUser.id,
          files: [profileS3Id],
          action: UPLOAD_ACTION.POST,
          companyId: data.companyId,
        })
        data.profileS3Id = get(uploadData, '0.s3Id')
      }
      if (backgroundS3Id) {
        const { appReducer } = getState()
        const uploadData = await uploadFiles({
          userId: appReducer.currentUser.id,
          files: [backgroundS3Id],
          action: UPLOAD_ACTION.POST,
          companyId: data.companyId,
        })
        data.backgroundS3Id = get(uploadData, '0.s3Id')
      }
      const result = await axios.post('api/v1/companies/personel', data)
      return result.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createCompanyPersonnel
