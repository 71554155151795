import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const PAGE_SIZE = 10

const fetchUserAvailablePets = createAsyncThunk(
  'main/fetchUserAvailablePets',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      const { userProfileReducer } = getState()
      const { offset } = userProfileReducer.available
      const res = await axios.get('/pets/api/v1/pets/availability', {
        params: {
          offset: offset,
          limit: PAGE_SIZE,
          sort: 'desc',
          sortField: 'name',
          userId: payload.userId,
        },
      })
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default fetchUserAvailablePets
