import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'

const fetchCompanyPets = createAsyncThunk(
  'companyProfile/fetchCompanyPets',
  async (_offset, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState()
      const { pets, profile } = get(state, 'companyProfileReducer')
      const { offset, limit } = pets
      const res = await axios.get(
        `/pets/api/v1/pets/company-pet/${profile.data.id}`,
        {
          params: {
            offset: _offset ?? offset,
            limit,
            sort: 'desc',
            sortField: 'createdDate',
          },
        },
      )
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
    }
  },
)

export default fetchCompanyPets
