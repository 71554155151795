import { createAsyncThunk } from '@reduxjs/toolkit'

const fetchLitterInfoTab = createAsyncThunk(
  'litterProfile/fetchLitterInfoTab',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default fetchLitterInfoTab
