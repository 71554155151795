import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const fetchUserProfile = createAsyncThunk(
  'userProfile/fetchUserProfile',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`/user/api/v1/users/byID/${payload}`)
      return res.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default fetchUserProfile
