import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import { toggleLoading } from 'commons/slice'
import get from 'lodash/get'
import service from 'services/post'
import { uploadFiles } from 'services/fileManagement'

const updatePost = createAsyncThunk(
  'main/updatePost',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const state = getState()
      const currentUser = get(state, 'appReducer.currentUser')
      const { postId, content, pictures, files, userInfo } = payload
      const data = {
        content: content ?? '',
        type: 'USER',
        s3Ids: pictures.map((picture) => picture.s3Id),
      }
      if (files.length) {
        const uploadData = await uploadFiles({
          userId: currentUser.id,
          files,
          action: UPLOAD_ACTION.POST,
          userInfo,
        })
        data.s3Ids.push(...uploadData.map(({ s3Id }) => s3Id))
      }
      const res = await service.updatePost(postId, data)
      return res
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updatePost
