export default function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      (Array.isArray(obj[propName]) && obj[propName].length === 0)
    ) {
      delete obj[propName]
    }
  }
  return obj
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

export function cleanWithNull(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === undefined ||
      obj[propName] === '' ||
      (Array.isArray(obj[propName]) && obj[propName].length === 0)
    ) {
      delete obj[propName]
    }
  }
  return obj
}
