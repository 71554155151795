import weightIcon from 'assets/icons/weight.svg'
import { LineChart } from 'commons/components/index'
import { PROFILE_TYPE } from 'commons/constants'
import { addMessageToUser } from 'commons/slice'
import { isPremium } from 'commons/utils/checkLevel'
import {
  ERROR_INVALID_DATE,
  ERROR_WEIGHT_BE_NUMBER,
} from 'commons/utils/error_messages_var'
import { isNumeric } from 'commons/utils/numberUtils'
import cleanObj from 'commons/utils/objectClean'
import Avatar from 'components/Avatar'
import i18n from 'i18next'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DateInput } from 'semantic-ui-calendar-react'
import { Button, Dropdown, Modal } from 'semantic-ui-react'
import * as styles from './health_info.module.scss'
import colorSet from './health_info_colorset'

const formatDate = 'YYYY-MM-DD'

const HealthInfo = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { appReducer } = useSelector((state) => state)
  const { currentUser } = appReducer

  const { owned, health: healthInfo, ownedPets, type = 'PET' } = props

  const [modalOpen, setModalOpen] = useState(false)
  const [interval, setUpdateInterval] = useState(t('common:All time'))
  const [details, setDetails] = useState({})
  const [date, setDate] = useState('')

  const filteredByInterval = filterDataByInterval(healthInfo, interval, t)
  const healthData = generateChartData(filteredByInterval)
  const processedHealthInfo = processHealthInfo(filteredByInterval)

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value })
  }

  const onCloseModal = (e) => {
    const list = Object.keys(details)

    const refreshed = list.reduce((acc, curr) => {
      acc[curr] = ''
      return acc
    }, {})

    setModalOpen(false)
    setDetails(refreshed)
  }

  const onUpdateWeight = (e) => {
    e.preventDefault()

    const cleaned = cleanObj(details)
    const list = Object.keys(cleaned)

    if (list.length === 0) {
      return
    }

    if (list.length === 1 && list[0] === 'date') {
      return
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i] === 'date') {
        if (!moment(cleaned['date'], formatDate).isValid()) {
          dispatch(addMessageToUser(ERROR_INVALID_DATE))
          return
        } else {
          cleaned['date'] =
            moment.utc(cleaned['date'], formatDate).unix() * 1000
        }
      } else {
        if (!isNumeric(cleaned[list[i]])) {
          dispatch(addMessageToUser(ERROR_WEIGHT_BE_NUMBER))
          return
        }
      }
    }

    let weightUpdate = {}
    if (type === 'LITTER') {
      weightUpdate = ownedPets
        .map((pet) => ({
          petId: pet.id,
          weight: Number(cleaned[pet.id]),
          dateWeight: date || moment().format(formatDate),
        }))
        .filter((item) => item.weight > 0)
    } else {
      weightUpdate = {
        petId: ownedPets[0].id,
        weight: cleaned[ownedPets[0].id],
        dateWeight: date || moment().format(formatDate),
      }
    }

    props.onUpdateWeight(weightUpdate)
    onCloseModal()
  }

  const onDateChange = (e, { name, value }) => {
    setDate(value)
  }

  return (
    <div>
      <div className={styles[`health-info`]}>
        <Modal
          onClose={onCloseModal}
          className={styles[`form-modal`]}
          open={modalOpen}
        >
          <form onSubmit={onUpdateWeight} className={styles[`form`]}>
            <div className={styles[`form-input--date`]}>
              <span
                className={`${styles[`icon`]} ${styles[`icon--calendar`]}`}
              />
              <DateInput
                animation='none'
                startMode='year'
                placeholder={t(
                  'common:Click here to choose date and then enter weight',
                )}
                closable
                dateFormat={formatDate}
                value={date}
                name='date'
                onChange={onDateChange}
                icon={false}
                className={styles[`date-picker`]}
                hideMobileKeyboard
                preserveViewMode={false}
                localization={i18n.language}
              />
            </div>
            {ownedPets &&
              ownedPets.map((pet) => (
                <div key={pet.id} className={styles[`form-input`]}>
                  <p className={styles[`pet-name`]}>{pet.name}</p>
                  <input
                    name={`${pet.id}`}
                    value={details[pet.id]}
                    onChange={onChange}
                  />
                  <p className={styles[`unit`]}>kg</p>
                </div>
              ))}

            <div className={styles[`action-buttons`]}>
              <button
                type='submit'
                className={`${styles[`button`]} ${styles[`button--update`]}`}
              >
                {t('common:Update weight')}
              </button>
              <button
                onClick={onCloseModal}
                className={`${styles[`button`]} ${styles[`button--cancel`]}`}
              >
                {t('common:Cancel')}
              </button>
            </div>
          </form>
        </Modal>
        <div className={styles['title']}>{t('common:Current weight')}</div>
        <div className={styles['content']}>
          {isEmpty(processedHealthInfo) ? (
            <div className={styles[`no-info`]}>
              <p>{t('common:No info yet.')}</p>
            </div>
          ) : (
            processedHealthInfo.map((measure) => (
              <div key={measure.petId} className={styles['weight']}>
                <div className={styles['weight__value']}>
                  <img src={weightIcon} alt='weight' />
                  <span>{measure.weight}kg</span>
                </div>
                <div className={styles['weight__info']}>
                  <Avatar
                    src={measure.avatar || measure.profileImage}
                    type={PROFILE_TYPE.PET}
                    link={`/pets/${measure.petId}`}
                    width={60}
                    height={60}
                    title={measure.petName}
                  />
                </div>
                <div className={styles[`weight__date`]}>
                  {moment(measure.dateWeight).format('L')}
                </div>
              </div>
            ))
          )}
        </div>
        {isPremium(currentUser.level) && owned && !isEmpty(ownedPets) && (
          <Button
            onClick={() => setModalOpen(true)}
            className={styles[`cta`]}
            primary
          >
            {t('common:Update weight')}
          </Button>
        )}
      </div>
      {!isEmpty(processedHealthInfo) && (
        <div className={styles[`growth-curve`]}>
          <div className={styles[`header`]}>
            <span className={styles[`title`]}>
              {t('common:Weight over time')}
            </span>
            <Dropdown text={interval} className={styles[`interval-options`]}>
              <Dropdown.Menu>
                {[
                  t('common:All time'),
                  t('common:Last week'),
                  t('common:Last month'),
                  t('common:Last year'),
                ].map((int) => (
                  <Dropdown.Item
                    onClick={() => setUpdateInterval(int)}
                    key={int}
                    text={int}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div id='growth' style={{ width: '100%', height: '600px' }}>
            <LineChart colorSet={colorSet} data={healthData} chartId='growth' />
          </div>
          <div className={styles[`description`]}>
            {healthData.map((pet, idx) => (
              <div key={idx} className={styles[`entity`]}>
                <span
                  style={{
                    backgroundColor: `${colorSet[idx]}`,
                  }}
                  className={styles[`color`]}
                />
                <p className={styles[`name`]}>{pet[0].pet.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const sortDateWeight = (list) => {
  if (!list) return []
  return [...list].sort((a, b) => {
    if (a.dateWeight < b.dateWeight) {
      return 1
    } else if (a.dateWeight > b.dateWeight) {
      return -1
    }
    return 0
  })
}

const filterDataByInterval = (data, interval, t) => {
  if (!data) {
    return []
  }

  const secondsInAWeek = 604800000
  const secondsInAMonth = 2592000000
  const secondsinAYear = 31536000000
  const today = Date.now()

  const filterByInterval = (intv) => {
    if (intv === 0) {
      return data
    } else {
      return data.filter((info) => {
        return info.dateWeight >= today - intv
      })
    }
  }

  const eva = new Map([
    [t('common:All time'), filterByInterval(0)],
    [t('common:Last week'), filterByInterval(secondsInAWeek)],
    [t('common:Last month'), filterByInterval(secondsInAMonth)],
    [t('common:Last year'), filterByInterval(secondsinAYear)],
  ])

  return eva.get(interval)
}

const processHealthInfo = (healthInfo, forChart) => {
  if (!healthInfo) {
    return []
  }

  const eachPet = healthInfo.reduce((acc, curr) => {
    if (acc[curr.petId]) {
      acc[curr.petId] = [...acc[curr.petId], curr]
    } else {
      acc[curr.petId] = [curr]
    }
    return acc
  }, {})

  if (forChart) {
    return eachPet
  }

  return Object.keys(eachPet).reduce((cur, petId) => {
    const arranged = sortDateWeight(eachPet[petId][0].petHealthDetail)
    const latestInfo = { ...eachPet[petId][0], ...arranged[0] }
    if (!arranged[0]) {
      return cur
    }
    cur.push(latestInfo)
    return cur
  }, [])
}

const generateChartData = (data) => {
  const healthInfo = processHealthInfo(data, true)
  const keys = Object.keys(healthInfo)
  return keys.reduce((cur, key) => {
    const heathMap = sortDateWeight(healthInfo[key][0].petHealthDetail)
    if (heathMap.length) {
      return (
        cur.push(
          heathMap.map((info) => {
            return {
              date: new Date(info.dateWeight),
              value: info.weight,
              pet: {
                id: healthInfo[key][0].petId,
                name: healthInfo[key][0].petName,
              },
            }
          }),
        ),
        cur
      )
    }
    return cur
  }, [])
}

export default HealthInfo
