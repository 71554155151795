import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { uploadFiles } from 'services/fileManagement'

const uploadNewImage = createAsyncThunk(
  'media/uploadNewImage',
  async (
    { file, userId, galleryType, petId },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(toggleLoading())
    try {
      const data = {
        userId,
        files: [file],
        action: UPLOAD_ACTION.POST,
      }
      petId && (data.petId = petId)
      const uploadData = await uploadFiles(data)
      return { file: uploadData[0], galleryType }
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default uploadNewImage
