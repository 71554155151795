import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import get from 'lodash/get'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import service from 'services/comment'
import { RATING_MAPPING } from 'commons/constants'
import { addMessageToUser } from 'commons/slice'

const PAGE_SIZE = 2

export const useComments = (
  postId,
  highlightCommentId,
  additionalData,
  previewComments = [],
  totalComments = 0,
) => {
  const dispatch = useDispatch()

  const { currentUser } = useSelector((state) => state.appReducer)
  const id = currentUser.id
  const checkHighLightRef = useRef(false)
  const fetchedCommentIds = useRef({})

  const [isFetching, setIsFetching] = useState(false)
  const [comments, setComments] = useState([])
  const [error, setError] = useState('')
  const [offset, setOffset] = useState(PAGE_SIZE)
  const [isFetchEnded, setIsEnded] = useState(totalComments <= PAGE_SIZE)
  const [isCommenting, setIsCommenting] = useState(false)
  const [commentSuccess, setCommentSuccess] = useState(false)

  const formatComment = ({
    userId,
    profileImage,
    displayName,
    ratings,
    ...rest
  }) => {
    const like = find(
      ratings,
      (item) => item.ratingType === RATING_MAPPING.LIKE,
    )
    return {
      ...rest,
      userId: { id: userId, displayName, profileImage },
      liked: get(like, 'identifiers', []).some((user) => user.id === id),
      likeAmount: get(like, 'amount', 0),
      likedList: get(like, 'identifiers', []),
    }
  }

  const formatComments = (data) => {
    return data.reduce((prev, comment) => {
      const {
        userId,
        profileImage,
        displayName,
        childComments,
        ratings,
        totalChildComment,
        ...rest
      } = comment
      if (fetchedCommentIds.current[rest.id]) {
        return prev
      }
      fetchedCommentIds.current[rest.id] = true
      return [
        ...prev,
        {
          ...rest,
          ...formatComment(comment),
          highlight: rest.id === highlightCommentId,
          childComments: childComments.map(formatComment),
          offset: PAGE_SIZE,
          limit: PAGE_SIZE,
          isFetchChildEnded: totalChildComment <= PAGE_SIZE,
        },
      ]
    }, [])
  }

  useEffect(() => {
    setComments(formatComments(previewComments))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewComments])

  useEffect(() => {
    if (highlightCommentId) {
      fetchComments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightCommentId])

  const fetchComments = async () => {
    try {
      setIsFetching(true)
      if (!checkHighLightRef.current && highlightCommentId) {
        if (highlightCommentId) {
          const highlightComment = await service.getCommentById(
            highlightCommentId,
          )
          if (highlightComment) {
            setComments((prev) => [
              ...formatComments([highlightComment]),
              ...prev,
            ])
            setTimeout(() => {
              const comment = document.getElementById(
                `comment-${highlightCommentId}`,
              )
              comment && comment.scrollIntoView({ behavior: 'smooth' })
            }, 300)
          } else {
            dispatch(addMessageToUser('Comment does not exist!'))
          }
        }
        return
      }
      const res = await service.getComments(postId, {
        offset,
        limit: PAGE_SIZE,
      })
      const { totalRecords, records } = res
      setOffset(Math.min(totalRecords, offset + PAGE_SIZE))
      setIsEnded(offset + PAGE_SIZE >= totalRecords)

      setComments((prev) => [...prev, ...formatComments(records)])
    } catch (error) {
      setError(ERROR_DEFAULT)
    } finally {
      setIsFetching(false)
      checkHighLightRef.current = true
    }
  }

  const addComment = async (content, files, mentions) => {
    try {
      setIsCommenting(true)
      setCommentSuccess(false)
      const res = await service.addComment({
        content,
        files,
        postId,
        userId: id,
        additionalData,
        mentions,
      })
      const { userId, profileImage, displayName, ...rest } = res
      setCommentSuccess(true)
      setOffset(offset + 1)
      setComments([
        {
          ...rest,
          userId: { id: userId, profileImage, displayName },
          limit: PAGE_SIZE,
          isFetchChildEnded: true,
          liked: false,
          likeAmount: 0,
          likedList: [],
        },
        ...comments,
      ])
    } catch (error) {
      throw error
    } finally {
      setIsCommenting(false)
    }
  }

  const addChildComment = async (content, files, parentId, mentions) => {
    try {
      const res = await service.addComment({
        content,
        files,
        postId,
        parentId,
        userId: id,
        additionalData,
        mentions,
      })
      const {
        userId: userCommentId,
        profileImage,
        displayName,
        ratings,
        ...data
      } = res
      const commentIdx = comments.findIndex(({ id }) => id === parentId)
      if (commentIdx < 0) {
        return
      }
      const updatedComments = cloneDeep(comments)
      updatedComments[commentIdx].limit++
      updatedComments[commentIdx].childComments.push({
        ...data,
        userId: { id: userCommentId, profileImage, displayName },
        // limit: updatedComments[commentIdx].limit + 1,
        liked: false,
        likeAmount: 0,
        likedList: [],
      })
      setComments(updatedComments)
    } catch (error) {
      throw error
    } finally {
    }
  }

  const fetchChildComments = async (commendId) => {
    try {
      const idx = comments.findIndex(({ id }) => id === commendId)
      if (idx < 0) return
      const updatedComments = cloneDeep(comments)
      let currentComment = updatedComments[idx]
      const { offset, limit, childComments } = currentComment
      const data = await service.getChildComments(commendId, { offset, limit })
      updatedComments[idx] = {
        ...currentComment,
        offset: Math.min(data.totalRecords, offset + PAGE_SIZE),
        isFetchChildEnded: offset + PAGE_SIZE >= data.totalRecords,
        totalChildComment: data.totalRecords,
        childComments: childComments.concat(data.records.map(formatComment)),
      }
      setComments(updatedComments)
    } catch (err) {}
  }

  const updateComment = async (commentId, params) => {
    try {
      const res = await service.updateComment(id, commentId, {
        ...params,
        postId,
      })
      const updatedComments = cloneDeep(comments)
      const parentId = params.parentId
      let updatedComment
      if (parentId) {
        const parentComment = updatedComments.find(({ id }) => id === parentId)
        updatedComment = parentComment?.childComments.find(
          ({ id }) => id === commentId,
        )
      } else {
        updatedComment = updatedComments.find(({ id }) => id === commentId)
      }
      if (!updatedComment) {
        return
      }
      updatedComment.content = res.content
      updatedComment.fileInformation = res.fileInformation
      setComments(updatedComments)
    } catch (error) {
      throw error
    }
  }

  const removeComment = async (commentId, parentId) => {
    try {
      await service.removeComment(commentId)
      let updatedComments = cloneDeep(comments)
      let ref = updatedComments
      if (parentId) {
        const parentComment = updatedComments.find(({ id }) => id === parentId)
        ref.offset--
        ref = parentComment.childComments
      }
      const commentIdx = ref.findIndex(({ id }) => id === commentId)
      if (commentIdx < 0) {
        return
      }
      ref.splice(commentIdx, 1)
      setComments(updatedComments)
      !parentId && setOffset(offset - 1)
    } catch (error) {
      throw error
    }
  }

  const rateComment = async (commentId, parentId) => {
    try {
      let updatedComments = cloneDeep(comments)
      let ref = updatedComments
      if (parentId) {
        const parentComment = updatedComments.find(({ id }) => id === parentId)
        ref = parentComment.childComments
      }
      const commentIdx = ref.findIndex(({ id }) => id === commentId)
      if (commentIdx < 0) {
        return
      }
      const ratingType = ref[commentIdx].liked
        ? RATING_MAPPING.DISLIKE
        : RATING_MAPPING.LIKE
      await service.rateComment({
        commentId,
        userId: id,
        ratingType,
      })
      ref[commentIdx].liked = ratingType === RATING_MAPPING.LIKE ? true : false
      ref[commentIdx].likeAmount =
        ratingType === RATING_MAPPING.LIKE
          ? ref[commentIdx].likeAmount + 1
          : ref[commentIdx].likeAmount - 1
      ref[commentIdx].likedList =
        ratingType === RATING_MAPPING.LIKE
          ? [currentUser, ...ref[commentIdx].likedList]
          : ref[commentIdx].likedList.filter(
              (user) => user.id !== currentUser.id,
            )
      setComments(updatedComments)
    } catch (error) {
      throw error
    }
  }

  return {
    isFetching,
    comments,
    error,
    isFetchEnded,
    isCommenting,
    commentSuccess,
    addComment,
    addChildComment,
    fetchComments,
    fetchChildComments,
    updateComment,
    removeComment,
    rateComment,
  }
}
