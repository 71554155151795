import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const createCompanyFile = createAsyncThunk(
  'companyProfile/createCompanyFile',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const { file, id } = payload
      const formData = new FormData()
      formData.append('file', file)
      const result = await axios.post(`/api/v1/companies/files/${id}`, formData)
      return result.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createCompanyFile
