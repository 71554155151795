import { createSlice } from '@reduxjs/toolkit'
import fetchNotification from './fetchNotification'
import readNotification from './readNotification'
import readAllNotification from './readAllNotification'

const initialState = {
  data: [],
  offset: 0,
  limit: 10,
  isFetchingNotification: false,
  isFetchNotificationEnd: false,
}

const slice = createSlice({
  name: 'notificationReducer',
  initialState,
  reducers: {
    resetNotificationState: () => {
      return initialState
    },
    pushNotification: (state, { payload }) => {
      state.data.unshift(payload)
    },
  },
  extraReducers: {
    [fetchNotification.pending]: (state) => {
      state.isFetchingNotification = true
    },
    [fetchNotification.fulfilled]: (state, { payload }) => {
      state.isFetchingNotification = false
      state.data.push(...payload)
    },
    [fetchNotification.rejected]: (state) => {
      state.isFetchingNotification = false
    },
    [readNotification.fulfilled]: (state, { payload }) => {
      const idx = state.data.findIndex((item) => item.messageId === payload)
      idx > -1 && (state.data[idx].unread = false)
    },
    [readAllNotification.fulfilled]: (state) => {
      state.data.forEach((item) => {
        item.unread = false
      })
    },
  },
})

const { reducer: notificationReducer, actions } = slice

export { fetchNotification, readNotification, readAllNotification }

export const { resetNotificationState, pushNotification } = actions

export default notificationReducer
