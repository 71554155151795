import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'
import forOwn from 'lodash/forOwn'

export const uploadFiles = async ({ userId, files, ...rest }) => {
  try {
    const formData = new FormData()
    forOwn(rest, (value, key) => formData.append(key, value))
    for (const file of files) {
      formData.append('files', file)
    }
    const res = await axios.post(
      `/common/api/v1/files/upload/${userId}`,
      formData,
    )
    return res.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

export const deleteFile = async (s3Id) => {
  try {
    await axios.post(`/common/api/v1/files/delete-file/${s3Id}`)
  } catch (error) {
    throw getErrorMessage(error)
  }
}

export const uploadVideo = async ({ userId, files, ...rest }) => {
  try {
    const formData = new FormData()
    forOwn(rest, (value, key) => formData.append(key, value))
    formData.append('file', files[0])
    const res = await axios.post(
      `/common/api/v1/files/upload-video/${userId}`,
      formData,
    )
    return res.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

export const deleteMultipleFiles = async (s3Ids) => {
  try {
    await axios.post('/common/api/v1/files/delete-multi-file', {
      s3Ids: Array.isArray(s3Ids) ? s3Ids : [s3Ids],
    })
  } catch (error) {
    throw getErrorMessage(error)
  }
}
