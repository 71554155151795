import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const fetchCompanyListing = createAsyncThunk(
  'companies/fetchCompanyListing',
  async (payload, { dispatch, rejectWithValue }) => {
    const { sort, sortField, limit, offset, userId } = payload
    try {
      const result = await axios.get(`/api/v1/companies/owner/${userId}`, {
        params: {
          sort: sort || 'desc',
          sortField: sortField || 'createdDate',
          limit,
          offset,
        },
      })
      return result.data.data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    }
  },
)

export default fetchCompanyListing
