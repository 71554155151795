import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'
import getUserProfile from './getUserProfile'

const refreshNewToken = createAsyncThunk(
  'app/refreshNewToken',
  async (_payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState()
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken) {
        const result = await axios.post('/user/api/v1/auth/refresh', {
          refreshToken,
        })
        localStorage.setItem('token', result.data.accessToken)
        if (!state.appReducer.currentUser.isAuthenticated) {
          dispatch(getUserProfile())
        }
        return
      }
      return rejectWithValue(getErrorMessage())
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default refreshNewToken
