import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { getErrorMessage, getFileType } from 'commons/utils'
import { uploadFiles, uploadVideo } from 'services/fileManagement'

const deletePost = async (postId) => {
  try {
    await axios.delete(`/activities/api/v1/posts/${postId}`)
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const updatePost = async (postId, data) => {
  try {
    const res = await axios.put(`/activities/api/v1/posts/${postId}`, data)
    return res.data.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

export const uploadPostMediaFiles = async ({ files, ...uploadInfo }) => {
  const s3Ids = []
  if (files.length) {
    const imageFiles = [],
      videoFiles = []
    files.forEach((file, idx) => {
      if (getFileType(file) === 'video') {
        return videoFiles.push({ file, idx })
      }
      imageFiles.push({ file, idx })
    })
    const uploadPromises = []
    if (imageFiles.length) {
      uploadPromises.push(
        uploadFiles({
          ...uploadInfo,
          files: imageFiles.map(({ file }) => file),
          action: UPLOAD_ACTION.POST,
        }),
      )
    }
    uploadPromises.push(
      ...videoFiles.map(({ file }) =>
        uploadVideo({
          ...uploadInfo,
          files: [file],
          action: UPLOAD_ACTION.POST,
        }),
      ),
    )
    const uploadResults = await Promise.all(uploadPromises)
    const imageUploads = imageFiles.length ? uploadResults[0] : []
    const videoUploads = uploadResults.slice(imageUploads.length ? 1 : 0)
    for (let i = 0; i < files.length; i++) {
      const imageIdx = imageFiles.findIndex(({ idx }) => idx === i)
      if (imageIdx > -1) {
        s3Ids.push(imageUploads[imageIdx].s3Id)
      } else {
        const videoIdx = videoFiles.findIndex(({ idx }) => idx === i)
        s3Ids.push(videoUploads[videoIdx].s3Id)
      }
    }
  }

  return s3Ids
}

const service = { deletePost, updatePost }

export default service
