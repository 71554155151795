import React from 'react'
import Loader from '../../resources/icons/Spinner-1s-200px.svg'
import * as styles from './inline_spinner.module.scss'

const InlineSpinner = () => {
  return (
    <div className={styles[`loader`]}>
      <img src={Loader} alt='spinner' />
    </div>
  )
}
export default InlineSpinner
