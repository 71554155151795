const sv = {
  ad: 'Andorra',
  ae: 'Förenade Arabemiraten',
  af: 'Afghanistan',
  ag: 'Antigua och Barbuda',
  ai: 'Anguilla',
  al: 'Albanien',
  am: 'Armenien',
  ao: 'Angola',
  ar: 'Argentina',
  as: 'Amerikanska Samoa',
  at: 'Österrike',
  au: 'Australien',
  aw: 'Aruba',
  ax: 'Åland',
  az: 'Azerbajdzjan',
  ba: 'Bosnien och Hercegovina',
  bb: 'Barbados',
  bd: 'Bangladesh',
  be: 'Belgien',
  bf: 'Burkina Faso',
  bg: 'Bulgarien',
  bh: 'Bahrain',
  bi: 'Burundi',
  bj: 'Benin',
  bm: 'Bermuda',
  bn: 'Brunei',
  bo: 'Bolivia',
  br: 'Brasilien',
  bs: 'Bahamas',
  bt: 'Bhutan',
  bv: 'Bouvetön',
  bw: 'Botswana',
  by: 'Vitryssland',
  bz: 'Belize',
  ca: 'Kanada',
  cc: 'Cocosöarna',
  cd: 'Kongo (Kongo-Kinshasa)',
  cf: 'Centralafrikanska republiken',
  cg: 'Kongo (Kongo-Brazzaville)',
  ch: 'Schweiz',
  ci: 'Elfenbenskusten',
  ck: 'Cooköarna',
  cl: 'Chile',
  cm: 'Kamerun',
  cn: 'Kina',
  co: 'Colombia',
  cr: 'Costa Rica',
  cu: 'Kuba',
  cv: 'Kap Verde',
  cx: 'Julön',
  cy: 'Cypern',
  cz: 'Tjeckien',
  de: 'Tyskland',
  dj: 'Djibouti',
  dk: 'Danmark',
  dm: 'Dominica',
  do: 'Dominikanska republiken',
  dz: 'Algeriet',
  ec: 'Ecuador',
  ee: 'Estland',
  eg: 'Egypten',
  eh: 'Västsahara',
  er: 'Eritrea',
  es: 'Spanien',
  et: 'Etiopien',
  fi: 'Finland',
  fj: 'Fiji',
  fk: 'Falklandsöarna',
  fm: 'Mikronesien',
  fo: 'Färöarna',
  fr: 'Frankrike',
  ga: 'Gabon',
  gb: 'Storbritannien',
  gd: 'Grenada',
  ge: 'Georgien',
  gf: 'Franska Guyana',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gl: 'Grönland',
  gm: 'Gambia',
  gn: 'Guinea',
  gp: 'Guadeloupe',
  gq: 'Ekvatorialguinea',
  gr: 'Grekland',
  gs: 'Sydgeorgien och Sydsandwichöarna',
  gt: 'Guatemala',
  gu: 'Guam',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  hk: 'Hongkong',
  hm: 'Heardön och McDonaldöarna',
  hn: 'Honduras',
  hr: 'Kroatien',
  ht: 'Haiti',
  hu: 'Ungern',
  id: 'Indonesien',
  ie: 'Irland',
  il: 'Israel',
  in: 'Indien',
  io: 'Brittiska territoriet i Indiska Oceanen',
  iq: 'Irak',
  ir: 'Iran',
  is: 'Island',
  it: 'Italien',
  jm: 'Jamaica',
  jo: 'Jordanien',
  jp: 'Japan',
  ke: 'Kenya',
  kg: 'Kirgizistan',
  kh: 'Kambodja',
  ki: 'Kiribati',
  km: 'Komorerna',
  kn: 'Saint Kitts och Nevis',
  kp: 'Demokratiska folkrepubliken Korea (Nordkorea)',
  kr: 'Republiken Korea (Sydkorea)',
  kw: 'Kuwait',
  ky: 'Caymanöarna',
  kz: 'Kazakstan',
  la: 'Laos',
  lb: 'Libanon',
  lc: 'Saint Lucia',
  li: 'Liechtenstein',
  lk: 'Sri Lanka',
  lr: 'Liberia',
  ls: 'Lesotho',
  lt: 'Litauen',
  lu: 'Luxemburg',
  lv: 'Lettland',
  ly: 'Libyen',
  ma: 'Marocko',
  mc: 'Monaco',
  md: 'Moldavien',
  me: 'Montenegro',
  mg: 'Madagaskar',
  mh: 'Marshallöarna',
  mk: 'Makedonien',
  ml: 'Mali',
  mm: 'Myanmar',
  mn: 'Mongoliet',
  mo: 'Macao',
  mp: 'Nordmarianerna',
  mq: 'Martinique',
  mr: 'Mauretanien',
  ms: 'Montserrat',
  mt: 'Malta',
  mu: 'Mauritius',
  mv: 'Maldiverna',
  mw: 'Malawi',
  mx: 'Mexiko',
  my: 'Malaysia',
  mz: 'Moçambique',
  na: 'Namibia',
  nc: 'Nya Kaledonien',
  ne: 'Niger',
  nf: 'Norfolkön',
  ng: 'Nigeria',
  ni: 'Nicaragua',
  nl: 'Nederländerna',
  no: 'Norge',
  np: 'Nepal',
  nr: 'Nauru',
  nu: 'Niue',
  nz: 'Nya Zeeland',
  om: 'Oman',
  pa: 'Panama',
  pe: 'Peru',
  pf: 'Franska Polynesien',
  pg: 'Papua Nya Guinea',
  ph: 'Filippinerna',
  pk: 'Pakistan',
  pl: 'Polen',
  pm: 'Saint Pierre och Miquelon',
  pn: 'Pitcairn',
  pr: 'Puerto Rico',
  ps: 'Staten Palestina',
  pt: 'Portugal',
  pw: 'Palau',
  py: 'Paraguay',
  qa: 'Qatar',
  re: 'Réunion',
  ro: 'Rumänien',
  rs: 'Serbien',
  ru: 'Ryssland',
  rw: 'Rwanda',
  sa: 'Saudiarabien',
  sb: 'Salomonöarna',
  sc: 'Seychellerna',
  sd: 'Sudan',
  se: 'Sverige',
  sg: 'Singapore',
  sh: 'Saint Helena,  Ascension och Tristan da Cunha',
  si: 'Slovenien',
  sj: 'Svalbard och Jan Mayen',
  sk: 'Slovakien',
  sl: 'Sierra Leone',
  sm: 'San Marino',
  sn: 'Senegal',
  so: 'Somalia',
  sr: 'Surinam',
  st: 'São Tomé och Príncipe',
  sv: 'El Salvador',
  sy: 'Syrien',
  sz: 'Swaziland',
  tc: 'Turks- och Caicosöarna',
  td: 'Tchad',
  tf: 'De franska territorierna i södra Indiska Oceanen',
  tg: 'Togo',
  th: 'Thailand',
  tj: 'Tadzjikistan',
  tk: 'Tokelau',
  tl: 'Östtimor',
  tm: 'Turkmenistan',
  tn: 'Tunisien',
  to: 'Tonga',
  tr: 'Turkiet',
  tt: 'Trinidad och Tobago',
  tv: 'Tuvalu',
  tw: 'Taiwan',
  tz: 'Tanzania',
  ua: 'Ukraina',
  ug: 'Uganda',
  um: 'Förenta Staternas mindre öar i Oceanien och Västindien',
  us: 'Förenta Staterna (USA)',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  va: 'Vatikanstaten',
  vc: 'Saint Vincent och Grenadinerna',
  ve: 'Venezuela',
  vg: 'Brittiska Jungfruöarna',
  vi: 'Amerikanska Jungfruöarna',
  vn: 'Vietnam',
  vu: 'Vanuatu',
  wf: 'Wallis och Futuna',
  ws: 'Samoa',
  ye: 'Yemen',
  yt: 'Mayotte',
  za: 'Sydafrika',
  zm: 'Zambia',
  zw: 'Zimbabwe',
}

export default sv
