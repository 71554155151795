import { createSlice } from '@reduxjs/toolkit'
import { NOTI_VERIFY_EMAIL } from 'commons/utils/error_messages_var'
import signUp from './signUp'
import signIn from './signIn'
import signInOTP from './signInOTP'

const initialState = {
  noti: { error: '', guide: '' },
}

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    removeError: (state) => {
      state.noti.error = ''
    },
    removeGuide: (state) => {
      state.noti.guide = ''
    },
    addError: (state, { payload }) => {
      state.noti.error = payload
    },
  },
  extraReducers: {
    [signUp.pending]: (state) => {},
    [signUp.fulfilled]: (state) => {
      state.noti.guide = NOTI_VERIFY_EMAIL
      state.noti.error = ''
    },
    [signUp.rejected]: (state, { payload }) => {
      state.noti.guide = ''
      state.noti.error = payload
    },
    [signIn.pending]: () => {},
    [signIn.fulfilled]: (state) => {
      state.noti.error = ''
    },
    [signIn.rejected]: (state, { payload }) => {
      state.noti.guide = ''
      state.noti.error = payload
    },
  },
})

const { reducer, actions } = authSlice

export const { removeError, removeGuide, addError } = actions

export { signUp, signIn, signInOTP }

export default reducer
