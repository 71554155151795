import { getFileType } from 'commons/utils'
import PostComponent from 'components/Posts/PostComponent'
import size from 'lodash/size'
import {
  closeLightbox,
  hideLightboxPost,
  loadLightboxPost,
  openLightbox,
  showLightboxPost,
} from 'pages/Main/slice'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import * as styles from './photo_lightbox.module.scss'

const PhotoLightbox = () => {
  const { t } = useTranslation()

  const { mainReducer } = useSelector((state) => state)
  const { lightbox } = mainReducer
  const {
    activeImage,
    isShowPost,
    imageSource,
    content: { s3Id, image, post },
  } = lightbox
  const isVideo = getFileType(image) === 'video'

  const dispatch = useDispatch()

  useEffect(() => {
    s3Id && dispatch(loadLightboxPost(s3Id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Id])

  const onLoadPost = () => {
    if (!isShowPost) {
      dispatch(showLightboxPost())
    } else {
      dispatch(hideLightboxPost())
    }
  }

  const onNext = () => {
    const next = imageSource[activeImage + 1]
    if (!next) return
    dispatch(
      openLightbox({
        url: next.url,
        imageSource,
        s3Id: next.s3Id,
        imageIdx: activeImage + 1,
      }),
    )
  }

  const onBack = () => {
    const back = imageSource[activeImage - 1]
    if (!back) return
    dispatch(
      openLightbox({
        url: back.url,
        imageSource,
        s3Id: back.s3Id,
        imageIdx: activeImage - 1,
      }),
    )
  }

  return (
    <Modal
      className={styles[`lightbox-container`]}
      open={lightbox.isShown}
      closeIcon
      onClose={() => dispatch(closeLightbox())}
      content={
        <div className={styles[`content`]}>
          <div className={styles[`main-picture`]}>
            {isVideo ? (
              <video autoPlay controls muted>
                <source src={image} />
              </video>
            ) : (
              <img src={image} alt='cat' />
            )}
          </div>
          <div className={styles[`controls`]}>
            <div
              onClick={() => onBack()}
              className={`${styles[`icon`]} ${styles[`icon--back`]} ${
                activeImage <= 0 ? styles['icon--disabled'] : ''
              }`}
            />
            {!!size(post) && (
              <div className={styles[`toggle-button`]}>
                <div onClick={onLoadPost} className={styles[`button`]}>
                  <p>
                    {!isShowPost
                      ? t(`common:Show related post`)
                      : t(`common:Hide related post`)}
                  </p>
                  <span
                    className={`${styles[`icon`]} ${
                      !isShowPost ? styles[`icon--show`] : styles[`icon--hide`]
                    }`}
                  />
                </div>
              </div>
            )}
            <div
              onClick={() => onNext()}
              className={`${styles[`icon`]} ${styles[`icon--next`]} ${
                activeImage >= imageSource.length - 1
                  ? styles['icon--disabled']
                  : ''
              }`}
            />
          </div>
          {isShowPost && (
            <div className={styles[`post`]}>
              <PostComponent origin={`Lightbox`} post={post} />
            </div>
          )}
        </div>
      }
    />
  )
}

export default PhotoLightbox
