import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'

const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload, { dispatch, rejectWithValue }) => {
    const newPayload = {
      email: payload.email,
      password: payload.password,
      confirmPassword: payload.password,
      locale: localStorage.getItem('i18nextLng'),
    }
    try {
      dispatch(toggleLoading())
      const response = await axios.post(
        '/user/api/v1/auth/register',
        newPayload,
      )
      return response
    } catch (error) {
      const code = get(error, 'response.data.code')
      if (code === 'PEZZIE.400') {
        return rejectWithValue('Email already exists.')
      }
      return rejectWithValue(getErrorMessage(error))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default signUp
