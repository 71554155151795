import iconImg from 'assets/icons/img.svg'
import { ImagePicker } from 'commons/components'
import Tooltip from 'commons/components/Tooltip/Tooltip'
import UserPlaceholder from 'commons/resources/icons/default_Human Blue 174px.svg'
import PetPlaceholder from 'commons/resources/icons/default_Pet Blue 174px.svg'
import { extractMeta } from 'commons/slice'
import { getContentWithoutMention } from 'commons/utils/emojis'
import debounce from 'lodash/debounce'
import { Avatar } from 'pages/Main/main_routes/commons/component/index'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import InputForm from '../InputForm'
import * as styles from './post_form.module.scss'
import { getFileType } from 'commons/utils'

const PostForm = ({
  onSubmit,
  data,
  inputPlaceholder,
  type,
  isEdit,
  defaultValues = {},
}) => {
  const [metaPreview, setMetaPreview] = useState(null)
  const [text, setText] = useState(defaultValues.content || '')
  const [uploadedPictures, setUploadedPictures] = useState(
    defaultValues.files || [],
  )
  const [pictures, setPictures] = useState([])
  const [pictureBlobs, setPictureBlobs] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const detectLinkPreview = useCallback(
    debounce((url) => {
      dispatch(extractMeta(url)).then(({ error, payload }) => {
        if (error) {
          return Promise.reject(error)
        }
        setMetaPreview({ ...payload.data.data, url })
      })
    }, 1000),
    [],
  )

  const onRemoveMeta = () => {
    setMetaPreview(null)
  }

  const shortLinkPreview = (url) => {
    const originLink = new URL(url)
    return originLink.hostname || ''
  }

  const onRemoveUploadedPicture = (idx) => {
    setUploadedPictures([
      ...uploadedPictures.slice(0, idx),
      ...uploadedPictures.slice(idx + 1),
    ])
  }

  const onRemovePicture = (idx) => {
    setPictures([...pictures.slice(0, idx), ...pictures.slice(idx + 1)])
    setPictureBlobs([...pictureBlobs].splice(idx, 1))
  }

  const onSaveImage = (local, remote) => {
    setMetaPreview(null)
    setPictures(pictures.concat(local))
    setPictureBlobs(pictureBlobs.concat(remote))
  }

  const onSave = () => {
    const { content, mentions } = getContentWithoutMention(text)
    if (isEdit) {
      onSubmit(content, uploadedPictures, pictureBlobs)
      return
    }
    onSubmit(content, pictureBlobs, metaPreview, mentions).then((res) => {
      if (res.error) {
        return
      }
      setPictures([])
      setPictureBlobs([])
      setText('')
      setMetaPreview(null)
    })
  }

  const renderPreview = (picture) => {
    if (picture.startsWith('blob') || getFileType(picture) === 'video') {
      return (
        <video controls muted>
          <source src={picture} />
        </video>
      )
    }
    return <img src={picture} alt='post' />
  }

  const Placeholder =
    type === 'company' || !data.userId ? UserPlaceholder : PetPlaceholder

  const onInput = (val) => {
    setText(val)
    if (metaPreview) {
      return
    }
    const linkDetect = val.match(/(https?:\/\/[^\s]+)/g)
    linkDetect && detectLinkPreview(linkDetect[0])
  }

  return (
    <div className={styles['post-form']}>
      <div className={styles[`text-input`]}>
        <Avatar
          followIcon={false}
          size={{ width: '48px', height: '48px' }}
          nameShown={false}
          profileData={data}
          content={{
            image:
              data.profileImage || data.profile || data.avatar || Placeholder,
          }}
          profileType={type || (data.userId ? 'pet' : 'user')}
          id={data.id}
        />
        <InputForm
          value={text}
          onChangeValue={onInput}
          placeholder={inputPlaceholder}
        />
      </div>
      <div className={styles[`activity`]}>
        <Tooltip
          title={t('common:Add photos or videos less than 60mb')}
          place='left'
        >
          <div>
            <ImagePicker
              acceptVideo
              onSaveImage={onSaveImage}
              icon='media'
              component={
                <div className={styles['picker']}>
                  <img src={iconImg} alt='icon' />
                  {t('common:Photo/Video')}
                </div>
              }
            />
          </div>
        </Tooltip>
        <Button primary onClick={onSave}>
          {t(`common:${isEdit ? 'Save' : 'Post'}`)}
        </Button>
      </div>
      {!pictures.length && metaPreview && (
        <div
          className={`${styles['link-preview-container']} ${
            metaPreview.img ? styles['non-img'] : ''
          }`}
        >
          {metaPreview.img && (
            <div className={styles['link-preview-image']}>
              <img
                className={styles['preview-img']}
                width='150'
                height='150'
                src={metaPreview?.img}
                alt='link preview'
              />
            </div>
          )}
          <div className={styles['link-preview-content']}>
            <p>{shortLinkPreview(metaPreview.url).toUpperCase()}</p>
            <p className={styles['link-preview-title']}>
              {metaPreview.title || shortLinkPreview(metaPreview.url)}
            </p>
            <p className={styles['link-preview-description']}>
              {metaPreview.description}
            </p>
          </div>
          <span
            className={styles['link-preview-close']}
            onClick={onRemoveMeta}
          />
        </div>
      )}
      {!!(uploadedPictures.length || pictures.length) && (
        <div className={styles[`images`]}>
          {uploadedPictures.map((picture, idx) => (
            <div key={idx} className={styles[`image`]}>
              <span
                onClick={() => onRemoveUploadedPicture(idx)}
                className={styles[`icon--delete`]}
              />
              {renderPreview(picture.url)}
            </div>
          ))}
          {pictures.map((picture, idx) => (
            <div key={idx} className={styles[`image`]}>
              <span
                onClick={() => onRemovePicture(idx)}
                className={styles[`icon--delete`]}
              />
              {renderPreview(picture)}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(PostForm)
