import React from 'react'
import * as styles from './name.module.scss'
import { useDispatch, useStore } from 'react-redux'
import { avatarNavigate } from 'commons/slice'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

const Name = (props) => {
  const { selectStyle, name } = props
  const dispatch = useDispatch()
  const store = useStore().getState()
  const className = selectStyle
    ? `${styles[`name`]} ${styles[selectStyle]}`
    : `${styles[`name`]}`
  const history = useHistory()

  const onNavigate = () => {
    const currentUser = store?.appReducer?.currentUser
    const { id: activeUserId = '' } = currentUser
    const { id, profileType, profileData } = props

    if (id === activeUserId) {
      history.push(`/user`)
    } else {
      switch (profileType) {
        case 'user': {
          history.push(`/user/${id}`)
          break
        }
        case 'pet': {
          dispatch(avatarNavigate({ id, profileType })).then(
            ({ error, payload }) => {
              if (error) {
                return Promise.reject()
              }
              history.push(payload)
            },
          )
          break
        }

        case 'offspring':
          if (
            get(profileData, 'userid') === activeUserId ||
            get(profileData, 'userid.id') === activeUserId
          ) {
            history.push(`/litters/${id}`)
          } else {
            history.push(`/litter/${id}`)
          }

          break
        case 'company':
          history.push(`/companies/${id}`)
          break

        default:
          return
      }
    }
  }

  return (
    <div onClick={onNavigate} className={className}>
      {name}
    </div>
  )
}

export default Name
