import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const deleteLitter = createAsyncThunk(
  'main/deleteLitter',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      await axios.delete(`/pets/api/v1/pets/litters/${payload}`)
      return payload
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    }
  },
)

export default deleteLitter
