const en = {
  'Add a pup': 'Add a pup',
  'Breeder - kennel name - date of birth - males - females':
    'Breeder - kennel name - date of birth - males - females',
  Cancel: 'Cancel',
  'Choose a name for your litter...': 'Choose a name for your litter...',
  'Content is required in field': 'Content is required in field',
  'Copy-paste link to kennel club or other database':
    'Copy-paste link to kennel club or other database',
  Create: 'Create',
  'Create offspring': 'Create offspring',
  'Create parent': 'Create parent',
  'Date of birth': 'Date of birth',
  'Do you want to add another pet?': 'Do you want to add another pet?',
  Done: 'Done',
  'For breeding': 'For breeding',
  'Here you can tell more': 'Here you can tell more',
  'If you own the dam and it has a profile already you can add it from here':
    'If you own the dam and it has a profile already you can add it from here',
  'If you own the sire and it has a profile already you can add it from here':
    'If you own the sire and it has a profile already you can add it from here',
  Intact: 'Intact',
  'Invalid input in field': 'Invalid input in field',
  'Litter description placeholder':
    'Litter info: date of birth - breed - qty males/females - dam/sire - country',
  MHNature: 'MH Nature Description',
  'More detailed information': 'More detailed information',
  Next: 'Next',
  'No results found': 'No results found',
  'Official and/or petname': 'Official and/or petname',
  PKSearch: 'PK Search',
  Price: 'Price',
  'Ready to leave': 'Ready to leave',
  Save: 'Save',
  Select: 'Select',
  'Select breed': 'Select breed',
  'Select country': 'Select country',
  'Select species': 'Select species',
  Skip: 'Skip',
  'Unknown Organization': 'Unknown Organization',
  'affen pinscher': 'affen pinscher',
  'afghan hound': 'afghan hound',
  age: 'Age',
  agility: 'Agility',
  agouti: 'agouti',
  aidi: 'aidi',
  'airedale terrier': 'airedale terrier',
  akita: 'akita',
  'alaskan malamute': 'alaskan malamute',
  'alentejo mastiff': 'alentejo mastiff',
  'alpine dachsbracke': 'alpine dachsbracke',
  amber: 'amber',
  'american akita': 'american akita',
  'american cocker spaniel': 'american cocker spaniel',
  'american eskimo dog': 'american eskimo dog',
  'american fox hound': 'american fox hound',
  'american hairless terrier': 'american hairless terrier',
  'american staffordshire terrier': 'american staffordshire terrier',
  'american toy fox terrier': 'american toy fox terrier',
  'american water spaniel': 'american water spaniel',
  'anatolian shepherd dog': 'anatolian shepherd dog',
  'anglo-francais de petite venerie': 'anglo-francais de petite venerie',
  'anglo-russian hound': 'anglo-russian hound',
  'appenzell cattle dog': 'appenzell cattle dog',
  apricot: 'apricot',
  'arabian greyhound': 'arabian greyhound',
  'ardennes cattle dog': 'ardennes cattle dog',
  ariegeois: 'ariegeois',
  'artesian-norman basset': 'artesian-norman basset',
  assistant: 'Assistant Dog',
  'australian cattle dog': 'australian cattle dog',
  'australian kelpie': 'australian kelpie',
  'australian shepherd': 'australian shepherd',
  'australian silky terrier': 'australian silky terrier',
  'australian stumpy tail cattle dog': 'australian stumpy tail cattle dog',
  'australian terrier': 'australian terrier',
  'austrian black and tan hound': 'austrian black and tan hound',
  'austrian pinscer': 'austrian pinscer',
  availability: 'Availability',
  availableForBreeding: 'For stud',
  azawakh: 'azawakh',
  'azores cattle dog': 'azores cattle dog',
  basenji: 'basenji',
  'basset fauve de bretagne': 'basset fauve de bretagne',
  'basset hound': 'basset hound',
  'baverian mountain scenthound': 'baverian mountain scenthound',
  beagle: 'beagle',
  'beagle-harrier': 'beagle-harrier',
  'bearded collie': 'bearded collie',
  beauceron: 'beauceron',
  'bedlington terrier': 'bedlington terrier',
  beige: 'beige',
  'belgian griffon': 'belgian griffon',
  'belgian shepherd dog, groenendael': 'belgian shepherd dog, groenendael',
  'belgian shepherd dog, laekenois': 'belgian shepherd dog, laekenois',
  'belgian shepherd dog, malinois': 'belgian shepherd dog, malinois',
  'belgian shepherd dog, tervueren': 'belgian shepherd dog, tervueren',
  belton: 'belton',
  'bergamasco shepherd dog': 'bergamasco shepherd dog',
  'berger picard': 'berger picard',
  'bernese mountain dog': 'bernese mountain dog',
  'bichon frize': 'bichon frize',
  'biewer-terrieri': 'biewer-terrieri',
  billy: 'billy',
  biscuit: 'biscuit',
  black: 'black',
  'black & tan': 'black & tan',
  'black & tan coon hound': 'black & tan coon hound',
  'black fringes': 'black fringes',
  'black terrier': 'black terrier',
  blackbrindle: 'blackbrindle',
  blaffa: 'blaffa',
  blaireau: 'blaireau',
  blenheim: 'blenheim',
  'blood hound': 'blood hound',
  blue: 'blue',
  'blue cascony basset': 'blue cascony basset',
  'blue merle': 'blue merle',
  'blue picardy spaniel': 'blue picardy spaniel',
  'bluetick coonhound': 'bluetick coonhound',
  'bohemian wire-haired pointing griffon':
    'bohemian wire-haired pointing griffon',
  bolognese: 'bolognese',
  'border collie': 'border collie',
  'border terrier': 'border terrier',
  borzoi: 'borzoi',
  'bosanski ostodlaki gonic barak': 'bosanski ostodlaki gonic barak',
  'boston terrier': 'boston terrier',
  'bourbonnais pointing dog': 'bourbonnais pointing dog',
  'braque de l`ariege': 'braque de l`ariege',
  'braque d´auvergne': 'braque d´auvergne',
  'braque francais, type gascogne d´auvergne':
    'braque francais, type gascogne d´auvergne',
  'brazilian terrier': 'brazilian terrier',
  breed: 'Breed',
  breeding: 'For breeding',
  bretony: 'bretony',
  briard: 'briard',
  brindle: 'brindle',
  'briquet griffon vendee': 'briquet griffon vendee',
  broholmer: 'broholmer',
  bronze: 'bronze',
  'brown ': 'brown ',
  'brussels griffon': 'brussels griffon',
  'bull terrier': 'bull terrier',
  bulldog: 'bulldog',
  bullmastiff: 'bullmastiff',
  'burgos pointing dog': 'burgos pointing dog',
  'cairn terrier': 'cairn terrier',
  'canaan dog': 'canaan dog',
  'canadian eskimo dog': 'canadian eskimo dog',
  caniCross: 'Cani Cross',
  'cao de castro laboreiro': 'cao de castro laboreiro',
  cat: 'Cat',
  'catalan sheepdog, smooth': 'catalan sheepdog, smooth',
  'catalan sheepdog, wirehaired': 'catalan sheepdog, wirehaired',
  'caucasian shepherd dog': 'caucasian shepherd dog',
  'cavalier king charles spaniel': 'cavalier king charles spaniel',
  'central asian shepherd dog': 'central asian shepherd dog',
  'cesky terrier': 'cesky terrier',
  champagne: 'champagne',
  charcoal: 'charcoal',
  'chesapeake bay retriever': 'chesapeake bay retriever',
  chestnut: 'chestnut',
  'chien d´artois': 'chien d´artois',
  'chihuahua, long coat': 'chihuahua, long coat',
  'chihuahua, smooth coat': 'chihuahua, smooth coat',
  'chinese crested dog': 'chinese crested dog',
  identificationNumber: 'Microchip number/identification',
  chocolate: 'chocolate',
  'chodsky pes': 'chodsky pes',
  'chow chow': 'chow chow',
  'ciobanesc romanesc de bucovina ': 'ciobanesc romanesc de bucovina ',
  'cirneco dell`etna': 'cirneco dell`etna',
  'clumber spaniel': 'clumber spaniel',
  'coarse-haired istrian hound': 'coarse-haired istrian hound',
  coat: 'Coat',
  'cocker spaniel': 'cocker spaniel',
  'cockerpoo (cocker-poodle), large': 'cockerpoo (cocker-poodle), large',
  'cockerpoo (cocker-poodle), medium': 'cockerpoo (cocker-poodle), medium',
  'cockerpoo (cocker-poodle), small': 'cockerpoo (cocker-poodle), small',
  'collie, rough': 'collie, rough',
  'collie, smooth': 'collie, smooth',
  color: 'Color',
  copper: 'copper',
  'cor do monte': 'cor do monte',
  'coton de tulear': 'coton de tulear',
  cream: 'cream',
  'cream markings': 'cream markings',
  'croatian sheepdog': 'croatian sheepdog',
  'curly coated retriever': 'curly coated retriever',
  curlyCoated: 'Curly coated',
  'czeslovakian wolfdog': 'czeslovakian wolfdog',
  'dachshund miniature, long-haired': 'dachshund miniature, long-haired',
  'dachshund miniature, smooth-haired': 'dachshund miniature, smooth-haired',
  'dachshund miniature, wire-haired': 'dachshund miniature, wire-haired',
  'dachshund, standard, long-haired': 'dachshund, standard, long-haired',
  'dachshund, standard, smooth-haired': 'dachshund, standard, smooth-haired',
  'dachshund, standard, wire-haired': 'dachshund, standard, wire-haired',
  dalmatian: 'dalmatian',
  dam: 'Dam',
  dance: 'Dog Dance',
  'dandie dinmont terrier': 'dandie dinmont terrier',
  'danish spitz': 'danish spitz',
  'danish-swedish farm dog': 'danish-swedish farm dog',
  'dapple tan': 'dapple tan',
  dateOfBirth: 'Date of birth',
  deerhound: 'deerhound',
  description: 'Description',
  doberman: 'doberman',
  dobo: 'Dobo',
  dog: 'Dog',
  'dogo argentino': 'dogo argentino',
  'dogo canario': 'dogo canario',
  'dogue de bordeaux': 'dogue de bordeaux',
  domino: 'domino',
  'double dapple': 'double dapple',
  'drentse partridge dog': 'drentse partridge dog',
  'dutch shepherd dog, long-haired': 'dutch shepherd dog, long-haired',
  'dutch shepherd dog, rough-haired': 'dutch shepherd dog, rough-haired',
  'dutch shepherd dog, short-haired': 'dutch shepherd dog, short-haired',
  'dutch smoushond': 'dutch smoushond',
  'east siperian laika': 'east siperian laika',
  'east-european shepherd ': 'east-european shepherd ',
  'english foxhound': 'english foxhound',
  'english setter': 'english setter',
  'english springer spaniel': 'english springer spaniel',
  'english toy terrier': 'english toy terrier',
  'entlebuch cattle dog': 'entlebuch cattle dog',
  'epagneul de saint-usage': 'epagneul de saint-usage',
  'estonian hound': 'estonian hound',
  eurasier: 'eurasier',
  fako: 'fako',
  'fako mazkos': 'fako mazkos',
  fauve: 'fauve',
  fawn: 'fawn',
  female: 'Female',
  'field spaniel': 'field spaniel',
  'fila brasileiro': 'fila brasileiro',
  'Dog breeds': 'Dog breeds',
  mixed: 'Mixbreeds',
  'finnish hound': 'finnish hound',
  'finnish lapponian dog': 'finnish lapponian dog',
  'finnish spitz': 'finnish spitz',
  'flanders cattle dog': 'flanders cattle dog',
  'flat coated retriever': 'flat coated retriever',
  forSales: 'For sales',
  'fox terrier, smooth-haired': 'fox terrier, smooth-haired',
  'fox terrier, wire-haired': 'fox terrier, wire-haired',
  'francais blanc et noir': 'francais blanc et noir',
  'francais blanc et orange': 'francais blanc et orange',
  'francais tricolore': 'francais tricolore',
  'french bulldog': 'french bulldog',
  'french pointing dog -pyrenean type': 'french pointing dog -pyrenean type',
  'french spaniel': 'french spaniel',
  'french water dog': 'french water dog',
  'french wire-haired korthals pointing griffon':
    'french wire-haired korthals pointing griffon',
  fringes: 'fringes',
  'frisian pointing dog': 'frisian pointing dog',
  'frisian water dog': 'frisian water dog',
  'full markings': 'full markings',
  gender: 'Sex',
  'german boxer': 'german boxer',
  'german hound': 'german hound',
  'german hunting terrier': 'german hunting terrier',
  'german pinscher': 'german pinscher',
  'german rough-haired pointer': 'german rough-haired pointer',
  'german shepherd dog': 'german shepherd dog',
  'german shepherd, long and harsh outer coat':
    'german shepherd, long and harsh outer coat',
  'german short-haired pointer': 'german short-haired pointer',
  'german spaniel': 'german spaniel',
  'german wire-haired pointer': 'german wire-haired pointer',
  'giant schnauzer, black': 'giant schnauzer, black',
  'giant schnauzer, pepper & salt': 'giant schnauzer, pepper & salt',
  ginger: 'ginger',
  'glen of imal terrier': 'glen of imal terrier',
  golden: 'golden',
  'golden retriever': 'golden retriever',
  'gonczy polski': 'gonczy polski',
  'gordon setter': 'gordon setter',
  'gotland hound ': 'gotland hound ',
  'grand anglo-francais blanc et noir': 'grand anglo-francais blanc et noir',
  'grand anglo-francais blanc et orange':
    'grand anglo-francais blanc et orange',
  'grand anglo-francais tricolore': 'grand anglo-francais tricolore',
  'grand basset griffon vendéen': 'grand basset griffon vendéen',
  'grand gascon saintongeois': 'grand gascon saintongeois',
  'grand griffon vendeen': 'grand griffon vendeen',
  'great dane, black & harlequin': 'great dane, black & harlequin',
  'great dane, blue': 'great dane, blue',
  'great dane, yellow, & tiger': 'great dane, yellow, & tiger',
  'greater swiss mountain dog': 'greater swiss mountain dog',
  'greenland dog': 'greenland dog',
  grey: 'grey',
  'grey mask': 'grey mask',
  greyhound: 'greyhound',
  'griffon bleu de gasgogne': 'griffon bleu de gasgogne',
  'griffon fauve de bretagne': 'griffon fauve de bretagne',
  'griffon nivernais': 'griffon nivernais',
  grizzle: 'grizzle',
  'gross spitz (giand spitz), black and brown':
    'gross spitz (giand spitz), black and brown',
  'gross spitz (giand spitz), white': 'gross spitz (giand spitz), white',
  guidance: 'Guidance',
  hairless: 'hairless',
  'halden hound': 'halden hound',
  'hamilton hound': 'hamilton hound',
  'hamvas -bluegrey': 'hamvas -bluegrey',
  'hanoverian scenthound': 'hanoverian scenthound',
  harlequin: 'harlequin',
  harrier: 'harrier',
  havanese: 'havanese',
  height: 'Height',
  'hellenic hound': 'hellenic hound',
  hobbies: 'Hobbies',
  hokkaido: 'hokkaido',
  horse: 'Horse (supported in the future)',
  hovawart: 'hovawart',
  'hungarian greyhound': 'hungarian greyhound',
  'hungarian short-haired pointing dog': 'hungarian short-haired pointing dog',
  'hungarian wire-haired pointing dog': 'hungarian wire-haired pointing dog',
  hunting: 'Hunting',
  'hygen hound': 'hygen hound',
  'hällefors dog': 'hällefors dog',
  'ibizan hound, smooth-haired': 'ibizan hound, smooth-haired',
  'ibizan hound, wire-haired': 'ibizan hound, wire-haired',
  'icelandic sheepdog': 'icelandic sheepdog',
  countryOfBirth: 'Country of birth',
  'irish red & white setter': 'irish red & white setter',
  'irish red setter': 'irish red setter',
  'irish terrier': 'irish terrier',
  'irish water spaniel': 'irish water spaniel',
  'irish wolfhound': 'irish wolfhound',
  isabella: 'isabella',
  'istrian course-haired hound': 'istrian course-haired hound',
  'istrian short-haired hound': 'istrian short-haired hound',
  'italian corso dog': 'italian corso dog',
  'italian greyhound': 'italian greyhound',
  'italian hound, course-haired': 'italian hound, course-haired',
  'italian hound, short haired': 'italian hound, short haired',
  'italian pointing dog': 'italian pointing dog',
  'italian wire-haired pointing dog': 'italian wire-haired pointing dog',
  'jack russell terrier': 'jack russell terrier',
  'japanese chin': 'japanese chin',
  'japanese spitz': 'japanese spitz',
  'japanese terrier': 'japanese terrier',
  'jet black': 'jet black',
  kai: 'kai',
  karamis: 'karamis',
  'karelian bear dog': 'karelian bear dog',
  'karst shepherd dog': 'karst shepherd dog',
  kennelClubRegistered: 'Kennel club registered',
  registeredKennelName: 'Registered kennel name',
  'kerry blue terrier': 'kerry blue terrier',
  kimo: 'kimo',
  'king charles spaniel': 'king charles spaniel',
  'kintamani-bali dog': 'kintamani-bali dog',
  kishu: 'kishu',
  'kleinspitz (miniature spitz), black and brown':
    'kleinspitz (miniature spitz), black and brown',
  'kleinspitz (miniature spitz), other colours':
    'kleinspitz (miniature spitz), other colours',
  'kleinspitz (miniature spitz), white': 'kleinspitz (miniature spitz), white',
  komondor: 'komondor',
  kooikerhondje: 'kooikerhondje',
  kopo: 'kopo',
  'korea jindo dog': 'korea jindo dog',
  kromfohrländer: 'kromfohrländer',
  kuvasz: 'kuvasz',
  'labradoodle (labrador-poodle), large':
    'labradoodle (labrador-poodle), large',
  'labradoodle (labrador-poodle), medium':
    'labradoodle (labrador-poodle), medium',
  'labradoodle (labrador-poodle), small':
    'labradoodle (labrador-poodle), small',
  labrador: 'labrador',
  'lagotto romagnolo': 'lagotto romagnolo',
  'lakeland terrier': 'lakeland terrier',
  'lancashire heeler': 'lancashire heeler',
  'landseere continental-european': 'landseere continental-european',
  'lapponian herder': 'lapponian herder',
  'large munsterlander': 'large munsterlander',
  lemon: 'lemon',
  leonberger: 'leonberger',
  'lhasa apso': 'lhasa apso',
  lilac: 'lilac',
  lion: 'lion',
  'little lion dog': 'little lion dog',
  liver: 'liver',
  lizard: 'Lizard (supported in the future)',
  longHair: 'Long hair',
  lookingForHome: 'Looking for home',
  mahongany: 'mahongany',
  'majorca mastiff': 'majorca mastiff',
  'majorca shepherd dog': 'majorca shepherd dog',
  male: 'Male',
  maltese: 'maltese',
  'maltipoo (poodle-maltese)': 'maltipoo (poodle-maltese)',
  'manchester terrier': 'manchester terrier',
  'maremmano-abruzzes sheepdog': 'maremmano-abruzzes sheepdog',
  markiesje: 'markiesje',
  marking: 'Marking',
  mastiff: 'mastiff',
  'maszkos fako': 'maszkos fako',
  merle: 'merle',
  message: 'Message',
  'mexican hairless, intermediate': 'mexican hairless, intermediate',
  'mexican hairless, miniature': 'mexican hairless, miniature',
  'mexican hairless, stantard': 'mexican hairless, stantard',
  'miniature american shepherd': 'miniature american shepherd',
  'miniature bull terrier': 'miniature bull terrier',
  'miniature pinscher': 'miniature pinscher',
  'miniature schnauzer, black': 'miniature schnauzer, black',
  'miniature schnauzer, black & silver': 'miniature schnauzer, black & silver',
  'miniature schnauzer, pepper & salt': 'miniature schnauzer, pepper & salt',
  'miniature schnauzer, white': 'miniature schnauzer, white',
  'mittelspitz (medium size spitz), black abd brown':
    'mittelspitz (medium size spitz), black abd brown',
  'mittelspitz (medium size spitz), other colours':
    'mittelspitz (medium size spitz), other colours',
  'mittelspitz (medium size spitz), white':
    'mittelspitz (medium size spitz), white',
  mix: 'Mix',
  'mixed-breed, large': 'mixed-breed, large',
  'mixed-breed, medium': 'mixed-breed, medium',
  'mixed-breed, small': 'mixed-breed, small',
  mondioring: 'Mondioring',
  'montenegrian mountain hound': 'montenegrian mountain hound',
  mottled: 'mottled',
  mudi: 'mudi',
  multicolor: 'multicolor',
  mushing: 'Mushing',
  name: 'Name',
  'napolin mastiff': 'napolin mastiff',
  neutered: 'Castrated/sterilized',
  newfoundland: 'newfoundland',
  No: 'No',
  'norfolk terrier': 'norfolk terrier',
  norrbottenspitz: 'norrbottenspitz',
  'norwegian buhund': 'norwegian buhund',
  'norwegian elkhund black': 'norwegian elkhund black',
  'norwegian elkhund grey': 'norwegian elkhund grey',
  'norwegian hound': 'norwegian hound',
  'norwegian lund hund': 'norwegian lund hund',
  'norwich terrier': 'norwich terrier',
  'nova scotia duck tolling retriever': 'nova scotia duck tolling retriever',
  obedience: 'Obedience',
  'old danish pointer': 'old danish pointer',
  'old english sheepdog': 'old english sheepdog',
  onBreedingTerms: 'On breeding terms',
  orange: 'orange',
  otterhound: 'otterhound',
  pale: 'pale',
  papillon: 'papillon',
  'parson russell terrier': 'parson russell terrier',
  particolor: 'particolor',
  patched: 'patched',
  'peekapoo (pegingese-poodle)': 'peekapoo (pegingese-poodle)',
  pegingese: 'pegingese',
  'pepper & salt': 'pepper & salt',
  'peruvian hairless dog, large': 'peruvian hairless dog, large',
  'peruvian hairless dog, medium': 'peruvian hairless dog, medium',
  'peruvian hairless dog, miniature': 'peruvian hairless dog, miniature',
  'petit basset griffon vendéen': 'petit basset griffon vendéen',
  'petit bleu de gascogne ': 'petit bleu de gascogne ',
  'petit gascon saintongeois ': 'petit gascon saintongeois ',
  phalène: 'phalène',
  'pharaoh hound': 'pharaoh hound',
  'picardy spaniel': 'picardy spaniel',
  piebald: 'piebald',
  pied: 'pied',
  pinto: 'pinto',
  plattenhund: 'plattenhund',
  plott: 'plott',
  'podenco canario': 'podenco canario',
  'podengo portuguese, smooth-haired large':
    'podengo portuguese, smooth-haired large',
  'podengo portuguese, smooth-haired medium':
    'podengo portuguese, smooth-haired medium',
  'podengo portuguese, wire-haired large':
    'podengo portuguese, wire-haired large',
  pointer: 'pointer',
  poitevin: 'poitevin',
  'polish greyhound': 'polish greyhound',
  'polish hound': 'polish hound',
  'polish lowland sheepdog': 'polish lowland sheepdog',
  pomeranian: 'pomeranian',
  'poodle medium, black, brown & white': 'poodle medium, black, brown & white',
  'poodle medium, grey, apricot & red': 'poodle medium, grey, apricot & red',
  'poodle miniature, black, brown & white':
    'poodle miniature, black, brown & white',
  'poodle miniature, grey, apricot & red':
    'poodle miniature, grey, apricot & red',
  'poodle standard, black, brown & white':
    'poodle standard, black, brown & white',
  'poodle standard, grey, apricot & red':
    'poodle standard, grey, apricot & red',
  porcelaine: 'porcelaine',
  'portuguese pointing dog': 'portuguese pointing dog',
  'portuguese sheepdog': 'portuguese sheepdog',
  'portuguese warren dog, medium, wire-haired':
    'portuguese warren dog, medium, wire-haired',
  'portuguese warren dog, miniature, smooth-haired':
    'portuguese warren dog, miniature, smooth-haired',
  'portuguese warren dog, miniature, wire-haired':
    'portuguese warren dog, miniature, wire-haired',
  'portuguese water dog': 'portuguese water dog',
  'posavski gonic': 'posavski gonic',
  post: 'Post',
  powderPuff: 'Powder puff',
  'prague ratter': 'prague ratter',
  price: 'Price',
  protection: 'Protection',
  pudelpointer: 'pudelpointer',
  pug: 'pug',
  'puli, other colors': 'puli, other colors',
  'puli, white': 'puli, white',
  pumi: 'pumi',
  pure: 'pure',
  'pyrenean mastiff': 'pyrenean mastiff',
  'pyrenean mountain dog': 'pyrenean mountain dog',
  'pyrenean sheepdog, longhaired': 'pyrenean sheepdog, longhaired',
  'pyrenean sheepdog, smooth-faced': 'pyrenean sheepdog, smooth-faced',
  rabbit: 'Rabbit (supported in the future)',
  'rabbit dachshund, long-haired': 'rabbit dachshund, long-haired',
  'rabbit dachshund, smooth-haired': 'rabbit dachshund, smooth-haired',
  'rabbit dachshund, wire-haired': 'rabbit dachshund, wire-haired',
  rallyObedience: 'Rally-Obedience',
  'rat terrier': 'rat terrier',
  'ratonero bodeguero andaluz': 'ratonero bodeguero andaluz',
  readyToLeave: 'Ready to leave',
  red: 'red',
  'red white': 'red white',
  'red, born pink': 'red, born pink',
  reddish: 'reddish',
  registrationCountry: 'Country of residence',
  registraionLink: 'Registration Link',
  registerNumber: 'Register number',
  registrationOrganization: 'Registration Organization',
  rescure: 'Rescue Dog Action',
  'rhodesian ridgeback': 'rhodesian ridgeback',
  roan: 'roan',
  rodent: 'Rodent (supported in the future)',
  'romanian carpathian shepherd dog': 'romanian carpathian shepherd dog',
  'romanian mioritic shepherd dog': 'romanian mioritic shepherd dog',
  rotweiler: 'rotweiler',
  ruby: 'ruby',
  'russian hound': 'russian hound',
  'russian spaniel': 'russian spaniel',
  'russian toy, long-haired': 'russian toy, long-haired',
  'russian toy, short-haired': 'russian toy, short-haired',
  'russian-european laika': 'russian-european laika',
  'russkaja tsvetnaja bolonka': 'russkaja tsvetnaja bolonka',
  rust: 'rust',
  sable: 'sable',
  saddleback: 'saddleback',
  'saint bernard, long haired': 'saint bernard, long haired',
  'saint bernard, short haired': 'saint bernard, short haired',
  'saint germain pointer': 'saint germain pointer',
  saluki: 'saluki',
  samoyed: 'samoyed',
  sand: 'sand',
  'sarloos wolfdog': 'sarloos wolfdog',
  schapendoes: 'schapendoes',
  'schiller hound': 'schiller hound',
  schipperke: 'schipperke',
  'schnoodle (schnauzer -poodle), large':
    'schnoodle (schnauzer -poodle), large',
  'schnoodle (schnauzer -poodle), medium':
    'schnoodle (schnauzer -poodle), medium',
  'schnoodle (schnauzer -poodle), small':
    'schnoodle (schnauzer -poodle), small',
  'scottish terrier': 'scottish terrier',
  seal: 'seal',
  'sealyham terrier': 'sealyham terrier',
  sedge: 'sedge',
  seeker: 'Seeker Dogs',
  'segugio maremmano': 'segugio maremmano',
  semenAvailable: 'Ability for semen shipping',
  senap: 'senap',
  'serbian hound': 'serbian hound',
  'serbian tricolor hound': 'serbian tricolor hound',
  'serra de estrela mountain dog, long coat':
    'serra de estrela mountain dog, long coat',
  'serra de estrela mountain dog, smooth coat':
    'serra de estrela mountain dog, smooth coat',
  sesame: 'sesame',
  'shar pei': 'shar pei',
  'shetland sheepdog': 'shetland sheepdog',
  shiba: 'shiba',
  'shih tzu': 'shih tzu',
  shikoku: 'shikoku',
  shortHair: 'Short hair',
  shows: 'Dog shows',
  'siberian husky': 'siberian husky',
  silver: 'silver',
  sire: 'Sire',
  'skye terrier': 'skye terrier',
  'slate merle': 'slate merle',
  'slovakian chuvach': 'slovakian chuvach',
  'slovakian hound': 'slovakian hound',
  'slovakian wire-haired pointing dog': 'slovakian wire-haired pointing dog',
  'small brabant griffon': 'small brabant griffon',
  'small munsterlander': 'small munsterlander',
  'small swiss hound, small bernese hound':
    'small swiss hound, small bernese hound',
  'small swiss hound, small jura hound': 'small swiss hound, small jura hound',
  'small swiss hounds, small schwyz hound':
    'small swiss hounds, small schwyz hound',
  'small swiss hounds,small luzerne hound':
    'small swiss hounds,small luzerne hound',
  smoothCoat: 'Smooth coat',
  'småland hound': 'småland hound',
  'soft coated wheaten terrier': 'soft coated wheaten terrier',
  solid: 'solid',
  'south russian shepherd dog': 'south russian shepherd dog',
  'spaniel de pont-audemer': 'spaniel de pont-audemer',
  'spanish greyhound': 'spanish greyhound',
  'spanish hound': 'spanish hound',
  'spanish mastiff': 'spanish mastiff',
  'spanish waterdog': 'spanish waterdog',
  species: 'Species',
  speckled: 'speckled',
  spotted: 'spotted',
  'staffordshire bull terrier': 'staffordshire bull terrier',
  'standard schnauzer, black': 'standard schnauzer, black',
  'standard schnauzer, pepper & salt': 'standard schnauzer, pepper & salt',
  steelgrey: 'steelgrey',
  'steirische rauhhaarbracke': 'steirische rauhhaarbracke',
  'sussex spaniel': 'sussex spaniel',
  'swedish dachsbracke': 'swedish dachsbracke',
  'swedish elkhound, white': 'swedish elkhound, white',
  'swedish elkhund': 'swedish elkhund',
  'swedish lapphund': 'swedish lapphund',
  'swedish vallhund': 'swedish vallhund',
  'swiss hound - schwyrz hound': 'swiss hound - schwyrz hound',
  'swiss hound -bernese hound': 'swiss hound -bernese hound',
  'swiss hound -jura hound': 'swiss hound -jura hound',
  'swiss hound -luzerne hound': 'swiss hound -luzerne hound',
  'taiwan dog': 'taiwan dog',
  tan: 'tan',
  'tan dapple': 'tan dapple',
  'tan sable': 'tan sable',
  'tan with white markings': 'tan with white markings',
  'tan/golden': 'tan/golden',
  tanpiepald: 'tanpiepald',
  'tatra shepherd dog': 'tatra shepherd dog',
  'tenderfield terrier': 'tenderfield terrier',
  'thai bangkaew dog': 'thai bangkaew dog',
  'thai ridgeback': 'thai ridgeback',
  'tibetan mastiff': 'tibetan mastiff',
  'tibetan spaniel': 'tibetan spaniel',
  'tibetan terrier': 'tibetan terrier',
  'tiroler bracke': 'tiroler bracke',
  title: 'Title',
  tobacco: 'tobacco',
  tornjak: 'tornjak',
  tosa: 'tosa',
  'treeing walker coonhound ': 'treeing walker coonhound ',
  tricolor: 'tricolor',
  'trout colored roan': 'trout colored roan',
  twocolor: 'twocolor',
  unavailable: 'Unavailable',
  'uruguayan cimarron': 'uruguayan cimarron',
  'volpino italiano': 'volpino italiano',
  waterRescure: 'Water rescue',
  weight: 'Weight',
  'weimaraner, long-haired': 'weimaraner, long-haired',
  'weimaraner, short-haired': 'weimaraner, short-haired',
  'welsh corgi cardigan': 'welsh corgi cardigan',
  'welsh corgi pembroke': 'welsh corgi pembroke',
  'welsh springer spaniel': 'welsh springer spaniel',
  'welsh terrier': 'welsh terrier',
  'west highland white terrier': 'west highland white terrier',
  'west siberian laika': 'west siberian laika',
  'westfälische dachsbracke ': 'westfälische dachsbracke ',
  wheat: 'wheat',
  wheaten: 'wheaten',
  whippet: 'whippet',
  white: 'white',
  'white and tan': 'white and tan',
  'white particolor': 'white particolor',
  'white swiss shepherd dog': 'white swiss shepherd dog',
  'white with black spots': 'white with black spots',
  'white with brown spots': 'white with brown spots',
  'white with patches': 'white with patches',
  'white without patches': 'white without patches',
  'wild board': 'wild board',
  'wild patterned': 'wild patterned',
  wireHaired: 'Wire haired',
  'with badger markings': 'with badger markings',
  'with beige markings': 'with beige markings',
  'with biscuit markings': 'with biscuit markings',
  'with black and brown markings': 'with black and brown markings',
  'with black and tan markings': 'with black and tan markings',
  'with black and white markings': 'with black and white markings',
  'with black cover hair': 'with black cover hair',
  'with black mandle': 'with black mandle',
  'with black markings': 'with black markings',
  'with black mask': 'with black mask',
  'with black nose': 'with black nose',
  'with black patch': 'with black patch',
  'with black spots': 'with black spots',
  'with blackbrindle markings': 'with blackbrindle markings',
  'with blue and tan markings': 'with blue and tan markings',
  'with blue markings': 'with blue markings',
  'with brindle markings': 'with brindle markings',
  'with brindle patch': 'with brindle patch',
  'with brown and black markings': 'with brown and black markings',
  'with brown and white markings': 'with brown and white markings',
  'with brown blaze': 'with brown blaze',
  'with brown markings': 'with brown markings',
  'with brown mask': 'with brown mask',
  'with brown nose': 'with brown nose',
  'with brown patches': 'with brown patches',
  'with brown/grey markings': 'with brown/grey markings',
  'with cream markings': 'with cream markings',
  'with dark markings': 'with dark markings',
  'with dark mask': 'with dark mask',
  'with fawn and brown markings': 'with fawn and brown markings',
  'with fawn markings': 'with fawn markings',
  'with gold markings': 'with gold markings',
  'with grey markings': 'with grey markings',
  'with greyfawn markings': 'with greyfawn markings',
  'with grizzle markings': 'with grizzle markings',
  'with lemon markings': 'with lemon markings',
  'with liver nose': 'with liver nose',
  'with liver spots': 'with liver spots',
  'with mask': 'with mask',
  'with orange markings': 'with orange markings',
  'with pale markings': 'with pale markings',
  'with red markings': 'with red markings',
  'with redbrown and black markings': 'with redbrown and black markings',
  'with redbrown markings': 'with redbrown markings',
  'with sable markings': 'with sable markings',
  'with sand markings': 'with sand markings',
  'with tan markings': 'with tan markings',
  'with tricolor markings': 'with tricolor markings',
  'with white': 'with white',
  'with white and brindle markings': 'with white and brindle markings',
  'with white and tan markings': 'with white and tan markings',
  'with white markings': 'with white markings',
  'with white markings roaned': 'with white markings roaned',
  'with white markings ticked': 'with white markings ticked',
  'with yellow markings': 'with yellow markings',
  'without white': 'without white',
  'woflspitz (keeshond)': 'woflspitz (keeshond)',
  wolfgrey: 'wolfgrey',
  'yakutian laika': 'yakutian laika',
  yellow: 'yellow',
  Yes: 'Yes',
  'yogoslavian shepherd dog - sharplanina':
    'yogoslavian shepherd dog - sharplanina',
  'yorkipoo (yorkshire terrier - toy poodle)':
    'yorkipoo (yorkshire terrier - toy poodle)',
  'yorkshire terrier': 'yorkshire terrier',
  'Patern (Marking)': 'Patern (Marking)',
  bicolor: 'bicolor',
  'burmese pointed': 'burmese pointed',
  'himalayan pointed': 'himalayan pointed',
  'blotched tabby': 'blotched tabby',
  mitted: 'mitted',
  'unspecified tabby': 'unspecified tabby',
  shatted: 'shatted',
  shell: 'shell',
  snowshoe: 'snowshoe',
  'ticked tabby': 'ticked tabby',
  'mackerel tabby': 'mackerel tabby',
  tipped: 'tipped',
  'tonkinese pointed': 'tonkinese pointed',
  'siamese pointed': 'siamese pointed',
  'spotted tabby': 'spotted tabby',
  'any other amount of white': 'any other amount of white',
  van: 'van',
  'other patern': 'other patern',
  'amber tortie': 'amber tortie',
  'apricot based on cream': 'apricot based on cream',
  'beige tortie': 'beige tortie',
  'black tortie': 'black tortie',
  'blue tortie': 'blue tortie',
  brown: 'brown',
  'brown tortie': 'brown tortie',
  'caramel based on blue': 'caramel based on blue',
  'caramel based on creme': 'caramel based on creme',
  'caramel based on fawn/beige': 'caramel based on fawn/beige',
  'caramel based on lilac': 'caramel based on lilac',
  'caramel, where the base colour is unknown':
    'caramel, where the base colour is unknown',
  'caramel/apricot': 'caramel/apricot',
  'chokolate tortie': 'chokolate tortie',
  'cinamon/red': 'cinamon/red',
  cinnamon: 'cinnamon',
  'cinnamon tortie': 'cinnamon tortie',
  'cream based amber': 'cream based amber',
  'creme amber': 'creme amber',
  'fawn tortie': 'fawn tortie',
  'golden silver': 'golden silver',
  grizzly: 'grizzly',
  'light tortie amber': 'light tortie amber',
  'lilac tortie': 'lilac tortie',
  'non-regognized variety': 'non-regognized variety',
  'red (aby/som)': 'red (aby/som)',
  'red based on amber': 'red based on amber',
  'red tortie (aby/som)': 'red tortie (aby/som)',
  ruddy: 'ruddy',
  tawny: 'tawny',
  tortie: 'tortie',
  'tortie silvertabby': 'tortie silvertabby',
  'other color': 'other color',
  'curly coated': 'curly coated',
  'long-coat': 'long-coat',
  'powder puff': 'powder puff',
  'semi-long coat': 'semi-long coat',
  'short-coat': 'short-coat',
  'smooth-coat': 'smooth-coat',
  'wire-hair': 'wire-hair',
  'Cat breeds (FIFe)': 'Cat breeds (FIFe)',
  'Cat breeds other': 'Cat breeds other',
  abyssinian: 'abyssinian',
  'american curl, longhair': 'american curl, longhair',
  'american curl, shorthair': 'american curl, shorthair',
  balinese: 'balinese',
  bengali: 'bengali',
  bombay: 'bombay',
  'british, longhair': 'british, longhair',
  'british, shorthair': 'british, shorthair',
  burmese: 'burmese',
  burmilla: 'burmilla',
  chartreux: 'chartreux',
  'cornish rex': 'cornish rex',
  cymric: 'cymric',
  'devon rex': 'devon rex',
  'don sphynx': 'don sphynx',
  'egyptian mau': 'egyptian mau',
  european: 'european',
  exotic: 'exotic',
  'german rex': 'german rex',
  'housecat, longhair': 'housecat, longhair',
  'housecat, shorthair': 'housecat, shorthair',
  'japanin bobtail': 'japanin bobtail',
  'karelian bobtail, longhair': 'karelian bobtail, longhair',
  'karelian bobtail, shorthair': 'karelian bobtail, shorthair',
  korat: 'korat',
  'kurilian bobtail, longhair': 'kurilian bobtail, longhair',
  'kurilian bobtail, shorthair': 'kurilian bobtail, shorthair',
  'la perm, longhair': 'la perm, longhair',
  'la perm, shorthair': 'la perm, shorthair',
  lykoi: 'lykoi',
  'maine coon': 'maine coon',
  manx: 'manx',
  'neva masquerade': 'neva masquerade',
  'norwegian forrest cat': 'norwegian forrest cat',
  ocicat: 'ocicat',
  'oriental longhair': 'oriental longhair',
  'oriental shorthair': 'oriental shorthair',
  persian: 'persian',
  peterbald: 'peterbald',
  ragdoll: 'ragdoll',
  'russian blue': 'russian blue',
  'sacred birman': 'sacred birman',
  'selkirk rex, longthair': 'selkirk rex, longthair',
  'selkirk rex, shorthair': 'selkirk rex, shorthair',
  siamese: 'siamese',
  siberian: 'siberian',
  singapura: 'singapura',
  sokoke: 'sokoke',
  somali: 'somali',
  sphynx: 'sphynx',
  thai: 'thai',
  'turkish angora': 'turkish angora',
  'turkish van': 'turkish van',
  aegean: 'aegean',
  'american bobtail, longhair': 'american bobtail, longhair',
  'american bobtail, shorthair': 'american bobtail, shorthair',
  'american wirehair': 'american wirehair',
  'american, shorthair': 'american, shorthair',
  anatoli: 'anatoli',
  "aphrodite's giant, longhair": "aphrodite's giant, longhair",
  "aphrodite's giant, shorthair": "aphrodite's giant, shorthair",
  'arabian mau': 'arabian mau',
  'asian, longhair': 'asian, longhair',
  'asian, semi-longhair': 'asian, semi-longhair',
  'asian, shorthair': 'asian, shorthair',
  'australian bombay': 'australian bombay',
  'australian mist': 'australian mist',
  bambino: 'bambino',
  'bengal, longhair': 'bengal, longhair',
  'bengal, shorthair': 'bengal, shorthair',
  birman: 'birman',
  'bohemian rex': 'bohemian rex',
  'brazilian, shorthair': 'brazilian, shorthair',
  'burmese, american': 'burmese, american',
  'burmese, european': 'burmese, european',
  'burmilla, longhair': 'burmilla, longhair',
  'burmilla, shorthaired': 'burmilla, shorthaired',
  'california spangled': 'california spangled',
  'celtic, shorthair': 'celtic, shorthair',
  ceylon: 'ceylon',
  'chantilly-tiffany': 'chantilly-tiffany',
  chausie: 'chausie',
  cheetoh: 'cheetoh',
  'chinchilla persian, longhair': 'chinchilla persian, longhair',
  'chinese li hua': 'chinese li hua',
  classicat: 'classicat',
  'colorpoint, shorthair': 'colorpoint, shorthair',
  'cymric/manx, longhair': 'cymric/manx, longhair',
  'deutsch, langhaar': 'deutsch, langhaar',
  'foreign white, longhair': 'foreign white, longhair',
  'foreign white, shorthair': 'foreign white, shorthair',
  'havana brown': 'havana brown',
  'highlander, longhair': 'highlander, longhair',
  'highlander, shorthair': 'highlander, shorthair',
  'himalayan colorpoint': 'himalayan colorpoint',
  'isle of man, longhair': 'isle of man, longhair',
  'isle of man, shorthair': 'isle of man, shorthair',
  kanaani: 'kanaani',
  'khao manee': 'khao manee',
  'landrace cat, longhair': 'landrace cat, longhair',
  'landrace cat, shorthair': 'landrace cat, shorthair',
  'laperm, longhair': 'laperm, longhair',
  'laperm, shorthair': 'laperm, shorthair',
  mandalay: 'mandalay',
  'mekong bobtail': 'mekong bobtail',
  minskin: 'minskin',
  'minuet/napoleon, longhair': 'minuet/napoleon, longhair',
  'minuet/napoleon, shorthair': 'minuet/napoleon, shorthair',
  'mixed breed': 'mixed breed',
  'munchkin, longhair': 'munchkin, longhair',
  'munchkin, shorthair': 'munchkin, shorthair',
  nebelung: 'nebelung',
  'ojos azules, longhair': 'ojos azules, longhair',
  'ojos azules, shorthair': 'ojos azules, shorthair',
  'original, longhair/doll face persian':
    'original, longhair/doll face persian',
  'other breed': 'other breed',
  'pixiebob, longhair': 'pixiebob, longhair',
  'pixiebob, shorthair': 'pixiebob, shorthair',
  ragamuffin: 'ragamuffin',
  savannah: 'savannah',
  'scottish fold, longhair': 'scottish fold, longhair',
  'scottish fold, shorthair': 'scottish fold, shorthair',
  'scottish straight, longhair': 'scottish straight, longhair',
  'scottish straight, shorthair': 'scottish straight, shorthair',
  serengeti: 'serengeti',
  'seychellois, longhair': 'seychellois, longhair',
  'seychellois, shorthair': 'seychellois, shorthair',
  tiffanie: 'tiffanie',
  'tonkinese, longhair': 'tonkinese, longhair',
  'tonkinese, shorthair': 'tonkinese, shorthair',
  toyger: 'toyger',
  'ukrainian levkoy': 'ukrainian levkoy',
  'ural rex, longhair': 'ural rex, longhair',
  'ural rex, shorthair': 'ural rex, shorthair',
  'yourk/yourk chocolate': 'yourk/yourk chocolate',
  other: 'other',
  'litter name': 'Litter name',
  'Kennel name': 'Kennel name',
  'Registration country': 'Registration country',
  'Birthday / planned birthday': 'Birthday / planned birthday',
  birthday: 'Date of Birth',
  'Select sex': 'Select sex',
  'YYYY-MM-DD': 'YYYY-MM-DD',
  'Additional information': 'Additional information',
  'Pet URL': 'Pet URL',
  americanbulldog: 'americanbulldog',
  boerbel: 'boerbel',
  'continental bulldog': 'continental bulldog',
  'silky greyhound': 'silky greyhound',
  Australianlabradoodle: 'Australianlabradoodle',
  inMemorial: 'In memorial',
  'In Memorial - a digital final resting place':
    'In Memorial - a digital final resting place',
  'Registration countries': 'Registration countries',
}

export default en
