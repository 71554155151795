import { PROFILE_TYPE } from 'commons/constants'
import UserPlaceholder from 'commons/resources/icons/default_Human White 512px.svg'
import PetPlaceholder from 'commons/resources/icons/default_Pet White 512px.svg'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const placeHolderMapping = {
  [PROFILE_TYPE.USER]: UserPlaceholder,
  [PROFILE_TYPE.PET]: PetPlaceholder,
  [PROFILE_TYPE.COMPANY]: UserPlaceholder,
  default: UserPlaceholder,
}

const Wrapper = styled.div({
  display: 'flex',
  gap: 12,
})
const ImgWrapper = styled.div((props) => ({
  border: 'solid 1px var(--color-border)',
  borderRadius: '50%',
  width: props.width,
  height: props.height,
  position: 'relative',
  '&:after': {
    content: props.showStatus ? '""' : '',
    position: 'absolute',
    left: '90%',
    top: '90%',
    transform: 'translate(-50%, -50%)',
    width: 12,
    height: 12,
    border: 'solid 2px var(--color-white)',
    borderRadius: '50%',
    backgroundColor: props.isOnline ? '#31a24c' : '#808080',
  },
  img: {
    borderRadius: '50%',
  },
}))
const Content = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  gap: 4,
})
const Title = styled.span({ color: '#393A4F', fontWeight: 600 })
const Desc = styled.span({
  color: '#757A91',
  fontSize: 14,
  lineHeight: '14.56px',
})

const Avatar = ({
  src,
  link,
  type,
  width = 48,
  height = 48,
  title,
  desc,
  showStatus,
  id,
}) => {
  const placeHolder = placeHolderMapping[type] || placeHolderMapping.default

  const { userStatus } = useSelector((state) => state.appReducer)

  return (
    <Link to={link}>
      <Wrapper>
        <ImgWrapper
          width={width}
          height={height}
          showStatus={showStatus && type === PROFILE_TYPE.USER}
          isOnline={userStatus[id]}
        >
          <img
            src={src || placeHolder}
            alt='avatar'
            width={width}
            height={height}
          />
        </ImgWrapper>
        {!!title && (
          <Content>
            {<Title>{title}</Title>}
            {desc && <Desc>{desc}</Desc>}
          </Content>
        )}
      </Wrapper>
    </Link>
  )
}

export default React.memo(Avatar)
