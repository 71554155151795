import { createSlice } from '@reduxjs/toolkit'
import fetchPetPosts from './fetchPetPosts'
import createPetPost from './createPetPost'
import deletePetPost from './deletePetPost'
import updatePetPost from './updatePetPost'
import fetchPetProfile from './fetchPetProfile'
import fetchPetHealthInfo from './fetchPetHealthInfo'
import updatePetWeight from './updatePetWeight'
import updatePetProfile from './updatePetProfile'
import deletePetImage from './deletePetImage'
import { PAGE_SIZE } from 'commons/constants'
import fetchPetFiles from './fetchPetFiles'
import createPetFile from './createPetFile'
import deletePetFile from './deletePetFile'

const initialState = {
  posts: {
    offset: 0,
    limit: PAGE_SIZE,
    data: [],
    isFetchingPosts: false,
    isFetchPostsEnded: false,
    isCreateLoading: false,
    isCreateSuccess: false,
    isCreateFail: false,
  },
  profile: {
    isFetchingPetProfile: false,
    data: {},
    error: '',
  },
  health: {
    data: {},
    isFetchingHealthInfo: false,
  },
  files: {
    offset: 0,
    limit: 16,
    data: [],
    isFetching: false,
    isFetchEnded: false,
    isCreateSuccess: false,
  },
}

const petProfileSlice = createSlice({
  name: 'petProfileReducer',
  initialState,
  reducers: {
    resetPetProfile: () => {
      return initialState
    },
    resetPetPosts: (state) => {
      state.posts = initialState.posts
    },
  },
  extraReducers: {
    [fetchPetPosts.pending]: (state) => {
      state.posts.isFetchingPosts = true
    },
    [fetchPetPosts.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.posts = {
        ...state.posts,
        offset: offset + limit,
        isFetchPostsEnded: offset + limit >= totalRecords,
        data: state.posts.data.concat(records),
        isFetchingPosts: false,
      }
    },
    [fetchPetPosts.rejected]: (state) => {
      state.posts.isFetchingPosts = false
    },
    [createPetPost.pending]: (state) => {
      state.posts.isCreateLoading = true
    },
    [createPetPost.fulfilled]: (state, { payload }) => {
      state.posts = {
        ...state.posts,
        isCreateLoading: false,
        isCreateSuccess: true,
        data: [payload, ...state.posts.data],
      }
    },
    [createPetPost.rejected]: (state) => {
      state.posts = {
        ...state.posts,
        isCreateFail: true,
        isCreateLoading: false,
      }
    },
    [deletePetPost.pending]: () => {},
    [deletePetPost.fulfilled]: (state, { payload }) => {
      const data = state.posts.data
      const postIdx = data.findIndex(({ id }) => id === payload)
      if (postIdx < 0) {
        return state
      }
      data.splice(postIdx, 1)
    },
    [updatePetPost.fulfilled]: (state, { payload }) => {
      const data = state.posts.data
      const postIdx = data.findIndex(({ id }) => id === payload.id)
      if (postIdx < 0) {
        return state
      }
      data[postIdx] = payload
    },
    [fetchPetProfile.pending]: (state) => {
      state.profile.isFetchingPetProfile = true
    },
    [fetchPetProfile.fulfilled]: (state, { payload }) => {
      state.profile.isFetchingPetProfile = false
      state.profile.data = payload
    },
    [fetchPetProfile.rejected]: (state, { payload }) => {
      state.profile.isFetchingPetProfile = false
      state.error = payload
    },
    [fetchPetHealthInfo.pending]: (state) => {
      state.health.isFetchingHealthInfo = true
    },
    [fetchPetHealthInfo.fulfilled]: (state, { payload }) => {
      state.health.isFetchingHealthInfo = false
      state.health.data = payload
    },
    [fetchPetHealthInfo.rejected]: (state, { payload }) => {
      state.health.isFetchingHealthInfo = false
      state.error = payload
    },
    [updatePetWeight.rejected]: (state, { payload }) => {
      state.health.isFetchingHealthInfo = false
      state.error = payload
    },
    [updatePetProfile.fulfilled]: (state, { payload }) => {
      state.profile.data = { ...state.profile.data, ...payload }
    },
    [deletePetImage.fulfilled]: (state, { payload }) => {
      state.profile.data[payload] = null
    },
    [deletePetImage.rejected]: (state, { payload }) => {
      state.profile.error = payload
    },
    [fetchPetFiles.pending]: (state) => {
      state.files.isFetching = true
    },
    [fetchPetFiles.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.files = {
        ...state.files,
        offset: offset + limit,
        isFetchEnded: offset + limit >= totalRecords,
        data: offset ? state.files.data.concat(records) : records,
        isFetching: false,
      }
    },
    [fetchPetFiles.rejected]: (state) => {
      state.files.isFetching = false
    },
    [createPetFile.fulfilled]: (state, { payload }) => {
      state.files = {
        ...state.files,
        offset: state.files.offset++,
        data: [payload, ...state.files.data],
      }
    },
    [deletePetFile.fulfilled]: (state, { payload: s3Id }) => {
      const idx = state.files.data.findIndex((file) => file.s3Id === s3Id)
      if (idx >= 0) {
        state.files.data.splice(idx, 1)
        state.files.offset--
      }
    },
  },
})

const { actions, reducer } = petProfileSlice

export {
  fetchPetPosts,
  createPetPost,
  deletePetPost,
  updatePetPost,
  fetchPetProfile,
  fetchPetHealthInfo,
  updatePetWeight,
  updatePetProfile,
  deletePetImage,
  fetchPetFiles,
  createPetFile,
  deletePetFile,
}

export const { resetPetProfile, resetPetPosts } = actions

export default reducer
