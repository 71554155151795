import React from 'react'
import { Controller } from 'react-hook-form'
import { Label, Form, Grid, TextArea } from 'semantic-ui-react'
import FormErrorMessage from '../FormErrorMessage'
import PropTypes from 'prop-types'
import get from 'lodash/get'

const FormTextArea = ({
  label = '',
  name,
  control,
  error,
  required = false,
  iconPosition = 'left',
  rules,
  readOnly,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Form.Field readOnly={readOnly}>
          {label && (
            <Label
              as='label'
              className={get(rules, 'required') ? 'required' : ''}
            >
              {label}
            </Label>
          )}
          <Grid>
            {readOnly ? (
              field.value
            ) : (
              <>
                <TextArea {...field} {...rest} />
                <FormErrorMessage error={error} />
              </>
            )}
          </Grid>
        </Form.Field>
      )}
    />
  )
}

FormTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  iconPosition: PropTypes.string,
  rules: PropTypes.object,
}

export default React.memo(FormTextArea)
