export const fetchUserTestimonials = () => ({
  type: 'FETCH_USER_TESTIMONIALS',
})

export const fetchUserTestimonialsDone = (testimonials) => ({
  type: 'FETCH_USER_TESTIMONIALS_DONE',
  payload: {
    testimonials,
  },
})

export const updateUserTestimonials = (messageId, updateType) => ({
  type: 'UPDATE_USER_TESTIMONIALS',
  payload: { messageId, updateType },
})
