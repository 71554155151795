import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'
import { addError } from 'pages/Auth/slice'

const getUserProfile = createAsyncThunk(
  'app/getUserProfile',
  async (_payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get('/user/api/v1/users/me')
      return res.data.data
    } catch (error) {
      return rejectWithValue(dispatch(addError(getErrorMessage(error))))
    }
  },
)

export default getUserProfile
