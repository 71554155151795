export { default as countries } from './countries'
export * from './validations'

export const UPLOAD_ACTION = {
  POST: 'POST',
}

export const PAGE_SIZE = 5

export const PET_GENDER_OPTIONS = [
  {
    value: 'MALE',
    text: 'male',
  },
  {
    value: 'FEMALE',
    text: 'female',
  },
]

export const USER_GENDER_OPTIONS = [
  ...PET_GENDER_OPTIONS,
  {
    value: 'OTHER',
    text: 'other',
  },
]

export const GENDER_MAPPING = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
}

export const POST_TYPE = {
  USER: 'USER',
  PET: 'PET',
  COMPANY: 'COMPANY',
}

export const USER_LEVEL = {
  NORMAL: 'NORMAL',
  PREMIUM: 'PREMIUM',
  PRO: 'PRO',
  BUSINESS: 'BUSINESS',
}

export const USER_LEVEL_NUMBERS = {
  [USER_LEVEL.NORMAL]: 0,
  [USER_LEVEL.PREMIUM]: 1,
  [USER_LEVEL.PRO]: 2,
  [USER_LEVEL.BUSINESS]: 3,
}

export const FORM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
}

export const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
}

export const REACT_QUERY_STATE = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAI: 'fail',
}

export const RATING_MAPPING = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
}

export const USER_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

export const PROFILE_TYPE = {
  USER: 'USER',
  PET: 'PET',
  COMPANY: 'COMPANY',
  LITTER: 'LITTER',
  PERSONNEL: 'PERSONNEL',
}

export const PROFILE_STATUS = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
}
