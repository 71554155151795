import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pets: {
    data: [],
    isFetchingPets: false,
    isFetchPetsEnded: false,
    isFetchPetsSuccess: false,
  },
  litters: {
    data: [],
    isCreatingLitter: false,
    isCreateLitterSuccess: false,
  },
  unsavedLitter: {},
  transferModal: {
    searchResults: [],
  },
  transfers: {
    _ownReservationData: [],
    _ownOfferData: [],
    _receivedReservationData: [],
    _receivedOfferData: [],
  },
  isAddingChild: false,
  isAddingParent: false,
}

const petsSlice = createSlice({
  name: 'petsReducer',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: {},
})

const { actions, reducer } = petsSlice

export const { resetState } = actions

export * from './actions'

export default reducer
