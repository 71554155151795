import rootAxios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const getToken = () => {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch {
    return null
  }
}

const axios = rootAxios.create({ baseURL: API_URL })

axios.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

const uploadAxios = rootAxios.create({ baseURL: API_URL })
uploadAxios.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) config.headers['Authorization'] = 'Bearer ' + token
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)
export { axios, uploadAxios }

export default axios
