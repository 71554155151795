import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const readNotification = createAsyncThunk(
  'notification/readNotification',
  async (id, { rejectWithValue }) => {
    try {
      await axios.put(
        `/notification/api/v1/notification-auditings/${id}/marked`,
      )
      return id
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default readNotification
