import React from 'react'
import { Controller } from 'react-hook-form'
import { Form, Grid, Label, Dropdown } from 'semantic-ui-react'
import FormErrorMessage from '../FormErrorMessage'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { find, isArray } from 'lodash'

const FormSelect = ({
  label = '',
  name,
  error,
  control,
  iconPosition = 'left',
  onChange: customOnChange,
  rules,
  setValue,
  multiple,
  readOnly,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ...field } }) => (
        <Form.Field readOnly={readOnly}>
          {label && (
            <Label
              as='label'
              className={get(rules, 'required') ? 'required' : ''}
            >
              {label}
            </Label>
          )}
          <Grid>
            {readOnly ? (
              <span className='text'>
                {(multiple && value ? value : [value])
                  .map((item) =>
                    get(find(rest.options, { value: item }), 'text', ''),
                  )
                  .join(', ')}
              </span>
            ) : (
              <>
                <Dropdown
                  {...field}
                  {...rest}
                  multiple={multiple}
                  value={multiple && !value ? [] : value}
                  ref={() => {
                    field.ref({
                      focus: () => {},
                    })
                  }}
                  selection
                  onChange={(_e, { value }) => {
                    onChange(value)
                    customOnChange && customOnChange(value)
                  }}
                  selectOnBlur={false}
                  noResultsMessage={t('common:No results found')}
                />
                <FormErrorMessage error={error} />
              </>
            )}
          </Grid>
        </Form.Field>
      )}
    />
  )
}

FormSelect.propTypes = {
  label: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  iconPosition: PropTypes.string,
  readOnly: PropTypes.bool,
}

export default React.memo(FormSelect)
