import { createAsyncThunk } from '@reduxjs/toolkit'

const hideBreederTestimonials = createAsyncThunk(
  'main/hideBreederTestimonials',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
    } catch (error) {
    } finally {
    }
  },
)

export default hideBreederTestimonials
