import { InlineSpinner } from 'commons/components'
import Avatar from 'components/Avatar'
import { useCheckScroll } from 'hooks'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import * as layouts from 'styles/layouts.module.scss'
import * as styles from './follower_tab.module.scss'

const FollowerTab = ({
  onFetchFollowerTab,
  followers,
  isFetching,
  isFetchEnded,
  isCurrentUser,
}) => {
  const { isBottom } = useCheckScroll()

  const { t } = useTranslation()

  useEffect(() => {
    if (isBottom && !isFetching && !isFetchEnded) {
      onFetchFollowerTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom, isFetching, isFetchEnded])

  const onClickRemove = () => {}

  return (
    <div className={styles[`follower-tab`]}>
      {!isEmpty(followers) && (
        <div className={layouts['list']}>
          {followers.map((follow) => (
            <div
              key={follow.id}
              className={`${layouts['list__item']} ${layouts['list__item--full']} ${styles['follow']}`}
            >
              <Avatar
                link={`/user/${follow.id}`}
                src={follow.profileImage}
                title={follow.displayName || follow.name}
                desc={follow.country}
                type={follow.type}
              />
              {isCurrentUser && (
                <Button basic onClick={() => onClickRemove(follow)}>
                  {t('common:Following')}
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
      {!isFetching && isEmpty(followers) && (
        <p style={{ fontStyle: 'italic' }}>{t('common:No follows yet')}</p>
      )}
      {isFetching && <InlineSpinner />}
    </div>
  )
}

export default React.memo(FollowerTab)
