import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const PAGE_SIZE = 20

const fetchFollowing = createAsyncThunk(
  'following/fetchFollowing',
  async (userId, { rejectWithValue, getState }) => {
    const {
      followingReducer: { followingOffset },
    } = getState()
    try {
      const result = await axios.get(`/activities/api/v2/follows/following`, {
        params: {
          offset: followingOffset,
          limit: PAGE_SIZE,
          userId,
        },
      })
      return result.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default fetchFollowing
