import { createSlice } from '@reduxjs/toolkit'
import resetUserPassword from './resetUserPassword'
import disableAccount from './disableAccount'
import updateEmail from './updateEmail'

const initialState = {
  type: '',
  link: '',
  pending: false,
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    error: '',
  },
}

const settingsSlice = createSlice({
  name: 'settingsReducer',
  initialState,
  reducers: {
    resetStatus: () => {
      return initialState
    },
  },
})

const { actions, reducer } = settingsSlice

export const { resetStatus } = actions

export { disableAccount, resetUserPassword, updateEmail }

export default reducer
