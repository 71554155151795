import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const updateUserAbout = createAsyncThunk(
  'userProfile/updateUserAbout',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(toggleLoading())
    try {
      const res = await axios.post(`/user/api/v1/users/about`, payload)
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updateUserAbout
