import { createAsyncThunk } from '@reduxjs/toolkit'
import { POST_TYPE } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'
import { uploadPostMediaFiles } from 'services/post'

const createCompanyPost = createAsyncThunk(
  'companyProfile/createCompanyPost',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const { companyId, content, files, userId, metadata } = payload
      const requestData = {
        companyId,
        content: content ?? '',
        type: POST_TYPE.COMPANY,
        metadata,
      }
      requestData.s3Ids = await uploadPostMediaFiles({
        files,
        userId,
        companyId,
      })

      const res = await axios.post('/activities/api/v1/posts', requestData)
      const data = get(res, 'data.data')
      return data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createCompanyPost
