import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'

const fetchPetFiles = createAsyncThunk(
  'petProfile/fetchPetFiles',
  async (_offset, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState()
      const { files, profile } = get(state, 'petProfileReducer')
      const { offset, limit } = files
      const res = await axios.get(
        `/pets/api/v2/pets/files/${profile.data.id}`,
        {
          params: {
            offset: _offset ?? offset,
            limit,
          },
        },
      )
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default fetchPetFiles
