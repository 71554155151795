import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import * as styles from './tooltip.module.scss'

const Tooltip = (props) => {
  const componentRef = useRef()

  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState({ left: 0, top: 0 })
  const { children, title, place, hidden, style = {} } = props
  const className = ['top', 'right', 'bottom', 'left'].includes(place)
    ? styles[place]
    : style.right
  const onMouseOver = (e) => {
    setVisible(true)
    const position = {}
    switch (place) {
      case 'top': {
        position.left = e.clientX
        position.top = e.clientY - 10
        break
      }
      case 'right': {
        position.left = e.clientX + 10
        position.top = e.clientY
        break
      }
      case 'bottom': {
        position.left = e.clientX
        position.top = e.clientY + 10
        break
      }
      default: {
        position.left = e.clientX - 10
        position.top = e.clientY
        break
      }
    }
    setPosition(position)
  }

  useEffect(() => {
    componentRef.current.addEventListener('mouseover', onMouseOver)
    componentRef.current.addEventListener('mouseleave', onMouseLeave)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      componentRef.current.removeEventListener('mouseover', onMouseOver)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      componentRef.current.removeEventListener('mouseleave', onMouseLeave)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMouseLeave = (e) => {
    setVisible(false)
  }

  const Child = () => {
    return (
      <div
        className={`${styles.tooltip} ${props.className || ''}`}
        style={position}
      >
        <div className={styles.point}>
          <div className={`${styles.container} ${className}`} style={style}>
            {title}
          </div>
        </div>
      </div>
    )
  }

  const Component = children?.type || 'div'
  const childProps = children.props || { children }
  return (
    <>
      <Component {...childProps} ref={componentRef} />
      {!hidden && visible && ReactDOM.createPortal(<Child />, document.body)}
    </>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  place: PropTypes.oneOf([undefined, 'top', 'right', 'bottom', 'left']),
  style: PropTypes.oneOf([undefined, PropTypes.object, PropTypes.string]),
  hidden: PropTypes.bool,
}

export default Tooltip
