import { createSlice } from '@reduxjs/toolkit'
import createFeedPost from './createFeedPost'
import fetchFeed from './fetchFeed'
import deletePost from './deletePost'
import updatePost from './updatePost'

const initialState = {
  isFetching: false,
  posts: [],
  ended: false,
  offset: 0,
}

const feedReducer = createSlice({
  name: 'feedReducer',
  initialState,
  reducers: {
    unLikeFeedPost: (state, { payload }) => {
      const { likeId, postId } = payload
      state.posts = state.posts.map((post) => {
        if (post.id === postId) {
          post.likes = post.likes.filter((like) => like.id !== likeId)
        }
        return post
      })
    },
    likeFeedComment: (state, { payload }) => {
      const { commentId, postId, like } = payload
      state.posts = state.posts.map((post) => {
        if (post.id === postId) {
          post.comments = post.comments.map((cmt) => {
            if (cmt.id === commentId) {
              if (cmt.likes) {
                cmt.likes = cmt.likes.concat([like])
              } else {
                cmt.likes = [like]
              }
            }
            return cmt
          })
        }
        return post
      })
    },
    unlikeFeedComment: (state, { payload }) => {
      const { commentId, postId, likeId } = payload
      state.posts = state.posts.map((post) => {
        if (post.id === postId) {
          post.comments = post.comments.map((cmt) => {
            if (cmt.id === commentId) {
              cmt.likes = cmt.likes.filter((like) => like.id !== likeId)
            }
            return cmt
          })
        }
        return post
      })
    },
    resetFeedState: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchFeed.pending]: (state) => {
      state.isFetching = true
    },
    [fetchFeed.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      //temp
      const filterRecords = records.filter(
        (item) => item.petInfo || item.userInfo,
      )
      //temp
      if (!filterRecords.length) {
        return {
          ...state,
          isFetching: false,
        }
      }
      return {
        ...state,
        isFetching: false,
        ended: offset + limit >= totalRecords,
        posts: !offset ? filterRecords : state.posts.concat(filterRecords),
        offset: offset + limit,
      }
    },
    [fetchFeed.rejected]: (state) => {
      state.isFetching = false
    },
    [createFeedPost.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.posts = [payload, ...state.posts]
    },
    [deletePost.pending]: () => {},
    [deletePost.fulfilled]: (state, { payload }) => {
      const data = state.posts
      const postIdx = data.findIndex(({ id }) => id === payload)
      if (postIdx < 0) {
        return state
      }
      state.offset -= 1
      data.splice(postIdx, 1)
    },
    [updatePost.fulfilled]: (state, { payload }) => {
      const data = state.posts
      const postIdx = data.findIndex(({ id }) => id === payload.id)
      if (postIdx < 0) {
        return state
      }
      data[postIdx] = payload
    },
  },
})

const { actions, reducer } = feedReducer

export const {
  unLikeFeedPost,
  likeFeedComment,
  unlikeFeedComment,
  resetFeedState,
} = actions

export { fetchFeed, createFeedPost, deletePost, updatePost }

export default reducer
