import { createSlice } from '@reduxjs/toolkit'
import fetchLitters from './fetchLitters'
import deleteLitter from './deleteLitter'
import createNewLitter from './createNewLitter'

const initialState = {
  litters: [],
  litterOffset: 0,
  isLitterFetching: false,
  isLitterFetchEnd: false,
  isCreateLitterSuccess: false,
}

const slice = createSlice({
  name: 'litterReducer',
  initialState,
  reducers: {
    resetLitterState: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchLitters.pending]: (state) => {
      state.isLitterFetching = true
    },
    [fetchLitters.fulfilled]: (state, { payload }) => {
      const { offset, limit, records, totalRecords } = payload
      state.isLitterFetching = false
      state.litters = offset === 0 ? records : state.litters.concat(records)
      state.litterOffset = offset + limit
      state.isLitterFetchEnd = offset + limit >= totalRecords
    },
    [fetchLitters.rejected]: (state) => {
      state.isLitterFetching = false
    },
    [deleteLitter.fulfilled]: (state, { payload }) => {
      state.litters = state.litters.filter((litter) => litter.id !== payload)
    },
    [createNewLitter.pending]: (state) => {
      state.isCreatingLitter = true
      state.isCreateLitterSuccess = false
    },
    [createNewLitter.fulfilled]: (state) => {
      state.isCreatingLitter = false
      state.isCreateLitterSuccess = true
    },
    [createNewLitter.rejected]: (state) => {
      state.isCreatingLitter = false
    },
  },
})

const { actions, reducer: litterReducer } = slice

export { fetchLitters, deleteLitter, createNewLitter }

export const { resetLitterState } = actions

export default litterReducer
