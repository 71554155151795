import { createAsyncThunk } from '@reduxjs/toolkit'
import { POST_TYPE } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'
import get from 'lodash/get'
import { getErrorMessage } from 'commons/utils'
import { uploadPostMediaFiles } from 'services/post'

const createPetPost = createAsyncThunk(
  'petProfile/createPetPost',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const { petId, content, files, userId, metadata } = payload
      const requestData = {
        petId,
        content: content ?? '',
        type: POST_TYPE.PET,
        metadata,
      }
      requestData.s3Ids = await uploadPostMediaFiles({ files, userId, petId })

      const res = await axios.post('/activities/api/v1/posts', requestData)
      const data = get(res, 'data.data')
      return data
    } catch (error) {
      return rejectWithValue(
        dispatch(addMessageToUser(getErrorMessage(error, ERROR_DEFAULT))),
      )
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createPetPost
