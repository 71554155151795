import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const loadLightboxPost = createAsyncThunk(
  'main/loadLightboxPost',
  async (s3Id, { dispatch, rejectWithValue }) => {
    try {
      // dispatch(toggleLoading())
      const res = await axios.get(`/activities/api/v1/posts/gallery/${s3Id}`)
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      // dispatch(toggleLoading())
    }
  },
)

export default loadLightboxPost
