import React, { useState } from 'react'
import * as styles from './text_with_link.module.scss'
import Linkify from 'react-linkify'
import { PopupForm } from 'commons/components/index'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { openPopupForm } from 'commons/slice'
import * as textStyles from 'commons/scss/text.module.scss'

const TextWithLink = (props) => {
  const {
    editable,
    content,
    textStyle = 'common-text-5',
    linkStyle = 'link-text-1',
    fieldName,
    onEdit,
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [readMore, setReadMore] = useState(false)

  const onFormSubmit = (form) => {
    onEdit({ [fieldName]: form[fieldName] })
  }

  const readMoreButton = () => {
    if (!content.length || content.length < 520) return null
    return (
      <span
        className={styles['read-more']}
        onClick={() => setReadMore(!readMore)}
      >
        {t(`common:Read ${readMore ? 'less' : 'more'}`) +
          ` ${readMore ? '▲' : '▼'}`}
      </span>
    )
  }

  const shortText = () => {
    let text = content
    if (content.length >= 520 && !readMore) {
      text = content.substring(0, 520)
      text += '...'
    }
    return text
  }

  return (
    <div className={styles[`text-with-link`]}>
      {editable && (
        <PopupForm
          formData={[{ fieldName, type: 'text' }]}
          initValue={{ [fieldName]: content }}
          buttonText={t('common:Edit')}
          onSubmit={onFormSubmit}
        />
      )}

      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target='_blank'
            rel='noreferrer'
            href={decoratedHref}
            key={key}
            className={textStyles[linkStyle]}
          >
            {decoratedText}
          </a>
        )}
      >
        <p className={`${styles['text']} ${textStyles[textStyle]}`}>
          {content ? (
            <>
              {shortText()}
              {readMoreButton()}
            </>
          ) : (
            <>
              {editable && (
                <span className={textStyles['common-text-4']}>
                  {t('common:Click edit to add description')}
                </span>
              )}
            </>
          )}{' '}
          {editable && (
            <span
              className={styles[`edit-button`]}
              onClick={() => dispatch(openPopupForm())}
            >
              {t('common:Edit')}
            </span>
          )}
        </p>
      </Linkify>
    </div>
  )
}

export default React.memo(TextWithLink)
