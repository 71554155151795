import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'
import get from 'lodash/get'
import { uploadPostMediaFiles } from 'services/post'

const createUserPost = createAsyncThunk(
  'main/createUserPost',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const state = getState()
      const currentUser = get(state, 'appReducer.currentUser')
      const { userId, content, files, metadata } = payload
      const requestData = {
        content: content ?? '',
        type: 'USER',
        ...(userId !== currentUser.id && { targetId: userId }),
        metadata,
      }
      requestData.s3Ids = await uploadPostMediaFiles({
        files,
        userId: currentUser.id,
      })
      const res = await axios.post('/activities/api/v1/posts', requestData)
      const data = get(res, 'data.data')
      return data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createUserPost
