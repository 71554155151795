import UserPlaceholder from 'commons/resources/icons/default_Human Blue 174px.svg'
import filter from 'lodash/filter'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'semantic-ui-react'
import { Avatar, Name } from '../index'
import * as styles from './reservation_tab.module.scss'

const ReservationStatus = ({
  reservations,
  petId,
  onConfirmPetOffer: callbackConfirmPetOffer,
  onAnswerReservation,
}) => {
  const { t } = useTranslation()

  const [activeReservation, setActiveReservation] = useState({})
  const [toBeTransferPet, setToBeTransferPet] = useState({
    reservationId: '',
    userName: '',
    userId: '',
  })
  const [open, setOpen] = useState(false)

  const onSetActive = (reservation) => {
    setActiveReservation((prevState) => ({
      ...prevState,
      activeReservation:
        prevState.activeReservation.id === reservation.id ? {} : reservation,
    }))
  }

  const onOpenTransferModal = (reservation) => {
    setOpen(true)
    setToBeTransferPet({
      reservationId: reservation.id,
      userName: reservation.userId.displayName,
      userId: reservation.userId.id,
    })
  }

  const onConfirmPetOffer = () => {
    callbackConfirmPetOffer(
      petId,
      toBeTransferPet.userId,
      toBeTransferPet.reservationId,
    )
    setOpen(false)
    setToBeTransferPet({ reservationId: '', userName: '', userId: '' })
    setActiveReservation({})
  }

  const onCancel = () => {
    setOpen(false)
    setToBeTransferPet({ reservationId: '', userName: '', userId: '' })
  }

  const accepted = maxBy(
    filter(reservations, (res) => res.answer === true),
    (r) => r.createdAt,
  )

  const buttonsGenerator = (activeReservation, reservations) => {
    if (!activeReservation.id) {
      return <React.Fragment></React.Fragment>
    }

    if (accepted) {
      return <React.Fragment></React.Fragment>
    } else if (get(activeReservation, 'answer') === false) {
      return (
        <div className={styles[`rejected`]}>
          <span />
          <p>{t('common:You have rejected this request')}</p>
        </div>
      )
    } else {
      return (
        <div className={styles[`action-buttons`]}>
          <button
            onClick={() => onAnswerReservation(true, activeReservation)}
            className={`${styles[`button`]} ${styles[`button--accept`]}`}
          >
            {t('common:Accept')}
          </button>
          <button
            onClick={() => {
              onAnswerReservation(false, activeReservation)
              setActiveReservation({})
            }}
            className={`${styles[`button`]} ${styles[`button--decline`]}`}
          >
            {t('common:Decline')}
          </button>
        </div>
      )
    }
  }

  return (
    <div className={styles[`reservation-status`]}>
      <Modal
        onUnmount={() => {
          setToBeTransferPet({ reservationId: '', userName: '' })
        }}
        className={styles[`transfer-container`]}
        open={open}
      >
        <p className={styles[`title`]}>
          {t('common:You are transferring this pet profile to ')}{' '}
          {toBeTransferPet.userName}
        </p>
        <p className={styles[`username`]}>{}</p>
        <div className={styles[`button-container`]}>
          <button
            onClick={onConfirmPetOffer}
            className={`${styles[`button--transfer`]} ${styles[`button`]}`}
          >
            {t('common:Confirm transfer')}
          </button>
          <span className={styles[`icon--confirm`]} />
        </div>

        <button
          onClick={onCancel}
          className={`${styles[`button`]} ${styles[`button--cancel`]}`}
        >
          {t('common:Cancel')}
        </button>
      </Modal>
      {accepted ? (
        <React.Fragment>
          <div className={styles[`accepted`]}>
            <div className={styles[`text-input`]}>
              <Avatar
                followIcon={false}
                size={{ width: '92px', height: '92px' }}
                nameShown={false}
                profileData={accepted.userId}
                content={{
                  image: accepted.userId?.profileImage || UserPlaceholder,
                }}
                profileType={accepted.userId.userId ? 'pet' : 'user'}
                id={accepted.userId.id}
              />

              <p>
                {t('common:Reserved by')}{' '}
                <Name
                  profileData={get(accepted, 'userid')}
                  name={get(accepted, 'userid.displayName')}
                  profileType={'user'}
                  id={get(accepted, 'userid.id')}
                />
              </p>
            </div>
            <div>
              <div className={styles[`action-buttons`]}>
                <button
                  onClick={() => onOpenTransferModal(accepted)}
                  className={`${styles[`button`]} ${
                    styles[`button--transfer`]
                  }`}
                >
                  {t('common:Transfer pet profile')}
                </button>
                <span className={styles[`icon--transfer`]} />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={styles[`reservations`]}>
          {activeReservation.id && (
            <div className={styles[`avatar`]}>
              <Avatar
                followIcon={false}
                size={{ width: '90px', height: '90px' }}
                nameShown={false}
                profileData={get(activeReservation, 'userid')}
                content={{
                  image:
                    activeReservation?.userId?.profileImage ||
                    activeReservation?.userId?.profile ||
                    UserPlaceholder,
                }}
                profileType={'user'}
                id={get(activeReservation, 'userid.id')}
              />
            </div>
          )}

          {get(reservations, 'length') === 0 ? (
            <div className={styles[`no-reservations`]}>
              {t('common:No reservation requests yet')}
            </div>
          ) : (
            <div className={styles[`users`]}>
              <p className={styles[`title`]}>
                {activeReservation.id ? (
                  <>
                    {`${t('common:Reservation request from')} `}
                    <Name
                      profileData={get(activeReservation, 'userid')}
                      name={get(activeReservation, 'userid.displayName')}
                      profileType={'user'}
                      id={get(activeReservation, 'userid.id')}
                    />
                  </>
                ) : (
                  `${t('common:Reservation requests received from')}
                                ${get(reservations, 'length')}${
                    get(reservations, 'length') > 1
                      ? t('common: people')
                      : t('common: person')
                  }`
                )}
              </p>
              <div className={styles[`avatars`]}>
                {reservations.map((reservation) => {
                  return (
                    <img
                      onClick={() => onSetActive(reservation)}
                      key={reservation.id}
                      alt='user'
                      src={
                        reservation?.userId?.profileImage ||
                        reservation?.userId?.profile ||
                        UserPlaceholder
                      }
                      className={
                        get(reservation, 'id') === activeReservation
                          ? `${styles[`ava`]} ${styles[`ava--active`]}`
                          : `${styles[`ava`]}`
                      }
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {buttonsGenerator(activeReservation, reservations)}
    </div>
  )
}

export default React.memo(ReservationStatus)
