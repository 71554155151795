import { createSlice } from '@reduxjs/toolkit'
import findIndex from 'lodash/findIndex'
import fetchLitterProfile from './fetchLitterProfile'
import deleteLitterProfilePicture from './deleteLitterProfilePicture'
import updateLitterProfile from './updateLitterProfile'
import fetchLitterParents from './fetchLitterParents'
import fetchLitterInfoTab from './fetchLitterInfoTab'
import addParentToLitter from './addParentToLitter'
import fetchLitterReservation from './fetchLitterReservation'
import updatePetAvailability from './updatePetAvailability'
import answerToReservation from './answerToReservation'
import confirmPetOffer from './confirmPetOffer'
import withDrawPetOffer from './withDrawPetOffer'
import fetchLitterHealthInfo from './fetchLitterHealthInfo'
import updateLitterWeight from './updateLitterWeight'

const initialState = {
  litter: {
    isFetchingLitter: false,
    data: {},
  },
  healthInfo: {
    data: [],
  },
}

const litterProfileSlice = createSlice({
  name: 'litterProfileReducer',
  initialState,
  reducers: {
    resetLitterReducer: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchLitterProfile.pending]: (state) => {
      state.litter.isFetchingLitter = true
    },
    [fetchLitterProfile.fulfilled]: (state, { payload }) => {
      state.litter = {
        isFetchingLitter: false,
        data: payload,
      }
    },
    [fetchLitterProfile.rejected]: (state) => {
      state.litter.isFetchingLitter = false
    },
    [updateLitterProfile.fulfilled]: (state, { payload }) => {
      state.litter.data = { ...state.litter.data, ...payload }
    },
    [updatePetAvailability.fulfilled]: (state, { payload }) => {
      const { petId, avail, price } = payload
      const selectedIdx = findIndex(
        state.litter._reservationData,
        ({ id }) => id === petId,
      )
      if (selectedIdx < 0) {
        return state
      }
      const selectedData = state.litter._reservationData[selectedIdx]
      if (!price) {
        delete selectedData.price
      } else {
        selectedData.price = price
      }
      state.litter._reservationData[selectedIdx] = {
        ...selectedData,
        availability: avail,
      }
    },
    [deleteLitterProfilePicture.fulfilled]: (state, { payload }) => {
      state.litter.data[payload] = null
    },
    [deleteLitterProfilePicture.rejected]: (state, { payload }) => {
      state.litter.error = payload
    },
    [fetchLitterHealthInfo.fulfilled]: (state, { payload }) => {
      state.healthInfo = {
        ...state.healthInfo,
        data: payload,
      }
    },
    [answerToReservation.fulfilled]: (state, { payload }) => {
      const { answer, reservationId, petId } = payload
      state.litter = {
        ...state.litter,
        _reservationData: state.litter._reservationData.map((pet) => {
          if (pet.id === petId) {
            return {
              ...pet,
              availability: pet.availability,
              _reservationData: pet._reservationData.map((res) => {
                if (res.id === reservationId) {
                  return {
                    ...res,
                    answer: answer,
                    status: 2,
                  }
                } else {
                  return res
                }
              }),
            }
          } else {
            return pet
          }
        }),
      }
    },
  },
})

const { actions, reducer } = litterProfileSlice

export const { resetLitterReducer } = actions

export {
  fetchLitterProfile,
  deleteLitterProfilePicture,
  updateLitterProfile,
  fetchLitterParents,
  fetchLitterInfoTab,
  addParentToLitter,
  fetchLitterReservation,
  updatePetAvailability,
  answerToReservation,
  confirmPetOffer,
  withDrawPetOffer,
  fetchLitterHealthInfo,
  updateLitterWeight,
}

export default reducer
