import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const fetchLitterHealthInfo = createAsyncThunk(
  'litterProfile/fetchLitterHealthInfo',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const res = await axios.get(
        `/pets/api/v1/pets/litters/measurements/${payload}`,
      )
      return res.data.data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default fetchLitterHealthInfo
