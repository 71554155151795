import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const assignPetToParent = createAsyncThunk(
  'familyTree/assignPetToParent',
  async ({ id, parentId }, { rejectWithValue }) => {
    try {
      await axios.put(`/pets/api/v1/pet-family-trees/${id}/assign/${parentId}`)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default assignPetToParent
