import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { uploadFiles } from 'services/fileManagement'

const updatePetProfile = createAsyncThunk(
  'petProfile/updatePetProfile',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const {
        petProfileReducer,
        appReducer: { currentUser },
      } = getState()
      const {
        profile: {
          data: { id, userInfo, avatar, backgroundImg, species, ...petProfile },
        },
      } = petProfileReducer
      const { image, ...newPetProfile } = payload
      const updatedPetProfile = { ...newPetProfile, avatar, backgroundImg }
      if (image) {
        const uploadData = await uploadFiles({
          userId: userInfo.id || currentUser.id,
          files: [image.file],
          action: UPLOAD_ACTION.POST,
          petId: id,
        })
        newPetProfile[image.name] = uploadData[0].s3Id
        updatedPetProfile[image.name] = uploadData[0].url
      }
      await axios.put(`/pets/api/v1/pets/profiles/${id}`, {
        ...petProfile,
        speciesId: species.speciesName,
        ...newPetProfile,
      })
      return updatedPetProfile
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updatePetProfile
