import { createSlice } from '@reduxjs/toolkit'
import fetchPetFollowers from './fetchPetFollowers'
import getPetConfig from './getPetConfig'
import avatarNavigate from './avatarNavigate'
import fetchActivePetHealthInfo from './fetchActivePetHealthInfo'
import updatePetWeight from './updatePetWeight'
import getUserProfile from './getUserProfile'
import refreshNewToken from './refreshNewToken'
import signOut from './signOut'
import cleanObj from '../utils/objectClean'
import addLitterChild from './addLitterChild'
import updateLocale from './updateLocale'
import updateUserProfile from './updateUserProfile'
import deleteUserImage from './deleteUserImage'
import blockUser from './blockUser'
import extractMeta from './extractMeta'
import getPaymentCurrency from './getPaymentCurrency'
import changeProfileStatus from './changeProfileStatus'

const initialState = {
  isLoading: true,
  currentUser: { isAuthenticated: null, level: null },
  images: [],
  activePet: {
    images: [],
    isFetchingFamily: false,
    originalSire: '',
    originalDam: '',
    page: 1,
  },
  message: '',
  isPopupFormShown: false,
  petConfigs: [],
  petConfigLoaded: false,
  isRefreshToken: false,
  isBlockSuccess: false,
  paymentCurrency: null,
  userStatus: {},
}

const appSlice = createSlice({
  name: 'appReducer',
  initialState,
  reducers: {
    toggleLoading: (state) => {
      state.isLoading = !state.isLoading
    },
    updateUserProfileDone: (state, { payload: profile }) => {
      if (profile.dateOfBirth) {
        profile.dateOfBirth = new Date(profile.dateOfBirth).getTime()
      }
      state.currentUser = cleanObj({
        ...state.currentUser,
        ...profile,
      })
    },
    addMessageToUser: (state, { payload }) => {
      state.message = payload
    },
    removeMessageToUser: (state) => {
      state.message = ''
    },
    openPopupForm: (state) => {
      state.isPopupFormShown = true
    },
    closePopupForm: (state) => {
      state.isPopupFormShown = false
    },
    updateUserStatus: (state, { payload }) => {
      const data = JSON.parse(payload)
      state.userStatus = data.reduce((acc, item) => {
        return { ...acc, [item.userId]: 'ONLINE' }
      }, {})
    },
  },
  extraReducers: {
    [fetchPetFollowers.pending]: (state) => {
      state.isLoading = true
    },
    [fetchPetFollowers.fulfilled]: (state, { payload: followers }) => {
      state.isLoading = false
      state.activePet = {
        ...state.activePet,
        followers,
      }
    },
    [getPetConfig.fulfilled]: (state, { payload }) => {
      state.petConfigs = payload
      state.petConfigLoaded = true
    },
    [getUserProfile.pending]: (state) => {
      state.isLoading = true
    },
    [getUserProfile.fulfilled]: (state, { payload: user }) => {
      return {
        ...state,
        isLoading: false,
        currentUser: {
          ...state.currentUser,
          ...user,
          isAuthenticated: true,
        },
        isRefreshToken: true,
      }
    },
    [getUserProfile.rejected]: (state) => {
      state.isLoading = false
      state.isRefreshToken = true
    },
    [signOut.fulfilled]: (state) => {
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('token')
      localStorage.removeItem('idToken')
      localStorage.removeItem('token')
      state.currentUser = initialState.currentUser
    },
    [updateLocale.pending]: (state) => {
      state.isLoading = true
    },
    [updateLocale.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.currentUser = { ...state.currentUser, ...payload }
    },

    [updateLocale.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.message = payload
    },
    [updateUserProfile.pending]: (state) => {
      state.isLoading = true
    },
    [updateUserProfile.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.currentUser = { ...state.currentUser, ...payload }
    },
    [updateUserProfile.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.message = payload
    },
    [refreshNewToken.rejected]: (state) => {
      if (!state.isRefreshToken) {
        state.isLoading = false
        state.isRefreshToken = true
      }
    },
    [deleteUserImage.fulfilled]: (state, { payload }) => {
      state.currentUser[payload] = null
    },
    [blockUser.pending]: (state) => {
      state.isBlockSuccess = false
    },
    [blockUser.fulfilled]: (state) => {
      state.isBlockSuccess = true
    },
    [getPaymentCurrency.fulfilled]: (state, { payload }) => {
      state.paymentCurrency = payload
    },
    [getPaymentCurrency.rejected]: (state, { payload }) => {
      state.message = payload
    },
    [changeProfileStatus.pending]: (state, { payload }) => {
      state.isLoading = true
    },
    [changeProfileStatus.fulfilled]: (state, { payload }) => {
      state.isLoading = false
      state.currentUser.profileStatus = payload
    },
    [changeProfileStatus.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.message = payload
    },
  },
})

export {
  fetchPetFollowers,
  avatarNavigate,
  fetchActivePetHealthInfo,
  updatePetWeight,
  getUserProfile,
  refreshNewToken,
  signOut,
  getPetConfig,
  addLitterChild,
  updateLocale,
  updateUserProfile,
  deleteUserImage,
  blockUser,
  extractMeta,
  getPaymentCurrency,
  changeProfileStatus,
}

const { actions, reducer } = appSlice

export const {
  toggleLoading,
  updateUserProfileDone,
  addMessageToUser,
  removeMessageToUser,
  openPopupForm,
  closePopupForm,
  updateUserStatus,
} = actions

export default reducer
