import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

export const useCheckScroll = () => {
  const [position, setPosition] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false)
    return () => {
      window.removeEventListener('scroll', onScroll, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (position) {
      setPosition('')
    }
  }, [position])

  const onScroll = throttle(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight - 500
    ) {
      setPosition('bottom')
    } else if (window.scrollY < 5) {
      setPosition('top')
    } else {
      setPosition('')
    }
  }, 200)

  return {
    isTop: position === 'top',
    isBottom: position === 'bottom',
  }
}
