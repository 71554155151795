import { createAsyncThunk } from '@reduxjs/toolkit'
import commonServices from 'services/commons'

const changeProfileStatus = createAsyncThunk(
  'app/changeProfileStatus',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await commonServices.changeProfileStatus(payload)
      return payload.status
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export default changeProfileStatus
