import React from 'react'
import { withTranslation } from 'react-i18next'
import { Header, Footer } from '../../commons/components/index'
import * as styles from './styles.module.scss'
import PropTypes from 'prop-types'

const NoMatch = ({ t, showFullLayout = true }) => {
  return (
    <div className={styles[`container`]}>
      {showFullLayout && <Header />}
      <div className={styles[`content`]}>
        <h3>{t('common:Oops, this page is either unavailable or delete!')}</h3>
      </div>
      {showFullLayout && <Footer />}
    </div>
  )
}

NoMatch.propTypes = {
  t: PropTypes.func,
  showFullLayout: PropTypes.bool,
}

export default withTranslation()(NoMatch)
