import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import FinlandFlagIcon from '../../resources/icons/finland.png'
import EnglishFlagIcon from '../../resources/icons/united-kingdom.png'
import * as styles from './language_widget.module.scss'
import { updateLocale } from 'commons/slice'

const LanguageWidget = ({ isLogin }) => {
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()

  const appReducer = useSelector((state) => state.appReducer)
  const { currentUser = {} } = appReducer

  const locale = currentUser.locale || i18n.language

  useEffect(() => {
    if (!['en', 'fi'].includes(i18n.language)) {
      i18n.changeLanguage('en')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeLocale = (_e, { value }) => {
    i18n.changeLanguage(value)
    if (currentUser.isAuthenticated && currentUser.locale) {
      dispatch(updateLocale(value))
    }
  }

  const languages = [
    {
      text: (
        <div className={styles[`language-options`]}>
          <img src={EnglishFlagIcon} alt={t('common:English')} />
          {t('common:English')}
        </div>
      ),
      value: 'en',
    },
    {
      text: (
        <div className={styles[`language-options`]}>
          <img src={FinlandFlagIcon} alt={t('common:Finnish')} />
          {t('common:Finnish')}
        </div>
      ),
      value: 'fi',
    },
  ]
  return (
    <div
      className={`${styles[`language-widget-container`]} ${
        isLogin ? styles['login-language'] : ''
      }`}
    >
      <Dropdown
        className={styles[`dropdown`]}
        onChange={onChangeLocale}
        options={languages}
        value={locale}
      ></Dropdown>
    </div>
  )
}

export default React.memo(LanguageWidget)
