import React from 'react'
import * as styles from './withBackButton.module.scss'
import { withRouter } from 'react-router-dom'

const WithBackButton = (Component) => {
  class WithBackWrapper extends React.Component {
    render() {
      const { history } = this.props
      return (
        <div className={styles[`with-back-button-container`]}>
          <span
            onClick={() => history.goBack()}
            className={styles[`back-button`]}
          />
          <Component />
        </div>
      )
    }
  }

  return withRouter(WithBackWrapper)
}

export default WithBackButton
