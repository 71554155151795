import AcceptModal from 'commons/components/AcceptModal'
import BlockModal from 'commons/components/ActionModal/BlockModal'
import { PROFILE_TYPE } from 'commons/constants'
import { blockUser } from 'commons/slice'
import { getFileType } from 'commons/utils'
import {
  getContentWithMention,
  getContentWithoutMention,
} from 'commons/utils/emojis'
import Avatar from 'components/Avatar'
import map from 'lodash/map'
import { ActionButton, Name } from 'pages/Main/main_routes/commons/component'
import { openFocusedPhoto } from 'pages/Main/slice'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import CommentInput from '../CommentInput'
import * as styles from './styles.module.scss'
import IconPlaceholder from 'components/IconPlaceholder'
import likeWhiteIcon from 'assets/icons/like-white.svg'
import LoadMore from 'components/LoadMore'

const Comment = ({
  comment,
  addChildComment,
  fetchChildComments,
  updateComment,
  removeComment,
  rateComment,
}) => {
  const {
    id,
    fileInformation,
    childComments,
    isFetchChildEnded,
    liked,
    likeAmount,
    likedList,
  } = comment

  const { t } = useTranslation()
  const activeElementRef = useRef()

  const appReducer = useSelector((state) => state.appReducer)
  const { currentUser } = appReducer
  const { locale } = currentUser

  const dispatch = useDispatch()

  const [addingChild, setAddingChild] = useState(false)
  const [modalType, setModalType] = useState('')
  const [selectedComment, setSelectedComment] = useState(null)
  const [editCommentData, setEditCommentData] = useState(null)

  const [userOptions, ownerOptions] = useMemo(
    () => [
      [
        { value: 'report', text: t('post:Report comment'), icon: 'report' },
        { value: 'block', text: t('post:Block user'), icon: 'block' },
        { value: 'mute', text: t('post:Mute user'), icon: 'mute' },
      ],
      [
        { value: 'delete', text: t('post:Delete comment'), icon: 'delete' },
        { value: 'edit', text: t('post:Edit comment'), icon: 'edit' },
      ],
    ],
    [t],
  )

  const onToggleAddChild = () => {
    setAddingChild(!addingChild)
  }

  const onOptionClick = (type, values) => {
    if (type === 'edit') {
      return setEditCommentData(values)
    }
    setModalType(type)
    setSelectedComment(values)
  }
  const onResetSelect = () => {
    setModalType('')
    setSelectedComment(null)
  }

  const onConfirmDelete = () => {
    removeComment(
      selectedComment.id,
      selectedComment.id === comment.id ? null : comment.id,
    )
    onResetSelect()
  }
  const onBlock = () => {
    dispatch(blockUser({ id: comment.userId.id, type: 'USER' }))
    setModalType('')
  }

  const onAddChildComment = (text, _pictures, files) => {
    const { content, mentions } = getContentWithoutMention(text)
    addChildComment(content, files, comment.id, mentions).then(onToggleAddChild)
  }

  const onChangeComment = (parentId) => (content, pictures, files) => {
    const params = { content, pictures, files }
    parentId && (params.parentId = parentId)
    updateComment(editCommentData.id, params).then(() =>
      setEditCommentData(null),
    )
  }

  const onClickAction = (e) => {
    const parent = e.target.closest(`.${styles['comment-item']}`)
    if (!parent) {
      return
    }
    activeElementRef.current = parent
    parent.classList.add(styles['comment-item--active'])
  }

  const onCloseAction = () => {
    activeElementRef.current?.classList.remove(styles['comment-item--active'])
  }

  const isOwner = currentUser?.id === comment.userId?.id
  const actionOptions = isOwner ? ownerOptions : userOptions

  const onClickMedia = (imageSource) => {
    dispatch(openFocusedPhoto({ imageIdx: 0, imageSource }))
  }

  const renderPreview = (url, imageSource) => {
    if (!url) {
      return null
    }
    if (getFileType(url) === 'video') {
      return (
        <video
          controls
          autoPlay
          src={url}
          onClick={() => onClickMedia(imageSource)}
          className={styles[`comment-picture`]}
          muted
        >
          <source src={url} />
        </video>
      )
    }
    return (
      <img
        onClick={() => onClickMedia(imageSource)}
        className={styles[`comment-picture`]}
        src={url}
        alt='pic'
      />
    )
  }

  const renderComment = (item, isChild = false) => {
    const isOwner = currentUser?.id === item.userId?.id
    const actionOptions = isOwner ? ownerOptions : userOptions
    let content = null
    if (editCommentData?.id === item.id) {
      content = (
        <CommentInput
          hideAvatar
          onEdit={editCommentData}
          userId={comment.userId}
          onSubmit={onChangeComment(isChild && comment.id)}
        />
      )
    } else {
      const itemLiked = isChild ? item.liked : liked
      const itemLikeAmount = isChild ? item.likeAmount : likeAmount
      content = (
        <div id={`comment-${id}`} className={styles['comment-content']}>
          <div
            className={`${styles[`comment-text`]} ${
              item.highlight ? styles['comment-item--highlight'] : ''
            }`}
          >
            <div className={styles[`username`]}>
              <div className={styles[`username__wrap`]}>
                <Name
                  profileData={item.petId || item.userId}
                  name={item.petId?.name || item.userId?.displayName}
                  profileType={item.petId ? 'pet' : 'user'}
                  id={item.petId?.id || item.userId?.id}
                />
                {!!item.createdDate && (
                  <span className={styles[`active-hour`]}>
                    <span> &middot; </span>
                    <Moment locale={locale ? locale : 'en'} ago fromNow>
                      {item.createdDate}
                    </Moment>
                  </span>
                )}
              </div>
              <div className={styles.actions} onClick={onClickAction}>
                <ActionButton
                  onOptionClick={(value) => onOptionClick(value, item)}
                  header={t('common:Actions')}
                  options={actionOptions}
                  onClose={onCloseAction}
                />
              </div>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: getContentWithMention(item.content, item.mentions),
              }}
            />
            {!!itemLikeAmount && (
              <div className={styles['like']}>
                <IconPlaceholder
                  isActive
                  icon={likeWhiteIcon}
                  width={16}
                  height={16}
                  iconWidth={12}
                />
                <span>{itemLikeAmount}</span>
              </div>
            )}
          </div>
          {!!fileInformation.length &&
            renderPreview(fileInformation[0].url, fileInformation)}
          <div className={styles['comment-cta']}>
            <div
              className={`${styles['comment-cta__action']} ${
                itemLiked ? styles['comment-cta__action--active'] : ''
              }`}
              onClick={() => rateComment(...(isChild ? [item.id, id] : [id]))}
            >
              {t('common:Like')}
            </div>
            <div className={styles['comment-cta__divider']} />
            <div
              className={styles['comment-cta__action']}
              onClick={onToggleAddChild}
            >
              {t('common:Reply')}
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        className={`${styles['comment-wrapper']} ${
          isChild ? styles['comment-wrapper__child'] : ''
        }`}
      >
        <Avatar
          width={36}
          height={36}
          src={item.userId.profileImage}
          type={PROFILE_TYPE.USER}
          link={`/user/${item.userId.id}`}
          showStatus
          id={item.userId.id}
        />
        {content}
      </div>
    )
  }

  return (
    <>
      {modalType === 'block' && (
        <BlockModal
          title={t('Block user') + '?'}
          accept={t('post:Block user')}
          cancel={t('common:Cancel')}
          onAccept={onBlock}
          onCancel={onResetSelect}
          onResetSelect={onResetSelect}
        />
      )}
      {modalType === 'delete' && (
        <AcceptModal
          title={t('post:Delete comment') + '?'}
          accept={t('common:Delete')}
          cancel={t('common:Cancel')}
          onAccept={onConfirmDelete}
          onCancel={onResetSelect}
          onResetSelect={onResetSelect}
        />
      )}
      {renderComment(comment)}
      <div className={styles['child-comment']}>
        {map(childComments, (item) => renderComment(item, true))}
        {!isFetchChildEnded && (
          <LoadMore onClick={() => fetchChildComments(id)}>
            {t('common:Load more')}
          </LoadMore>
        )}
        {addingChild && (
          <div className={styles[`child-comment__input`]}>
            <CommentInput
              hideAvatar
              onSubmit={onAddChildComment}
              userId={comment.userId}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default React.memo(Comment)
