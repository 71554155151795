import React, { memo } from 'react'
import PropTypes from 'prop-types'
import emojis from 'commons/utils/emojis'
import styles from './emoji_select.module.scss'
import emojiStyles from 'commons/scss/emoji.module.scss'
import DropBox from 'commons/components/DropBox'

const EmojiSelect = (props) => {
  const { onSelect } = props
  return (
    <DropBox
      className={styles[`emoji-select`]}
      label={
        <i
          className={`${emojiStyles[`emoji`]} ${styles['emoji-label']}`}
          title='Emoji'
        />
      }
      labelClassName={styles['label']}
      icon={false}
      content={
        <div className={styles[`emoji-list`]}>
          {Object.entries(emojis).map(([key, emoji]) => {
            if (!emoji.k) return null
            return (
              <div key={key} className={styles['emoji-item']}>
                <img
                  key={key}
                  onClick={() => onSelect?.(key)}
                  className={`${emojiStyles[`emoji`]} ${
                    emojiStyles[emoji.className]
                  }`}
                  src={emoji.icon}
                  alt={emoji.alt}
                />
              </div>
            )
          })}
        </div>
      }
    />
  )
}

EmojiSelect.propTypes = {
  onSelect: PropTypes.func,
}

export default memo(EmojiSelect)
