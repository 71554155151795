import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const getPets = createAsyncThunk(
  'media/getPets',
  async (userId, { dispatch, rejectWithValue }) => {
    try {
      const result = await axios.get('/pets/api/v1/pets', {
        params: {
          userId,
          offset: 0,
          limit: 10,
          sort: 'desc',
          sortField: 'createdDate',
        },
      })
      return result.data.data.records
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default getPets
