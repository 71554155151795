import { createAsyncThunk } from '@reduxjs/toolkit'
import { POST_TYPE } from 'commons/constants'
import axios from 'commons/http/axios'
import get from 'lodash/get'

const fetchPetPosts = createAsyncThunk(
  'petProfile/fetchPetPosts',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const posts = get(state, 'petProfileReducer.posts')
      const { offset, limit } = posts
      const res = await axios.get(`/activities/api/v1/posts/list`, {
        params: {
          offset,
          limit,
          type: POST_TYPE.PET,
          id: payload,
        },
      })
      return res.data.data
    } catch (error) {
      return rejectWithValue()
    } finally {
    }
  },
)

export default fetchPetPosts
