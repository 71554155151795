import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const pinPost = createAsyncThunk(
  'main/pinPost',
  async (post, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      await axios.post(
        `/activities/api/v1/${post.isPin ? 'unpin' : 'pin'}/${post.id}`,
      )
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default pinPost
