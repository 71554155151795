import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Form, Grid, Icon, Input, Label } from 'semantic-ui-react'
import styled from 'styled-components'
import FormErrorMessage from '../FormErrorMessage'

const StyledInput = styled(Input)({
  '&.ui.icon.input > i.icon': {
    pointerEvents: 'all',
    cursor: 'pointer',
  },
})
const FormInput = ({
  label = '',
  name,
  control,
  error,
  required = false,
  rules,
  type: originalType = 'text',
  icon: originalIcon,
  readOnly,
  ...rest
}) => {
  const [type, setType] = useState(originalType)
  const [icon, setIcon] = useState(originalIcon)

  useEffect(() => {
    if (originalType !== 'password' || originalIcon) {
      return
    }
    setIcon(type === originalType ? 'eye slash' : 'eye')
  }, [type])

  const onClickIcon = () => {
    if (originalType !== 'password') {
      return
    }
    setType(type === 'password' ? 'text' : 'password')
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <Form.Field readOnly={readOnly}>
            {label && (
              <Label
                as='label'
                className={get(rules, 'required') ? 'required' : ''}
              >
                {label}
              </Label>
            )}
            <Grid>
              {readOnly ? (
                <span className='text'>{field.value}</span>
              ) : (
                <>
                  <StyledInput
                    {...field}
                    {...rest}
                    type={type}
                    icon={<Icon name={icon} onClick={onClickIcon} />}
                  />
                  <FormErrorMessage error={error} />
                </>
              )}
            </Grid>
          </Form.Field>
        )
      }}
    />
  )
}

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  icon: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
}

export default React.memo(FormInput)
