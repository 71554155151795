import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'

const extractMeta = createAsyncThunk('app/extractMeta', async (payload) => {
  try {
    const res = await axios.post(`/activities/api/v1/metadata/extract`, {
      url: payload,
    })
    return res
  } catch (error) {
    return []
  }
})

export default extractMeta
