import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'
import { uploadFiles } from 'services/fileManagement'

const createCompanyPet = createAsyncThunk(
  'companyProfile/createCompanyPet',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const { avatar, ...data } = payload
      if (avatar) {
        const currentUser = get(getState(), 'appReducer.currentUser')
        const uploadData = await uploadFiles({
          userId: currentUser.id,
          files: [avatar],
          action: UPLOAD_ACTION.POST,
          companyId: data.companyId,
        })
        data.avatar = get(uploadData, '0.s3Id')
      }
      const result = await axios.post('/pets/api/v1/pets', data)
      return result.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createCompanyPet
