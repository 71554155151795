import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const likePost = async (data) => {
  try {
    return await axios.post(`/activities/api/v1/posts-ratings`, data)
  } catch (error) {
    throw getErrorMessage(error)
  }
}
const service = {
  likePost,
}

export default service
