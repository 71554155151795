import React from 'react'
import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './i18n'
import 'moment/locale/fi'

ReactDOM.render(<App />, document.getElementById('root'))
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
