import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { suggestionReducerMapping } from '../constant'

const PAGE_SIZE = 10

const fetchSuggestFollow = createAsyncThunk(
  'following/fetchSuggestFollow',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    const { search, type, page } = payload
    const {
      followingReducer: { suggestions },
    } = getState()
    try {
      const result = await axios.get(`/search-engine/api/v1/discoveries`, {
        params: {
          page:
            page ?? type === 'ALL'
              ? 1
              : suggestions[suggestionReducerMapping[type]].page,
          size: PAGE_SIZE,
          keyword: search,
          type,
        },
      })
      return {
        type,
        page,
        result: result.data.data,
      }
    } catch (error) {
      return []
    }
  },
)

export default fetchSuggestFollow
