import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'

const deleteSponsor = createAsyncThunk(
  'companyProfile/deleteSponsor',
  async ({ companyId, s3Id }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleLoading())
      const res = await axios.post(
        `/api/v1/companies/about/sponsors/${companyId}`,
        { s3Id },
      )
      return res.data.data
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deleteSponsor
