import { createSlice } from '@reduxjs/toolkit'

const initialState = { testimonials: [] }

const userTestimonialSlice = createSlice({
  name: 'userTestimonialReducer',
  initialState,
  reducers: {},
  extraReducers: {},
})

const { reducer } = userTestimonialSlice

export * from './actions'

export default reducer
