import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const updateLocale = createAsyncThunk(
  'app/updateLocale',
  async (locale, { rejectWithValue }) => {
    try {
      const res = await axios.put('/user/api/v1/users/locale', {
        locale,
      })
      return res.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default updateLocale
