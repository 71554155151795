import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'

const getPetConfig = createAsyncThunk('app/getPetConfig', async (_payload) => {
  try {
    const result = await axios.get('species')
    const configs = await Promise.all(
      result.data.map(async (item) => {
        try {
          const res = await axios.get(`pet/traits?species=${item._id}`)
          return { ...item, ...res.data }
        } catch {
          return {
            ...item,
            availablities: [],
            hobbies: [],
            breeding: [],
            marking: [],
            coats: [],
            colors: [],
          }
        }
      }),
    )
    return configs
  } catch (error) {
    return []
  }
})

export default getPetConfig
