import { createAsyncThunk } from '@reduxjs/toolkit'

const fetchLitterReservation = createAsyncThunk(
  'litterProfile/fetchLitterReservation',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default fetchLitterReservation
