import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const blockUser = createAsyncThunk(
  'app/blockUser',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      await axios.post(`/activities/api/v2/follows/block`, {
        receiverId: payload.id,
        receiverType: payload.type,
      })
      return payload
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default blockUser
