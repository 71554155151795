import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'

const deleteCompanyPersonnel = createAsyncThunk(
  'companyProfile/deleteCompanyPersonnel',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`api/v1/companies/personel/${id}`)
      return id
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export default deleteCompanyPersonnel
