import PetPlaceholder from 'commons/resources/icons/default_Pet Blue 174px.svg'
import * as spaceStyles from 'commons/scss/space.module.scss'
import * as textStyles from 'commons/scss/text.module.scss'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Name, TextWithLink } from '../../commons/component'
import * as styles from './pet_card.module.scss'
import { get } from 'lodash'

const ResultCard = (props) => {
  const { t } = useTranslation()
  const { pet } = props

  const [petData, setPetData] = useState(null)

  useEffect(() => {
    const clonePetProfile = cloneDeep(pet)
    const { detail, ...newPetProfile } = clonePetProfile
    forEach(detail, (item) => {
      let value
      try {
        value = JSON.parse(item.value)
      } catch (error) {
        value = item.value
      }
      newPetProfile[item.key] = value
    })
    newPetProfile.gender = newPetProfile.gender ? 'female' : 'male'
    setPetData(newPetProfile)
  }, [pet])

  const layout = [
    'availability',
    'gender',
    'breed',
    'registrationCountry',
    'breeding',
  ]

  const addTag = (tagName, text) => {
    return (
      <div className={`${styles[`tag`]} ${styles[`tag--${tagName}`]}`}>
        <span
          className={`${styles[`status`]} ${styles[`status--${tagName}`]}`}
        />
        <p>{text}</p>
      </div>
    )
  }

  const availTags = (availability, price, pet) => {
    const options =
      availability && availability.length > 0 ? availability : ['unavailable']

    return options.map((avail) => {
      return (
        <React.Fragment key={'avail-' + avail}>
          {pet.id && addTag(avail, t(`pet:${avail}`))}
          {avail === 'forSales' && price ? addTag(avail, `${price} €`) : null}
        </React.Fragment>
      )
    })
  }

  const breedingTags = (breeding) => {
    if (!breeding || !Array.isArray(breeding) || breeding.length === 0)
      return null

    return breeding.map((b) => {
      return (
        <React.Fragment key={'breeding-' + b}>
          {addTag(b, t(`pet:${b}`))}
        </React.Fragment>
      )
    })
  }

  const tags = (pet, layout) => {
    if (pet.type === 'COMPANY') {
      return
    }
    return (
      <div className={styles[`tags`]}>
        {layout.map((item) => {
          const content = pet[item]
          if (item !== 'availability' && !content) {
            return null
          }
          let itemLayout
          switch (item) {
            case 'availability':
              itemLayout = availTags(content, pet.price, pet)
              break
            case 'breeding':
              itemLayout = breedingTags(content)
              break
            default:
              itemLayout = (
                <div
                  className={`${styles[`tag`]} ${styles[`tag--${item}`]}`}
                  key={item}
                >
                  <span
                    className={`${styles[`status`]} ${
                      styles[`status--${item}`]
                    }`}
                  />
                  {item === 'registrationCountry' ? (
                    <p>{t(`country:${content}`)}</p>
                  ) : (
                    <p>{t(`pet:${content}`)}</p>
                  )}
                </div>
              )
              break
          }
          return itemLayout
        })}
      </div>
    )
  }

  const ownedBy = (pet) => {
    return (
      pet.userInfo?.displayName && (
        <div className={styles[`owner`]}>
          <div className={styles[`owner--label`]}>{t('common:Owned by')} </div>
          <div className={styles[`owner--name`]}>
            <Name
              profileData={pet.userInfo}
              name={pet.userInfo.displayName}
              profileType={'user'}
              id={pet.userInfo.id}
              selectStyle='alt-style-2'
            />
          </div>
        </div>
      )
    )
  }

  const litter = (pet) => {
    return (
      pet.offspring?.name && (
        <div className={styles[`litter`]}>
          <div className={styles[`litter--picture`]}>
            <Avatar
              followIcon={false}
              size={{ width: '36px', height: '36px' }}
              nameShown={false}
              profileData={pet.offspring}
              content={{
                image:
                  pet.offspring?.profileImage ||
                  pet.offspring?.profile ||
                  PetPlaceholder,
              }}
              profileType={'offspring'}
              id={pet.offspring._id}
            />
          </div>
          <div className={styles[`litter--name`]}>
            <Name
              profileData={pet.offspring}
              name={pet.offspring.name}
              profileType={'offspring'}
              id={pet.offspring.id}
            />
          </div>
        </div>
      )
    )
  }

  const description = get(petData, 'description') || get(petData, 'details.bio')

  return petData ? (
    <div key={petData.id} className={styles[`pet`]}>
      <div className={styles[`pet-info`]}>
        <div className={styles[`avatar`]}>
          <Avatar
            profileData={petData}
            content={{
              image: petData?.profileImage || petData?.image || PetPlaceholder,
            }}
            profileType={
              petData.type === 'LITTER'
                ? 'offspring'
                : petData.type.toLowerCase()
            }
            id={petData.id}
            size={{ width: '92px', height: '92px' }}
            followIcon={false}
            nameShown={false}
          />
        </div>
        <div className={styles[`main-info`]}>
          <div className={styles[`name`]}>
            <Name
              profileData={petData}
              name={petData.displayName}
              profileType={
                petData.type === 'LITTER'
                  ? 'offspring'
                  : petData.type.toLowerCase()
              }
              id={petData.id}
            />
          </div>
          {ownedBy(petData)}
          <div className={styles[`description`]}>
            {petData.dateOfBirth ? (
              <div className={spaceStyles['spacing-2']}>
                <TextWithLink
                  linkStyle={'link-text-2'}
                  textStyle={'common-text-3'}
                  content={`${t('common:Birthday')}: ${moment(
                    petData.dateOfBirth,
                  ).format('Y-MM-DD')}`}
                />
              </div>
            ) : (
              ''
            )}
            {description ? (
              <div className={spaceStyles['spacing-2']}>
                <TextWithLink
                  linkStyle={'link-text-2'}
                  textStyle={'common-text-3'}
                  content={description}
                />
              </div>
            ) : (
              <div className={spaceStyles['spacing-2']}>
                <p className={textStyles[`common-text-1`]}>
                  {t('common:No description available')}
                </p>
              </div>
            )}
          </div>
          {tags(petData, layout)}
          {litter(petData)}
        </div>
      </div>
    </div>
  ) : null
}

export default ResultCard
