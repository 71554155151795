export default function base64ToBlob(base64, mime) {
  mime = mime || ''
  let sliceSize = 512
  let byteChars = window.atob(base64)
  let byteArrays = []

  for (
    let offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    let slice = byteChars.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: mime })

  return new File(
    [blob],
    `${new Date().toISOString()}.${mime.split('/').at(-1)}`,
    { type: mime },
  )
}
