import React from 'react'
import * as styles from './transfer_offer.module.scss'
import { ReceivedTransfer, OwnTransfer } from '../index'

export default function TranferOffer(props) {
  const { ownTransfers, receivedTransfers, onWithdraw, onAnswerTransferOffer } =
    props

  return (
    <div>
      <div className={styles[`transfers`]}>
        {ownTransfers && ownTransfers.length > 0 && (
          <React.Fragment>
            {ownTransfers.map((transfer) => (
              <OwnTransfer
                key={transfer._id}
                onWithdrawTransfer={onWithdraw}
                data={transfer}
              />
            ))}
          </React.Fragment>
        )}

        {receivedTransfers && receivedTransfers.length > 0 && (
          <React.Fragment>
            {receivedTransfers.map((transfer) => (
              <ReceivedTransfer
                key={transfer.id}
                onAnswerTransferOffer={onAnswerTransferOffer}
                data={transfer}
              />
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
