import React from 'react'
import { Controller } from 'react-hook-form'
import { Label, Form, Grid } from 'semantic-ui-react'
import FormErrorMessage from '../FormErrorMessage'
import PropTypes from 'prop-types'
import { DateInput } from 'semantic-ui-calendar-react'
import get from 'lodash/get'

const FormDate = ({
  label = '',
  name,
  control,
  error,
  required = false,
  iconPosition = 'left',
  rules,
  readOnly,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Form.Field readOnly={readOnly}>
          {label && (
            <Label
              as='label'
              className={get(rules, 'required') ? 'required' : ''}
            >
              {label}
            </Label>
          )}
          <Grid>
            {readOnly ? (
              field.value
            ) : (
              <>
                <>
                  <DateInput
                    {...field}
                    {...rest}
                    ref={() => {
                      field.ref({
                        focus: () => {},
                      })
                    }}
                    value={field.value ?? ''}
                    onChange={(_e, { value }) => {
                      field.onChange(value)
                    }}
                    animation='none'
                    startMode='year'
                    closable
                    dateFormat='YYYY-MM-DD'
                    onBlur={() => {
                      setTimeout(field.onBlur)
                    }}
                  />
                  <FormErrorMessage error={error} />
                </>
              </>
            )}
          </Grid>
        </Form.Field>
      )}
    />
  )
}

FormDate.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  iconPosition: PropTypes.string,
  rules: PropTypes.object,
}

export default React.memo(FormDate)
