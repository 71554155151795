import { createSlice } from '@reduxjs/toolkit'
import fetchPersonnelProfile from './fetchPersonnelProfile'
import updatePersonnelProfile from './updatePersonnelProfile'

const initialState = {
  personnelProfile: {
    isFetching: false,
    data: null,
  },
}

const userProfileSlice = createSlice({
  name: 'userProfileReducer',
  initialState,
  reducers: {
    resetPersonnelProfile: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchPersonnelProfile.pending]: (state) => {
      state.personnelProfile.isFetching = true
    },
    [fetchPersonnelProfile.fulfilled]: (state, { payload }) => {
      state.personnelProfile.isFetching = false
      state.personnelProfile.data = payload
    },
    [fetchPersonnelProfile.rejected]: (state) => {
      state.personnelProfile.isFetching = false
    },
    [updatePersonnelProfile.fulfilled]: (state, { payload }) => {
      state.personnelProfile.data = payload
    },
  },
})

const { actions, reducer: personnelProfileReducer } = userProfileSlice

export { fetchPersonnelProfile, updatePersonnelProfile }

export const { resetPersonnelProfile } = actions

export default personnelProfileReducer
