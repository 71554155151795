import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import * as styles from './styles.module.scss'
import AcceptModal from '../../AcceptModal'
import { useState } from 'react'

const BlockModal = ({ onCancel, t, blockUser, userId, onAccept }) => {
  const [reminder, setReminder] = useState('')

  const handleBlock = () => {
    blockUser?.(userId, reminder)
    onAccept?.()
  }

  const handleChange = (e) => {
    setReminder(e.target.value)
  }

  return (
    <AcceptModal
      title={
        <>
          <span>
            {t(
              'post:If you block this user they will be unable to view your profile, follow you, comment on your posts and send you a private message. You will not see their posts in the feed.',
            )}
          </span>
          <div className={styles.textarea}>
            <textarea
              name='reminder'
              placeholder={t(
                'post:You can write here an optional reminder of why you blocked this user. It is visible only to you.',
              )}
              onChange={handleChange}
            />
          </div>
        </>
      }
      titleClassName={styles.title}
      onAccept={handleBlock}
      onCancel={onCancel}
    />
  )
}

BlockModal.propTypes = {
  userId: PropTypes.number,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
}
const mapStateToProps = (state) => {
  const { appReducer } = state
  return {
    currentUser: appReducer ? appReducer.currentUser : {},
    isFetchingComments: appReducer ? appReducer.isFetchingComments : false,
  }
}

const mapDispatchToProps = {
  // comment,
  // getPostComments,
  // like,
  // unlike,
  // likeComment,
  // openFocusedPhoto
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(BlockModal))
