import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const revokeAssignPet = createAsyncThunk(
  'familyTree/revokeAssignPet',
  async ({ id, parentId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoading())
      await axios.delete(
        `/pets/api/v1/pet-family-trees/${id}/revoke/${parentId}`,
      )
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default revokeAssignPet
