import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoading())
      await axios.delete(`/api/v1/companies/${id}`)
      return id
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deleteCompany
