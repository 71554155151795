import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const fetchOwnedPets = createAsyncThunk(
  'pets/fetchOwnedPets',
  async (payload, { dispatch, rejectWithValue }) => {
    const { sort, sortField, limit, offset, userId } = payload
    try {
      const result = await axios.get(`/pets/api/v1/pets`, {
        params: {
          userId,
          sort: sort || 'desc',
          sortField: sortField || 'createdDate',
          limit,
          offset,
        },
      })
      return result.data.data
      // .records.map((item) => ({
      //   ...item,
      //   createdAt: new Date(item.createdAt).getTime(),
      // }))
    } catch (error) {
      if (error?.response?.data?.code === 'PET_00011') {
        return []
      }
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    }
  },
)

export default fetchOwnedPets
