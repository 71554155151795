import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const appFollow = createAsyncThunk(
  'main/appFollow',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(toggleLoading())
      const type = payload.userId ? 'pet' : 'human'
      await axios.post(`follow`, { type, targetId: payload._id })
    } catch (error) {
      dispatch(addMessageToUser(ERROR_DEFAULT))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default appFollow
