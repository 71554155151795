export const organizationTypeOptions = [
  'nonprofit',
  'privately held',
  'public company',
  'sole proprietorship',
  'other',
]

export const industryOptions = ['natureOfBusinesses', 'manufacturers']

export const sectorOptions = {
  [industryOptions[0]]: [
    'Agent',
    'Animal blogger & expert',
    'Animal Boarding kennel',
    'Animal talent agency',
    'Animal toy maker',
    'Animal trainer',
    'Animal whisperer',
    'Aquarium shop',
    'At-home boarding service operator',
    'Bed/housing designer',
    'Behavioral specialist',
    'Boutique pet shop',
    'Boutique pet toy store',
    'Carshop',
    'Cat cafe operator',
    'Cat influencer',
    'Cat toilet trainer',
    'Collar and leash maker',
    'Collar designer',
    'Consultant',
    'Custom pet portrait artist',
    'Delivery service provider',
    'Design pet beds manufacturer ',
    'Distributor',
    'Dog and cat boarding',
    'Dog bathing',
    'Dog beer brewer',
    'Dog influencer',
    'Dog manicurist',
    'Dog show organized',
    'Dog sports competition organizer',
    'Dog trainer',
    'Dog trainer for hunting',
    'Dog trainer for security',
    'Dog training centre',
    'Dog walker',
    'Dog whisperer',
    'Dog workout coach',
    'Doggy Daycare',
    'Fish tank designer',
    'Fur dying specialist',
    'Furniture designer',
    'Grooming services',
    'Guard dog security service provider',
    'Home made pet food manufacturer',
    'Home pet monitor expert',
    'Hotel',
    'Importer & distributor',
    'In home cleaner',
    'Influrencer',
    'Kennel cleaning provider',
    'Large animal sitter',
    'Luxury boarding service operator',
    'Maker of breath mints for dogs',
    'Manufacturer',
    'Manufacturer private label',
    'Marketing & communication',
    'Mobile dog grooming',
    'Mobile dog training',
    'Mobile pet clinic',
    'Obedience expert',
    'Online shop',
    'Online pet expert',
    'Organic treat maker',
    'Organization',
    'Pet accessories designer',
    'Pet ambulance',
    'Pet bakery',
    'Pet bandana maker',
    'Pet boarding kennel',
    'Pet boutique',
    'Pet brand manager',
    'Pet business marketing',
    'Pet business marketing specialist',
    'Pet cafe operator',
    'Pet clothing designer',
    'Pet coffins and urns',
    'Pet day care',
    'Pet event organizer',
    'Pet event planner',
    'Pet food truck owner',
    'Pet franchise',
    'Pet friendly hotel',
    'Pet friendly restaurant',
    'Pet funeral provider ',
    'Pet health expert',
    'Pet hotel',
    'Pet influencer',
    'Pet insurance company',
    'Pet marketing',
    'Pet marketing ecommerce',
    'Pet massage therapy',
    'Pet momager',
    'Pet movie producer',
    'Pet nutritionist',
    'Pet photographer',
    'Pet physigal terapist',
    'Pet pilow creator',
    'Pet press',
    'Pet related radio talk show',
    'Pet related tv show',
    'Pet restaurant',
    'Pet retailer',
    'Pet sharing service operator',
    'Pet shelter',
    'Pet shop with lifestock',
    'Pet sitter',
    'Pet spa operator',
    'Pet supply store',
    'Pet taxi',
    'Pet toy designer',
    'Pet transportation',
    'Pet travel agency',
    'Pet treat subscription service provider',
    'Pet treats bakery',
    'Pet-portrait',
    'Petshop',
    'Petsitting',
    'Pooch couture',
    'Poop scooping',
    'Press',
    'Publisher of pet magazines',
    'Puppy training courses',
    'Renter of unusual animals',
    'Rescue organization',
    'Retail chain',
    'Saler of breath mints for dogs',
    'Shelter matching expert',
    'Social media influencer',
    'Social media pet person',
    'Supplier pet industry',
    'Tag engraver',
    'Trade show organizer',
    'T-shirt designer',
    'Veterinarian',
    'Virtual pet trainer',
    'Wholesaler',
    'Yard cleaner',
    'YouTube training expert',
    'Other',
  ],
  [industryOptions[1]]: [
    'Accessories',
    'Aquarium products',
    'Bird accessories',
    'Bird food',
    'Bird healthcare products',
    'Bird toys',
    'Birds',
    'Cages',
    'Cat accessories',
    'Cat food',
    'Cat healthcare products',
    'Cat litter',
    'Cat snacks & treats',
    'Cat toys',
    'Cat vet food',
    'Dog accessories',
    'Dog food',
    'Dog healthcare products',
    'Dog snacks &treats',
    'Dog toys',
    'Equipment & machinery',
    'Feed additives',
    'Fish',
    'Fish food',
    'Fish healthcare products',
    'Grooming products',
    'Horse feed',
    'Horse products',
    'Kennels and houses',
    'Livestock',
    'Packaging materials',
    'Plants',
    'Protein sources',
    'Reptile & terrarium products',
    'Reptile food',
    'Rodents toys',
    'Small animal accessories',
    'Small animal food',
    'Small animal healthcare products',
    'Small animal toys',
    'Toys',
    'Other',
  ],
}

export const allSectorOptions = Object.values(sectorOptions).reduce(
  (acc, item) => [...acc, ...item],
  [],
)

export const companySizeOptions = [
  '1-9 employees',
  '10-49 employees',
  '50-199 employees',
  '200-499 employees',
  '500-999 employees',
  '1000-4999 employees',
  '5000-9999 employees',
  '10,000+ employees',
]
