import map from 'lodash/map'
import React from 'react'
import { useDispatch } from 'react-redux'
import { openLightbox } from '../../pages/Main/slice'
import * as styles from './image_list.module.scss'
import SingleImage from './SingleImage'
import * as layoutStyles from 'styles/layouts.module.scss'

const ImageList = ({ images, onSelect, selected, isSelecting }) => {
  const dispatch = useDispatch()

  const onOpenLightbox = (imageData, imageIdx) => {
    dispatch(openLightbox({ ...imageData, imageIdx, imageSource: images }))
  }

  return (
    <div
      className={`${styles[`image-list-container`]} ${layoutStyles['list']}`}
    >
      {map(images, (item, idx) => {
        return (
          <SingleImage
            s3Id={item?.s3Id}
            isSelecting={isSelecting}
            onOpenLightbox={() => onOpenLightbox(item, idx)}
            isSelected={selected.includes(item.s3Id)}
            onSelect={onSelect}
            key={item.s3Id}
            imageId={item.s3Id}
            image={item.url}
            imageData={item}
          />
        )
      })}
    </div>
  )
}

export default React.memo(ImageList)
