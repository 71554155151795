import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const deleteMediaImage = createAsyncThunk(
  'media/deleteMediaImage',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const { s3Ids } = payload
      await axios.post(`/common/api/v1/files/delete-multi-file`, {
        s3Ids,
      })
      return payload
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deleteMediaImage
