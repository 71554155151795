import { InlineSpinner } from 'commons/components'
import Avatar from 'components/Avatar'
import { useCheckScroll } from 'hooks'
import isEmpty from 'lodash/isEmpty'
import { unFollow } from 'pages/Main/main_routes/Following/slice'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import * as layouts from 'styles/layouts.module.scss'
import * as styles from './following_tab.module.scss'

const FollowingTab = ({
  onFetchFollowingTab,
  followings,
  isFetching,
  isFetchEnded,
  isCurrentUser,
}) => {
  const { isBottom } = useCheckScroll()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isBottom && !isFetching && !isFetchEnded) {
      onFetchFollowingTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom, isFetching, isFetchEnded])

  const onClickRemove = (value) => {
    dispatch(unFollow(value))
  }

  return (
    <div className={layouts[`following-tab`]}>
      {!isEmpty(followings) && (
        <div className={layouts['list']}>
          {followings.map((follow) => (
            <div
              key={follow.id}
              className={`${layouts['list__item']} ${layouts['list__item--full']} ${styles['following']}`}
            >
              <Avatar
                link={`/user/${follow.id}`}
                src={follow.profileImage}
                title={follow.displayName || follow.name}
                desc={follow.country}
                type={follow.type}
              />
              {isCurrentUser && (
                <Button basic onClick={() => onClickRemove(follow)}>
                  {t('common:Unfollow')}
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
      {!isFetching && isEmpty(followings) && (
        <p style={{ fontStyle: 'italic' }}>{t('common:No follows yet')}</p>
      )}
      {isFetching && <InlineSpinner />}
    </div>
  )
}

export default React.memo(FollowingTab)
