import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { uploadFiles } from 'services/fileManagement'

const updateLitterProfile = createAsyncThunk(
  'litterProfile/updateLitterProfile',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const { litterProfileReducer, appReducer } = getState()
      const {
        litter: {
          data: { id, avatar, species, s3Avatar, ...litterProfile },
        },
      } = litterProfileReducer
      const { image, ...newLitterProfile } = payload
      const updatedPetProfile = { ...newLitterProfile }
      if (image) {
        const uploadData = await uploadFiles({
          userId: appReducer.currentUser.id,
          files: [image.file],
          action: UPLOAD_ACTION.POST,
          petId: id,
        })
        newLitterProfile[image.name] = uploadData[0].s3Id
        updatedPetProfile[image.name] = uploadData[0].url
      }
      await axios.put(`/pets/api/v1/pets/litters/${id}`, {
        ...litterProfile,
        speciesId: species.speciesName,
        ...newLitterProfile,
      })
      return updatedPetProfile
    } catch (error) {
      return rejectWithValue(addMessageToUser(getErrorMessage(error)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updateLitterProfile
