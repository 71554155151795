import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { galleryReducerMapping } from '../constant'

const fetchGalleries = createAsyncThunk(
  'media/fetchGalleries',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const { galleryType } = payload
      const { mediaReducer } = getState()
      const gallery = mediaReducer[galleryReducerMapping[galleryType]]

      const params = {
        ...payload,
        offset: gallery.offset,
        limit: gallery.limit,
      }
      const res = await axios.get(`/common/api/v1/files/load-user-media`, {
        params,
      })
      return { galleryType, ...res.data }
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    }
  },
)

export default fetchGalleries
