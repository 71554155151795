import React, { lazy, Suspense as ReactSuspense } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spinner } from 'commons/components'
import NoMatch from 'components/NoMatch'

const Main = lazy(() => import('./pages/Main'))
const Auth = lazy(() => import('./pages/Auth'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

const STATIC_PATHS = ['/', '/signin-otp', '/forgot-password']

export const Suspense = ({ component }) => (
  <ReactSuspense fallback={<Spinner />}>{component}</ReactSuspense>
)

const Routes = () => {
  const { pathname, search } = useLocation()
  const { isRefreshToken, currentUser } = useSelector(
    (state) => state.appReducer,
  )

  if (!isRefreshToken) {
    return null
  }

  if (!currentUser.isAuthenticated && !STATIC_PATHS.includes(pathname)) {
    if (
      process.env.NODE_ENV === 'development' ||
      ['/settings', '/companies'].includes(pathname)
    ) {
      const next = encodeURIComponent(pathname + search)
      return <Redirect to={`/?next=${next}`} />
    }
    return <Redirect to='/' />
  }

  if (
    pathname + search !== '/settings?tab=account' &&
    currentUser.isAuthenticated &&
    !currentUser.dob
  ) {
    return <Redirect to='/settings?tab=account' />
  }

  return (
    <Switch>
      <Route exact path='/signin-otp'>
        <Suspense component={<Auth />} />
      </Route>
      <Route exact path='/forgot-password'>
        <Suspense component={<ForgotPassword />} />
      </Route>
      <Route path='/'>
        <Suspense
          component={currentUser.isAuthenticated ? <Main /> : <Auth />}
        />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  )
}

export default Routes
