import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'
import { uploadFiles } from 'services/fileManagement'

const updateUserProfile = createAsyncThunk(
  'app/updateUserProfile',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { appReducer } = getState()
      const {
        currentUser: { profileImage, backgroundImage, ...userProfile },
      } = appReducer
      const { image, ...newUserProfile } = payload
      if (image) {
        const uploadData = await uploadFiles({
          userId: appReducer.currentUser.id,
          files: [image.file],
          action: UPLOAD_ACTION.POST,
        })
        newUserProfile[image.name] = uploadData[0].s3Id
        userProfile[image.name] = uploadData[0].url
      }
      const res = await axios.put(`/user/api/v1/users/updateProfile`, {
        ...userProfile,
        ...newUserProfile,
      })
      return res.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default updateUserProfile
