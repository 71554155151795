import { addMessageToUser } from 'commons/slice'
import { ERROR_MEDIA_LONG } from 'commons/utils/error_messages_var'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import PostForm from '../PostForm'

const EditModal = ({ post, onAccept, onCancel, inputPlaceholder }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { currentUser } = useSelector((state) => state.appReducer)

  const onSubmit = (content, pictures, files) => {
    if (files.length <= 10) {
      onAccept({ postId: post.id, content, pictures, files })
    } else {
      dispatch(addMessageToUser(ERROR_MEDIA_LONG))
    }
  }

  return (
    <Modal
      open
      size='small'
      centered={false}
      closeIcon
      closeOnDimmerClick
      closeOnEscape
      onClose={onCancel}
    >
      <Modal.Header>
        <h4>{t('common:Edit Post')}</h4>
      </Modal.Header>
      <Modal.Description>
        <PostForm
          data={currentUser}
          isEdit
          onSubmit={onSubmit}
          onCancel={onCancel}
          defaultValues={post}
          inputPlaceholder={inputPlaceholder}
        />
      </Modal.Description>
    </Modal>
  )
}

EditModal.propTypes = {
  post: PropTypes.object,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  accept: PropTypes.string,
  cancel: PropTypes.string,
}

export default React.memo(EditModal)
