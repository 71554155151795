const colors = [
  '#2996CC',
  '#F7B267',
  '#FFE066',
  '#68B684',
  '#FF6B6B',
  '#76BCDE',
  '#F9CE9E',
  '#FFEB9D',
  '#9ED0B0',
  '#FFA0A0',
  '#4FA9D5',
  '#F8C082',
  '#FFE581',
  '#83C39A',
  '#FF8585',
  '#227BA7',
  '#CB9255',
  '#D1B854',
  '#56956D',
  '#D15858',
  '#1B6082',
  '#9E7242',
  '#A38F41',
  '#437454',
  '#A34545',
]
export default colors
