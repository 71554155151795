import React from 'react'
import * as styles from './picture_dropzone.module.scss'
import Dropzone from 'react-dropzone'
import AvatarPlaceholder from '../../resources/icons/user (1).svg'

const classNameGenerators = (avatarFullWidth, whitePictureBackground, name) => {
  if (avatarFullWidth) {
    return `${styles[`${name}`]} ${styles[`${name}--fullWidth`]}`
  } else if (whitePictureBackground) {
    return `${styles[`${name}`]} ${styles[`${name}--white-background`]}`
  } else {
    return `${styles[`${name}`]}`
  }
}

const documentFileFormat =
  '.doc, .docx, .dot, .dotx, .docm, .dotm, .xls, .xlsx, .xlsm, .xlsb, .xlt, .xltx, .xltm, .pdf'
const imageFormat = 'image/jpeg, image/png'

const PictureDropzone = (props) => {
  const {
    onDropFile,
    imageUrl,
    avatarFullWidth,
    whitePictureBackground,
    disabled,
    isMemorial,
    acceptDocumentFile,
  } = props
  return (
    <Dropzone
      multiple={false}
      accept={acceptDocumentFile ? documentFileFormat : imageFormat}
      onDrop={(acceptedFiles) => onDropFile(acceptedFiles)}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            className={classNameGenerators(
              avatarFullWidth,
              whitePictureBackground,
              'dropzone-wrapper',
            )}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div
              id='dropzone'
              className={classNameGenerators(
                avatarFullWidth,
                whitePictureBackground,
                'dropzone',
              )}
            >
              {imageUrl && (
                <img
                  className={`${styles[`preview`]} ${
                    avatarFullWidth ? styles[`preview--fullWidth`] : ''
                  } ${isMemorial ? styles['preview--memorial'] : ''}`}
                  src={imageUrl || AvatarPlaceholder}
                  alt='avatar'
                />
              )}
              {!disabled && (
                <span
                  className={`${classNameGenerators(
                    avatarFullWidth,
                    whitePictureBackground,
                    acceptDocumentFile ? 'icon--file' : 'icon--camera',
                  )} ${styles['icon']}`}
                />
              )}
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  )
}
export default PictureDropzone
