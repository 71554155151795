import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import get from 'lodash/get'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const PAGE_SIZE = 10

const searchPetForm = createAsyncThunk(
  'searchReducer/searchPetForm',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const page = get(state, 'searchReducer.page')
      const { type, ...data } = payload
      const response = await axios.post(
        `/search-engine/api/v1/searchings?page=${page}&size=${PAGE_SIZE}&type=${type.value}`,
        data,
      )
      return (
        Object.values(response.data.data).find((item) => item && item.length) ??
        []
      )
    } catch (error) {
      return rejectWithValue(ERROR_DEFAULT)
    }
  },
)

export default searchPetForm
