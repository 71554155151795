import i18n from 'i18next'
import auth_en from './commons/translations/en/auth.json'
import auth_fi from './commons/translations/fin/auth.json'
import common_en from './commons/translations/en/common.json'
import common_fi from './commons/translations/fin/common.json'
import profileForm_en from './commons/translations/en/profile_form.json'
import profileForm_fi from './commons/translations/fin/profile_form.json'
import message_en from './commons/translations/en/message.json'
import message_fi from './commons/translations/fin/message.json'
import petProfile_en from './commons/translations/en/petProfile.json'
import petProfile_fi from './commons/translations/fin/petProfile.json'
import petSearch_en from './commons/translations/en/pet_search.json'
import petSearch_fi from './commons/translations/fin/pet_search.json'
import post_en from './commons/translations/en/post.json'
import post_fi from './commons/translations/fin/post.json'
import { translation as petTranslations } from './commons/resources/dynamic_configs/index'
import { translation as countryTranslations } from './commons/components/CountryPicker/countries'
import { addResource } from './commons/utils/i18next'

const appended_auth_fi = {
  Login: 'Kirjaudu',
  Register: 'Luo käyttäjätili',
  'Email address': 'Sähköposti',
  Password: 'Salasana',
  'Forgot your password?': 'Unohditko salasanasi?',
  'Remember me': 'Muista minut',
  'Log out': 'Kirjaudu ulos',
  ...auth_fi,
}

const appended_auth_en = {
  Login: 'Login',
  Register: 'Register',
  'Email address': 'Email address',
  Password: 'Password',
  'Forgot your password?': 'Forgot your password?',
  'Remember me': 'Remember me',
  'Log out': 'Log out',
  ...auth_en,
}

const resources = {
  common: {
    fi: common_fi,
    en: common_en,
  },
  auth: {
    fi: appended_auth_fi,
    en: appended_auth_en,
  },
  profileForm: {
    fi: profileForm_fi,
    en: profileForm_en,
  },
  petProfile: {
    fi: petProfile_fi,
    en: petProfile_en,
  },
  petSearch: {
    fi: petSearch_fi,
    en: petSearch_en,
  },
  message: {
    fi: message_fi,
    en: message_en,
  },
  pet: {
    // From dynamic form
    fi: petTranslations['pet']['form']['fi'],
    en: petTranslations['pet']['form']['en'],
  },
  country: {
    // From dynamic form
    fi: countryTranslations['fi'],
    en: countryTranslations['en'],
  },
  post: {
    fi: post_fi,
    en: post_en,
  },
}

addResource(i18n, resources)

export default i18n
