import { createSlice } from '@reduxjs/toolkit'
import createPetProfile from './createPetProfile'

const initialState = {
  isCreatePetSuccess: false,
  createdPetId: null,
}

const profileFormSlice = createSlice({
  name: 'createPetReducer',
  initialState,
  reducers: {
    resetCreatePet: () => {
      return initialState
    },
  },
  extraReducers: {
    [createPetProfile.pending]: (state) => {
      state.isCreatePetSuccess = false
    },
    [createPetProfile.fulfilled]: (state, { payload }) => {
      state.isCreatePetSuccess = true
      state.createdPetId = payload
    },
  },
})

const { actions, reducer: createPetReducer } = profileFormSlice

export const { resetCreatePet } = actions

export { createPetProfile }

export * from './actions'

export default createPetReducer
