import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const readNotification = createAsyncThunk(
  'notification/readAllNotification',
  async (_payload, { rejectWithValue }) => {
    try {
      return await axios.put(
        `/notification/api/v1/notification-auditings/marked`,
      )
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default readNotification
