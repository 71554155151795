import en from './en'
import fi from './fi'
import sv from './sv'

const countries = {
  en,
  fi,
  sv,
}

export default countries
