import React from 'react'
import * as styles from './OwnTransfer.module.scss'
import { Avatar, Name, TextWithLink } from '../index'
import get from 'lodash/get'
import { toProperCase } from 'commons/utils/sanitize'
import { useTranslation } from 'react-i18next'
import PetPlaceholder from 'commons/resources/icons/default_Pet Blue 174px.svg'
import UserPlaceholder from 'commons/resources/icons/default_Human Blue 174px.svg'
import * as textStyles from 'commons/scss/text.module.scss'
import * as spaceStyles from 'commons/scss/space.module.scss'

const OwnTransfer = (props) => {
  const { data, onWithdrawTransfer } = props
  const { t } = useTranslation()
  return (
    <div className={styles[`transfer`]}>
      <div className={styles[`pet`]}>
        <div className={styles[`avatar`]}>
          <Avatar
            followIcon={false}
            size={{
              width: '92px',
              height: '92px',
            }}
            nameShown={false}
            profileData={get(data, 'petid')}
            content={{
              image:
                data?.petId?.profileImage ||
                data?.petId?.profile ||
                PetPlaceholder,
            }}
            profileType={'pet'}
            id={get(data, 'petid.id')}
          />
        </div>
        <div className={styles[`info`]}>
          <Name
            profileData={get(data, 'petid')}
            name={get(data, 'petid.name')}
            profileType={'pet'}
            id={get(data, 'petid.id')}
          />
          {get(data, 'petid.description') ? (
            <div className={spaceStyles['spacing-2']}>
              <TextWithLink
                linkStyle={'link-text-2'}
                textStyle={'common-text-3'}
                content={get(data, 'petid.description')}
              />
            </div>
          ) : (
            <div className={spaceStyles['spacing-2']}>
              <p className={textStyles[`common-text-1`]}>
                {t('common:No description available')}
              </p>
            </div>
          )}
          <div className={styles[`extra`]}>
            <div className={styles[`gender`]}>
              <span className={styles[`icon--gender`]} />
              <p>{t(`common:${toProperCase(get(data, 'petid.gender'))}`)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles[`user`]}>
        <div className={styles[`avatar`]}>
          <Avatar
            followIcon={false}
            size={{
              width: '92px',
              height: '92px',
            }}
            nameShown={false}
            profileData={get(data, 'answerer')}
            content={{
              image:
                data?.answerer?.profileImage ||
                data?.answerer?.profile ||
                UserPlaceholder,
            }}
            profileType={'user'}
            id={get(data, 'answerer.id')}
          />
        </div>
        <div className={styles[`info`]}>
          <p className={styles[`name`]}>
            {t('common:You have started a pet profile transfer to ')}{' '}
            <Name
              profileData={get(data, 'answerer')}
              name={get(data, 'answerer.displayName')}
              profileType={'user'}
              id={get(data, 'answerer.id')}
            />
            {'.'}
          </p>
          <p className={textStyles[`common-text-3`]}>
            {t(
              'common:Waiting for an action from the receiver. Once they accept the transfer you will lose access to the pet profile.',
            )}
          </p>
        </div>
      </div>
      <div className={styles[`action-buttons`]}>
        <div
          onClick={() =>
            onWithdrawTransfer(data.id, data.petid.id || data.petid)
          }
          className={styles[`button-container`]}
        >
          <button className={`${styles[`button`]} ${styles[`button--delete`]}`}>
            {t('common:Cancel pet profile transfer')}
          </button>
          <span className={`${styles[`icon`]} ${styles[`icon--delete`]}`} />
        </div>
      </div>
    </div>
  )
}

export default OwnTransfer
