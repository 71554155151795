import { UAParser } from 'ua-parser-js'

export const getDeviceInfo = () => {
  const parser = new UAParser(navigator.userAgent)
  const result = parser.getResult()

  return {
    deviceId: `${result.os.name} ${result.os.version}`,
    deviceType: `${result.browser.name} ${result.browser.version}`,
  }
}
