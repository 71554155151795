import { createAsyncThunk } from '@reduxjs/toolkit'
import service from 'services/post'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'
import { addMessageToUser } from 'commons/slice'

const deletePost = createAsyncThunk(
  'main/deletePost',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      await service.deletePost(payload)
      return payload
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    }
  },
)

export default deletePost
