import React from 'react'
import * as styles from './searchable_dropdown.module.scss'
import Placeholder from '../../../../../../commons/resources/icons/default_Human White 290px.svg'
import { withTranslation } from 'react-i18next'

class SearchableDropdown extends React.Component {
  state = { shown: false, user: { id: '', name: '' } }

  toggleOptions = (e) => {
    this.setState({ shown: !this.state.shown })
  }

  onInputChange = (name) => {
    this.setState({
      user: {
        id: '',
        name,
      },
    })

    if (name.length > 2) {
      this.props.onInputChange(name)
    }
  }

  onChooseOption = (opt) => {
    this.props.onChooseOption(opt)
    this.setState({
      user: {
        id: opt.id,
        name: opt.name,
      },
      shown: false,
    })
  }

  onClear = () => {
    this.props.onClear()
    this.setState({
      user: { id: '', name: '' },
      shown: false,
    })
  }

  render() {
    const { name, users, t } = this.props
    const options = users.map((user) => {
      return {
        id: user.id,
        name: user.displayName,
        image: user?.profileImage || user?.profile || Placeholder,
      }
    })

    return (
      <div className={styles[`searchable-dropdown`]}>
        <div className={styles[`input`]}>
          <input
            onBlur={() =>
              setTimeout(() => this.setState({ shown: false }), 150)
            }
            onFocus={this.toggleOptions}
            autocomplete='off'
            placeholder={t('common:Enter the name')}
            type='text'
            onChange={(e) => this.onInputChange(e.target.value)}
            name={name}
            value={this.state.user.name}
          />
          <span className={styles[`icon--name`]} />
          {this.state.user.name !== '' && (
            <span onClick={this.onClear} className={styles[`icon--delete`]} />
          )}

          <div
            className={
              this.state.shown
                ? `${styles[`options`]} ${styles[`options--show`]}`
                : `${styles[`options`]}`
            }
          >
            {options && options.length > 0 ? (
              options.map((opt) => (
                <div
                  onClick={() => this.onChooseOption(opt)}
                  className={styles[`option`]}
                  key={opt.id}
                >
                  <img src={opt.image} alt='user' />
                  <p>{opt.name}</p>
                </div>
              ))
            ) : (
              <div className={styles[`option`]}>
                <p>{t('common:No results found')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SearchableDropdown)
