import React from 'react'
import * as styles from './avatar.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { follow, unFollow } from '../../../Following/slice'
import PetPlaceholder from 'commons/resources/icons/default_Pet White 512px.svg'
import UserPlaceholder from 'commons/resources/icons/default_Human White 512px.svg'
import ImageFallbackWhite from 'commons/resources/icons/image_fallback_white.svg'
import ImageFallbackBlue from 'commons/resources/icons/image_fallback_blue.svg'

const Avatar = (props) => {
  const {
    content,
    profileType: propProfileType,
    profileData,
    id,
    imageFallback,
    size,
    followIcon = true,
    nameShown = true,
    onClick,
  } = props
  const profileType = propProfileType.toLowerCase()

  const history = useHistory()
  const dispatch = useDispatch()
  const { appReducer, followingReducer } = useSelector((state) => state)
  const { currentUser, userStatus } = appReducer
  const { followings } = followingReducer

  const onToProfile = (id) => {
    switch (profileType) {
      case 'user': {
        history.push(`/user/${id}`)
        break
      }

      case 'pet': {
        history.push(`/pets/${id}`)
        break
      }

      case 'offspring':
        history.push(`/litters/${id}`)
        break

      case 'company':
        history.push(`/companies/${id}`)
        break

      default:
        return
    }
  }

  const onFollowClick = () => {
    if (followings?.find((item) => item.followId === profileData.followId)) {
      dispatch(unFollow(profileData))
    } else {
      dispatch(follow({ id: profileData.followId, type: profileType }))
    }
  }

  const showFallbackImage = (ev) => {
    ev.target.src =
      imageFallback === 'white' ? ImageFallbackWhite : ImageFallbackBlue
  }

  const Placeholder = ['user', 'company', 'personnel'].includes(profileType)
    ? UserPlaceholder
    : PetPlaceholder

  const followed = followings.find(
    (item) => item.followId === profileData.followId,
  )

  return (
    <div className={styles[`avatar-container`]}>
      <div
        style={{ ...size }}
        className={`${styles[`avatar`]} ${
          profileType === 'user' ? styles['status'] : ''
        } ${userStatus[id] ? styles['online'] : ''}`}
      >
        <img
          onClick={() => (onClick ? onClick() : onToProfile(id))}
          alt='avatar'
          className={styles[`picture`]}
          src={content.image ? content.image : Placeholder}
          onError={showFallbackImage}
        />

        {id !== currentUser.id && followIcon && (
          <>
            {['user', 'company'].includes(profileType) && (
              <div
                onClick={onFollowClick}
                className={
                  followed
                    ? `${styles[`follow-icon`]} ${
                        styles[`follow-icon--user`]
                      } ${styles[`follow-icon--user--active`]} `
                    : `${styles[`follow-icon`]} ${
                        styles[`follow-icon--user`]
                      } ${styles[`follow-icon--user--inactive`]}`
                }
              ></div>
            )}
            {['pet', 'offspring'].includes(profileType) && (
              <div
                onClick={onFollowClick}
                className={
                  followed
                    ? `${styles[`follow-icon--pet`]} ${
                        styles[`follow-icon--pet--active`]
                      } ${styles[`follow-icon`]}`
                    : `${styles[`follow-icon--pet`]} ${
                        styles[`follow-icon--pet--inactive`]
                      }  ${styles[`follow-icon`]}`
                }
              ></div>
            )}
          </>
        )}
      </div>
      {nameShown && content.name && (
        <p className={styles[`name`]}>{content.name}</p>
      )}
    </div>
  )
}

export default Avatar
