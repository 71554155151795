import { createAsyncThunk } from '@reduxjs/toolkit'
import { getErrorMessage } from 'commons/utils'
import { deleteFile } from 'services/fileManagement'

const deleteCompanyImage = createAsyncThunk(
  'companyProfile/deleteCompanyImage',
  async (payload, { rejectWithValue }) => {
    try {
      const { s3Id, field } = payload
      await deleteFile(s3Id)
      return field
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default deleteCompanyImage
