import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'

const signInOTP = createAsyncThunk(
  'auth/signInOTP',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/user/api/v1/auth/sign-in/otp/${payload}`,
      )
      const { accessToken, refreshToken } = response.data
      localStorage.setItem('token', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      return response
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || 'User or password is invalid',
      )
    }
  },
)

export default signInOTP
