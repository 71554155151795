import React from 'react'
import * as styles from './focused_photo.module.scss'
import { connect, useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { closeFocusedPhoto, navigateFocusedPhoto } from 'pages/Main/slice'
import ReactImageFallback from 'react-image-fallback'
import DeletedImage from '../../resources/images/deleted_photo.svg'
import { getFileType } from 'commons/utils'

const FocusedPhoto = ({
  closeFocusedPhoto,
  focusedPhoto: { isShown, activeImage, imageSource = [] },
}) => {
  const dispatch = useDispatch()

  const onNavigateFocusedPhoto = (direction) => {
    dispatch(navigateFocusedPhoto(direction))
  }

  const renderPreview = (url) => {
    if (!url) {
      return null
    }
    if (getFileType(url) === 'video') {
      return (
        <video controls autoPlay src={url} muted>
          <source src={url} />
        </video>
      )
    }
    return <ReactImageFallback src={url} fallbackImage={DeletedImage} />
  }

  return (
    <Modal
      open={isShown}
      closeIcon
      onClose={() => closeFocusedPhoto()}
      content={
        <div className={styles[`focused-photo-container`]}>
          {renderPreview(imageSource[activeImage]?.url)}
          <div className={styles[`controls`]}>
            {imageSource.length > 1 && activeImage > 0 && (
              <div
                onClick={() => onNavigateFocusedPhoto(-1)}
                className={`${styles[`icon`]} ${styles[`icon--back`]}`}
              />
            )}
            <div className={styles[`spacer`]} />
            {imageSource.length > 1 && activeImage < imageSource.length - 1 && (
              <div
                onClick={() => onNavigateFocusedPhoto(1)}
                className={`${styles[`icon`]} ${styles[`icon--next`]}`}
              />
            )}
          </div>
        </div>
      }
    />
  )
}

const mapStateToProps = (state) => {
  const { mainReducer } = state
  return {
    focusedPhoto: mainReducer
      ? mainReducer.focusedPhoto
      : { isShown: false, activeImage: 0, imageSource: [] },
  }
}

const mapDispatchToProps = {
  closeFocusedPhoto,
  navigateFocusedPhoto,
}

export default connect(mapStateToProps, mapDispatchToProps)(FocusedPhoto)
