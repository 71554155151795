import React, { useState } from 'react'
import { PictureDropzone, MediaDropzone, ImageCropper } from '../index'
import Resizer from 'react-image-file-resizer'
import { Modal } from 'semantic-ui-react'
import { imageResizerConfig } from '../../utils/imageResizerConfig'
import { getFileType } from 'commons/utils'
import { useDispatch } from 'react-redux'
import { addMessageToUser } from 'commons/slice'

const ImagePicker = ({
  onSaveImage,
  name,
  onCancelImage,
  cropSize = {
    height: 200,
    width: 200,
  },
  imageUrl,
  whitePictureBackground,
  icon,
  avatarFullWidth,
  disabled = false,
  component,
  isMemorial,
  acceptVideo,
  acceptDocumentFile,
}) => {
  const dispatch = useDispatch()
  const [imagePreview, setImagePreview] = useState('')
  const [imageCropping, setImageCropping] = useState(false)

  const onDropFile = ([acceptedFile]) => {
    if (acceptDocumentFile) {
      return onSaveImage(acceptedFile)
    }
    if (getFileType(acceptedFile) === 'video') {
      //60MB
      if (acceptedFile.size > 20 * 1024 * 1024) {
        return dispatch(
          addMessageToUser('Please select a video less than 60MB.'),
        )
      }
      return onSaveImage?.(
        URL.createObjectURL(acceptedFile),
        acceptedFile,
        name ?? '',
      )
    }
    Resizer.imageFileResizer(
      acceptedFile,
      imageResizerConfig.maxWidth,
      imageResizerConfig.maxHeight,
      imageResizerConfig.compressFormat,
      imageResizerConfig.quality,
      0,
      (uri) => {
        setImageCropping(true)
        setImagePreview(uri)
      },
      'base64',
    )
  }

  const onSaveImages = (local, remote) => {
    setImageCropping(false)
    onSaveImage?.(local, remote, name ?? '')
  }

  const onCancelImages = (e) => {
    setImageCropping(false)
    setImagePreview('')
    if (onCancelImage) {
      onCancelImage(e)
    }
  }

  return (
    <div>
      <Modal
        centered={false}
        closeIcon
        onClose={onCancelImages}
        open={imageCropping}
      >
        <ImageCropper
          onCancelImage={onCancelImages}
          onSaveImage={onSaveImages}
          imageUrl={imagePreview}
          cropSize={cropSize}
        />
      </Modal>
      {icon === 'media' ? (
        <MediaDropzone
          disabled={disabled}
          onDropFile={onDropFile}
          component={component}
          acceptVideo={acceptVideo}
        />
      ) : (
        <PictureDropzone
          whitePictureBackground={whitePictureBackground}
          avatarFullWidth={avatarFullWidth}
          onDropFile={onDropFile}
          imageUrl={imageUrl}
          disabled={disabled}
          isMemorial={isMemorial}
          acceptDocumentFile={acceptDocumentFile}
        />
      )}
    </div>
  )
}

export default ImagePicker
