const fi = {
  'Add a pup': 'Lisää pentu',
  'Breeder - kennel name - date of birth - males - females':
    'Kasvattaja - kennelnimi - syntymäaika - uroksia - narttuja',
  Cancel: 'Peru',
  'Choose a name for your litter...': 'Valitse pentueen nimi…',
  'Content is required in field': 'Syöte vaaditaan kenttään',
  'Copy-paste link to kennel club or other database':
    'Kopioi ja liitä linkki jalostustietokantaan',
  'Create offspring': 'Luo pentu',
  Create: 'Luo profiili',
  'Create parent': 'Luo vanhempi',
  'Date of birth': 'Syntymäaika',
  'Do you want to add another pet?': 'Haluatko lisätä toisen lemmikin?',
  Done: 'Tehty',
  'For breeding': 'Jalostukseen',
  'Here you can tell more': 'Tässä voit kertoa lisää',
  'If you own the dam and it has a profile already you can add it from here':
    'Mikäli omistat emän ja sillä on jo profiili voit lisätä sen tästä',
  'If you own the sire and it has a profile already you can add it from here':
    'Mikäli omistat isän ja sillä on jo profiili voit lisätä sen tästä',
  Intact: 'Leikkaamaton',
  'Invalid input in field': 'Virheellinen syöte kentässä',
  'Litter description placeholder':
    'Tietoa pentueesta: syntymäaika - rotu - lkm uros/narttu - isä/emä - maa',
  MHNature: 'MH -luonnekuvaus',
  'More detailed information': 'Lisää tarkemmat tiedot',
  Next: 'Seuraava',
  'No results found': 'Ei hakutuloksia',
  'Official and/or petname': 'Virallinen nimi ja/tai lempinimi',
  PKSearch: 'PK-Haku',
  Price: 'Hinta',
  'Ready to leave': 'Luovutusikäinen',
  Save: 'Tallenna',
  Select: 'Valitse',
  'Select breed': 'Valitse rotu',
  'Select country': 'Valitse maa',
  'Select species': 'Valitse laji',
  Skip: 'Ohita',
  'Unknown Organization': 'Järjestö ei tiedossa',
  'affen pinscher': 'apinapinseri',
  'afghan hound': 'afgaaninvinttikoira',
  age: 'Ikä',
  agility: 'Agility',
  agouti: 'agouti',
  aidi: 'aidi',
  'airedale terrier': 'airedalenterrieri',
  akita: 'akita',
  'alaskan malamute': 'alaskanmalamuutti',
  'alentejo mastiff': 'alentejonkoira',
  'alpine dachsbracke': 'alppienajokoira',
  amber: 'amber/keltainen',
  'american akita': 'amerikanakita',
  'american cocker spaniel': 'amerikancockerspanieli',
  'american eskimo dog': 'amerikan eskimo koira',
  'american fox hound': 'amerikankettukoira',
  'american hairless terrier': 'amerikankarvatonterrieri',
  'american staffordshire terrier': 'amerikanstaffordshirenterrieri',
  'american toy fox terrier': 'amerikankääpiökettuterrieri',
  'american water spaniel': 'amerikanvesispanieli',
  'anatolian shepherd dog': 'kangalinkoira',
  'anglo-francais de petite venerie':
    'englantilais-ranskalainen pienriistan ajokoira',
  'anglo-russian hound': 'englantilais-venäläinen ajokoira',
  'appenzell cattle dog': 'appenzellinpaimenkoira',
  apricot: 'aprikoosi',
  'arabian greyhound': 'sloughi',
  'ardennes cattle dog': 'ardennienkarjakoira',
  ariegeois: 'ariegenajokoira',
  'artesian-norman basset': 'basset artesien normand',
  assistant: 'Avustajakoira',
  'australian cattle dog': 'australiankarjakoira',
  'australian kelpie': 'australiankelpie',
  'australian shepherd': 'australianpaimenkoira',
  'australian silky terrier': 'silkkiterrieri',
  'australian stumpy tail cattle dog': 'australian stumpy tail cattle dog',
  'australian terrier': 'australianterrieri',
  'austrian black and tan hound': 'itävallanajokoira',
  'austrian pinscer': 'itävallanpinseri',
  availability: 'Saatavuus',
  availableForBreeding: 'Tarjolla jalostukseen',
  azawakh: 'azawakh',
  'azores cattle dog': 'sao miguelinfila',
  basenji: 'basenji',
  'basset fauve de bretagne': 'bretagnenbassetti',
  'basset hound': 'basset hound',
  'baverian mountain scenthound': 'baijerinvuoristovihikoira',
  beagle: 'beagle',
  'beagle-harrier': 'beagle harrier',
  'bearded collie': 'partacollie',
  beauceron: 'beaucenpaimenkoira / beuaceron',
  'bedlington terrier': 'bedlingtoninterrieri',
  beige: 'beige',
  'belgian griffon': 'griffon belge',
  'belgian shepherd dog, groenendael': 'belgianpaimenkoira, groenendael',
  'belgian shepherd dog, laekenois': 'belgianpaimenkoira, laekenois',
  'belgian shepherd dog, malinois': 'belgianpaimenkoira, malinois',
  'belgian shepherd dog, tervueren': 'belgianpaimenkoira, tervueren',
  belton: 'belton',
  'bergamasco shepherd dog': 'bergamasco',
  'berger picard': 'picardienpaimenkoira',
  'bernese mountain dog': 'berninpaimenkoira',
  'bichon frize': 'bichon frise',
  'biewer-terrieri': 'biewerterrieri',
  billy: 'billy',
  biscuit: 'biscuit - keksi',
  black: 'musta',
  'black & tan': 'black & tan',
  'black & tan coon hound': 'pesukarhukoira',
  'black fringes': 'black fringes',
  'black terrier': 'venäjänmustaterrieri',
  blackbrindle: 'blackbrindle',
  blaffa: 'blaffa',
  blaireau: 'blaireau',
  blenheim: 'blenheim',
  'blood hound': 'vihikoira',
  blue: 'sininen',
  'blue cascony basset': 'gascognenbassetti',
  'blue merle': 'blue merle',
  'blue picardy spaniel': 'sininenpicardienspanieli',
  'bluetick coonhound': 'sininenpesukarhukoira',
  'bohemian wire-haired pointing griffon': 'tsekinseisoja',
  bolognese: 'bolognese',
  'border collie': 'bordercollie',
  'border terrier': 'borderterrieri',
  borzoi: 'venäjänvinttikoira',
  'bosanski ostodlaki gonic barak': 'karkeakarvainen bosnian ajokoira',
  'boston terrier': 'bostoninterrieri',
  'bourbonnais pointing dog': "bourbonnais'nseisoja",
  'braque de l`ariege': 'braque de láriege',
  'braque d´auvergne': 'auvergnenseisoja',
  'braque francais, type gascogne d´auvergne':
    'ranskanseisoja, gascognelaistyyppi',
  'brazilian terrier': 'brasilianterrieri',
  breed: 'Rotukoirat',
  breeding: 'Jalostukseen',
  bretony: 'bretoni',
  briard: 'brienpaimenkoira / briardi',
  brindle: 'brindle - tiikerijuovainen',
  'briquet griffon vendee': 'briquet griffon vendeen',
  broholmer: 'broholminkoira',
  bronze: 'pronssi',
  'brown ': 'ruskea',
  'brussels griffon': 'griffon bruxellois',
  'bull terrier': 'bullterrieri',
  bulldog: 'englanninbulldoggi',
  bullmastiff: 'bullmastiffi',
  'burgos pointing dog': 'burgosinseisoja',
  'cairn terrier': 'cairnterrieri',
  'canaan dog': 'kaanaankoira',
  'canadian eskimo dog': 'kanadaneskimokoira',
  caniCross: 'Canicross',
  'cao de castro laboreiro': 'castro laboreironkoira',
  cat: 'Kissa',
  'catalan sheepdog, smooth': 'katalonianpaimenkoira, sileäkarvainen',
  'catalan sheepdog, wirehaired': 'katalonianpaimenkoira, karkeakarvainen',
  'caucasian shepherd dog': 'kaukasiankoira',
  'cavalier king charles spaniel': 'cavalier kingcharlesinspanieli',
  'central asian shepherd dog': 'keskiaasiankoira',
  'cesky terrier': 'ceskyterrieri',
  champagne: 'shampanja',
  charcoal: 'charcoal - antrasiitti',
  'chesapeake bay retriever': 'chesapeakelahdennoutaja',
  chestnut: 'chestnut - kastanjanruskea',
  'chien d´artois': 'artoisinajokoira',
  'chihuahua, long coat': 'chihuahua, pitkäkarvainen',
  'chihuahua, smooth coat': 'chihuahua, lyhytkarvainen',
  'chinese crested dog': 'kiinanharjakoira',
  identificationNumber: 'Mikrosirunumero/tunnistusmerkintä',
  chocolate: 'suklaa',
  'chodsky pes': 'tsekinpaimenkoira',
  'chow chow': 'chow chow',
  'ciobanesc romanesc de bucovina ': 'bukovinankoira',
  'cirneco dell`etna': 'etnan koira',
  'clumber spaniel': 'clumberinspanieli',
  'coarse-haired istrian hound': 'karkeakarvainen istrianajokoira',
  coat: 'Karvanlaatu',
  'cocker spaniel': 'cockerspanieli',
  'cockerpoo (cocker-poodle), large': 'cockerpoo (cockeri-villakoira), iso',
  'cockerpoo (cocker-poodle), medium':
    'cockerpoo (cockeri-villakoira), keskikokoinen',
  'cockerpoo (cocker-poodle), small': 'cockerpoo (cockeri-villakoira), pieni',
  'collie, rough': 'collie, pitkäkarvainen',
  'collie, smooth': 'collie, sileäkarvainen',
  color: 'Väri',
  copper: 'kupari',
  'cor do monte': 'cor do monte',
  'coton de tulear': 'coton de tulear',
  cream: 'cream - kermanvärinen',
  'cream markings': 'kermanvärisin merkein',
  'croatian sheepdog': 'kroatianpaimenkoira',
  'curly coated retriever': 'kiharakarvainennoutaja',
  curlyCoated: 'Kiharakarvainen',
  'czeslovakian wolfdog': 'ceskoslovensky vlciak',
  'dachshund miniature, long-haired': 'kääpiömäyräkoira, pitkäkarvainen',
  'dachshund miniature, smooth-haired': 'kääpiömäyräkoira, lyhytkarvainen',
  'dachshund miniature, wire-haired': 'kääpiömäyräkoira, karkeakarvainen',
  'dachshund, standard, long-haired': 'mäyräkoira, pitkäkarvainen',
  'dachshund, standard, smooth-haired': 'mäyräkoira, lyhytkarvainen',
  'dachshund, standard, wire-haired': 'mäyräkoira, karkeakarvainen',
  dalmatian: 'dalmatiankoira',
  dam: 'Emä',
  dance: 'Koiratanssi',
  'dandie dinmont terrier': 'dandiedinmontinterrieri',
  'danish spitz': 'tanskanpystykorva',
  'danish-swedish farm dog': 'tanskalais-ruotsalainen pihakoira',
  'dapple tan': 'dapple tan',
  dateOfBirth: 'Syntymäaika',
  deerhound: 'skotlanninhirvikoira',
  description: 'Kuvaus',
  doberman: 'dobermanni',
  dobo: 'Dobo',
  dog: 'Koira',
  'dogo argentino': 'dogo argentino',
  'dogo canario': 'kanariandoggi',
  'dogue de bordeaux': 'bordeauxindoggi',
  domino: 'domino - riistanvärinen',
  'double dapple': 'double dapple',
  'drentse partridge dog': 'hollanninpeltopyykoira',
  'dutch shepherd dog, long-haired': 'hollanninpaimenkoira, pitkäkarvainen',
  'dutch shepherd dog, rough-haired': 'hollanninpaimenkoira, karkeakarvainen',
  'dutch shepherd dog, short-haired': 'hollanninpaimenkoira, lyhytkarvainen',
  'dutch smoushond': 'hollanninrottakoira',
  'east siperian laika': 'itäsiperianlaika',
  'east-european shepherd ': 'itäeuroopanpaimenkoira',
  'english foxhound': 'englanninkettukoira',
  'english setter': 'englanninsetteri',
  'english springer spaniel': 'englanninspringerspanieli',
  'english toy terrier': 'englanninkääpiöterrieri',
  'entlebuch cattle dog': 'entlebuchinpaimenkoira',
  'epagneul de saint-usage': 'saint-usugenspanieli',
  'estonian hound': 'eestinajokoira',
  eurasier: 'eurasier',
  fako: 'fako',
  'fako mazkos': 'fako mazkos',
  fauve: 'fauve',
  fawn: 'fawn - vaaleanruskea',
  female: 'Naaras',
  other: 'muu',
  'field spaniel': 'fieldspanieli',
  'fila brasileiro': 'fila brasileiro',
  'Dog breeds': 'Rotukoirat',
  mixed: 'Monirotuiset',
  'finnish hound': 'suomenajokoira',
  'finnish lapponian dog': 'suomenlapinkoira',
  'finnish spitz': 'suomenpystykorva',
  'flanders cattle dog': 'bouvier',
  'flat coated retriever': 'sileäkarvainennoutaja',
  forSales: 'Myytävänä',
  'fox terrier, smooth-haired': 'kettuterrieri, sileäkarvainen',
  'fox terrier, wire-haired': 'kettuterrieri, karkeakarvainen',
  'francais blanc et noir': 'francais blanc & noir',
  'francais blanc et orange': 'francais blanc & orange',
  'francais tricolore': 'francais tricolore',
  'french bulldog': 'ranskanbulldoggi',
  'french pointing dog -pyrenean type': 'ranskanseisoja, pyreneläistyyppi',
  'french spaniel': 'ranskanspanieli',
  'french water dog': 'barbet',
  'french wire-haired korthals pointing griffon': 'korthalsingriffoni',
  fringes: 'fringes',
  'frisian pointing dog': 'stabijhoun',
  'frisian water dog': 'friisinvesikoira',
  'full markings': 'täysin merkein',
  gender: 'Sukupuoli',
  'german boxer': 'bokseri',
  'german hound': 'saksan ajokoira',
  'german hunting terrier': 'saksanmetsästysterrieri',
  'german pinscher': 'pinseri',
  'german rough-haired pointer': 'saksanseisoja, pitkäkarvainen',
  'german shepherd dog': 'saksanpaimenkoira',
  'german shepherd, long and harsh outer coat':
    'saksanpaimenkoira, pitkäkarvainen',
  'german short-haired pointer': 'saksanseisoja, lyhytkarvainen',
  'german spaniel': 'viiriäiskoira',
  'german wire-haired pointer': 'saksanseisoja, karkeakarvainen',
  'giant schnauzer, black': 'suursnautseri, musta',
  'giant schnauzer, pepper & salt': 'suursnautseri, pippuri ja suola',
  ginger: 'ginger - punaruskea',
  'glen of imal terrier': 'glen of Imaalinterrieri',
  golden: 'golden -kulta',
  'golden retriever': 'kultainennoutaja',
  'gonczy polski': 'puolanjahtikoira',
  'gordon setter': 'gordoninsetteri',
  'gotland hound ': 'gotlanninajokoira',
  'grand anglo-francais blanc et noir': 'grand anglo-francais blanc et noir',
  'grand anglo-francais blanc et orange':
    'grand anglo-francais blanc et orange',
  'grand anglo-francais tricolore': 'grand anglo-francais tricolore',
  'grand basset griffon vendéen': 'grand basset griffon vendéen',
  'grand gascon saintongeois': 'iso cascogne-saintongenajokoira',
  'grand griffon vendeen': 'grand griffon vendeen',
  'great dane, black & harlequin': 'tanskandoggi, musta ja harlekiini',
  'great dane, blue': 'tanskandoggi, sininen',
  'great dane, yellow, & tiger': 'tanskandoggi, keltainen ja tiikerijuovainen',
  'greater swiss mountain dog': 'isosveitsinpaimenkoira',
  'greenland dog': 'grönlanninkoira',
  grey: 'harmaa',
  'grey mask': 'grey mask - harmaa maski',
  greyhound: 'englanninvinttikoira',
  'griffon bleu de gasgogne': 'griffon bleu de gasgogne',
  'griffon fauve de bretagne': 'griffon fauve de bretagne',
  'griffon nivernais': 'griffon nivernais',
  grizzle: 'grizzle - riistanvärinen',
  'gross spitz (giand spitz), black and brown':
    'saksanpystykorva, grosspitz, musta ja ruskea',
  'gross spitz (giand spitz), white': 'saksanpystykorva, grosspitz, valkoinen',
  guidance: 'Opastus',
  hairless: 'karvaton',
  'halden hound': 'haldeninajokoira',
  'hamilton hound': 'hamiltoninajokoira',
  'hamvas -bluegrey': 'hamvas -siniharmaa',
  'hanoverian scenthound': 'hannoverinvihikoira',
  harlequin: 'harlekiini',
  harrier: 'harrier',
  havanese: 'havannankoira',
  height: 'Korkeus',
  'hellenic hound': 'kreikanajokoira',
  hobbies: 'Harrastukset',
  hokkaido: 'hokkaidonkoira',
  horse: 'Hevonen (tuki tulossa pian)',
  hovawart: 'hovawart',
  'hungarian greyhound': 'unkarinvinttikoira',
  'hungarian short-haired pointing dog': 'unkarinvisla, lyhytkarvainen',
  'hungarian wire-haired pointing dog': 'unkarinvisla, karkeakarvainen',
  hunting: 'Metsästys',
  'hygen hound': 'hygeninajokoira',
  'hällefors dog': 'hälleforsinkoira',
  'ibizan hound, smooth-haired': 'ibizanpodenco, lyhytkarvainen',
  'ibizan hound, wire-haired': 'ibizanpodenco, karkeakarvainen',
  'icelandic sheepdog': 'islanninlammaskoira',
  countryOfBirth: 'Syntymämaa',
  'irish red & white setter': 'punavalkoinen irlanninsetteri',
  'irish red setter': 'punainen irlanninsetteri',
  'irish terrier': 'irlanninterrieri',
  'irish water spaniel': 'irlanninvesispanieli',
  'irish wolfhound': 'irlanninsusikoira',
  isabella: 'isabella ',
  'istrian course-haired hound': 'istrianajokoira, karkeakarvainen',
  'istrian short-haired hound': 'istrianajokoira, lyhytkarvainen',
  'italian corso dog': 'cane corso',
  'italian greyhound': 'italianvinttikoira',
  'italian hound, course-haired': 'italianajokoira, karkeakarvainen',
  'italian hound, short haired': 'italianajokoira, lyhytkarvainen',
  'italian pointing dog': 'italianseisoja',
  'italian wire-haired pointing dog': 'spinone',
  'jack russell terrier': 'jackrussellinterrieri',
  'japanese chin': 'japanese chin',
  'japanese spitz': 'japaninpystykorva',
  'japanese terrier': 'japaninterrieri',
  'jet black': 'jet black -karhunmusta',
  kai: 'kainkoira',
  karamis: 'karamis',
  'karelian bear dog': 'karjalankarhukoira',
  'karst shepherd dog': 'karstinpaimenkoira',
  kennelClubRegistered: 'Rekisteröity kennelliitossa',
  registeredKennelName: 'Rekisteröity kennelnimi',
  'kerry blue terrier': 'kerrynterrieri',
  kimo: 'kimo',
  'king charles spaniel': 'kingcharlesinspanieli',
  'kintamani-bali dog': 'kintamani-balinkoira',
  kishu: 'kishunkoira',
  'kleinspitz (miniature spitz), black and brown':
    'saksanpystykorva, kleinspitz, musta ja ruskea',
  'kleinspitz (miniature spitz), other colours':
    'saksanpystykorva, kleinpitz, muut värit',
  'kleinspitz (miniature spitz), white':
    'saksanpystykorva, kleinpitz, valkoinen',
  komondor: 'komondor',
  kooikerhondje: 'kooikerhondje',
  kopo: 'kopo',
  'korea jindo dog': 'koreanjindonkoira',
  kromfohrländer: 'kromfohrländer',
  kuvasz: 'kuvasz',
  'labradoodle (labrador-poodle), large':
    'labradoodle (labradori-villakoira), iso',
  'labradoodle (labrador-poodle), medium':
    'labradoodle (labradori-villakoira), keskikoinen',
  'labradoodle (labrador-poodle), small':
    'labradoodle (labradori-villakoira), pieni',
  labrador: 'labradorinnoutaja',
  'lagotto romagnolo': 'lagotto romagnolo',
  'lakeland terrier': 'lakelandinterrieri',
  'lancashire heeler': 'lancashirenkarjakoira',
  'landseere continental-european': 'landseer',
  'lapponian herder': 'lapinporokoira',
  'large munsterlander': 'isomünsterinseisoja',
  lemon: 'lemon - keltainen',
  leonberger: 'leonberginkoira',
  'lhasa apso': 'lhasa apso',
  lilac: 'lila',
  lion: 'leijonankeltainen',
  'little lion dog': 'löwchen',
  liver: 'maksanruskea',
  lizard: 'Lisko (tuki tulossa pian)',
  longHair: 'Pitkäkarvainen',
  lookingForHome: 'Etsii kotia',
  mahongany: 'mahonki',
  'majorca mastiff': 'mallorcandoggi',
  'majorca shepherd dog': 'mallorcanpaimenkoira',
  male: 'Uros',
  maltese: 'maltankoira',
  'maltipoo (poodle-maltese)': 'maltipoo (maltankoira-villakoira)',
  'manchester terrier': 'manchesterinterrieri',
  'maremmano-abruzzes sheepdog': 'maremmano-abruzzese',
  markiesje: 'markiesje',
  marking: 'Merkit',
  mastiff: 'mastiffi',
  'maszkos fako': 'maszkos fako',
  merle: 'merle',
  message: 'Viesti',
  'mexican hairless, intermediate': 'meksikonkarvatonkoira, keskikokoinen',
  'mexican hairless, miniature': 'meksikonkarvatonkoira, pieni',
  'mexican hairless, stantard': 'meksikonkarvatonkoira, iso',
  'miniature american shepherd': 'pieni amerikanpaimenkoira',
  'miniature bull terrier': 'kääpiöbullterrieri',
  'miniature pinscher': 'kääpiöpinseri',
  'miniature schnauzer, black': 'kääpiösnautseri, musta',
  'miniature schnauzer, black & silver': 'kääpiösnautseri, musta-hopea',
  'miniature schnauzer, pepper & salt': 'kääpiösnautseri, pippuri & suola',
  'miniature schnauzer, white': 'kääpiösnautseri, valkoinen',
  'mittelspitz (medium size spitz), black abd brown':
    'saksanpystykorva, mittelspitz, musta ja ruskea',
  'mittelspitz (medium size spitz), other colours':
    'saksanpystykorva, mittelpitz, muut värit',
  'mittelspitz (medium size spitz), white':
    'saksanpystykorva, mittelpitz, valkoinen',
  mix: 'Mix',
  'mixed-breed, large': 'monirotuinen, iso',
  'mixed-breed, medium': 'monirotuinen, keskikokoinen',
  'mixed-breed, small': 'monirotuinen, pieni',
  mondioring: 'Mondioring',
  'montenegrian mountain hound': 'montenegronajokoira',
  mottled: 'läikikäs',
  mudi: 'mudi',
  multicolor: 'multicolor - monivärinen',
  mushing: 'Valjakkourheilu',
  name: 'Nimi',
  'napolin mastiff': 'napolinmastiffi',
  neutered: 'Kastroitu/steriloitu',
  newfoundland: 'newfoundlandinkoira',
  No: 'Ei',
  'norfolk terrier': 'norfolkinterrieri',
  norrbottenspitz: 'pohjanpystykorva',
  'norwegian buhund': 'buhund',
  'norwegian elkhund black': 'musta norjanhirvikoira',
  'norwegian elkhund grey': 'harmaa norjanhirvikoira',
  'norwegian hound': 'dunkerinajokoira',
  'norwegian lund hund': 'lunnikoira',
  'norwich terrier': 'norwichinterrieri',
  'nova scotia duck tolling retriever': 'novascotiannoutaja',
  obedience: 'Tottelevaisuus',
  'old danish pointer': 'vanhatanskankanakoira',
  'old english sheepdog': 'vanhaenglanninlammaskoira',
  onBreedingTerms: 'Sijoitukseen',
  orange: 'orange - oranssi',
  otterhound: 'saukkokoira',
  pale: 'pale - vaalea',
  papillon: 'papillon',
  'parson russell terrier': 'parsonrussellinterrieri',
  particolor: 'particolor',
  patched: 'kulta',
  'peekapoo (pegingese-poodle)': 'peekapoo (pegingese-villakoira)',
  pegingese: 'kiinanpalatsikoira, pekingeesi',
  'pepper & salt': 'pippuri & suola',
  'peruvian hairless dog, large': 'perunkarvatonkoira, iso',
  'peruvian hairless dog, medium': 'perunkarvatonkoira, keskikokoinen',
  'peruvian hairless dog, miniature': 'perunkarvatonkoira, pieni',
  'petit basset griffon vendéen': 'petit basset griffon vendéen',
  'petit bleu de gascogne ': 'pieni gascognenajokoira',
  'petit gascon saintongeois ': 'pieni cascogne-saintongenajokoira',
  phalène: 'phalene',
  'pharaoh hound': 'faaraokoira',
  'picardy spaniel': 'picardienspanieli',
  piebald: 'laikullinen',
  pied: 'kupari',
  pinto: 'pinto',
  plattenhund: 'lemon',
  plott: 'plottinajokoira',
  'podenco canario': 'kanarianpodenco',
  'podengo portuguese, smooth-haired large':
    'portugalinpodengo, sileäkarvainen iso',
  'podengo portuguese, smooth-haired medium':
    'portugalinpodengo, sileäkarvainen, keskikokoinen',
  'podengo portuguese, wire-haired large':
    'portugalinpodengo, karkeakarvainen iso',
  pointer: 'pointteri',
  poitevin: 'poitevin',
  'polish greyhound': 'puolanvinttikoira',
  'polish hound': 'puolanajokoira',
  'polish lowland sheepdog': 'polski owczarek nizinny',
  pomeranian: 'saksanpystykorva, pomeranian',
  'poodle medium, black, brown & white':
    'villakoira keskikokoinen, musta, ruskea, valkoinen',
  'poodle medium, grey, apricot & red':
    'villakoira keskikokoinen, harmaa, aprikoosi, punainen',
  'poodle miniature, black, brown & white':
    'villakoira kääpiö, musta, ruskea, valkoinen',
  'poodle miniature, grey, apricot & red':
    'villakoira kääpiö, harmaa, aprikoosi, punainen',
  'poodle standard, black, brown & white':
    'villakoira iso, musta, ruskea, valkoinen',
  'poodle standard, grey, apricot & red':
    'villakoira iso, harmaa, aprikoosi, punainen',
  porcelaine: 'porcelaine',
  'portuguese pointing dog': 'portugalinseisoja',
  'portuguese sheepdog': 'sierra de airesinpaimenkoira',
  'portuguese warren dog, medium, wire-haired':
    'portugalinpodengo, karkeakarvainen keskikokoinen',
  'portuguese warren dog, miniature, smooth-haired':
    'portugalinpodengo, sileäkarvainen, pieni',
  'portuguese warren dog, miniature, wire-haired':
    'portugalinpodengo, karkeakarvainen, pieni',
  'portuguese water dog': 'portugalinvesikoira',
  'posavski gonic': 'posavinanajokoira',
  post: 'Jälki',
  powderPuff: 'Puuterihuisku',
  'prague ratter': 'prahanrottakoira',
  price: 'Hinta',
  protection: 'Suojelu',
  pudelpointer: 'villakoirapointteri',
  pug: 'mopsi',
  'puli, other colors': 'puli, muut värit',
  'puli, white': 'puli, valkoinen',
  pumi: 'pumi',
  pure: 'liila',
  'pyrenean mastiff': 'pyreneittenmastiffi',
  'pyrenean mountain dog': 'pyreneittenkoira',
  'pyrenean sheepdog, longhaired': 'pyreneittenpaimenkoira, pitkäkarvainen',
  'pyrenean sheepdog, smooth-faced': 'pyreneittenpaimenkoira, lyhytkarvainen',
  rabbit: 'Kani (tuki tulossa pian)',
  'rabbit dachshund, long-haired': 'kaniinimäyräkoira, pitkäkarvainen',
  'rabbit dachshund, smooth-haired': 'kaniinimäyräkoira, lyhytkarvainen',
  'rabbit dachshund, wire-haired': 'kaniinimäyräkoira, karkeakarvainen',
  rallyObedience: 'Rally-toko',
  'rat terrier': 'amerikanrottaterrieri',
  'ratonero bodeguero andaluz': 'andalusianrottakoira',
  readyToLeave: 'Luovutusikäinen',
  red: 'punainen',
  'red white': 'mahonki',
  'red, born pink': 'punainen, syntynyt pinkkinä',
  reddish: 'punertava',
  registrationCountry: 'Asuinmaa',
  registraionLink: 'Rekisteröintilinkki',
  registerNumber: 'Rekisterinumero',
  registrationOrganization: 'Rekisteröintiorganisaatio',
  rescure: 'Pelastuskoiratoiminta',
  'rhodesian ridgeback': 'rhodesiankoira',
  roan: 'päistärikkö',
  rodent: 'Jyrsijä (tuki tulossa pian)',
  'romanian carpathian shepherd dog': 'karpaattienkoira',
  'romanian mioritic shepherd dog': 'romanian mioritic shepherd dog',
  rotweiler: 'rottweiler',
  ruby: 'ruby',
  'russian hound': 'venäjänajokoira',
  'russian spaniel': 'venäjänspanieli',
  'russian toy, long-haired': 'venäjäntoy, pitkäkarvainen',
  'russian toy, short-haired': 'venäjäntoy, lyhytkarvainen',
  'russian-european laika': 'venäläis-eurooppalainen laika',
  'russkaja tsvetnaja bolonka': 'venäjänbolonka',
  rust: 'rust - ruskea',
  sable: 'sable - soopeli',
  saddleback: 'saddleback - satulakuvioin',
  'saint bernard, long haired': 'bernhardinkoira, pitkäkarvainen',
  'saint bernard, short haired': 'bernhardinkoira, lyhytkarvainen',
  'saint germain pointer': 'saint-germaininseisoja',
  saluki: 'saluki',
  samoyed: 'samojedinkoira',
  sand: 'sand - hiekka',
  'sarloos wolfdog': 'saarlooswolfhond',
  schapendoes: 'schapendoes',
  'schiller hound': 'schillerinajokoira',
  schipperke: 'schipperke',
  'schnoodle (schnauzer -poodle), large':
    'schnoodle (snautseri -villakoira), iso',
  'schnoodle (schnauzer -poodle), medium':
    'schnoodle (snautseri -villakoira), keskikokoinen',
  'schnoodle (schnauzer -poodle), small':
    'schnoodle (snautseri -villakoira), pieni',
  'scottish terrier': 'skotlanninterrieri',
  seal: 'seal',
  'sealyham terrier': 'sealyhaminterrieri',
  sedge: 'sedge',
  seeker: 'Etsijäkoirat',
  'segugio maremmano': 'maremman ajokoira',
  semenAvailable: 'Sperman lähetys mahdollisuus',
  senap: 'sinappi',
  'serbian hound': 'serbian ajokoira',
  'serbian tricolor hound': 'kolmivärinen serbianajokoira',
  'serra de estrela mountain dog, long coat':
    'estrelanvuoristokoira, pitkäkarvainen',
  'serra de estrela mountain dog, smooth coat':
    'estrelanvuoristokoira, lyhytkarvainen',
  sesame: 'seesami',
  'shar pei': 'shar pei',
  'shetland sheepdog': 'shetlanninlammaskoira',
  shiba: 'shiba',
  'shih tzu': 'shih tzu',
  shikoku: 'shikokunkoira',
  shortHair: 'Lyhytkarvainen',
  shows: 'Koiranäyttelyt',
  'siberian husky': 'siperianhusky',
  silver: 'silver - hopea',
  sire: 'Isä',
  'skye terrier': 'skyenterrieri',
  'slate merle': 'slate merle',
  'slovakian chuvach': 'slovakiancuvac',
  'slovakian hound': 'slovakianajokoira',
  'slovakian wire-haired pointing dog': 'slovakianseisoja, karkeakarvainen',
  'small brabant griffon': 'petit brabancon',
  'small munsterlander': 'pienimünsterinseisoja',
  'small swiss hound, small bernese hound': 'pieniberninajokoira',
  'small swiss hound, small jura hound': 'pienijuranajokoira',
  'small swiss hounds, small schwyz hound': 'pienischwynajokoira',
  'small swiss hounds,small luzerne hound': 'pieniluznerninajokoira',
  smoothCoat: 'Sileäkarvainen',
  'småland hound': 'smoolanninajokoira',
  'soft coated wheaten terrier': 'vehnäterrieri',
  solid: 'solid - yksivärinen',
  'south russian shepherd dog': 'etelävenäjänkoira',
  'spaniel de pont-audemer': 'pont-audemerinspanieli',
  'spanish greyhound': 'espanjanvinttikoira',
  'spanish hound': 'sabueso espanol',
  'spanish mastiff': 'espanjanmastiffi',
  'spanish waterdog': 'espanjanvesikoira',
  species: 'Laji',
  speckled: 'speckled - täplikäs',
  spotted: 'spotted - pilkullinen',
  'staffordshire bull terrier': 'staffordshirenbullterrieri',
  'standard schnauzer, black': 'snautseri, musta',
  'standard schnauzer, pepper & salt': 'snautseri, pippuri ja suola',
  steelgrey: 'teräksenharmaa',
  'steirische rauhhaarbracke': 'karkeakarvainen steierinaojokoira',
  'sussex spaniel': 'sussexinspanieli',
  'swedish dachsbracke': 'dreeveri',
  'swedish elkhound, white': 'valkoinen ruotsinhirvikoira',
  'swedish elkhund': 'jämtlanninpystykorva',
  'swedish lapphund': 'ruotsinlapinkoira',
  'swedish vallhund': 'länsigöötanmaanpystykorva',
  'swiss hound - schwyrz hound': 'sveitsinajokoira, schwyzinajokoira',
  'swiss hound -bernese hound': 'sveitsinajokoira, berninajokoira',
  'swiss hound -jura hound': 'sveitsinajokoira, juranajokoira',
  'swiss hound -luzerne hound': 'sveitsinajokoira, luzerninajokoira',
  'taiwan dog': 'taiwaninkoira',
  tan: 'tan - ruosteenpunainen',
  'tan dapple': 'tan merkein',
  'tan sable': 'tan dapple',
  'tan with white markings': 'tan valkoisin merkein',
  'tan/golden': 'tan/golden',
  tanpiepald: 'tanpiepald',
  'tatra shepherd dog': 'owczarek podhalanski',
  'tenderfield terrier': 'tenterfieldinterrieri',
  'thai bangkaew dog': 'thaimaanpystykorva',
  'thai ridgeback': 'thai ridgeback',
  'tibetan mastiff': 'tiibetinmastiffi',
  'tibetan spaniel': 'tiibetinspanieli',
  'tibetan terrier': 'tiibetinterrieri',
  'tiroler bracke': 'tirolinajokoira',
  title: 'Titteli',
  tobacco: 'tupakka',
  tornjak: 'tornjak',
  tosa: 'tosa',
  'treeing walker coonhound ': 'walkerinpesukarhukoira',
  tricolor: 'tricolor -kolmivärinen',
  'trout colored roan': 'trout colored roan',
  twocolor: 'twocolor - kaksivärinen ',
  unavailable: 'Ei saatavilla',
  'uruguayan cimarron': 'uruguayncimarron',
  'volpino italiano': 'volpino italiano',
  waterRescure: 'Vesipelastus',
  weight: 'Paino',
  'weimaraner, long-haired': 'weimarinseisoja, pitkäkarvainen',
  'weimaraner, short-haired': 'weimarinseisoja, lyhytkarvainen',
  'welsh corgi cardigan': 'welsh corgi cardigan',
  'welsh corgi pembroke': 'welsh corgi pembroke',
  'welsh springer spaniel': 'walesinspringerspanieli',
  'welsh terrier': 'walesinterrieri',
  'west highland white terrier': 'valkoinen länsiylämaanterrieri',
  'west siberian laika': 'länsisiperianlaika',
  'westfälische dachsbracke ': 'westfälische dachsbracke ',
  wheat: 'vehnä',
  wheaten: 'wheaten - vehnänvärinen ',
  whippet: 'whippet',
  white: 'white - valkoinen',
  'white and tan': 'valkoisin ja tan merkein',
  'white particolor': 'white particolor',
  'white swiss shepherd dog': 'valkoinenpaimenkoira',
  'white with black spots': 'valkoinen mustin merkein ',
  'white with brown spots': 'valkoinen ruskein merkein ',
  'white with patches': 'valkoinen pohjaväri laikuin',
  'white without patches': 'valkoinen pohjaväri ilman laikkuja',
  'wild board': 'wild board',
  'wild patterned': 'wild patterned - riistanvärinen',
  wireHaired: 'Karkeakarvainen',
  'with badger markings': 'badger - mäyränvärisin merkein',
  'with beige markings': 'beige merkein',
  'with biscuit markings': 'with biscuit markings',
  'with black and brown markings': 'mustin ja ruskein merkein',
  'with black and tan markings': 'mustin ja tan merkein',
  'with black and white markings': 'mustin ja valkoisin merkein',
  'with black cover hair': 'mustin peitinkarvoin',
  'with black mandle': 'musta manteli',
  'with black markings': 'mustin merkein',
  'with black mask': 'musta maski',
  'with black nose': 'musta kirsu',
  'with black patch': 'mustin täplin',
  'with black spots': 'mustin merkein',
  'with blackbrindle markings': 'mustabrindle merkein',
  'with blue and tan markings': 'sinisin ja tan merkein',
  'with blue markings': 'sinisin merkein',
  'with brindle markings': 'brindle merkein',
  'with brindle patch': 'brindle laikuin',
  'with brown and black markings': 'ruskein ja mustin merkein',
  'with brown and white markings': 'ruskein ja valkoisin merkein',
  'with brown blaze': 'ruskea blaze',
  'with brown markings': 'ruskein merkein ',
  'with brown mask': 'ruskea maski',
  'with brown nose': 'ruskea kirsu',
  'with brown patches': 'ruskein laikuin',
  'with brown/grey markings': 'ruskeaharmaa merkein',
  'with cream markings': 'kermanvärisin merkein ',
  'with dark markings': 'tummin merkein',
  'with dark mask': 'tumma maski',
  'with fawn and brown markings': 'vaalein ja ruskein merkin',
  'with fawn markings': 'vaalein merkein',
  'with gold markings': 'kullankeltaisin merkein',
  'with grey markings': 'harmain merkein',
  'with greyfawn markings': 'harmaafawn merkein',
  'with grizzle markings': 'riistanvärisin merkein',
  'with lemon markings': 'sitruunankeltaisin merkein',
  'with liver nose': 'maksanruskea nenä',
  'with liver spots': 'maksanruskein merkein',
  'with mask': 'maskilla',
  'with orange markings': 'oranssi merkein',
  'with pale markings': 'vaalein merkein',
  'with red markings': 'punaisin merkein',
  'with redbrown and black markings': 'punaruskein ja mustin merkein',
  'with redbrown markings': 'punaruskein merkein',
  'with sable markings': 'soopeli merkein',
  'with sand markings': 'hiekanvärisin merkein',
  'with tan markings': 'tan merkein',
  'with tricolor markings': 'tricolor - kolmivärisin merkein ',
  'with white': 'valkoisen kanssa ',
  'with white and brindle markings': 'valkoisin ja brindle merkein',
  'with white and tan markings': 'valkoisin ja tan merkein',
  'with white markings': 'valkoisin merkein',
  'with white markings roaned': 'valkoisin merkein, "roaned"',
  'with white markings ticked': 'valkoisin merkein, "ticked"',
  'with yellow markings': 'keltaisin merkein',
  'without white': 'ilman valkoista väriä',
  'woflspitz (keeshond)': 'keeshond',
  wolfgrey: 'wolfgrey - sudenharmaa',
  'yakutian laika': 'jakutianlaika',
  yellow: 'keltainen',
  Yes: 'Kyllä',
  'yogoslavian shepherd dog - sharplanina': 'sarplaninac',
  'yorkipoo (yorkshire terrier - toy poodle)':
    'yorkipoo (yorkshireterrieri - kääpiö villakoira)',
  'yorkshire terrier': 'yorkshirenterrieri',
  'Patern (Marking)': 'Kuvio (Merkit)',
  bicolor: 'bicolour',
  'burmese pointed': 'burmanaamio',
  'himalayan pointed': 'himalajanaamio',
  'blotched tabby': 'klassinen tabby',
  mitted: 'mitted',
  'unspecified tabby': 'määrittelemätön tabbykuvio',
  shatted: 'shaded',
  shell: 'shell',
  snowshoe: 'snowshoe',
  'ticked tabby': 'ticked tabby',
  'mackerel tabby': 'tiikeri',
  tipped: 'tipped',
  'tonkinese pointed': 'tonkineesinaamio',
  'siamese pointed': 'siamese pointed',
  'spotted tabby': 'täplikäs',
  'any other amount of white': 'valkoista määrittelemätön määrä',
  van: 'van',
  'other patern': 'muu kuvio',
  'amber tortie': 'kilpikonna amber',
  'apricot based on cream': 'apricot based on cream',
  'beige tortie': 'beigekilpikonna',
  'black tortie': 'musta kilpikonna',
  'blue tortie': 'sinikilpikonna',
  brown: 'ruskea',
  'brown tortie': 'ruskeakilpikonna',
  'caramel based on blue': 'karamelli, perustuu siniseen',
  'caramel based on creme': 'karamelli, perustuu cremeen',
  'caramel based on fawn/beige': 'karamelli, perustuu beigeen',
  'caramel based on lilac': 'karamelli, perustuu lilaan',
  'caramel, where the base colour is unknown':
    'karamelli, jonka perusväriä ei tiedetä',
  'caramel/apricot': 'karamelli/aprikoosi',
  'chokolate tortie': 'suklaakilpikonna',
  'cinamon/red': 'kaneli/punainen',
  cinnamon: 'kaneli',
  'cinnamon tortie': 'kanelikilpikonna',
  'cream based amber': 'cream based amber',
  'creme amber': 'creme amber',
  'fawn tortie': 'fawn kilpikonna',
  'golden silver': 'golden silver',
  grizzly: 'riistanvärinen',
  'light tortie amber': 'vaalea kilpikonna amber',
  'lilac tortie': 'lilakilpikonna',
  'non-regognized variety': 'hyväksymätön väri',
  'red (aby/som)': 'punainen (aby/som)',
  'red based on amber': 'puna amber',
  'red tortie (aby/som)': 'punakilpikonna (aby/som)',
  ruddy: 'pronssi',
  tawny: 'tawny',
  tortie: 'kilpikonna',
  'tortie silvertabby': 'kilpikonnahopeatabby',
  'other color': 'muu väri',
  'curly coated': 'kiharakarvainen',
  'long-coat': 'pitkäkarvainen',
  'powder puff': 'powder puff',
  'semi-long coat': 'puolipitkäkarvainen',
  'short-coat': 'sileäkarvainen',
  'smooth-coat': 'lyhytkarvainen',
  'wire-hair': 'karkeakarvainen',
  'Cat breeds (FIFe)': 'Kissarodut (FIFe)',
  'Cat breeds other': 'Muut kissarodut',
  abyssinian: 'abessiinialainen',
  'american curl, longhair': 'american curl, pitkäkarva',
  'american curl, shorthair': 'amerikan curl, lyhytkarva',
  balinese: 'balineesi',
  bengali: 'bengali',
  bombay: 'bombay',
  'british, longhair': 'brittiläinen, pitkäkarva',
  'british, shorthair': 'brittiläinen, lyhytkarva',
  burmese: 'burma',
  burmilla: 'burmilla',
  chartreux: 'kartusiaani',
  'cornish rex': 'cornish rex',
  cymric: 'cymric',
  'devon rex': 'devon rex',
  'don sphynx': 'don sphinx',
  'egyptian mau': 'egyptin mau',
  european: 'eurooppalainen',
  exotic: 'exotic',
  'german rex': 'german rex',
  'housecat, longhair': 'kotikissa, pitkäkarva',
  'housecat, shorthair': 'kotikissa, lyhytkarva',
  'japanin bobtail': 'japanin bobtail',
  'karelian bobtail, longhair': 'karelian bobtail, pitkäkarva',
  'karelian bobtail, shorthair': 'karelian bobtail, lyhytkarva',
  korat: 'korat',
  'kurilian bobtail, longhair': 'kurilian bobtail, pitkäkarva',
  'kurilian bobtail, shorthair': 'kurilian bobtail, lyhytkarva',
  'la perm, longhair': 'la perm, pitkäkarva',
  'la perm, shorthair': 'la perm, lyhytkarva',
  lykoi: 'lykoi',
  'maine coon': 'maine coon',
  manx: 'manx',
  'neva masquerade': 'neva masquerade',
  'norwegian forrest cat': 'norjalainen metsäkissa',
  ocicat: 'ocicat',
  'oriental longhair': 'itämainen lyhytkarva',
  'oriental shorthair': 'itämainen pitkäkarva',
  persian: 'persialainen',
  peterbald: 'peterbald',
  ragdoll: 'ragdoll',
  'russian blue': 'venäjän sininen',
  'sacred birman': 'pyhä birma',
  'selkirk rex, longthair': 'selkirk rex, pitkäkarva',
  'selkirk rex, shorthair': 'selkirk rex, lyhytkarva',
  siamese: 'siamilainen',
  siberian: 'siperian kissa',
  singapura: 'singapura',
  sokoke: 'sokoke',
  somali: 'somali',
  sphynx: 'sphinx',
  thai: 'thai',
  'turkish angora': 'turkkilainen angora',
  'turkish van': 'turkkilainen van',
  aegean: 'aegean',
  'american bobtail, longhair': 'american bobtail, pitkäkarva',
  'american bobtail, shorthair': 'american bobtail, lyhytkarva',
  'american wirehair': 'american, wirehair',
  'american, shorthair': 'american, shorthair',
  anatoli: 'anatoli',
  "aphrodite's giant, longhair": "aphrodite's giant, pitkäkarva",
  "aphrodite's giant, shorthair": "aphrodite's giant, lyhytkarva",
  'arabian mau': 'arabian mau',
  'asian, longhair': 'asian, pitkäkarva',
  'asian, semi-longhair': 'asian puolipitkäkarva',
  'asian, shorthair': 'asian, lyhytkarva',
  'australian bombay': 'australian bombay',
  'australian mist': 'australian mist',
  bambino: 'bambino',
  'bengal, longhair': 'bengali, pitkäkarva',
  'bengal, shorthair': 'bengali, lyhytkarva',
  birman: 'birma',
  'bohemian rex': 'bohemian rex',
  'brazilian, shorthair': 'brazilian, lyhytkarva',
  'burmese, american': 'burma, amerikkalainen',
  'burmese, european': 'burma, eurooppalainen',
  'burmilla, longhair': 'burmilla, pitkäkarva',
  'burmilla, shorthaired': 'burmilla, lyhytkarva',
  'california spangled': 'california spangled',
  'celtic, shorthair': 'celtic, lyhytkarva',
  ceylon: 'ceylon',
  'chantilly-tiffany': 'chantilly-tiffany',
  chausie: 'chausie',
  cheetoh: 'cheetoh',
  'chinchilla persian, longhair': 'chinchilla persialainen',
  'chinese li hua': 'chinese li hua',
  classicat: 'classicat',
  'colorpoint, shorthair': 'colorpoint, lyhytkarva',
  'cymric/manx, longhair': 'cymric, pitkäkarva',
  'deutsch, langhaar': 'deutsch, langhaar',
  'foreign white, longhair': 'foreign white, pitkäkarva',
  'foreign white, shorthair': 'foreign white, lyhytkarva',
  'havana brown': 'havana brown',
  'highlander, longhair': 'highlander, pitkäkarva',
  'highlander, shorthair': 'highlander, lyhytkarva',
  'himalayan colorpoint': 'colourpoint persialainen',
  'isle of man, longhair': 'isle of man, pikäkarva',
  'isle of man, shorthair': 'isle of man, lyhytkarva ',
  kanaani: 'kanaani',
  'khao manee': 'khao manee',
  'landrace cat, longhair': 'maatiaiskissa, pitkäkarva',
  'landrace cat, shorthair': 'maatiaiskissa, lyhytkarva',
  'laperm, longhair': 'laperm, pitkäkarva',
  'laperm, shorthair': 'laperm, lyhytkarva',
  mandalay: 'mandalay',
  'mekong bobtail': 'mekong bobtail',
  minskin: 'minskin',
  'minuet/napoleon, longhair': 'minuet/napoleon, pitkäkarva',
  'minuet/napoleon, shorthair': 'minuet/napoleon, lyhytkarva',
  'mixed breed': 'monirotuinen',
  'munchkin, longhair': 'munchkin, pitkäkarva',
  'munchkin, shorthair': 'munchkin, lyhytkarva',
  nebelung: 'nebelung',
  'ojos azules, longhair': 'ojos azules, pitkäkarva',
  'ojos azules, shorthair': 'ojos azules, lyhytkarva',
  'original, longhair/doll face persian': 'original longhair/doll face persian',
  'other breed': 'muu rotu',
  'pixiebob, longhair': 'pixiebob, pitkäkarva',
  'pixiebob, shorthair': 'pixiebob, pitkäkarva',
  ragamuffin: 'ragamuffin',
  savannah: 'savannah',
  'scottish fold, longhair': 'scottish fold , pitkäkarva',
  'scottish fold, shorthair': 'scottish fold, lyhytkarva',
  'scottish straight, longhair': 'scottish straight, pitkäkarva',
  'scottish straight, shorthair': 'scottish straight, lyhytkarva',
  serengeti: 'serengeti',
  'seychellois, longhair': 'seychellien, pitkäkarva',
  'seychellois, shorthair': 'seychellien, lyhytkarva',
  tiffanie: 'tiffany',
  'tonkinese, longhair': 'tonkineesi, pitkäkarva',
  'tonkinese, shorthair': 'tonkineesi, lyhytkarva',
  toyger: 'toyger',
  'ukrainian levkoy': 'ukrainian levkoy',
  'ural rex, longhair': 'ural rex, pitkäkarva',
  'ural rex, shorthair': 'ural rex, lyhytkarva',
  'yourk/yourk chocolate': 'yourk/yourk chocolate',
  'litter name': 'Pentueen nimi',
  'Kennel name': 'Kennelin nimi',
  'Registration country': 'Rekisteröintimaa',
  'Birthday / planned birthday': 'Syntymäpäivä / suunniteltu syntymäpäivä',
  birthday: 'Syntymäaika',
  'Select sex': 'Valitse sukupuoli',
  'YYYY-MM-DD': 'VVVV-KK-PP',
  'Additional information': 'Lisää tarkemmat tiedot',
  'Pet URL': 'Lisää Linkki',
  americanbulldog: 'amerikanbulldoggi',
  boerbel: 'boerboel',
  'continental bulldog': 'continental bulldog',
  'silky greyhound': 'silkkivinttikoira',
  Australianlabradoodle: 'Australianlabradoodle',
  inMemorial: 'In memorial',
  'In Memorial - a digital final resting place':
    'In Memorial - digitaalinen viimeinen leposija',
  'Registration countries': 'Rekisteröintimaat',
}

export default fi
