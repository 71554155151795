import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'semantic-ui-react'

const AcceptModal = ({
  onAccept,
  onCancel,
  title,
  accept,
  cancel,
  size = 'tiny',
  open = true,
}) => {
  const { t } = useTranslation()

  return (
    <Modal size={size} open={open} onClose={onCancel}>
      <Modal.Content> {title || t('common:Are you sure?')}</Modal.Content>
      <Modal.Actions>
        <Button basic color='white' onClick={onCancel}>
          {cancel || t('common:Cancel')}
        </Button>
        <Button primary onClick={onAccept}>
          {accept || t('common:Accept')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(AcceptModal)
