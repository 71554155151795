import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { getDeviceInfo } from 'commons/utils/getDeviceInfo'

const signIn = createAsyncThunk(
  'auth/logIn',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleLoading())
      const response = await axios.post('/user/api/v1/auth/login', {
        ...payload,
        deviceInfo: getDeviceInfo(),
      })
      const { accessToken, refreshToken } = response.data
      localStorage.setItem('token', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      return response
    } catch (error) {
      return rejectWithValue(
        getErrorMessage(error, 'User or password is invalid'),
      )
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default signIn
