import { createSlice } from '@reduxjs/toolkit'
import fetchGalleries from './fetchGalleries'
import deleteMediaImage from './deleteMediaImage'
import getPets from './getPets'
import uploadNewImage from './uploadNewImage'
import { galleryReducerMapping } from '../constant'

const PAGE_SIZE = 15

const initialState = {
  allGalleries: {
    offset: 0,
    limit: PAGE_SIZE,
    isFetchEnded: false,
    data: [],
  },
  personGalleries: {
    offset: 0,
    limit: PAGE_SIZE,
    isFetchEnded: false,
    data: [],
  },
  petGalleries: {
    offset: 0,
    limit: PAGE_SIZE,
    isFetchEnded: false,
    data: [],
  },
  companyGalleries: {
    offset: 0,
    limit: PAGE_SIZE,
    isFetchEnded: false,
    data: [],
  },
  isFetching: false,
  isFetchingPets: false,
  isFetchEnded: false,
}

const mediaSlice = createSlice({
  name: 'mediaReducer',
  initialState,
  reducers: {
    resetMediaState: () => {
      return initialState
    },
    resetPrevState: (state, { payload }) => {
      const type = galleryReducerMapping[payload]
      state[type] = initialState[type]
    },
  },
  extraReducers: {
    [fetchGalleries.pending]: (state) => {
      state.isFetching = true
    },
    [fetchGalleries.fulfilled]: (state, { payload }) => {
      const { records, limit, offset, totalRecords, galleryType } = payload
      const gallery = state[galleryReducerMapping[galleryType]]

      state.isFetching = false
      gallery.isFetchEnded = limit + offset >= totalRecords
      gallery.data = gallery.data.concat(records)
      gallery.offset += limit
    },
    [fetchGalleries.rejected]: (state) => {
      state.isFetching = false
    },
    [deleteMediaImage.fulfilled]: (state, { payload }) => {
      const { s3Ids, galleryType } = payload
      const gallery = state[galleryReducerMapping[galleryType]]
      gallery.data = gallery.data.filter((item) => !s3Ids.includes(item.s3Id))
      gallery.offset -= s3Ids.length
    },
    [getPets.pending]: (state) => {
      state.isFetchingPets = false
    },
    [getPets.fulfilled]: (state, { payload }) => {
      state.isFetchingPets = false
      state.pets = payload
    },
    [getPets.rejected]: (state) => {
      state.isFetchingPets = false
    },
    [uploadNewImage.fulfilled]: (state, { payload }) => {
      const { file, galleryType } = payload
      const gallery = state[galleryReducerMapping[galleryType]]
      gallery.data.unshift(file)
      gallery.offset++
    },
  },
})

const { actions, reducer } = mediaSlice

export { deleteMediaImage, fetchGalleries, getPets, uploadNewImage }

export const { resetMediaState, resetPrevState } = actions

export default reducer
