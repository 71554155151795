import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export function addResource(i18n, resources) {
  if (!i18n.hasResourceBundle)
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        // we init with resources
        nsSeparator: false,
        keySeparator: false,
        resources: {},
        debug: false,
        // keySeparator: false, // we use content as keys
        interpolation: {
          escapeValue: false,
        },
      })

  for (var ns in resources) {
    const tr_ns = resources[ns]
    for (var lng in tr_ns) i18n.addResourceBundle(lng, ns, tr_ns[lng])
  }
}

export function removeResource(i18n, resources) {
  for (var ns in resources) {
    const tr_ns = resources[ns]
    for (var lng in tr_ns) {
      if (i18n.hasResourceBundle(lng, ns)) i18n.removeResourceBundle(lng, ns)
    }
  }
}

export function translateArray(tr, array) {
  const translation = array.map((item) => {
    return tr(item)
  })

  return translation
}
