export * from './getErrorMessage'
export * from './getDeviceInfo'

const images = ['jpg', 'gif', 'png']
const videos = ['mp4', '3gp', 'ogg', 'mov']

export const getFileType = (file) => {
  if (file instanceof File) {
    if (file.type.match('image.*')) return 'image'

    if (file.type.match('video.*')) return 'video'

    if (file.type.match('audio.*')) return 'audio'
  }
  if (typeof file === 'string') {
    let extension = file.split('.')
    extension = extension[extension.length - 1].toLowerCase()
    if (images.includes(extension)) return 'image'

    if (videos.includes(extension)) return 'video'
  }
  return 'other'
}
