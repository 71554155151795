import { createAsyncThunk } from '@reduxjs/toolkit'
import { POST_TYPE } from 'commons/constants'
import axios from 'commons/http/axios'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

const fetchUserPosts = createAsyncThunk(
  'userProfile/fetchUserPosts',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const posts = get(state, 'userProfileReducer.posts')
      const { offset, limit } = posts
      let data = {}
      if (isNil(payload.postId)) {
        const res = await axios.get(`/activities/api/v1/posts/list`, {
          params: {
            offset: payload.offset ?? offset,
            limit,
            type: POST_TYPE.USER,
            id: payload.userId,
          },
        })
        data = res.data.data
      } else {
        const res = await axios.get(
          `/activities/api/v1/posts/${payload.postId}`,
        )
        data = {
          limit: 1,
          offset: 0,
          totalRecords: 1,
          records: [res.data.data],
        }
      }

      return data
    } catch (error) {
      return rejectWithValue()
    } finally {
    }
  },
)

export default fetchUserPosts
