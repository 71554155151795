const { USER_LEVEL_NUMBERS, USER_LEVEL } = require('commons/constants')

export const isExactlyPremium = (level) =>
  USER_LEVEL_NUMBERS[level] === USER_LEVEL_NUMBERS[USER_LEVEL.PREMIUM]

export const isPremium = (level) =>
  USER_LEVEL_NUMBERS[level] >= USER_LEVEL_NUMBERS[USER_LEVEL.PREMIUM]

export const isPro = (level) =>
  USER_LEVEL_NUMBERS[level] >= USER_LEVEL_NUMBERS[USER_LEVEL.PRO]

export const isBusiness = (level) =>
  USER_LEVEL_NUMBERS[level] >= USER_LEVEL_NUMBERS[USER_LEVEL.BUSINESS]

const checkLevel = { isExactlyPremium, isPro, isPremium, isBusiness }

const checkLevelMapping = {
  [USER_LEVEL.PREMIUM]: isPremium,
  [USER_LEVEL.PRO]: isPro,
  [USER_LEVEL.BUSINESS]: isBusiness,
}

export { checkLevelMapping }

export default checkLevel
