import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const appUnfollow = createAsyncThunk(
  'main/appUnfollow',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(toggleLoading())
      await axios.patch(`follow/unfollow`, { targetId: payload })
    } catch (error) {
      dispatch(addMessageToUser(ERROR_DEFAULT))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default appUnfollow
