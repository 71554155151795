import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const fetchLitterProfile = createAsyncThunk(
  'litterProfile/fetchLitterProfile',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`/pets/api/v1/pets/litters/${payload}`)
      return { id: parseInt(payload), ...res.data.data }
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    }
  },
)

export default fetchLitterProfile
