import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const invitePeople = createAsyncThunk(
  'following/invitePeople',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      await axios.post(`/user/api/v1/users/sendInvitation`, {
        emails: payload,
      })
      return payload
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default invitePeople
