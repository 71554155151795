import { createSlice } from '@reduxjs/toolkit'
import fetchUserPosts from './fetchUserPosts'
import createUserPost from './createUserPost'
import deletePost from './deletePost'
import updatePost from './updatePost'
import pinPost from './pinPost'
import fetchUserProfile from './fetchUserProfile'
import fetchUserAvailablePets from './fetchUserAvailablePets'
import fetchUserAbout from './fetchUserAbout'
import updateUserAbout from './updateUserAbout'
import deleteSponsor from './deleteSponsor'
import { PAGE_SIZE } from 'commons/constants'
import fetchUserFiles from './fetchUserFiles'
import createUserFile from './createUserFile'
import deleteUserFile from './deleteUserFile'

const initialState = {
  posts: {
    offset: 0,
    limit: PAGE_SIZE,
    data: [],
    isFetchingPosts: false,
    isFetchPostsEnded: false,
    isCreateLoading: false,
    isCreateSuccess: false,
    isCreateFail: false,
  },
  userProfile: {
    isFetchingUserProfile: false,
    data: null,
  },
  available: {
    data: [],
    offset: 0,
    isFetchingAvailability: false,
    isFetchAvailabilityEnded: false,
  },
  userAbout: {
    isFetchingUserAbout: false,
    data: null,
  },
  files: {
    offset: 0,
    limit: 16,
    data: [],
    isFetching: false,
    isFetchEnded: false,
    isCreateSuccess: false,
  },
}

const userProfileSlice = createSlice({
  name: 'userProfileReducer',
  initialState,
  reducers: {
    resetUserProfile: () => {
      return initialState
    },
    resetUserPosts: (state) => {
      state.posts = initialState.posts
    },
  },
  extraReducers: {
    [fetchUserPosts.pending]: (state) => {
      state.posts.isFetchingPosts = true
    },
    [fetchUserPosts.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.posts = {
        ...state.posts,
        offset: offset + limit,
        isFetchPostsEnded: offset + limit >= totalRecords,
        data: !offset ? records : state.posts.data.concat(records),
        isFetchingPosts: false,
      }
    },
    [fetchUserPosts.rejected]: (state) => {
      state.posts.isFetchingPosts = false
    },
    [createUserPost.pending]: (state) => {
      state.posts.isCreateLoading = true
    },
    [createUserPost.fulfilled]: (state, { payload }) => {
      state.posts = {
        ...state.posts,
        isCreateLoading: false,
        isCreateSuccess: true,
        data: [payload, ...state.posts.data],
      }
    },
    [createUserPost.rejected]: (state) => {
      state.posts = {
        ...state.posts,
        isCreateFail: true,
        isCreateLoading: false,
      }
    },
    [deletePost.pending]: () => {},
    [deletePost.fulfilled]: (state, { payload }) => {
      const data = state.posts.data
      const postIdx = data.findIndex(({ id }) => id === payload)
      if (postIdx < 0) {
        return state
      }
      data.splice(postIdx, 1)
    },
    [updatePost.fulfilled]: (state, { payload }) => {
      const data = state.posts.data
      const postIdx = data.findIndex(({ id }) => id === payload.id)
      if (postIdx < 0) {
        return state
      }
      data[postIdx] = payload
    },
    [fetchUserProfile.pending]: (state) => {
      state.userProfile.isFetchingUserProfile = true
    },
    [fetchUserProfile.fulfilled]: (state, { payload }) => {
      state.userProfile.isFetchingUserProfile = false
      state.userProfile.data = payload
    },
    [fetchUserProfile.rejected]: (state) => {
      state.userProfile.isFetchingUserProfile = false
    },
    [fetchUserAvailablePets.pending]: (state) => {
      state.available.isFetchingAvailability = true
    },
    [fetchUserAvailablePets.fulfilled]: (state, { payload }) => {
      const { offset, limit, totalRecords, records } = payload
      state.available.isFetchingAvailability = false
      state.available.isFetchAvailabilityEnded = offset + limit >= totalRecords
      state.available.data =
        offset === 0 ? records : state.available.concat(records)
    },
    [fetchUserAvailablePets.rejected]: (state) => {
      state.available.isFetchingAvailability = false
    },
    [fetchUserAbout.pending]: (state) => {
      state.userAbout.isFetchingUserAbout = true
    },
    [fetchUserAbout.fulfilled]: (state, { payload }) => {
      state.userAbout.isFetchingUserAbout = false
      state.userAbout.data = payload
    },
    [fetchUserAbout.rejected]: (state) => {
      state.userAbout.isFetchingUserAbout = false
    },
    [updateUserAbout.fulfilled]: (state, { payload }) => {
      state.userAbout.data = payload
    },
    [deleteSponsor.fulfilled]: (state, { payload }) => {
      state.userAbout.data = payload
    },
    [pinPost.fulfilled]: (state) => {
      state.posts = initialState.posts
    },
    [fetchUserFiles.pending]: (state) => {
      state.files.isFetching = true
    },
    [fetchUserFiles.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.files = {
        ...state.files,
        offset: offset + limit,
        isFetchEnded: offset + limit >= totalRecords,
        data: offset ? state.files.data.concat(records) : records,
        isFetching: false,
      }
    },
    [fetchUserFiles.rejected]: (state) => {
      state.files.isFetching = false
    },
    [createUserFile.fulfilled]: (state, { payload }) => {
      state.files = {
        ...state.files,
        offset: state.files.offset++,
        data: [payload, ...state.files.data],
      }
    },
    [deleteUserFile.fulfilled]: (state, { payload: s3Id }) => {
      const idx = state.files.data.findIndex((file) => file.s3Id === s3Id)
      if (idx >= 0) {
        state.files.data.splice(idx, 1)
        state.files.offset--
      }
    },
  },
})

const { actions, reducer } = userProfileSlice

export {
  fetchUserPosts,
  createUserPost,
  deletePost,
  updatePost,
  pinPost,
  fetchUserProfile,
  fetchUserAvailablePets,
  fetchUserAbout,
  updateUserAbout,
  deleteSponsor,
  fetchUserFiles,
  createUserFile,
  deleteUserFile,
}

export const { resetUserProfile, resetUserPosts } = actions

export default reducer
