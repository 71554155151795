import { ImageCropper, PictureDropzone } from 'commons/components'
import { imageResizerConfig } from 'commons/utils/imageResizerConfig'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'
import { Form, Modal, Grid } from 'semantic-ui-react'
import FormErrorMessage from '../FormErrorMessage'
import PropTypes from 'prop-types'
import * as styles from './styles.module.scss'

const ImagePicker = ({
  cropSize = {
    height: 200,
    width: 200,
  },
  whitePictureBackground = false,
  avatarFullWidth = false,
  onChange,
  defaultValue,
}) => {
  const [imagePreview, setImagePreview] = useState('')
  const [imageCropping, setImageCropping] = useState(false)
  const [picture, setPicture] = useState(defaultValue)

  const onDropFile = (acceptedFiles) => {
    Resizer.imageFileResizer(
      acceptedFiles[0],
      imageResizerConfig.maxWidth,
      imageResizerConfig.maxHeight,
      imageResizerConfig.compressFormat,
      imageResizerConfig.quality,
      0,
      (uri) => {
        setImageCropping(true)
        setImagePreview(uri)
      },
      'base64',
    )
  }

  const onSaveImage = (local, remote) => {
    setImageCropping(false)
    onChange(remote)
    setPicture(local)
  }

  const onCancelImage = (e) => {
    setImageCropping(false)
    setImagePreview('')
  }

  return (
    <div className={`${avatarFullWidth && styles['full-width']} no-padding`}>
      <Modal
        centered={false}
        closeIcon
        onClose={onCancelImage}
        open={imageCropping}
      >
        <ImageCropper
          onCancelImage={onCancelImage}
          onSaveImage={onSaveImage}
          imageUrl={imagePreview}
          cropSize={cropSize}
        />
      </Modal>
      <PictureDropzone
        whitePictureBackground={whitePictureBackground}
        avatarFullWidth={avatarFullWidth}
        onDropFile={onDropFile}
        imageUrl={picture}
      />
    </div>
  )
}

const FormImagePicker = ({ name, control, error, rules, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field } }) => (
        <Form.Field className={styles['form-image-picker']}>
          <Grid className={styles['form-image-picker--grid']}>
            <ImagePicker {...field} {...rest} />
            <FormErrorMessage name={name} error={error} />
          </Grid>
        </Form.Field>
      )}
    />
  )
}

FormImagePicker.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
}

export default React.memo(FormImagePicker)
