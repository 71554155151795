import React, { memo, useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ArrowDown from '../../resources/icons/arrow-down.svg'
import styles from './styles.module.scss'

const DropBox = (props) => {
  const {
    visible = false,
    onDrop,
    label,
    content,
    disable,
    className = '',
    labelClassName = '',
    contentClassName = '',
    icon = <img src={ArrowDown} alt='dropdown' className={styles.default} />,
  } = props
  const [dropdown, setDropdown] = useState(visible)
  const [isTop, setIsTop] = useState(false)
  const ref = useRef(null)
  const labelRef = useRef(null)
  const contentRef = useRef(null)

  const handleClick = () => {
    if (!disable) {
      if (typeof onDrop === 'function') onDrop(!visible)
      else setDropdown(!dropdown)
      if (!dropdown) {
        const bottomLabel = labelRef.current.getBoundingClientRect()?.bottom
        const windowHeight = window.innerHeight
        const contentHeight = contentRef.current?.offsetHeight
        const diff = 8
        setIsTop(bottomLabel + contentHeight + diff < windowHeight)
      }
      document.documentElement.removeEventListener('click', handleClickBody)
      document.documentElement.addEventListener('click', handleClickBody)
    }
  }

  const handleClickBody = useCallback((e) => {
    if (e.target instanceof Element && !ref.current?.contains(e.target)) {
      if (typeof onDrop === 'function') onDrop(false)
      else setDropdown(false)
      document.documentElement.removeEventListener('click', handleClickBody)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      document.documentElement.removeEventListener('click', handleClickBody)
    }
  }, [handleClickBody])

  useEffect(() => {
    if (disable && (dropdown || visible)) {
      if (typeof onDrop === 'function') onDrop(false)
      else setDropdown(false)
    }
  }, [disable, dropdown, visible, setDropdown, onDrop])

  return (
    <div
      ref={ref}
      className={`${styles.dropdownContainer} ${className} ${
        disable ? styles.disable : ''
      } `}
    >
      <div
        ref={labelRef}
        className={`${styles.label} ${labelClassName} ${
          !icon ? styles.noIcon : ''
        }`}
        onClick={handleClick}
      >
        {label}
        {icon && <div className={styles.icon}>{icon}</div>}
      </div>
      <div
        ref={contentRef}
        className={`${styles.content} ${contentClassName} ${
          isTop ? styles.top : ''
        }`}
        style={{ visibility: dropdown || visible ? 'visible' : 'hidden' }}
      >
        {content}
      </div>
    </div>
  )
}

DropBox.propTypes = {
  label: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
  disable: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  icon: PropTypes.bool,
  visible: PropTypes.bool,
  onDrop: PropTypes.func,
}

export default memo(DropBox)
