import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const updateCompanyAbout = createAsyncThunk(
  'companyProfile/updateCompanyAbout',
  async ({ companyId, ...payload }, { rejectWithValue, dispatch }) => {
    dispatch(toggleLoading())
    try {
      const res = await axios.post(
        `/api/v1/companies/about/${companyId}`,
        payload,
      )
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updateCompanyAbout
