import { createAsyncThunk } from '@reduxjs/toolkit'
import { PAGE_SIZE } from 'commons/constants'
import axios from 'commons/http/axios'

const fetchFeed = createAsyncThunk(
  'Feed/fetchFeed',
  async ({ defaultOffset, explore }, { getState, rejectWithValue }) => {
    try {
      const {
        feedReducer: { offset },
      } = getState()
      const res = await axios.get(
        `/activities/api/v1/posts/news-feed${explore ? '/explore' : ''}`,
        {
          params: {
            limit: PAGE_SIZE,
            offset: defaultOffset ?? offset,
          },
        },
      )
      return res.data.data
    } catch (error) {
      return rejectWithValue('')
    }
  },
)

export default fetchFeed
