import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

export const sendUserToken = async (data) => {
  try {
    await axios.post('/notification/api/v1/notifications/token', data)
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const service = { sendUserToken }

export default service
