import { createSlice } from '@reduxjs/toolkit'
import fetchFamilyTree from './fetchFamilyTree'
import assignPetToParent from './assignPetToParent'
import revokeAssignPet from './revokeAssignPet'

const initialState = {
  isFetchingFamily: false,
  data: [],
  isAssigning: false,
  isAssignSuccess: false,
  isRevokeSuccess: false,
}

const slice = createSlice({
  name: 'familyTreeReducer',
  initialState,
  reducers: {
    resetFamilyState: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchFamilyTree.pending]: (state) => {
      state.isFetchingFamily = true
    },
    [fetchFamilyTree.fulfilled]: (state, { payload }) => {
      state.isFetchingFamily = false
      state.data = payload
    },
    [fetchFamilyTree.rejected]: (state) => {
      state.isFetchingFamily = false
    },
    [assignPetToParent.pending]: (state) => {
      state.isAssigning = true
      state.isAssignSuccess = false
    },
    [assignPetToParent.fulfilled]: (state) => {
      state.isAssigning = false
      state.isAssignSuccess = true
    },
    [assignPetToParent.rejected]: (state) => {
      state.isAssigning = false
    },
    [revokeAssignPet.pending]: (state) => {
      state.isRevokeSuccess = false
    },
    [revokeAssignPet.fulfilled]: (state) => {
      state.isRevokeSuccess = true
    },
  },
})

const { actions, reducer: familyTreeReducer } = slice

export { fetchFamilyTree, assignPetToParent, revokeAssignPet }

export const { resetFamilyState } = actions

export default familyTreeReducer
