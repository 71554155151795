import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const fetchFamilyTree = createAsyncThunk(
  'familyTree/fetchFamilyTree',
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/pets/api/v1/pet-family-trees/${id}`)
      return res.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default fetchFamilyTree
