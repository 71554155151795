import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import {
  ERROR_DEFAULT,
  ERROR_PASSWORDS_INCORRECT,
} from 'commons/utils/error_messages_var'

const resetUserPassword = createAsyncThunk(
  'settings/resetUserPassword',
  async (payload, { dispatch }) => {
    dispatch(toggleLoading())
    try {
      const { currentPassword, newPassword, confirmPassword } = payload
      await axios.put(`/user/api/v1/users/changePassWord`, {
        oldPassword: currentPassword,
        newPassword,
        confirmPassword,
      })
    } catch (error) {
      dispatch(
        addMessageToUser(
          error?.response?.data?.code === 4
            ? ERROR_PASSWORDS_INCORRECT
            : ERROR_DEFAULT,
        ),
      )
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default resetUserPassword
