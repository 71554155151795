import { createAsyncThunk } from '@reduxjs/toolkit'

const fetchActivePetHealthInfo = createAsyncThunk(
  'app/fetchActivePetHealthInfo',
  async (payload, { rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default fetchActivePetHealthInfo
