import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const avatarNavigate = createAsyncThunk(
  'app/avatarNavigate',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, profileType } = payload
      const url = profileType === 'pet' ? 'pets' : profileType
      await axios.get(`/pets/api/v1/${url}/${id}`)

      return `/${profileType}s/${id}`
    } catch (error) {
      return rejectWithValue(ERROR_DEFAULT)
    }
  },
)

export default avatarNavigate
