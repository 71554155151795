import { getFileType } from 'commons/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputForm from '../InputForm'
import * as styles from './styles.module.scss'
import Avatar from 'components/Avatar'
import { PROFILE_TYPE } from 'commons/constants'
import sendIcon from 'assets/icons/send.svg'
import IconPlaceholder from 'components/IconPlaceholder'

const decodeHtml = (html) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  const content = txt.value
  txt.remove()
  return content
}

const CommentInput = ({
  onEdit,
  onSubmit,
  userInfo,
  hideAvatar,
  commentRef,
}) => {
  const { t } = useTranslation()

  const { content: oldText, fileInformation } = onEdit || {}

  const [content, setContent] = useState(oldText ?? '')
  const [pictures, setPictures] = useState(fileInformation ?? [])
  const [newPictures, setNewPictures] = useState([])
  const [newPictureBlobs, setNewPictureBlobs] = useState([])

  const onRemovePicture = () => {
    setPictures([])
  }

  const onRemoveNewPicture = () => {
    setNewPictures([])
    setNewPictureBlobs([])
  }

  const onSaveImage = (local, remote) => {
    setNewPictures([local])
    setNewPictureBlobs([remote])
  }

  const onClickIconSubmit = () => {
    const newText = decodeHtml(content || '').trim()
    if (!newText && !newPictureBlobs.length) {
      return
    }
    onSubmit(newText, pictures, newPictureBlobs)?.then(() => {
      setContent('')
      setPictures([])
      setNewPictures([])
      setNewPictureBlobs([])
    })
  }

  const renderPreview = (picture) => {
    if (picture.startsWith('blob') || getFileType(picture) === 'video') {
      return (
        <video controls muted>
          <source src={picture} />
        </video>
      )
    }
    return <img src={picture} alt='comment' />
  }

  return (
    <div className={styles[`comment-input-container`]}>
      <div className={styles[`text-input`]}>
        {!hideAvatar && (
          <Avatar
            width={36}
            height={36}
            type={PROFILE_TYPE.USER}
            src={userInfo.profileImage}
            link={`/user/${userInfo.id}`}
            showStatus
            id={userInfo.id}
          />
        )}
        <div
          className={`${styles[`input-container`]} ${
            hideAvatar ? styles['hide-avatar'] : ''
          }`}
        >
          <InputForm
            value={content}
            onChangeValue={(val) => setContent(val)}
            placeholder={t(`common:Comment`)}
            onSaveImage={onSaveImage}
            isShowImagePicker={!pictures.length && !newPictures.length}
            isShowEmojiPicker
            outRef={commentRef}
          />
        </div>
        <IconPlaceholder
          width={40}
          height={40}
          icon={sendIcon}
          isActive={!!content.length}
          onClick={onClickIconSubmit}
        />
      </div>
      {!!pictures.length && (
        <div className={styles[`images`]}>
          {pictures.map((picture, idx) => (
            <div key={picture.s3Id} className={styles[`image`]}>
              <span
                onClick={() => onRemovePicture(idx)}
                className={styles[`icon--delete`]}
              />
              {renderPreview(picture.url)}
            </div>
          ))}
        </div>
      )}
      {!!newPictures.length && (
        <div className={styles[`images`]}>
          {newPictures.map((picture, idx) => (
            <div key={`${picture}${idx}`} className={styles[`image`]}>
              <span
                onClick={() => onRemoveNewPicture(idx)}
                className={styles[`icon--delete`]}
              />
              {renderPreview(picture)}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(CommentInput)
