import { createAsyncThunk } from '@reduxjs/toolkit'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { deleteMultipleFiles } from 'services/fileManagement'

const deleteCompanyFile = createAsyncThunk(
  'companyProfile/deleteCompanyFile',
  async (s3Id, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      await deleteMultipleFiles(s3Id)
      return s3Id
    } catch (error) {
      dispatch(addMessageToUser(error))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deleteCompanyFile
