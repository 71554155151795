import { createAsyncThunk } from '@reduxjs/toolkit'

const confirmPetOffer = createAsyncThunk(
  'litterProfile/confirmPetOffer',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default confirmPetOffer
