import { createAsyncThunk } from '@reduxjs/toolkit'

const userAnswerToReservation = createAsyncThunk(
  'main/userAnswerToReservation',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
    } catch (error) {
    } finally {
    }
  },
)

export default userAnswerToReservation
