import { createSlice } from '@reduxjs/toolkit'
import fetchOwnedPets from './fetchOwnedPets'
import deletePet from './deletePet'

const initialState = {
  data: [],
  isFetchingPets: false,
  isFetchPetsEnded: false,
  isFetchPetsSuccess: false,
  isCreateLitterSuccess: false,
}

const petsSlice = createSlice({
  name: 'petListingReducer',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchOwnedPets.pending]: (state) => {
      state.isFetchingPets = true
      state.isFetchPetsSuccess = false
    },
    [fetchOwnedPets.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.data = state.data.concat(records)
      state.isFetchingPets = false
      state.isFetchPetsEnded = limit + offset >= totalRecords
      state.isFetchPetsSuccess = true
    },
    [fetchOwnedPets.rejected]: (state) => {
      state.isFetchingPets = false
    },
    [deletePet.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter((item) => item.id !== payload)
    },
  },
})

const { actions, reducer: petListingReducer } = petsSlice

export const { resetState } = actions

export { fetchOwnedPets, deletePet }

export * from './actions'

export default petListingReducer
