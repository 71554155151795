import React from 'react'
import * as styles from './ValidatableInput.module.scss'
import Textarea from 'react-textarea-autosize'
import i18n, { NAMESPACE } from './ValidatableInput.translation'
import { DateInput } from 'semantic-ui-calendar-react'
import { stringToDate } from '../../utils/timeConverter'

class ValidatableInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: this.props.value,
      touched: false,
      error: {
        type: '',
        value: 0,
      },
    }
  }

  componentDidMount() {
    const touched = !!this.props.touched

    this.setState({ text: this.props.value || '' })

    if (touched) this.update(this.props.value || '')
  }

  componentDidUpdate() {
    if (this.props.reset) {
      this.reset()
      if (this.props.onReset) this.props.onReset()
      return
    }
  }

  reset = () => {
    this.setState({
      text: this.props.value,
      touched: false,
      error: {
        type: '',
        value: 0,
      },
    })
  }

  validateLength = (text) => {
    var error = {
      type: '',
      value: 0,
    }

    // maxLength
    var maxLength = 0
    if (this.props.maxLength) maxLength = this.props.maxLength
    else {
      switch (this.props.contentType) {
        case 'text/word':
          maxLength = 64
          break
        case 'text/line':
          maxLength = 256
          break
        case 'text/article':
          maxLength = 10000
          break
        default:
          maxLength = 0
      }
    }

    if (maxLength > 0 && text.length > maxLength) {
      error.type = 'INVALID_MAX_LENGTH'
      error.value = maxLength
      return error
    }

    // minLength
    var minLength = 0
    if (this.props.minLength) minLength = this.props.minLength
    else if (this.props.required === true) minLength = 1

    if (text.length < minLength) {
      error.type = 'INVALID_MIN_LENGTH'
      error.value = minLength
      return error
    }

    // Valid
    return error
  }

  validateContentType = (text) => {
    const content = text

    if (content.length === 0) return { type: '', value: 0 }

    const maxValue = this.props.maxValue ? this.props.maxValue : 0
    const minValue = this.props.minValue ? this.props.minValue : 0

    var error = {
      type: '',
      value: 0,
    }

    switch (this.props.contentType) {
      case 'text/word':
        if (content.search(/\s+/) >= 0) error.type = 'INVALID_TEXT_WORD'
        break
      case 'text/line':
        if (content.search(/\n+/) >= 0) error.type = 'INVALID_TEXT_LINE'
        break
      case 'number/int':
        if (!content.match(/^\d+$/)) error.type = 'INVALID_NUMBER_INT'
        else if (maxValue > 0 && content > maxValue) {
          error.type = 'INVALID_MAX_VALUE'
          error.value = maxValue
        } else if (content < minValue) {
          error.type = 'INVALID_MIN_VALUE'
          error.value = minValue
        }
        break
      case 'number/float':
        if (!content.match(/^\d+\.?\d*$/)) error.type = 'INVALID_NUMBER_FLOAT'
        else if (maxValue > 0 && content > maxValue) {
          error.type = 'INVALID_MAX_VALUE'
          error.value = maxValue
        } else if (content < minValue) {
          error.type = 'INVALID_MIN_VALUE'
          error.value = minValue
        }
        break
      case 'contact/phone':
        if (!content.match(/^\+?\d+[\d-]*$/))
          error.type = 'INVALID_CONTACT_PHONE'
        break
      case 'date':
        if (
          !/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(content)
        )
          error.type = 'INVALID_DATE'
        break
      default:
    }
    return error
  }

  validate = (text) => {
    var error = {
      type: '',
      value: 0,
    }

    // Validate length
    error = this.validateLength(text)

    if (error.type.length > 0) return error

    // customized validator
    if (this.props.validator) {
      const validator = this.props.validator(text)
      if (validator.length > 0) {
        error.type = 'INVALID_CUSTOMIZE'
        error.value = validator
        return error
      }
    }

    // validate contentType
    if (this.props.contentType) {
      error = this.validateContentType(text)
      if (error.type.length > 0) return error
    }

    return error
  }

  errorMssage = () => {
    let msg
    let t = i18n.t.bind(i18n)

    switch (this.state.error.type) {
      case 'INVALID_CUSTOMIZE':
        msg = this.state.error.value
        break
      case 'INVALID_MAX_VALUE':
      case 'INVALID_MIN_VALUE':
        msg =
          t(NAMESPACE + ':' + this.state.error.type) + this.state.error.value
        break
      case 'INVALID_MAX_LENGTH':
      case 'INVALID_MIN_LENGTH':
        msg =
          t(NAMESPACE + ':' + this.state.error.type) +
          this.state.error.value +
          t(NAMESPACE + ':CHARACTER')
        break
      default:
        msg = t(NAMESPACE + ':' + this.state.error.type)
    }

    return msg
  }

  update = (text) => {
    const error = this.validate(text)
    const valid = error.type === ''

    this.setState({
      text: text,
      error: error,
    })

    document.getElementById(this.props.name).style.border = valid
      ? 'none'
      : 'solid 1px #FF0000'

    return valid
  }

  onChange = (e) => {
    const valid = this.update(e.target.value)
    //this.blur(e, valid);
    const params = {
      name: this.props.name,
      value: e.target.value,
      valid: valid,
    }

    if (this.props.onChange) this.props.onChange(e, params)

    this.setState({
      touched: true,
    })
  }

  onDateChange = (e, { name, value }) => {
    const valid = this.update(value)
    const params = {
      name,
      value: name === 'dob' ? value : stringToDate(value),
      valid,
    }

    if (this.props.onChange) this.props.onChange(e, params)

    this.setState({
      touched: true,
    })
  }

  onBlur = (e) => {
    const valid = this.update(e.target.value)
    const params = {
      name: this.props.name,
      value: e.target.value,
      valid: valid,
    }

    if (this.props.onBlur) this.props.onBlur(e, params)
  }

  onClick = (e) => {
    if (this.props.onClick) this.props.onClick(e)
  }

  render() {
    const props = this.props

    const ShowStatus = () => {
      if (this.state.error.type === '') {
        return null
      } else {
        return (
          <div className={styles[`input-error`]}>
            <p>{this.errorMssage()}</p>
          </div>
        )
      }
    }

    switch (props.contentType) {
      case 'date':
        if (props.disabled) {
          return (
            <input
              id={props.name}
              required={props.required}
              disabled={props.disabled}
              name={props.name}
              value={this.state.text}
              placeholder={props.placeholder}
              type='text'
              tabIndex={props.tabIndex}
              style={props.style}
            />
          )
        } else {
          return (
            <div>
              <DateInput
                animation='none'
                startMode='year'
                placeholder={props.placeholder}
                closable
                dateFormat='YYYY-MM-DD'
                value={this.state.text}
                name={props.name}
                onChange={this.onDateChange}
                icon={false}
                id={props.name}
                className={props.className}
                hideMobileKeyboard
                preserveViewMode={false}
                localization={i18n.language}
              />
              <ShowStatus />
            </div>
          )
        }
      case 'text/article':
        return (
          <div>
            <div className={styles['inputWithIcon']}>
              {props.icon ? (
                <img
                  id={props.name + '_icon'}
                  src={props.icon}
                  alt={props.name}
                  className={styles['textAreaIcon']}
                />
              ) : null}
              <Textarea
                id={props.name}
                required={props.required}
                minRows={props.minRows}
                name={props.name}
                disabled={props.disabled}
                value={this.state.text}
                placeholder={props.placeholder}
                type='text'
                onChange={(e) => this.onChange(e)}
                onBlur={(e) => this.onBlur(e)}
                onClick={(e) => this.onClick(e)}
                tabIndex={props.tabIndex}
                style={props.style}
              />
            </div>
            <ShowStatus />
          </div>
        )
      default:
        return (
          <div>
            <div className={styles[`inputWithIcon`]}>
              {props.icon ? (
                <img
                  id={props.name + '_icon'}
                  src={props.icon}
                  alt={props.name}
                />
              ) : null}
              <input
                id={props.name}
                required={props.required}
                disabled={props.disabled}
                name={props.name}
                value={this.state.text}
                placeholder={props.placeholder}
                type='text'
                onChange={(e) => this.onChange(e)}
                onBlur={(e) => this.onBlur(e)}
                onClick={(e) => this.onClick(e)}
                tabIndex={props.tabIndex}
                style={props.style}
              />
            </div>
            <ShowStatus />
          </div>
        )
    }
  }
}

export default ValidatableInput
