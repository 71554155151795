import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { uploadFiles } from 'services/fileManagement'

const updateCompanyProfile = createAsyncThunk(
  'companyProfile/updateCompanyProfile',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const { companyProfileReducer } = getState()
      const {
        profile: {
          data: {
            id,
            userId,
            profileS3Id,
            backgroundS3Id,
            species,
            ...companyProfile
          },
        },
      } = companyProfileReducer
      const { image, ...newCompanyProfile } = payload
      if (image) {
        const uploadData = await uploadFiles({
          userId,
          files: [image.file],
          action: UPLOAD_ACTION.POST,
          companyId: id,
        })
        newCompanyProfile[image.name] = uploadData[0].s3Id
      }
      const response = await axios.put(`/api/v1/companies/${id}`, {
        ...companyProfile,
        ...newCompanyProfile,
      })
      return response.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updateCompanyProfile
