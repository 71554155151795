import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { uploadPostMediaFiles } from 'services/post'

const createFeedPost = createAsyncThunk(
  'Feed/createFeedPost',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const state = getState()
      const currentUser = get(state, 'appReducer.currentUser')
      const { content, files, metadata, mentions } = payload
      const requestData = {
        content: content ?? '',
        type: 'USER',
        s3Ids: [],
        metadata,
      }
      if (!isEmpty(mentions)) {
        requestData.mentions = mentions
      }
      requestData.s3Ids = await uploadPostMediaFiles({
        files,
        userId: currentUser.id,
      })

      const res = await axios.post('/activities/api/v1/posts', requestData)
      const post = res.data.data
      return post
    } catch (error) {
      dispatch(addMessageToUser(ERROR_DEFAULT))
      return rejectWithValue('')
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createFeedPost
