const en = {
  'Company name': 'Company name',
  'Organization type': 'Organization type',
  nonprofit: 'nonprofit',
  'privately held': 'privately held',
  'public company': 'public company',
  'sole proprietorship': 'sole proprietorship',
  other: 'other',
  'Select industry': 'Select industry',
  natureOfBusinesses: 'Nature of business',
  manufacturers: 'Manufacturer',
  Agent: 'Agent',
  'Animal blogger & expert': 'Animal blogger & expert',
  'Animal Boarding kennel': 'Animal Boarding kennel',
  'Animal talent agency': 'Animal talent agency',
  'Animal toy maker': 'Animal toy maker',
  'Animal trainer': 'Animal trainer',
  'Animal whisperer': 'Animal whisperer',
  'Aquarium shop': 'Aquarium shop',
  'At-home boarding service operator': 'At-home boarding service operator',
  'Bed/housing designer': 'Bed/housing designer',
  'Behavioral specialist': 'Behavioral specialist',
  'Boutique pet shop': 'Boutique pet shop',
  'Boutique pet toy store': 'Boutique pet toy store',
  Carshop: 'Carshop',
  'Cat cafe operator': 'Cat cafe operator',
  'Cat influencer': 'Cat influencer',
  'Cat toilet trainer': 'Cat toilet trainer',
  'Collar and leash maker': 'Collar and leash maker',
  'Collar designer': 'Collar designer',
  Consultant: 'Consultant',
  'Custom pet portrait artist': 'Custom pet portrait artist',
  'Delivery service provider': 'Delivery service provider',
  'Design pet beds manufacturer ': 'Design pet beds manufacturer ',
  Distributor: 'Distributor',
  'Dog and cat boarding': 'Dog and cat boarding',
  'Dog bathing': 'Dog bathing',
  'Dog beer brewer': 'Dog beer brewer',
  'Dog influencer': 'Dog influencer',
  'Dog manicurist': 'Dog manicurist',
  'Dog show organized': 'Dog show organized',
  'Dog sports competition organizer': 'Dog sports competition organizer',
  'Dog trainer': 'Dog trainer',
  'Dog trainer for hunting': 'Dog trainer for hunting',
  'Dog trainer for security': 'Dog trainer for security',
  'Dog training centre': 'Dog training centre',
  'Dog walker': 'Dog walker',
  'Dog whisperer': 'Dog whisperer',
  'Dog workout coach': 'Dog workout coach',
  'Doggy Daycare': 'Doggy Daycare',
  'Fish tank designer': 'Fish tank designer',
  'Fur dying specialist': 'Fur dying specialist',
  'Furniture designer': 'Furniture designer',
  'Grooming services': 'Grooming services',
  'Guard dog security service provider': 'Guard dog security service provider',
  'Home made pet food manufacturer': 'Home made pet food manufacturer',
  'Home pet monitor expert': 'Home pet monitor expert',
  Hotel: 'Hotel',
  'Importer & distributor': 'Importer & distributor',
  'In home cleaner': 'In home cleaner',
  Influrencer: 'Influrencer',
  'Kennel cleaning provider': 'Kennel cleaning provider',
  'Large animal sitter': 'Large animal sitter',
  'Luxury boarding service operator': 'Luxury boarding service operator',
  'Maker of breath mints for dogs': 'Maker of breath mints for dogs',
  'Manufacturer private label': 'Manufacturer private label',
  'Marketing & communication': 'Marketing & communication',
  'Mobile dog grooming': 'Mobile dog grooming',
  'Mobile dog training': 'Mobile dog training',
  'Mobile pet clinic': 'Mobile pet clinic',
  'Obedience expert': 'Obedience expert',
  'Online shop': 'Online shop',
  'Online pet expert': 'Online pet expert',
  'Organic treat maker': 'Organic treat maker',
  Organization: 'Organization',
  'Pet accessories designer': 'Pet accessories designer',
  'Pet ambulance': 'Pet ambulance',
  'Pet bakery': 'Pet bakery',
  'Pet bandana maker': 'Pet bandana maker',
  'Pet boarding kennel': 'Pet boarding kennel',
  'Pet boutique': 'Pet boutique',
  'Pet brand manager': 'Pet brand manager',
  'Pet business marketing': 'Pet business marketing',
  'Pet business marketing specialist': 'Pet business marketing specialist',
  'Pet cafe operator': 'Pet cafe operator',
  'Pet clothing designer': 'Pet clothing designer',
  'Pet coffins and urns': 'Pet coffins and urns',
  'Pet day care': 'Pet day care',
  'Pet event organizer': 'Pet event organizer',
  'Pet event planner': 'Pet event planner',
  'Pet food truck owner': 'Pet food truck owner',
  'Pet franchise': 'Pet franchise',
  'Pet friendly hotel': 'Pet friendly hotel',
  'Pet friendly restaurant': 'Pet friendly restaurant',
  'Pet funeral provider ': 'Pet funeral provider ',
  'Pet health expert': 'Pet health expert',
  'Pet hotel': 'Pet hotel',
  'Pet influencer': 'Pet influencer',
  'Pet insurance company': 'Pet insurance company',
  'Pet marketing': 'Pet marketing',
  'Pet marketing ecommerce': 'Pet marketing ecommerce',
  'Pet massage therapy': 'Pet massage therapy',
  'Pet momager': 'Pet momager',
  'Pet movie producer': 'Pet movie producer',
  'Pet nutritionist': 'Pet nutritionist',
  'Pet photographer': 'Pet photographer',
  'Pet physigal terapist': 'Pet physigal terapist',
  'Pet pilow creator': 'Pet pilow creator',
  'Pet press': 'Pet press',
  'Pet related radio talk show': 'Pet related radio talk show',
  'Pet related tv show': 'Pet related tv show',
  'Pet restaurant': 'Pet restaurant',
  'Pet retailer': 'Pet retailer',
  'Pet sharing service operator': 'Pet sharing service operator',
  'Pet shelter': 'Pet shelter',
  'Pet shop with lifestock': 'Pet shop with lifestock',
  'Pet sitter': 'Pet sitter',
  'Pet spa operator': 'Pet spa operator',
  'Pet supply store': 'Pet supply store',
  'Pet taxi': 'Pet taxi',
  'Pet toy designer': 'Pet toy designer',
  'Pet transportation': 'Pet transportation',
  'Pet travel agency': 'Pet travel agency',
  'Pet treat subscription service provider':
    'Pet treat subscription service provider',
  'Pet treats bakery': 'Pet treats bakery',
  'Pet-portrait': 'Pet-portrait',
  Petshop: 'Petshop',
  Petsitting: 'Petsitting',
  'Pooch couture': 'Pooch couture',
  'Poop scooping': 'Poop scooping',
  Press: 'Press',
  'Publisher of pet magazines': 'Publisher of pet magazines',
  'Puppy training courses': 'Puppy training courses',
  'Renter of unusual animals': 'Renter of unusual animals',
  'Rescue organization': 'Rescue organization',
  'Retail chain': 'Retail chain',
  'Saler of breath mints for dogs': 'Saler of breath mints for dogs',
  'Shelter matching expert': 'Shelter matching expert',
  'Social media influencer': 'Social media influencer',
  'Social media pet person': 'Social media pet person',
  'Supplier pet industry': 'Supplier pet industry',
  'Tag engraver': 'Tag engraver',
  'Trade show organizer': 'Trade show organizer',
  'T-shirt designer': 'T-shirt designer',
  Veterinarian: 'Veterinarian',
  'Virtual pet trainer': 'Virtual pet trainer',
  Wholesaler: 'Wholesaler',
  'Yard cleaner': 'Yard cleaner',
  'YouTube training expert': 'YouTube training expert',
  Other: 'Other',
  Accessories: 'Accessories',
  'Aquarium products': 'Aquarium products',
  'Bird accessories': 'Bird accessories',
  'Bird food': 'Bird food',
  'Bird healthcare products': 'Bird healthcare products',
  'Bird toys': 'Bird toys',
  Birds: 'Birds',
  Cages: 'Cages',
  'Cat accessories': 'Cat accessories',
  'Cat food': 'Cat food',
  'Cat healthcare products': 'Cat healthcare products',
  'Cat litter': 'Cat litter',
  'Cat snacks & treats': 'Cat snacks & treats',
  'Cat toys': 'Cat toys',
  'Cat vet food': 'Cat vet food',
  'Dog accessories': 'Dog accessories',
  'Dog food': 'Dog food',
  'Dog healthcare products': 'Dog healthcare products',
  'Dog snacks &treats': 'Dog snacks &treats',
  'Dog toys': 'Dog toys',
  'Equipment & machinery': 'Equipment & machinery',
  'Feed additives': 'Feed additives',
  Fish: 'Fish',
  'Fish food': 'Fish food',
  'Fish healthcare products': 'Fish healthcare products',
  'Grooming products': 'Grooming products',
  'Horse feed': 'Horse feed',
  'Horse products': 'Horse products',
  'Kennels and houses': 'Kennels and houses',
  Livestock: 'Livestock',
  'Packaging materials': 'Packaging materials',
  Plants: 'Plants',
  'Protein sources': 'Protein sources',
  'Reptile & terrarium products': 'Reptile & terrarium products',
  'Reptile food': 'Reptile food',
  'Rodents toys': 'Rodents toys',
  'Small animal accessories': 'Small animal accessories',
  'Small animal food': 'Small animal food',
  'Small animal healthcare products': 'Small animal healthcare products',
  'Small animal toys': 'Small animal toys',
  Toys: 'Toys',
  'Company size': 'Company size',
  employees: 'employees',
  'Company ID': 'Company ID',
  'Company VAT number': 'Company VAT number',
  'Website URL': 'Website URL',
  Brands: 'Brands',
  'Here you can tell more about your organization':
    'Here you can tell more about your organization',
  'I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages.':
    'I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages.',
  'Read the Pezzie terms of Service and Privacy Policy':
    'Read the Pezzie terms of Service and Privacy Policy',
  'Additional information': 'Additional information',
}

export default en
