import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../Spinner'
import * as styles from './loader.module.scss'

const Loader = () => {
  const appReducer = useSelector((state) => state.appReducer)
  const { isLoading } = appReducer

  useEffect(() => {
    // dispatch(getPetConfig())
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div
        className={
          !isLoading
            ? styles[`overlay`]
            : `${styles[`overlay`]} ${styles[`overlay--active`]}`
        }
      >
        <Spinner />
      </div>
    </div>
  )
}

export default React.memo(Loader)
