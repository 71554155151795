import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const getLitterChildren = createAsyncThunk(
  'childrenTab/getLitterChildren',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { litterChildrenReducer } = getState()
      const { offset } = litterChildrenReducer

      const res = await axios.get(`/pets/api/v1/pets/litters-pet/${payload}`, {
        params: {
          offset,
          limit: offset ? 16 : 15,
          sort: 'desc',
          sortField: 'createdDate',
        },
      })
      return res.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)
export default getLitterChildren
