import { combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import authReducer from 'pages/Auth/slice'
import createPetReducer from 'pages/Main/main_routes/CreateNewPet/slice'
import mainReducer from 'pages/Main/slice'
import feedReducer from 'pages/Main/main_routes/Feed/slice'
import followingReducer from 'pages/Main/main_routes/Following/slice'
import litterProfileReducer from 'pages/Main/main_routes/LitterProfile/slice'
import mediaReducer from 'components/Media/slice'
import petsReducer from 'pages/Main/main_routes/Pets/slice'
import appReducer from 'commons/slice'
import searchReducer from 'pages/Main/main_routes/Search/slice'
import settingsReducer from 'pages/Main/main_routes/Settings/slice'
import userTestimonialReducer from 'pages/Main/main_routes/user_testimonials/slice'
import userProfileReducer from 'pages/Main/main_routes/UserProfile/slice'
import petProfileReducer from 'pages/Main/main_routes/PetProfile/slice'
import litterChildrenReducer from 'components/ChildrenTab/slice'
import createChildrenReducer from 'pages/Main/main_routes/CreateNewChildren/slice'
import familyTreeReducer from 'components/FamilyTreeTab/slice'
import litterReducer from 'components/LitterList/slice'
import petListingReducer from 'components/PetListing/slice'
import notificationReducer from 'components/Notification/slice'
import createCompanyReducer from 'pages/Main/main_routes/CreateNewCompany/slice'
import companiesReducer from 'components/CompanyListing/slice'
import companyProfileReducer from 'pages/Main/main_routes/CompanyProfile/slice'
import personnelProfileReducer from 'pages/Main/main_routes/PersonnelProfile/slice'

const store = configureStore({
  reducer: combineReducers({
    authReducer,
    createPetReducer,
    litterProfileReducer,
    mainReducer,
    feedReducer,
    followingReducer,
    mediaReducer,
    petsReducer,
    appReducer,
    searchReducer,
    settingsReducer,
    userTestimonialReducer,
    userProfileReducer,
    petProfileReducer,
    litterChildrenReducer,
    createChildrenReducer,
    familyTreeReducer,
    litterReducer,
    petListingReducer,
    notificationReducer,
    createCompanyReducer,
    companiesReducer,
    companyProfileReducer,
    personnelProfileReducer,
  }),
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware()
    if (process.env.NODE_ENV === 'development') {
      middleware.push(logger)
    }
    return middleware
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
