import { createAsyncThunk } from '@reduxjs/toolkit'
import service from 'services/post'

const deletePetPost = createAsyncThunk(
  'petProfile/deletePetPost',
  async (postId, { rejectWithValue }) => {
    try {
      await service.deletePost(postId)
      return postId
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export default deletePetPost
