import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'

const fetchPetFollowers = createAsyncThunk(
  'app/fetchPetFollowers',
  async (payload) => {
    try {
      const { search, petId } = payload
      const result = await axios.get(
        `follow/pet/follower/${petId}?search=${search || ''} `,
      )
      return result.data.data
    } catch (error) {
      return []
    }
  },
)

export default fetchPetFollowers
