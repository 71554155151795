import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'
import { uploadFiles } from 'services/fileManagement'

const createNewLitter = createAsyncThunk(
  'litter/createNewLitter',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    try {
      const { avatar, ...data } = payload
      if (avatar) {
        const currentUser = get(getState(), 'appReducer.currentUser')
        const uploadData = await uploadFiles({
          userId: currentUser.id,
          files: [avatar],
          action: UPLOAD_ACTION.POST,
        })
        data.avatar = get(uploadData, '0.s3Id')
      }
      await axios.post('/pets/api/v1/pets/litters', data)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default createNewLitter
