import React from 'react'
import { Table } from 'semantic-ui-react'
import { textToHtml, processLink } from '../../utils/sanitize'
import * as styles from './InfoTable.module.scss'
import { translation as countries } from '../CountryPicker/countries'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import moment from 'moment'
import { TextWithLink } from 'pages/Main/main_routes/commons/component'

const InfoTable = (props) => {
  const { pet, format } = props
  const { t } = useTranslation()
  const tableContent = format.map((item) => {
    let content = pet[item.key]
    if (!content) {
      content = t('common:Not specified')

      if (item.hide_empty) return null
    } else if (typeof content === 'boolean') {
      content = content ? t('common:Yes') : t('common:No')
    } else if (typeof content === 'object') {
      if (!content) {
        content = t('common:Not specified')
      } else {
        content = Object.entries(content).map(([key, value]) =>
          t(`pet:${value}`),
        )
        content = content.join(', ')
      }
    } else {
      if (item.key === 'description') {
        content = (
          <TextWithLink
            textStyle={'common-text-3'}
            content={content}
            linkStyle={'link-text-2'}
          />
        )
      } else if (item.type === 'date') {
        content = moment(content).format('Y-MM-DD')
      } else if (item.type === 'country') {
        content = countries[i18n.language][content]
      } else if (item.type === 'userInput') {
        content = textToHtml(content)
      } else if (item.type === 'clickableLink') {
        content = (
          <a
            className={styles[`link`]}
            href={processLink(content)}
            target='_blank'
            rel='noreferrer'
          >
            {content}
          </a>
        )
      } else if (item.type !== 'noProcess') {
        content = t(`pet:${content}`)
      }
      if (item.suffix) content = content + ' ' + item.suffix
    }

    return (
      <Table.Row key={item.key} className={styles[`row`]}>
        <Table.Cell width={4} className={styles[`item_name`]}>
          {t([`pet:${item.key}`, `common:${item.key}`]).toUpperCase()}
        </Table.Cell>
        <Table.Cell width={12} className={styles[`item_content`]}>
          {content}
        </Table.Cell>
      </Table.Row>
    )
  })

  return (
    <Table padded='very' className={styles[`table`]}>
      <Table.Body>{tableContent}</Table.Body>
    </Table>
  )
}
export default InfoTable
