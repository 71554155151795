import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const search = createAsyncThunk(
  'following/search',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      if (!payload || payload.length < 3) {
        return rejectWithValue(
          'Search string must be at least three characters long.',
        )
      }
      const result = await axios.get(
        `follow/search?keyword=${payload.toLowerCase()}`,
      )
      return result.data.filter((item) => item)
    } catch (error) {
      dispatch(addMessageToUser(ERROR_DEFAULT))
      return rejectWithValue(null)
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default search
