import { createSlice } from '@reduxjs/toolkit'
import { PAGE_SIZE } from 'commons/constants'
import createCompanyFile from './createCompanyFile'
import createCompanyPersonnel from './createCompanyPersonnel'
import createCompanyPet from './createCompanyPet'
import createCompanyPost from './createCompanyPost'
import deleteCompanyFile from './deleteCompanyFile'
import deleteCompanyImage from './deleteCompanyImage'
import deleteCompanyPersonnel from './deleteCompanyPersonnel'
import deleteCompanyPost from './deleteCompanyPost'
import deleteSponsor from './deleteSponsor'
import fetchCompanyAbout from './fetchCompanyAbout'
import fetchCompanyFiles from './fetchCompanyFiles'
import fetchCompanyPersonnel from './fetchCompanyPersonnel'
import fetchCompanyPets from './fetchCompanyPets'
import fetchCompanyPosts from './fetchCompanyPosts'
import fetchCompanyProfile from './fetchCompanyProfile'
import updateCompanyAbout from './updateCompanyAbout'
import updateCompanyPost from './updateCompanyPost'
import updateCompanyProfile from './updateCompanyProfile'
import deleteCompanyPet from './deleteCompanyPet'

const initialState = {
  posts: {
    offset: 0,
    limit: PAGE_SIZE,
    data: [],
    isFetchingPosts: false,
    isFetchPostsEnded: false,
    isCreateLoading: false,
    isCreateSuccess: false,
    isCreateFail: false,
  },
  profile: {
    isFetchingCompanyProfile: false,
    data: null,
    error: '',
  },
  about: {
    isFetchingAbout: false,
    data: null,
  },
  pets: {
    offset: 0,
    limit: 16,
    data: [],
    isFetchingPets: false,
    isFetchPetsEnded: false,
  },
  personnel: {
    offset: 0,
    limit: 16,
    data: [],
    isFetching: false,
    isFetchEnded: false,
    isCreateSuccess: false,
    isUpdateSuccess: false,
  },
  files: {
    offset: 0,
    limit: 16,
    data: [],
    isFetching: false,
    isFetchEnded: false,
    isCreateSuccess: false,
  },
}

const companyProfileSlice = createSlice({
  name: 'companyProfileReducer',
  initialState,
  reducers: {
    resetCompanyProfile: () => {
      return initialState
    },
    resetCompanyPosts: (state) => {
      state.posts = initialState.posts
    },
  },
  extraReducers: {
    [fetchCompanyProfile.pending]: (state) => {
      state.profile.isFetchingCompanyProfile = true
    },
    [fetchCompanyProfile.fulfilled]: (state, { payload }) => {
      state.profile.isFetchingCompanyProfile = false
      state.profile.data = payload
    },
    [fetchCompanyProfile.rejected]: (state, { payload }) => {
      state.profile.isFetchingCompanyProfile = false
      state.error = payload
    },
    [updateCompanyProfile.fulfilled]: (state, { payload }) => {
      state.profile.data = { ...state.profile.data, ...payload }
    },
    [deleteCompanyImage.fulfilled]: (state, { payload }) => {
      state.profile.data[payload] = null
    },
    [fetchCompanyPosts.pending]: (state) => {
      state.posts.isFetchingPosts = true
    },
    [fetchCompanyPosts.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.posts = {
        ...state.posts,
        offset: offset + limit,
        isFetchPostsEnded: offset + limit >= totalRecords,
        data: state.posts.data.concat(records),
        isFetchingPosts: false,
      }
    },
    [fetchCompanyPosts.rejected]: (state) => {
      state.posts.isFetchingPosts = false
    },
    [createCompanyPost.pending]: (state) => {
      state.posts.isCreateLoading = true
    },
    [createCompanyPost.fulfilled]: (state, { payload }) => {
      state.posts = {
        ...state.posts,
        isCreateLoading: false,
        isCreateSuccess: true,
        data: [payload, ...state.posts.data],
      }
    },
    [createCompanyPost.rejected]: (state) => {
      state.posts = {
        ...state.posts,
        isCreateFail: true,
        isCreateLoading: false,
      }
    },
    [deleteCompanyPost.pending]: () => {},
    [deleteCompanyPost.fulfilled]: (state, { payload }) => {
      const data = state.posts.data
      const postIdx = data.findIndex(({ id }) => id === payload)
      if (postIdx < 0) {
        return state
      }
      data.splice(postIdx, 1)
    },
    [updateCompanyPost.fulfilled]: (state, { payload }) => {
      const data = state.posts.data
      const postIdx = data.findIndex(({ id }) => id === payload.id)
      if (postIdx < 0) {
        return state
      }
      data[postIdx] = payload
    },
    [fetchCompanyAbout.pending]: (state) => {
      state.about.isFetchingAbout = true
    },
    [fetchCompanyAbout.fulfilled]: (state, { payload }) => {
      state.about.isFetchingAbout = false
      state.about.data = payload
    },
    [fetchCompanyAbout.rejected]: (state) => {
      state.about.isFetchingAbout = false
    },
    [updateCompanyAbout.fulfilled]: (state, { payload }) => {
      state.about.data = payload
    },
    [deleteSponsor.fulfilled]: (state, { payload }) => {
      state.about.data = payload
    },
    [fetchCompanyPets.pending]: (state) => {
      state.pets.isFetchingPets = true
    },
    [fetchCompanyPets.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.pets = {
        ...state.pets,
        offset: offset + limit,
        isFetchPetsEnded: offset + limit >= totalRecords,
        data: offset ? state.pets.data.concat(records) : records,
        isFetchingPets: false,
      }
    },
    [fetchCompanyPets.rejected]: (state) => {
      state.pets.isFetchingPets = false
    },
    [createCompanyPersonnel.pending]: (state) => {
      state.personnel.isCreateSuccess = false
    },
    [createCompanyPersonnel.fulfilled]: (state, { payload }) => {
      state.personnel = {
        ...state.personnel,
        isCreateSuccess: true,
        data: [payload, ...state.personnel.data],
        offset: state.personnel.offset + 1,
      }
    },
    [fetchCompanyPersonnel.pending]: (state) => {
      state.personnel.isFetching = true
    },
    [fetchCompanyPersonnel.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.personnel = {
        ...state.personnel,
        offset: offset + limit,
        isFetchEnded: offset + limit >= totalRecords,
        data: offset ? state.personnel.data.concat(records) : records,
        isFetching: false,
      }
    },
    [fetchCompanyPersonnel.rejected]: (state) => {
      state.personnel.isFetching = false
    },
    [deleteCompanyPersonnel.fulfilled]: (state, { payload }) => {
      state.personnel = {
        ...state.personnel,
        data: state.personnel.data.filter((user) => user.id !== payload),
        offset: state.personnel.offset - 1,
      }
    },
    [fetchCompanyFiles.pending]: (state) => {
      state.files.isFetching = true
    },
    [fetchCompanyFiles.fulfilled]: (state, { payload }) => {
      const { totalRecords, records, offset, limit } = payload
      state.files = {
        ...state.files,
        offset: offset + limit,
        isFetchEnded: offset + limit >= totalRecords,
        data: offset ? state.files.data.concat(records) : records,
        isFetching: false,
      }
    },
    [fetchCompanyFiles.rejected]: (state) => {
      state.files.isFetching = false
    },
    [createCompanyFile.fulfilled]: (state, { payload }) => {
      state.files = {
        ...state.files,
        offset: state.files.offset++,
        data: [payload, ...state.files.data],
      }
    },
    [deleteCompanyFile.fulfilled]: (state, { payload: s3Id }) => {
      const idx = state.files.data.findIndex((file) => file.s3Id === s3Id)
      if (idx >= 0) {
        state.files.data.splice(idx, 1)
        state.files.offset--
      }
    },
    [deleteCompanyPet.fulfilled]: (state, { payload: petId }) => {
      const idx = state.pets.data.findIndex((pet) => pet.id === petId)
      if (idx >= 0) {
        state.pets.data.splice(idx, 1)
        state.pets.offset--
      }
    },
  },
})

const { actions, reducer: companyProfileReducer } = companyProfileSlice

export {
  createCompanyFile,
  createCompanyPersonnel,
  createCompanyPet,
  deleteCompanyPet,
  createCompanyPost,
  deleteCompanyFile,
  deleteCompanyImage,
  deleteCompanyPersonnel,
  deleteCompanyPost,
  deleteSponsor,
  fetchCompanyAbout,
  fetchCompanyFiles,
  fetchCompanyPersonnel,
  fetchCompanyPets,
  fetchCompanyPosts,
  fetchCompanyProfile,
  updateCompanyAbout,
  updateCompanyPost,
  updateCompanyProfile,
}

export const { resetCompanyProfile, resetCompanyPosts } = actions

export default companyProfileReducer
