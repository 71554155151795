import React, { useState } from 'react'
import * as styles from './image_cropper.module.scss'
import ImageCrop from 'react-easy-crop'
import { Slider } from 'react-semantic-ui-range'
import { useTranslation } from 'react-i18next'
import getCroppedImage from '../../utils/imageCrop'
import base64ToBlob from '../../utils/base64ToBlob'

const ImageCropper = ({ onCancelImage, imageUrl, cropSize, onSaveImage }) => {
  const { t } = useTranslation()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(0)

  const onCropChange = (crops) => {
    setCrop(crops)
  }

  const onCropComplete = (croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel)
  }

  const onZoomChange = (zooms) => {
    setZoom(zooms)
  }

  const onRotateClock = () => {
    setRotation((rotation + 90) % 360)
  }

  const onRotateCounterClock = () => {
    setRotation((rotation + 270) % 360)
  }

  const onCropImage = async (e) => {
    const croppedImage = await getCroppedImage(
      imageUrl,
      croppedAreaPixels,
      rotation,
    )

    let base64ImageContent = croppedImage.replace(
      /^data:image\/(png|jpeg);base64,/,
      '',
    )
    let blob = base64ToBlob(base64ImageContent, 'image/png')
    onSaveImage(croppedImage, blob)
  }

  return (
    <div className={styles[`cropper`]}>
      <ImageCrop
        minZoom={1}
        zoom={zoom}
        crop={crop}
        rotation={rotation}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        aspect={cropSize ? cropSize.width / cropSize.height : 1}
        image={imageUrl}
        style={{
          containerStyle: { backgroundColor: 'black' },
        }}
      />
      <div className={styles[`options`]}>
        <div className={styles[`controls`]}>
          <div className={styles[`slider-wrapper`]}>
            <span
              className={`${styles[`icon`]} ${styles[`icon--picture--small`]}`}
            ></span>
            <div className={styles[`slider`]}>
              <Slider
                value={zoom}
                color='red'
                settings={{
                  start: zoom,
                  min: 1,
                  max: 3,
                  step: 0.2,
                  onChange: (value) => {
                    setZoom(value)
                  },
                }}
              />
            </div>
            <span
              className={`${styles[`icon`]} ${styles[`icon--picture--big`]}`}
            ></span>
          </div>
          <div className={styles[`buttons`]}>
            <button onClick={() => onRotateClock()}>
              <span
                className={`${styles[`icon`]} ${styles[`icon--rotate--clock`]}`}
              ></span>
            </button>
            <button onClick={() => onRotateCounterClock()}>
              <span
                className={`${styles[`icon`]} ${
                  styles[`icon--rotate--counterclock`]
                }`}
              ></span>
            </button>
          </div>
        </div>
        <div className={styles[`exit-buttons`]}>
          <button onClick={() => onCancelImage()}>{t('common:Cancel')}</button>
          <button onClick={() => onCropImage()}>{t('common:Save')}</button>
        </div>
      </div>
    </div>
  )
}

export default ImageCropper
