import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const fetchNotification = createAsyncThunk(
  'notification/fetchNotification',
  async (_payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        '/notification/api/v1/notification-auditings?unread=true',
      )
      const data = res.data.data.records
      return data.map(({ id, data, createdAt, ...rest }) => ({
        messageId: id,
        from: createdAt,
        data,
        ...rest,
      }))
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default fetchNotification
