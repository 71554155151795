export const addLitterParent = (pet) => ({
  type: 'ADD_LITTER_PARENT',
  payload: {
    pet,
  },
})

export const findUserByDisplayName = (name) => ({
  type: 'FIND_USER_BY_DISPLAY_NAME',
  payload: { name },
})

export const transferPet = (userid, petid) => ({
  type: 'TRANSFER_PET',
  payload: {
    userid,
    petid,
  },
})

export const fetchPendingTransfer = () => ({
  type: 'FETCH_PENDING_TRANSFER',
})

export const withdrawTransfer = (id, petId) => ({
  type: 'WITHDRAW_TRANSFER',
  payload: { id, petId },
})

export const answerTransfer = (transferid, answer, petId) => ({
  type: 'ANSWER_TRANSFER_OFFER',
  payload: {
    transferid,
    answer,
    petId,
  },
})

export const answerToReservation__Pets = (answer, reservation, petId) => ({
  type: 'ANSWER_TO_RESERVATION__PETS',
  payload: {
    reservation,
    answer,
    petId,
  },
})

export const confirmPetOffer__Pets = (petId, transferee, reservationId) => ({
  type: 'CONFIRM_PET_OFFER__PETS',
  payload: {
    petId,
    transferee,
    reservationId,
  },
})

export const updatePetAvailability__Pets = (petId, avail, price) => ({
  type: 'UPDATE_PET_AVAILABILITY__PETS',
  payload: {
    petId,
    avail,
    price,
  },
})

export const withDrawReservation__Pets = (reservationId, petId) => ({
  type: 'WITHDRAW_RESERVATION__PETS',
  payload: {
    reservationId,
    petId,
  },
})
