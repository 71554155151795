import React from 'react'
import { Label, Form } from 'semantic-ui-react'
import FormDate from '../FormDate'
import FormErrorMessage from '../FormErrorMessage'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import * as styles from './styles.module.scss'

const FormDateRange = ({ name, error, ...rest }) => {
  return (
    <Form.Field>
      {rest.label && (
        <Label
          as='label'
          className={get(rest.rules, 'required') ? 'required' : ''}
        >
          {rest.label}
        </Label>
      )}
      <div className={`${styles['date-range-wrapper']}`}>
        <FormDate
          {...rest}
          label=''
          name={`${name}.0`}
          placeholder={'From'}
          error={get(error, 0)}
        />
        <FormDate
          {...rest}
          label=''
          name={`${name}.1`}
          placeholder={'To'}
          error={get(error, 1)}
        />
        <FormErrorMessage error={error} />
      </div>
    </Form.Field>
  )
}

FormDateRange.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  iconPosition: PropTypes.string,
  rules: PropTypes.object,
  formType: PropTypes.string,
}

export default React.memo(FormDateRange)
