import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'
import { uploadFiles } from 'services/fileManagement'

export const createNewChildren = createAsyncThunk(
  'children/createNewChildren',
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { avatar, ...data } = payload
      if (avatar) {
        const currentUser = get(getState(), 'appReducer.currentUser')
        const uploadData = await uploadFiles({
          userId: currentUser.id,
          files: [avatar],
          action: UPLOAD_ACTION.POST,
        })
        data.avatar = get(uploadData, '0.s3Id')
      }
      await axios.post(`/pets/api/v1/pets`, data)
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    }
  },
)

export const fetchLitter = createAsyncThunk(
  'children/fetchLitter',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`/pets/api/v1/pets/litters/${payload}`)
      return res.data.data
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(getErrorMessage(error))))
    }
  },
)

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: '',
  litter: null,
}

const slice = createSlice({
  name: 'createChildrenReducer',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
  },
  extraReducers: {
    [createNewChildren.pending]: (state) => {
      state.error = ''
    },
    [createNewChildren.fulfilled]: (state) => {
      state.isSuccess = true
    },
    [createNewChildren.rejected]: (state, { payload }) => {
      state.error = payload
    },
    [fetchLitter.pending]: (state) => {
      state.isLoad = true
    },
    [fetchLitter.fulfilled]: (state, { payload }) => {
      state.isLoad = false
      state.litter = payload
    },
    [fetchLitter.rejected]: (state) => {
      state.isLoad = false
    },
  },
})

const { actions, reducer } = slice

export const { resetState } = actions

export default reducer
