import React from 'react'
import * as styles from './image_list.module.scss'
import { getFileType } from 'commons/utils'
import * as layoutStyles from 'styles/layouts.module.scss'

const SingleImage = ({
  image,
  onSelect,
  imageId,
  isSelected,
  onOpenLightbox,
  isSelecting,
}) => {
  const isVideo = getFileType(image) === 'video'

  const onClick = (e) => {
    e.preventDefault()
    isSelecting ? onSelect(imageId) : onOpenLightbox()
  }

  return (
    <div className={`${layoutStyles['list__item']} ${styles[`single-image`]}`}>
      <div
        className={`${styles['image']} ${
          isSelected ? styles[`image--selected`] : ''
        }`}
      >
        <span className={`${styles['icon']} ${styles[`icon--selected`]}`} />
        {isVideo ? (
          <video controls muted onClick={onClick}>
            <source src={image} />
          </video>
        ) : (
          <img onClick={onClick} src={image} alt='pets' />
        )}
      </div>
    </div>
  )
}

export default React.memo(SingleImage)
