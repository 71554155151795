import { signOut } from 'commons/slice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Logo from 'commons/resources/icons/Logo.svg'
import LogoLogin from 'commons/resources/images/logo.png'
import { LanguageWidget } from '../index'
import * as styles from './styles.module.scss'

const Header = ({ isLogin }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const appReducer = useSelector((state) => state.appReducer)
  const { currentUser } = appReducer

  const onSignOut = (e) => {
    dispatch(signOut())
  }
  return (
    <header className={styles[`header`]}>
      <div className={styles[`container`]}>
        <a href='https://pezzie.com' className={styles[`logo`]}>
          {isLogin ? (
            <img src={LogoLogin} alt='Pezzie logo' />
          ) : (
            <img src={Logo} alt='Pezzie logo' />
          )}
        </a>
        <div className={styles[`header--right`]}>
          <LanguageWidget isLogin={isLogin} />
          {currentUser.isAuthenticated && (
            <h4 onClick={onSignOut}>{t('auth:Log out')}</h4>
          )}
        </div>
      </div>
    </header>
  )
}

export default React.memo(Header)
