import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyB6aXWkEZH26GZ2uBG_0cuOM0A8WRGhO2E',
  authDomain: 'pezzie-notification-app.firebaseapp.com',
  projectId: 'pezzie-notification-app',
  storageBucket: 'pezzie-notification-app.appspot.com',
  messagingSenderId: '128758027492',
  appId: '1:128758027492:web:85de5a62df36cda8c4c8f1',
  measurementId: 'G-7K98KF3QTR',
}

const firebaseApp = initializeApp(firebaseConfig)

const messaging = firebaseApp?.messaging?.isSupported() ? getMessaging(firebaseApp) : null;

export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          },
        )
      })
  }
  throw new Error("The browser doesn't support service worker.")
}

export const getFirebaseTokenWithSW = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        'BK6uJH-aklbloVs1BvbyvCVmSsL4ejwmLGO6oCwtVOj89JdCzvWLWgj6pxkQ0Krg0gGsrBzCwHqKhAZBIoLrXIs',
      serviceWorkerRegistration,
    }),
  )

export const getFirebaseToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BK6uJH-aklbloVs1BvbyvCVmSsL4ejwmLGO6oCwtVOj89JdCzvWLWgj6pxkQ0Krg0gGsrBzCwHqKhAZBIoLrXIs',
  })
}
export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)))
