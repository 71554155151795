import { createAsyncThunk } from '@reduxjs/toolkit'

const updatePetWeight = createAsyncThunk(
  'app/updatePetWeight',
  async (payload, { rejectWithValue }) => {
    try {
    } catch (error) {}
  },
)

export default updatePetWeight
