import { createSlice } from '@reduxjs/toolkit'
import fetchCompanyListing from './fetchCompanyListing'
import deleteCompany from './deleteCompany'

const initialState = {
  companyListing: {
    data: [],
    isFetchingCompanies: false,
    isFetchCompaniesEnded: false,
    isFetchCompaniesSuccess: false,
  },
}

const slice = createSlice({
  name: 'companiesReducer',
  initialState,
  reducers: {
    resetCompaniesListing: (state) => {
      state.companyListing = initialState.companyListing
    },
  },
  extraReducers: {
    [fetchCompanyListing.pending]: (state) => {
      state.companyListing.isFetchingCompanies = true
      state.companyListing.isFetchCompaniesSuccess = false
    },
    [fetchCompanyListing.fulfilled]: (
      { companyListing: state },
      { payload },
    ) => {
      const { totalRecords, records, offset, limit } = payload
      state.data = state.data.concat(records)
      state.isFetchCompaniesEnded = limit + offset >= totalRecords
      state.isFetchingCompanies = false
      state.isFetchCompaniesSuccess = true
    },
    [fetchCompanyListing.rejected]: (state) => {
      state.companyListing.isFetchingCompanies = false
    },
    [deleteCompany.fulfilled]: (state, { payload }) => {
      const idx = state.companyListing.data.findIndex(
        (company) => company.id === payload,
      )
      if (idx < 0) {
        return
      }
      state.companyListing.data.splice(idx, 1)
    },
  },
})

const { reducer: companiesReducer, actions } = slice

export { fetchCompanyListing, deleteCompany }

export const { resetCompaniesListing } = actions

export default companiesReducer
