import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const getPaymentCurrency = createAsyncThunk(
  'app/getPaymentCurrency',
  async (_payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get('/api/v1/subscription-prices')
      return res.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default getPaymentCurrency
