import React from 'react'
import * as styles from './media_dropzone.module.scss'
import Dropzone from 'react-dropzone'

const MediaDropzone = ({
  onDropFile,
  component: Component,
  disabled,
  acceptVideo,
}) => {
  return (
    <Dropzone
      multiple={false}
      accept={`image/jpeg, image/png, ${acceptVideo ? 'video/*' : ''}`}
      onDrop={(acceptedFiles) => onDropFile(acceptedFiles)}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {Component ? (
              Component
            ) : (
              <div
                className={`${styles[`icon`]} ${styles[`icon--media`]}`}
              ></div>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  )
}
export default MediaDropzone
