import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './styles.module.scss'

const LoadMore = ({ onClick, children }) => {
  return (
    <div className={styles['load-more']} onClick={onClick}>
      {children}
    </div>
  )
}

LoadMore.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default React.memo(LoadMore)
