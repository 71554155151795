import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'

const deleteSponsor = createAsyncThunk(
  'useProfile/deleteSponsor',
  async (s3Id, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleLoading())
      const res = await axios.delete(
        `/user/api/v1/users/about/sponsors/${s3Id}`,
      )
      return res.data.data
    } catch (error) {
      return rejectWithValue(error)
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deleteSponsor
