import { refreshNewToken } from 'commons/slice'
import React, { useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { Router } from 'react-router-dom'
import { ErrorBoundary, Message, ScrollToTop } from './commons/components/index'
import Loader from 'commons/components/Loader'
import Routes from './routes'
import store from './store'
import 'commons/scss/form.scss'
import { getFirebaseToken, onForegroundMessage } from 'firebase-config'
import { useSelector } from 'react-redux'
import notificationService from 'services/notification'
import { pushNotification } from 'components/Notification/slice'
import { createBrowserHistory } from 'history'

const refreshInterval = 1800000 //30 mins
// Token is refreshed every time window comes to foreground.
// Main reason for this is that on phone background refresh
// with setInterval() may not work. Hence we need to refresh
// when user foregrounds the app. For some reason the visibility
// change handler is fired twice. This timer ensures that we
// don't refresh the token unnecessarily twice.
// let lastVisibleRefresh = 0
// const lastVisibleRefreshTimeout = 1000 // 1 sec

const history = createBrowserHistory()

const App = () => {
  const dispatch = useDispatch()

  const { currentUser } = useSelector((state) => state.appReducer)
  const { id } = currentUser

  useEffect(() => {
    refreshToken()
    const interval = setInterval(refreshToken, refreshInterval)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log('Firebase token: ', firebaseToken)
        if (!firebaseToken) {
          return
        }
        notificationService.sendUserToken({
          messagingToken: firebaseToken,
          userIdentifier: id,
        })
      })
      .catch((err) =>
        console.log('An error occured while retrieving firebase token. ', err),
      )

    onForegroundMessage()
      .then((payload) => {
        console.log('Received foreground message: ', payload)
        dispatch(pushNotification(payload))
      })
      .catch((err) =>
        console.log(
          'An error occured while retrieving foreground message. ',
          err,
        ),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.id])

  const refreshToken = () => {
    dispatch(refreshNewToken())
  }

  return (
    <ScrollToTop>
      <Message />
      <Loader />
      <Routes />
    </ScrollToTop>
  )
}

const AppWrapper = () => {
  return (
    <div id='app' style={{ position: 'relative' }} className='App'>
      <ErrorBoundary>
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </ErrorBoundary>
    </div>
  )
}

export default AppWrapper
