import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'
import { addMessageToUser, toggleLoading } from 'commons/slice'

const deletePost = createAsyncThunk(
  'main/deletePost',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(toggleLoading())
      await axios.delete(`posts/${payload}`)
    } catch (error) {
      dispatch(addMessageToUser(ERROR_DEFAULT))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deletePost
