import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'
import { uploadPostMediaFiles } from './post'

const getComments = async (id, params) => {
  try {
    const res = await axios.get(`/activities/api/v1/comments/v2/${id}`, {
      params,
    })
    return res.data.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const getChildComments = async (id, params) => {
  try {
    const res = await axios.get(`/activities/api/v1/comments/v2/child/${id}`, {
      params,
    })
    return res.data.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const getCommentById = async (id) => {
  try {
    const res = await axios.get(`/activities/api/v1/comments/detail/${id}`)
    return res.data.data
  } catch (error) {
    return null
  }
}

const addComment = async (params) => {
  try {
    const {
      content,
      files,
      postId,
      parentId,
      userId,
      additionalData,
      mentions,
    } = params
    const requestData = {
      content: content ?? '',
      postId,
      parentId,
      mentions,
    }
    parentId && (requestData.parentId = parentId)
    requestData.s3Ids = await uploadPostMediaFiles({
      files,
      userId,
      ...additionalData,
    })
    const res = await axios.post(`/activities/api/v1/comments`, requestData)
    return res.data.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const updateComment = async (userId, commentId, params) => {
  try {
    const { files, pictures, ...rest } = params
    const requestData = {
      ...rest,
      s3Ids: pictures.map(({ s3Id }) => s3Id),
    }
    requestData.s3Ids.push(...(await uploadPostMediaFiles({ files, userId })))
    const res = await axios.put(
      `/activities/api/v1/comments/${commentId}`,
      requestData,
    )
    return res.data.data
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const removeComment = async (commentId) => {
  try {
    return await axios.delete(`/activities/api/v1/comments/${commentId}`)
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const rateComment = async (data) => {
  try {
    await axios.post('/activities/api/v1/comment-ratings', data)
  } catch (error) {
    throw getErrorMessage(error)
  }
}

const service = {
  getComments,
  getCommentById,
  addComment,
  updateComment,
  removeComment,
  rateComment,
  getChildComments,
}

export default service
