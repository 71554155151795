import { ImagePicker } from 'commons/components'
import Tooltip from 'commons/components/Tooltip/Tooltip'
import {
  getHTML,
  getText,
  mentionRegex,
  searchRegex,
} from 'commons/utils/emojis'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { fetchMentions } from 'services/commons'
import EmojiSelect from '../EmojiSelect'
import styles from './input_form.module.scss'
import { get } from 'lodash'

const decodeHtml = (html) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  const content = txt.value
  txt.remove()
  return content
}

const InputForm = (props) => {
  const {
    placeholder,
    onChangeValue,
    value,
    onSaveImage,
    isShowImagePicker = false,
    isShowEmojiPicker = false,
    outRef,
  } = props
  const { t } = useTranslation()
  const inputRef = useRef()
  const inputRootRef = useRef()
  const textBeforeCursorRef = useRef('')

  const [html, setHTML] = useState('')
  const [mention, setMention] = useState({
    users: [],
    index: -1,
    text: '',
  })
  const [isSelectMention, setIsSelectMention] = useState(false)

  const onInput = (e) => {
    const text = getText(e.target.value)
    onChangeValue(text === '<br>' ? '' : text)
    getCaret()
  }

  const handleClickOutside = (e) => {
    if (!inputRootRef.current.contains(e.target)) {
      setMention((prev) => ({ ...prev, users: [] }))
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    if (outRef) {
      outRef.current = inputRef.current
    }
    inputRef.current.addEventListener('mouseup', getCaret)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      inputRef.current.addEventListener('mouseup', getCaret)
    }
  }, [])

  useEffect(() => {
    if (!isNil(value)) {
      setHTML(getHTML(value))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setCaret()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html])

  useEffect(() => {
    if (mention.text) {
      debounceFetchMentions(mention.text)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mention.text])

  useEffect(() => {}, [mention.users])

  const debounceFetchMentions = useMemo(
    () =>
      debounce((text) => {
        fetchMentions(text).then((data) => {
          setMention((prev) => ({ ...prev, users: data }))
        })
      }, 200),
    [],
  )

  const getCaret = () => {
    const range = window.getSelection().getRangeAt(0)
    const preSelectionRange = range.cloneRange()
    preSelectionRange.selectNodeContents(inputRef.current)
    preSelectionRange.setEnd(range.startContainer, range.startOffset)
    const textBeforeCursor = preSelectionRange.toString()
    textBeforeCursorRef.current = textBeforeCursor
  }

  const setCaret = () => {
    try {
      const range = document.createRange()
      const sel = window.getSelection()
      const textBeforeCursor = textBeforeCursorRef.current
      const childNodes = inputRef.current.childNodes
      let childIndex = 0
      let offset = 0
      let textBeforeCursorLength = textBeforeCursor.length
      for (let i = 0; i < childNodes.length; i++) {
        const childLength = childNodes[i].textContent.length
        if (childLength < textBeforeCursorLength) {
          textBeforeCursorLength -= childLength
          childIndex++
        } else {
          offset = textBeforeCursorLength
          break
        }
      }
      if (isSelectMention) {
        setIsSelectMention(false)
        childIndex++
        offset = 1
      }
      const child = childNodes[childIndex]
      range.setStart(child?.firstChild ?? child, offset)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
      inputRef.current.focus()
      const childContent = child?.textContent ?? ''
      if (searchRegex.test(childContent)) {
        setMention({
          index: childIndex,
          text: childContent.slice(1),
          users: [],
        })
      } else {
        setMention({
          index: -1,
          text: '',
          users: [],
        })
      }
    } catch (e) {}
  }

  const onSelectEmoji = (emoji) => {
    console.log(textBeforeCursorRef.current, value)
    onChangeValue(
      `${textBeforeCursorRef.current}${emoji}${value.slice(
        textBeforeCursorRef.current.length,
      )}`,
    )
    textBeforeCursorRef.current = `${textBeforeCursorRef.current}${emoji}`
  }

  const onPaste = (e) => {
    const text = (e.originalEvent || e).clipboardData.getData('text/plain')
    const html = getHTML(text)
    if (document.queryCommandSupported('insertHTML')) {
      document.execCommand('insertHTML', false, html)
    } else {
      document.execCommand('paste', false, html)
    }
  }

  const onKeyUp = (e) => {
    if (e.key.includes('Arrow')) {
      getCaret()
    }
  }

  const onSelectUser = (idx) => {
    const childNodes = inputRef.current.childNodes
    const user = mention.users[idx]
    const newText = Array.from(childNodes).reduce((acc, node, nodeIdx) => {
      //get HTML of node
      if (mentionRegex.test(node.outerHTML)) {
        return `${acc}${node.outerHTML}`
      }
      if (nodeIdx === mention.index) {
        return `${acc}<span class="mention" data-id="${user.id}">${user.displayName}</span>&nbsp;`
      }
      return `${acc}${node.textContent}`
    }, '')
    onChangeValue(getText(newText))
    setIsSelectMention(true)
  }

  return (
    <div className={styles['input-wrap']}>
      <div className={styles[`emoji-input`]} ref={inputRootRef}>
        <div className={styles[`placeholder`]}>{placeholder ?? ''}</div>
        <ContentEditable
          innerRef={inputRef}
          className={`${styles[`input`]} ${
            isShowEmojiPicker ? styles['emoji'] : ''
          }`}
          html={html}
          onChange={onInput}
          onPaste={onPaste}
          onKeyUp={onKeyUp}
        />
        {!!mention.users.length &&
          inputRef.current?.childNodes[mention.index] &&
          createPortal(
            <div className={styles['mention__list']}>
              {mention.users.map((user, idx) => (
                <div
                  className={styles['mention__item']}
                  key={user.id}
                  onClick={() => onSelectUser(idx)}
                >
                  <img src={user.profileImage} alt='' />
                  <span>{user.displayName}</span>
                </div>
              ))}
            </div>,
            inputRootRef.current,
          )}
      </div>
      <div className={styles[`comment-action`]}>
        {isShowEmojiPicker && <EmojiSelect onSelect={onSelectEmoji} />}
        {isShowImagePicker && (
          <Tooltip
            title={t('common:Add photos or videos less than 60mb')}
            place='left'
          >
            <div>
              <ImagePicker
                acceptVideo
                onSaveImage={onSaveImage}
                icon='media'
                component={<div className={styles['icon-media']} />}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default memo(InputForm)
