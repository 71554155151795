import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, signOut, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'

const deleteAccount = createAsyncThunk(
  'settings/deleteAccount',
  async (_payload, { dispatch }) => {
    dispatch(toggleLoading())
    try {
      await axios.post(`/user/api/v1/users/disable`)
      return dispatch(signOut())
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default deleteAccount
