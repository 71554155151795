import { PROFILE_TYPE } from 'commons/constants'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

export const fetchMentions = async (key) => {
  try {
    const response = await axios.get(
      `/activities/api/v1/mentions?key=${key || ''}`,
    )
    return response.data.data
  } catch {
    return []
  }
}

const subPathMapping = {
  [PROFILE_TYPE.USER]: ['user/api', 'users'],
  [PROFILE_TYPE.PET]: ['pets/api', 'pets'],
  [PROFILE_TYPE.LITTER]: ['pet/api', 'pets'],
  [PROFILE_TYPE.COMPANY]: ['api', 'companies'],
}

export const changeProfileStatus = async ({ type, id, status }) => {
  try {
    const [subPath1, subPath2] = subPathMapping[type]
    await axios.patch(
      `/${subPath1}/v1/${subPath2}/${id}/profile-statuses/${status.toLowerCase()}`,
    )
    return status
  } catch (error) {
    throw getErrorMessage(error)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchMentions,
  changeProfileStatus,
}
