import React from 'react'
import * as styles from './styles.module.scss'

const BlackSpinner = () => (
  <div className={styles['loader']}>
    <div className={`${styles['loader-inner']} ${styles['one']}`}></div>
    <div className={`${styles['loader-inner']} ${styles['two']}`}></div>
    <div className={`${styles['loader-inner']} ${styles['three']}`}></div>
  </div>
)

export default React.memo(BlackSpinner)
