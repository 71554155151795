import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { ERROR_DEFAULT } from 'commons/utils/error_messages_var'

const acceptRequest = createAsyncThunk(
  'following/acceptRequest',
  async (payload, { dispatch, rejectWithValue }) => {
    const { id, eventId } = payload
    dispatch(toggleLoading())
    try {
      await axios.put(`/activities/api/v2/follows/${eventId}/accept`)
      return id
    } catch (error) {
      return rejectWithValue(dispatch(addMessageToUser(ERROR_DEFAULT)))
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default acceptRequest
