import { createAsyncThunk } from '@reduxjs/toolkit'
import { POST_TYPE } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import get from 'lodash/get'

const fetchCompanyPosts = createAsyncThunk(
  'companyProfile/fetchCompanyPosts',
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState()
      const posts = get(state, 'companyProfileReducer.posts')
      const { offset, limit } = posts
      const res = await axios.get(`/activities/api/v1/posts/list`, {
        params: {
          offset,
          limit,
          type: POST_TYPE.COMPANY,
          id: payload,
        },
      })
      return res.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
    }
  },
)

export default fetchCompanyPosts
