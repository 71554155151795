import React, { useState, useEffect } from 'react'
import * as styles from './popup_form.module.scss'
import { Modal } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { closePopupForm } from '../../slice'

const buildFormState = (data) => {
  if (data.length === 0) {
    return {}
  }

  return data.reduce((acc, curr) => {
    //TODO -- handle more data type
    return (acc[curr.fieldName] = curr.type === 'text' ? '' : '')
  }, {})
}

const PopupForm = (props) => {
  const { onSubmit, formData, initValue, buttonText } = props
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    ...buildFormState(formData),
    ...initValue,
  })

  // It takes a while until we get the initValue back from server. At first
  // initValue is undefined and later it changes to the actual value. We need
  // to update state when that happens.
  useEffect(() => {
    setForm((form) => ({ ...form, ...initValue }))
  }, [initValue])

  const modalShown = useSelector((state) => state.appReducer?.isPopupFormShown)

  const onInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    onSubmit(form)
    dispatch(closePopupForm())
  }

  const onClose = (e) => {
    dispatch(closePopupForm())
    setForm({ ...buildFormState(formData), ...initValue })
  }

  return (
    <div className={styles[`popup_form`]}>
      <Modal
        className={styles[`form-modal`]}
        open={modalShown}
        onClose={onClose}
      >
        <form onSubmit={onFormSubmit} className={styles[`form`]}>
          {formData.map((inp) => (
            <div key={inp.fieldName} className={styles[`form-input`]}>
              <textarea
                name={inp.fieldName}
                value={form[inp.fieldName]}
                type={inp.type}
                onChange={onInputChange}
                rows='10'
              />
            </div>
          ))}

          <div className={styles[`action-buttons`]}>
            <button
              type='submit'
              className={`${styles[`button`]} ${styles[`button--update`]}`}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}
export default PopupForm
