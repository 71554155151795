const fi = {
  'Company name': 'Yrityksen virallinen nimi',
  'Organization type': 'Organisaation tyyppi',
  nonprofit: 'voittoa tavoittelematon ',
  'privately held': 'yksityinen omistus',
  'public company': 'pörssiyhtiö',
  'sole proprietorship': 'yksityinen elinkeinonharjoittaja',
  other: 'muu',
  'Select industry': 'Valitsetoimiala',
  natureOfBusinesses: 'Liiketoiminta',
  manufacturers: 'Valmistaja',
  Agent: 'Agentti',
  'Animal blogger & expert': 'Eläinbloggaaja ja asiantuntija',
  'Animal Boarding kennel': 'Eläintenhoitopaikka',
  'Animal talent agency': 'Eläinten lahjakkuustoimisto',
  'Animal toy maker': 'Eläinten lelujen valmistaja',
  'Animal trainer': 'Eläinten kouluttaja',
  'Animal whisperer': 'Eläimen kuiskaaja',
  'Aquarium shop': 'Akvaariokauppa',
  'At-home boarding service operator': 'Lemmikin kotihoitopaikka',
  'Bed/housing designer': 'Sänky/peti suunnittelija',
  'Behavioral specialist': 'Käyttäytymisen asiantuntija',
  'Boutique pet shop': 'Boutique-eläinkauppa',
  'Boutique pet toy store': 'Boutique lemmikkieläinten lelukauppa',
  Carshop: 'Autokauppa',
  'Cat cafe operator': 'Kissakahvilan pitäjä',
  'Cat influencer': 'Kissavaikuttaja',
  'Cat toilet trainer': 'Kissan wc kouluttaja',
  'Collar and leash maker': 'Kaulapanta ja talutin valmistaja',
  'Collar designer': 'Panta suunnittelija',
  Consultant: 'Konsultti',
  'Custom pet portrait artist': 'Lemmikkimuotokuvataiteilija',
  'Delivery service provider': 'Toimituspalvelun tarjoaja',
  'Design pet beds manufacturer ': 'Design lemmikkisänkyjen valmistaja',
  Distributor: 'Tukkumyyjä',
  'Dog and cat boarding': 'Koirien ja kissajen täysihoito',
  'Dog bathing': 'Koiran pesupalvelu',
  'Dog beer brewer': 'Koiran olutpanimo',
  'Dog influencer': 'Koira vaikuttaja',
  'Dog manicurist': 'Koiran manikyyri',
  'Dog show organized': 'Koiranäyttely järjestetty',
  'Dog sports competition organizer': 'Koiraurheilukilpailujen järjestäjä',
  'Dog trainer': 'Koiran kouluttaja',
  'Dog trainer for hunting': 'Koirankouluttaja metsästykseen',
  'Dog trainer for security': 'Koiran kouluttaja turvallisuuteen',
  'Dog training centre': 'Koiran koulutuskeskus',
  'Dog walker': 'Koiranulkoiluttaja',
  'Dog whisperer': 'Koirakuiskaaja',
  'Dog workout coach': 'Koiran treenivalmentaja',
  'Doggy Daycare': 'Koiran päiväkoti',
  'Fish tank designer': 'Akvaariosuunnittelija',
  'Fur dying specialist': 'Turkkien kuivaamisen asiantuntija',
  'Furniture designer': 'Huonekalujen suunnittelija',
  'Grooming services': 'Trimmauspalvelut',
  'Guard dog security service provider': 'Vartiokoiran turvapalvelun tarjoaja',
  'Home made pet food manufacturer':
    'Kotitekoinen lemmikkieläinruoan valmistaja',
  'Home pet monitor expert': 'Kotieläinten valvonta-asiantuntija',
  Hotel: 'Hotelli',
  'Importer & distributor': 'Maahantuoja ja tukkumyyjä',
  'In home cleaner': 'Kotisiivooja',
  Influrencer: 'Vaikuttaja',
  'Kennel cleaning provider': 'Kennelin siivouspalvelu',
  'Large animal sitter': 'Isojen eläintenhoitaja',
  'Luxury boarding service operator': 'Ylellisen hoitopalvelun tarjoaja',
  'Maker of breath mints for dogs': 'Hengitysminttujen valmistaja koirille',
  'Manufacturer private label': 'Valmistaja omalle tuotemerkille',
  'Marketing & communication': 'Markkinointi- ja viestintätoimisto ',
  'Mobile dog grooming': 'Mobiili koirien trimmaus',
  'Mobile dog training': 'Mobiilikoiran koulutus',
  'Mobile pet clinic': 'Mobiili lemmikkiklinikka',
  'Obedience expert': 'Tottelevaisuuden asiantuntija',
  'Online shop': 'Nettikauppa',
  'Online pet expert': 'Online lemmikkiasiantuntija',
  'Organic treat maker': 'Luomuherkkujen valmistaja',
  Organization: 'Organisaatio',
  'Pet accessories designer': 'Lemmikkitarvikkeiden suunnittelija',
  'Pet ambulance': 'Lemmikkieläinten ambulanssi',
  'Pet bakery': 'Lemmikkileipomo',
  'Pet bandana maker': 'Lemmikkien huivivalmistaja',
  'Pet boarding kennel': 'Lemmikkien taysihoito palvelu',
  'Pet boutique': 'Lemmikkiputiikki',
  'Pet brand manager': 'Lemmikkieläinbrändin tuotepäällikkö',
  'Pet business marketing': 'Lemmikkiyrityksen markkinointi',
  'Pet business marketing specialist':
    'Lemmikkiyritysmarkkinoinnin asiantuntija',
  'Pet cafe operator': 'Lemmikkikahvilan pitäjä',
  'Pet clothing designer': 'Lemmikkien vaatteiden suunnittelija',
  'Pet coffins and urns': 'Lemmikkien arkut ja uurnat',
  'Pet day care': 'Lemmikkieläinten päivähoito',
  'Pet event organizer': 'Lemmikkitapahtuman järjestäjä',
  'Pet event planner': 'Lemmikkieläinten tapahtumasuunnittelija',
  'Pet food truck owner': 'Lemmikkieläinten ruokarekan omistaja',
  'Pet franchise': 'Lemmikkien franchising',
  'Pet friendly hotel': 'Lemmikkiystävällinen hotelli',
  'Pet friendly restaurant': 'Lemmikkiystävällinen ravintola',
  'Pet funeral provider ': 'Lemmikkieläinten hautajaisten palvelun tarjoaja',
  'Pet health expert': 'Lemmikkieläinten terveyden asiantuntija',
  'Pet hotel': 'Lemmikkihotelli',
  'Pet influencer': 'Lemmikkivaikuttaja',
  'Pet insurance company': 'Lemmikkieläinten vakuutusyhtiö',
  'Pet marketing': 'Lemmikkieläinten markkinointi',
  'Pet marketing ecommerce': 'Lemmikkimarkkinointi, verkkokauppa',
  'Pet massage therapy': 'Lemmikkieläinten hierontaterapia',
  'Pet momager': 'Lemmikkieläinten hoitaja',
  'Pet movie producer': 'Lemmikkielokuvan tuottaja',
  'Pet nutritionist': 'Lemmikkieläinten ravitsemusterapeutti',
  'Pet photographer': 'Lemmikkikuvaaja',
  'Pet physigal terapist': 'Lemmikkieläinten fyysioterapeutti',
  'Pet pilow creator': 'Lemmikkien tyynyjen suunnittelika',
  'Pet press': 'Lemmikkilehdistö',
  'Pet related radio talk show': 'Lemmikkieläinten keskusteluohjelma',
  'Pet related tv show': 'Lemmikkieläinten tv-ohjelma',
  'Pet restaurant': 'Lemmikkiravintola',
  'Pet retailer': 'Lemmikkieläinten jälleenmyyjä',
  'Pet sharing service operator': 'Lemmikkieläinten lainapalvelun tarjoaja',
  'Pet shelter': 'Lemmikkieläinten turvakoti',
  'Pet shop with lifestock': 'Eläinkauppa, jossa on lemmikkejä myynnissä',
  'Pet sitter': 'Lemmikinhoitaja',
  'Pet spa operator': 'Lemmikkieläinkylpylä',
  'Pet supply store': 'Lemmikkieläintarvikekauppa',
  'Pet taxi': 'Lemmikkitaksi',
  'Pet toy designer': 'Lemmikkilelujen suunnittelija',
  'Pet transportation': 'Lemmikkieläinten kuljetuspalvelu',
  'Pet travel agency': 'Lemmikkieläinten matkatoimisto',
  'Pet treat subscription service provider':
    'Lemmikkieläinten hoitojen tilauspalvelun tarjoaja',
  'Pet treats bakery': 'Lemmikkien herkkuleipomo',
  'Pet-portrait': 'Lemmikki-muotokuva',
  Petshop: 'Lemmikkikauppa',
  Petsitting: 'Lemmikkieläinten hoito',
  'Pooch couture': 'Pooch couture',
  'Poop scooping': 'Kakan kauhaaminen',
  Press: 'Lehdistö',
  'Publisher of pet magazines': 'Lemmikkilehtien kustantaja',
  'Puppy training courses': 'Pentujen koulutuskurssit',
  'Renter of unusual animals': 'Epätavallisten eläinten vuokraaja',
  'Rescue organization': 'Rescue - organisaatio',
  'Retail chain': 'Jälleenmyynti',
  'Saler of breath mints for dogs': 'Hengitysminttujen myynti koirille',
  'Shelter matching expert': 'Turvakotien asiantuntija',
  'Social media influencer': 'Sosiaalisen median vaikuttaja',
  'Social media pet person': 'Sosiaalisen median lemmikkivaikuttaja',
  'Supplier pet industry': 'Toimittaja lemmikkiteollisuus',
  'Tag engraver': 'Tagin kaivertaja',
  'Trade show organizer': 'Messujen järjestäjä',
  'T-shirt designer': 'T-paidan suunnittelija',
  Veterinarian: 'Eläinlääkäri',
  'Virtual pet trainer': 'Virtuaalinen lemmikkikouluttaja',
  Wholesaler: 'Tukkukauppias',
  'Yard cleaner': 'Pihan siivooja',
  'YouTube training expert': 'YouTuben koulutusasiantuntija',
  Other: 'Muut',
  Accessories: 'Tarvikkeet',
  'Aquarium products': 'Akvaariotuotteet',
  'Bird accessories': 'Lintujen tarvikkeet',
  'Bird food': 'Lintujen ruokaa',
  'Bird healthcare products': 'Lintujen terveydenhoitotuotteet',
  'Bird toys': 'Linnunlelut',
  Birds: 'Linnut',
  Cages: 'Häkit',
  'Cat accessories': 'Kissan tarvikkeet',
  'Cat food': 'Kissanruoka',
  'Cat healthcare products': 'Kissan terveydenhoitotuotteet',
  'Cat litter': 'Kissanhiekka',
  'Cat snacks & treats': 'Kissan välipalat ja herkut',
  'Cat toys': 'Kissanlelut',
  'Cat vet food': 'Kissanmärkäruoka',
  'Dog accessories': 'Koiran tarvikkeet',
  'Dog food': 'Koiran ruoka',
  'Dog healthcare products': 'Koiran terveydenhoitotuotteet',
  'Dog snacks &treats': 'Koiran välipalat & herkut',
  'Dog toys': 'Koiranlelut',
  'Equipment & machinery': 'Laitteet & koneet',
  'Feed additives': 'Rehujen lisäaineet',
  Fish: 'Kalat',
  'Fish food': 'Kalanruoka',
  'Fish healthcare products': 'Kalan terveydenhoitotuotteet',
  'Grooming products': 'Hoitotuotteet',
  'Horse feed': 'Hevosen rehu',
  'Horse products': 'Hevonen tuotteet',
  'Kennels and houses': 'Kennelit ja talot',
  Livestock: 'Kotieläin',
  'Packaging materials': 'Pakkausmateriaalit',
  Plants: 'Kasvit',
  'Protein sources': 'Proteiinin lähteet',
  'Reptile & terrarium products':
    'Matelijoille ja terraarioille tarkoitettuja tuotteita',
  'Reptile food': 'Matelijoiden ruoka',
  'Rodents toys': 'Jyrsijälelut',
  'Small animal accessories': 'Pieneläintarvikkeita',
  'Small animal food': 'Pieneläinruoka',
  'Small animal healthcare products': 'Pieneläinten terveydenhoitotuotteet',
  'Small animal toys': 'Pieneläinlelut',
  Toys: 'Lelut',
  'Company size': 'Yrityksen koko',
  employees: 'työntekijää',
  'Add a country': 'Lisää maa',
  'Company ID': 'Company ID',
  'Company VAT number': 'Company VAT number',
  'Website URL': 'Kotisivujen osoite',
  City: 'Kaupunki',
  Brands: 'Tuotemerkit',
  'Here you can tell more about your organization':
    'Tässä voit kertoa enemmän organisaatiostasi',
  'I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages.':
    'Vahvistan, että olen tämän organisaation valtuutettu edustaja ja minulla on oikeus toimia sen puolesta tämän sivun luomisessa ja hallinnassa. Organisaatio ja minä hyväksymme sivujen lisäehdot.',
  'Read the Pezzie terms of Service and Privacy Policy':
    'Lue käyttöehdot ja tietosuojaseloste',
  'Additional information': 'Lisää tarkemmat tiedot',
  'Headquarters location': 'Pääkonttorin sijaintimaa',
}

export default fi
