import React, { useEffect, useRef, useState } from 'react'
import * as styles from './action_button.module.scss'

const ActionButton = ({
  onOptionClick,
  onClose,
  position,
  options,
  buttonSize,
  backgroundWhite,
  tr,
  actionIcon,
  children,
}) => {
  const ref = useRef()
  const [extended, setExtended] = useState(false)

  useEffect(() => {
    if (extended) {
      document.documentElement.addEventListener('click', handleClickBody)
    }
    return () => {
      document.documentElement.removeEventListener('click', handleClickBody)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extended])

  const onToggleContent = () => {
    setExtended(!extended)
  }

  const onClick = (avail) => {
    onOptionClick(avail)
    onToggleContent()
  }

  const handleClickBody = (e) => {
    if (!ref.current.contains(e.target) && extended) {
      onClose?.()
      onToggleContent()
    }
  }

  return (
    <div
      style={{ ...position, ...buttonSize }}
      className={`${styles[`action-button`]} action-button`}
      ref={ref}
    >
      {children ? (
        <div onClick={onToggleContent}>{children}</div>
      ) : (
        <div
          style={{ ...buttonSize }}
          onClick={onToggleContent}
          className={`${
            backgroundWhite
              ? `${styles[`button--open`]} ${styles[`button--open--white`]}`
              : `${styles[`button--open`]}`
          }  action-button__icon`}
        >
          {actionIcon && <img src={actionIcon} alt='action-icon' />}
        </div>
      )}

      <div
        className={`${styles[`content`]} ${
          extended ? styles[`content--open`] : ''
        }`}
      >
        <div className={styles[`options`]}>
          {options &&
            options.length > 0 &&
            options.map((opt) => (
              <div
                key={opt.text}
                onClick={() => onClick(opt.value)}
                className={styles[`option`]}
              >
                <p>
                  {tr ? tr(opt.text) : opt.text}{' '}
                  {opt.icon && (
                    <span
                      className={`${styles[`icon`]} ${
                        styles[`icon--${opt.icon}`]
                      }`}
                    />
                  )}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ActionButton)
