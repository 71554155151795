const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx = canvas.getContext('2d')

  ctx.fillStyle = '#ffffff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.save()
  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate(rotation * (Math.PI / 180))
  ctx.translate(-canvas.width / 2, -canvas.height / 2)

  var rotPixelCrop

  switch (rotation) {
    case 90:
      rotPixelCrop = {
        x: pixelCrop.y,
        y: image.height - pixelCrop.width - pixelCrop.x - 1,
        width: pixelCrop.height,
        height: pixelCrop.width,
      }
      break
    case 180:
      rotPixelCrop = {
        x: image.width - pixelCrop.width - pixelCrop.x - 1,
        y: image.height - pixelCrop.height - pixelCrop.y - 1,
        width: pixelCrop.width,
        height: pixelCrop.height,
      }
      break
    case 270:
      rotPixelCrop = {
        x: image.width - pixelCrop.height - pixelCrop.y - 1,
        y: pixelCrop.x,
        width: pixelCrop.height,
        height: pixelCrop.width,
      }
      break
    default:
      rotPixelCrop = {
        x: pixelCrop.x,
        y: pixelCrop.y,
        width: pixelCrop.width,
        height: pixelCrop.height,
      }
      break
  }

  ctx.drawImage(
    image,
    rotPixelCrop.x,
    rotPixelCrop.y,
    rotPixelCrop.width,
    rotPixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  )
  // As Base64 string

  return canvas.toDataURL('image/jpeg')

  // As a blob
  // return new Promise((resolve, reject) => {
  //     canvas.toBlob(file => {
  //         resolve(URL.createObjectURL(file))
  //     }, 'image/jpeg')
  // })
}
