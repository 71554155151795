import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const NAMESPACE = 'VALIDATABLE_INPUT'

const en = {
  INVALID_TEXT_WORD: 'Input field should not contain whitespace',
  INVALID_TEXT_LINE: 'Input field should not contain newline',
  INVALID_NUMBER_INT: 'Input must be an integer number',
  INVALID_NUMBER_FLOAT: 'Input must be a float number',
  INVALID_DATE: 'Input must be in YYYY-MM-DD format',
  INVALID_MAX_VALUE: 'Input is greater than maximum limitation of ',
  INVALID_MIN_VALUE: 'Input is less than minimum limitation of ',
  INVALID_MAX_LENGTH: 'Input length is greater than maximum limitation of ',
  INVALID_MIN_LENGTH: 'Input length is less than minimum limitation of ',
  INVALID_CONTACT_PHONE:
    'Phone number should only contain "+","-" and numbers. "+" only allowed as prefix. Use "-" as separator. There must be at least one number',
  CHARACTER: ' characters',
}

const fi = {
  INVALID_TEXT_WORD: 'Syöte ei saa sisältää välilyöntejä',
  INVALID_TEXT_LINE: 'Syöte ei saa sisältää rivinvaihtoja',
  INVALID_NUMBER_INT: 'Syötteen on oltava kokonaisluku',
  INVALID_NUMBER_FLOAT: 'Syötteen on oltava desimaaliluku',
  INVALID_DATE: 'Syötteen on oltava muodossa VVVV-KK-PP',
  INVALID_MAX_VALUE: 'Syöte on suurempi kuin suurin sallittu arvo ',
  INVALID_MIN_VALUE: 'Syöte on pienempi kuin pienin sallittu arvo ',
  INVALID_MAX_LENGTH: 'Syötteen pituus on suurempi kuin suurin sallittu arvo: ',
  INVALID_MIN_LENGTH: 'Syötteen pituus on pienempi kuin pienin sallittu arvo: ',
  INVALID_CONTACT_PHONE:
    'Puhelinnumero voi sisältää ainoastaan "+", "-" ja numeroita. "+" saa käyttää vain etumerkkinä. Käytä "-" erottajana. Syötä vähintään yksi numero.',
  CHARACTER: ' kirjainta',
}

if (!i18n.hasResourceBundle)
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {},
      keySeparator: false, // we use content as keys
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    })

i18n.addResourceBundle('en', NAMESPACE, en)
i18n.addResourceBundle('fi', NAMESPACE, fi)

export default i18n
