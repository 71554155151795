import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getErrorMessage } from 'commons/utils'

const PAGE_SIZE = 20

const fetchLitters = createAsyncThunk(
  'main/fetchUserLitters',
  async (payload, { rejectWithValue, getState }) => {
    const { litterReducer } = getState()
    const { litterOffset } = litterReducer
    try {
      const res = await axios.get(`/pets/api/v1/pets/litters`, {
        params: {
          offset:
            typeof payload.offset === 'number' ? payload.offset : litterOffset,
          limit: PAGE_SIZE,
          sort: payload.sort || 'desc',
          sortField: payload.sortField || 'createdDate',
          userId: payload.userId,
        },
      })
      return res.data.data
    } catch (error) {
      return rejectWithValue(getErrorMessage(error))
    }
  },
)

export default fetchLitters
