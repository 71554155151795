import { createAsyncThunk } from '@reduxjs/toolkit'
import service from 'services/post'

const deleteCompanyPost = createAsyncThunk(
  'companyProfile/deleteCompanyPost',
  async (postId, { rejectWithValue }) => {
    try {
      await service.deletePost(postId)
      return postId
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export default deleteCompanyPost
