import emojiStyles from 'commons/scss/emoji.module.scss'

import Angry from 'commons/resources/emojis/ANGRY.png'
import Care from 'commons/resources/emojis/CARE.png'
import Launch from 'commons/resources/emojis/LAUGH.png'
import LikeHand from 'commons/resources/emojis/LIKE_HAND.png'
import LikeLick from 'commons/resources/emojis/LIKE_LICK.png'
import Love from 'commons/resources/emojis/LOVE.png'
import Poop from 'commons/resources/emojis/POOP.png'
import SadCry from 'commons/resources/emojis/SAD_CRY.png'
import WowShock from 'commons/resources/emojis/WOW_SHOCK.png'
import isEmpty from 'lodash/isEmpty'

const emojis = {
  ':e001:': {
    k: 1,
    syntax: ':e_angry:',
    alt: ':%e_angry%:',
    name: 'Angry',
    className: 'e-angry',
    icon: Angry,
  },
  ':e002:': {
    k: 1,
    syntax: ':e_care:',
    alt: ':%e_care%:',
    name: 'Care',
    className: 'e-care',
    icon: Care,
  },
  ':e003:': {
    k: 1,
    syntax: ':e_launch:',
    alt: ':%e_launch%:',
    name: 'Launch',
    className: 'e-launch',
    icon: Launch,
  },
  ':e004:': {
    k: 1,
    syntax: ':e_like_lick:',
    alt: ':%e_like_lick%:',
    name: 'Like Lick',
    className: 'e-like-lick',
    icon: LikeLick,
  },
  ':e005:': {
    k: 1,
    syntax: ':e_love:',
    alt: ':%e_love%:',
    name: 'Love',
    className: 'e-love',
    icon: Love,
  },
  ':e006:': {
    k: 1,
    syntax: ':e_love:',
    alt: ':%e_love%:',
    name: 'Poop',
    className: 'e-poop',
    icon: Poop,
  },
  ':e007:': {
    k: 1,
    syntax: ':e_love:',
    alt: ':%e_love%:',
    name: 'Sad Cry',
    className: 'e-sad_-cry',
    icon: SadCry,
  },
  ':e008:': {
    k: 1,
    syntax: ':e_love:',
    alt: ':%e_love%:',
    name: 'Wow Shock',
    className: 'e-wow-hock',
    icon: WowShock,
  },
  ':e009:': {
    k: 1,
    syntax: ':e_love:',
    alt: ':%e_love%:',
    name: 'Like Hand',
    className: 'e-like-hand',
    icon: LikeHand,
  },
}
export const getIcon = (emoji) => {
  return `<img class="${emojiStyles[`emoji`]} ${
    emojiStyles[emoji.className]
  }" src="${emoji.icon}" alt="${emoji.alt.toString()}">`
}

export const hashtagRegex = /#([a-zA-Z0-9_]+)/g

export const urlRegex = /((?:https?:\/\/|www\.)[^\s]+)/g

export const searchRegex = /@(\w+(?:\s+|&nbsp;+|\w+){0,4})\b/g

export const spanExceptMentionRegex =
  /<span(?![^>]*\bclass\s*=\s*["']?\bmention\b["']?)[^>]*>(.*?)<\/span>/g
export const mentionRegex =
  /<span class="mention" data-id="(\d+)">([^<]+)<\/span>/g

export const getHTML = (text, supportLink = false) => {
  if (!text) return ''
  Object.keys(emojis).forEach((key) => {
    const emoji = emojis[key]
    const icon = getIcon(emoji)
    text = text.replaceAll(key, icon)
  })

  text = text.replace(hashtagRegex, '<span class="hashtag">#$1</span>')
  text = text.replace(
    urlRegex,
    supportLink
      ? '<a href="$1" target="_blank">$1</a>'
      : '<span class="link">$1</span>',
  )
  text = text.replace(searchRegex, '<span>@$1</span>')
  text = text.replaceAll('\n', '<br>')
  return text
}

export const getText = (html) => {
  if (!html?.replaceAll) return ''
  Object.keys(emojis).forEach((key) => {
    const emoji = emojis[key]
    const icon = getIcon(emoji)
    html = html.replaceAll(icon, key)
  })
  html = html.replace(spanExceptMentionRegex, '$1')
  html = html.replaceAll('<br>', '\n')
  return html
}

export const getContentWithMention = (content, mentions) => {
  if (isEmpty(mentions)) {
    return getHTML(content, true)
  }
  let diff = 0
  return getHTML(
    mentions.reduce((acc, mention) => {
      const replaceText = `<a class="mention" href="/user/${mention.userId}">${mention.displayName}</a>`
      const startIndex = mention.startIndex + diff
      const endIndex = mention.endIndex + diff
      const before = acc.slice(0, startIndex)
      const after = acc.slice(endIndex)
      diff += replaceText.length - (endIndex - startIndex)
      return `${before}${replaceText}${after}`
    }, content),
    true,
  )
}

export const getContentWithoutMention = (content) => {
  const mentions = []
  let diff = 0
  if (mentionRegex.test(content)) {
    content = content.replace(mentionRegex, (match, id, name, index) => {
      mentions.push({
        userId: parseInt(id, 10),
        displayName: name,
        startIndex: index - diff,
        endIndex: index + name.length - diff,
      })
      diff += match.length - name.length
      return name
    })
  }
  return { content, mentions }
}

export default emojis
