import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { getDeviceInfo } from 'commons/utils/getDeviceInfo'

const signOut = createAsyncThunk('app/signOut', async (_payload) => {
  const payload = {
    deviceInfo: getDeviceInfo(),
    token: localStorage.getItem('token'),
  }
  try {
    if (localStorage.getItem('token'))
      await axios.put('/user/api/v1/users/logout', payload)
  } catch (error) {}
})

export default signOut
