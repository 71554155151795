import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'commons/http/axios'
import { toggleLoading } from 'commons/slice'

const PAGE_SIZE = 10

const fetchFollowRequests = createAsyncThunk(
  'following/fetchFollowRequests',
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(toggleLoading())
    const {
      followingReducer: { requestOffset },
    } = getState()
    try {
      const result = await axios.get(`/activities/api/v2/follows/requesting`, {
        params: {
          offset: requestOffset,
          limit: PAGE_SIZE,
        },
      })
      return result.data.data
    } catch (error) {
      return []
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default fetchFollowRequests
