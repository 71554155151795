import { createAsyncThunk } from '@reduxjs/toolkit'
import { UPLOAD_ACTION } from 'commons/constants'
import axios from 'commons/http/axios'
import { addMessageToUser, toggleLoading } from 'commons/slice'
import { getErrorMessage } from 'commons/utils'
import { uploadFiles } from 'services/fileManagement'

const updatePersonnelProfile = createAsyncThunk(
  'personnelProfile/updatePersonnelProfile',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    dispatch(toggleLoading())
    try {
      const {
        personnelProfileReducer,
        appReducer: { currentUser },
      } = getState()
      const {
        id,
        // profile,
        // background,
        companyId,
        ...personnelProfile
      } = personnelProfileReducer.personnelProfile.data
      const { image, ...newProfile } = payload
      // const updatedProfile = { ...newProfile, profile, background }
      if (image) {
        const uploadData = await uploadFiles({
          userId: currentUser.id,
          files: [image.file],
          action: UPLOAD_ACTION.POST,
          companyId,
        })
        newProfile[image.name] = uploadData[0].s3Id
        // updatedProfile[image.name] = uploadData[0].url
      }
      const result = await axios.post(`api/v1/companies/personel/${id}`, {
        ...personnelProfile,
        ...newProfile,
        id,
        companyId,
      })
      return result.data.data
    } catch (error) {
      dispatch(addMessageToUser(getErrorMessage(error)))
      return rejectWithValue()
    } finally {
      dispatch(toggleLoading())
    }
  },
)

export default updatePersonnelProfile
